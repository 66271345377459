import { Multiselect } from "multiselect-react-dropdown";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import AppCanvasLoader from "../../../../components/App/AppCanvasLoader";
import ToggleSwitch from "../../../../components/ToggleSwitch";
import * as API from "../../../../utils/api";
import { showToast } from "../../../../utils/helpers";
// Components
import Modal from "../Modal";
import Tabs from "../../../../components/Tabs";

class AddTemplateProfileModal extends Component {
  state = {
    profileName: {
      value: "",
      hasError: false,
    },
    isLoading: true,
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
    profileData: {
      methods: [],
      organizations: [],
      method_templet_list: [],
    },
    templateList1: [],
    templateList2: [],
    templateError: false,
    orgIdList: [],
    selectedOrgList: [],
    setDefault: false,
    isLoading: true,
    activeTab: "",
    newPopUpList1: [],
    newPopUpList2: [],
  };

  componentDidMount() {
    this.getPopupData();
  }

  getPopupData = () => {
    API.getAdminProfilePopupData(this.props.user).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data) {
          data.data.method_templet_list.map((method) => {
            if (method.method_id === 1) {
              this.setState({
                newPopUpList1: method.templates,
              });
            } else {
              this.setState({
                newPopUpList2: method.templates,
              });
            }
          });
          this.setData(data.data);
        }
        this.setState({ isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  setData = (respData) => {
    let { profileData } = this.state;
    if (respData.methods && respData.methods) {
      respData.methods.map((item) => {
        item.flag = false;
      });
      profileData.methods = respData.methods;
    }
    profileData.organizations = respData.organizations;
    profileData.method_templet_list = respData.method_templet_list;
    this.setState({ profileData }, () => {
      this.setDataForEdit();
    });
  };

  setDataForEdit = () => {
    let {
      profileName,
      templateList1,
      templateList2,
      profileData,
      activeTab,
      orgIdList,
      selectedOrgList,
    } = this.state;
    if (this.props.optionalProps && this.props.optionalProps.isEdit) {
      let editData = this.props.optionalProps.editProfileData;
      if (editData) {
        profileName.value = editData.name;
        let num1 = editData.method_templet_list.findIndex(
          (o) => o.method_id == 1
        );
        let num2 = editData.method_templet_list.findIndex(
          (o) => o.method_id == 2
        );
        if (num1 > -1) {
          templateList1 = editData.method_templet_list[num1].templates;
          let n1 = profileData.methods.findIndex((o) => o.id == 1);
          if (n1 > -1) {
            profileData.methods[n1].flag = true;
          }
          activeTab = 1;
        }
        if (num2 > -1) {
          templateList2 = editData.method_templet_list[num2].templates;
          let n2 = profileData.methods.findIndex((o) => o.id == 2);
          if (n2 > -1) {
            profileData.methods[n2].flag = true;
          }
          activeTab = 2;
        }
        if (editData.organizations && editData.organizations.length) {
          orgIdList = [];
          selectedOrgList = [];
          editData.organizations.map((item) => {
            orgIdList.push(item.organization_id);
            selectedOrgList.push({ name: item.name, id: item.organization_id });
          });
        }

        this.setState({
          profileName: profileName,
          profileData: profileData,
          activeTab: activeTab,
          setDefault: editData.is_default,
          templateList1: templateList1,
          templateList2: templateList2,
          selectedOrgList: selectedOrgList,
          orgIdList: orgIdList,
        });
      }
    }
  };

  handleModalClose = () => {
    this.props.hideModal();
  };

  handleNameChange = (value) => {
    this.state.profileName.value = value;
    this.state.profileName.hasError = false;
    this.setState({ profileName: this.state.profileName });
  };

  callUpdateAPI = () => {
    let { profileName, setDefault, orgIdList } = this.state;
    let pref = this.getPredObj();
    let payload = {
      id: this.props.optionalProps.editProfileData.id,
      name: profileName.value,
      is_default: setDefault,
      preferences: pref,
      organization_ids: orgIdList,
    };
    API.adminUpdateProfile(this.props.user, payload).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        this.props.updateModalState(true, "SUCCESS", {
          message: data.message,
        });
        this.props.optionalProps.reload(false, true);
        this.setState({ isLoading: false });
      } else {
        if (data && data.status_code && data.status_code == 400) {
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
          this.setState({ isLoading: false });
        } else {
          this.setState((state) => ({
            ...state,
            isLoading: false,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.status_code && data.status_code == 401 ? 401 : 500) ||
                  500
                : 500,
            },
          }));
        }
      }
    });
  };

  // object1.map(obj1 => {
  //   const match = object2.find(obj2 => obj2.slug === obj1.slug);
  //   if (match) {
  //     return { ...obj1, template_type: match.template_type };
  //   }
  //   return { ...obj1, template_type: null };
  // })

  getPredObj = () => {
    let { templateList1, templateList2, newPopUpList1, newPopUpList2 } =
      this.state;
    let data = [];
    if (templateList1 && templateList1.length) {
      let tempArray1 = [];
      tempArray1 = templateList1.map((obj1) => {
        const match = newPopUpList1.find((obj2) => obj2.slug === obj1.slug);
        if (match) {
          return { ...obj1, template_type: match.template_type };
        }
      });
      data.push({ method_id: 1, templates: tempArray1 });
    }
    if (templateList2 && templateList2.length) {
      let tempArray2 = [];
      tempArray2 = templateList2.map((obj1) => {
        const match = newPopUpList2.find((obj2) => obj2.slug === obj1.slug);
        if (match) {
          return { ...obj1, template_type: match.template_type };
        }
      });
      data.push({ method_id: 2, templates: tempArray2 });
    }
    return data;
  };

  callCreateAPI = () => {
    let { profileName, setDefault, orgIdList } = this.state;
    let pref = this.getPredObj();
    let payload = {
      name: profileName.value,
      is_default: setDefault,
      preferences: pref,
      organization_ids: orgIdList,
    };
    API.adminCreateProfile(this.props.user, payload).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        this.props.updateModalState(true, "SUCCESS", {
          message: data.message,
        });
        this.props.optionalProps.reload(false, true);
        this.setState({ isLoading: false });
      } else {
        if (data && data.status_code && data.status_code == 400) {
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
          this.setState({ isLoading: false });
        } else {
          this.setState((state) => ({
            ...state,
            isLoading: false,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.status_code && data.status_code == 401 ? 401 : 500) ||
                  500
                : 500,
            },
          }));
        }
      }
    });
  };

  validateForm = () => {
    let { profileName, templateList1, templateList2 } = this.state;
    let errorsArePresent = false;
    if (profileName.value === "") {
      errorsArePresent = true;
      profileName.hasError = true;
      this.setState({ profileName });
    }

    if (
      templateList1 &&
      templateList1.length < 1 &&
      templateList2 &&
      templateList2.length < 1
    ) {
      errorsArePresent = true;
      this.setState({ templateError: true });
    }

    if (errorsArePresent) {
      return false;
    } else {
      return true;
    }
  };

  handleSubmit = () => {
    if (this.props.optionalProps.isEdit && this.validateForm()) {
      this.callUpdateAPI();
    } else if (this.validateForm()) {
      this.callCreateAPI();
    }
  };

  onCheckboxClick = (event, i) => {
    const consumerId = event.target.name;
    let { profileData } = this.state;
    profileData.methods.map((item) => {
      if (item.name == consumerId) {
        if (event.target.checked) {
          item.flag = true;
          if (item.name == "Email") {
            this.setState({ activeTab: 1 });
          } else if (item.name == "Letter") {
            this.setState({ activeTab: 2 });
          }
        } else {
          item.flag = false;
          if (item.name == "Email") {
            this.setState({ templateList1: [] });
          } else if (item.name == "Letter") {
            this.setState({ templateList2: [] });
          }
          profileData.methods.map((i) => {
            let num = profileData.methods.findIndex((o) => o.flag === true);
            if (num > -1) {
              this.setState({ activeTab: num + 1 });
            } else {
              this.setState({ activeTab: "" });
            }
          });
        }
      }
    });
    this.setState({ profileData });
  };

  toggleButton = () => {
    this.setState({ setDefault: !this.state.setDefault });
  };

  onOrgSelect = (selectedList) => {
    let { orgIdList, selectedOrgList } = this.state;
    orgIdList = [];
    selectedOrgList = [];
    if (selectedList && selectedList.length) {
      selectedList.map((item) => {
        orgIdList.push(item.id);
        selectedOrgList.push(item);
      });
      this.setState({ orgIdList });
    } else {
      this.setState({ orgIdList: [], selectedOrgList: [] });
    }
  };

  onTemplate1Select = (selectedList) => {
    let { templateList1 } = this.state;
    templateList1 = [];
    if (selectedList && selectedList.length) {
      selectedList.map((item) => {
        templateList1.push(item);
      });
      this.setState({ templateList1, templateError: false });
    } else {
      this.setState({ templateList1: selectedList });
    }
  };

  onTemplate2Select = (selectedList) => {
    let { templateList2 } = this.state;
    templateList2 = [];
    if (selectedList && selectedList.length) {
      selectedList.map((item) => {
        templateList2.push(item);
      });
      this.setState({ templateList2, templateError: false });
    } else {
      this.setState({ templateList2: selectedList });
    }
  };

  getTemplates = (template) => {
    let { activeTab } = this.state;
    let num = template.findIndex((o) => o.method_id === activeTab);
    if (num > -1) {
      if (template[num] && template[num].templates) {
        return template[num].templates;
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  getStatusForSelection = (content) => {
    let { profileData } = this.state;
    let index = profileData.methods.findIndex(
      (o) => o.name === content && o.flag
    );
    if (index > -1) {
      return true;
    } else {
      return false;
    }
  };

  handleTabChange = (tabIndex) => {
    this.getStatusForSelection("Email");
    if (this.getStatusForSelection("Email") && tabIndex == 1) {
      this.setState({
        activeTab: tabIndex,
      });
    }
    if (this.getStatusForSelection("Letter") && tabIndex == 2) {
      this.setState({
        activeTab: tabIndex,
      });
    }
  };

  renderErrorMessage(input) {
    let message = "";
    if (input === "name") {
      message = "Please enter profile name";
    } else if (input === "template") {
      message = "Please select a template";
    }
    return <div className='input-error-message'>{message}</div>;
  }

  render() {
    let {
      profileName,
      profileData,
      error,
      setDefault,
      isLoading,
      templateError,
    } = this.state;
    const tabLabels = ["Email", "Letter"];

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }
    return (
      <Modal
        title={this.props.optionalProps.isEdit ? "Edit Profile" : "Add Profile"}
        closeOnClickOutside={true}
        hideModal={this.props.hideModal}
        style={{ maxWidth: `35em`, paddingBottom: "1em" }}
        optionalClasses='scroll task-popup-sp'
        titleClasses='modal-header'
      >
        {isLoading ? (
          <AppCanvasLoader />
        ) : (
          <div>
            <div
              className={`input-container ${
                profileName.hasError ? " error" : ""
              }`}
              style={{ paddingLeft: 0 }}
            >
              <label htmlFor='name'>Profile Name</label>
              <div>
                <input
                  name='name'
                  type='text'
                  maxLength={60}
                  placeholder='Enter Profile Name'
                  value={profileName.value}
                  onChange={(event) =>
                    this.handleNameChange(event.target.value)
                  }
                />
                {profileName.hasError ? this.renderErrorMessage("name") : null}
              </div>
            </div>
            <div className='pref-container'>
              <div>
                <label>Preferences</label>
                <div className='prof-comms-pref'>
                  {profileData &&
                  profileData.methods &&
                  profileData.methods.length
                    ? profileData.methods.map((item) => {
                        return (
                          <div className='pref-checkbox-view'>
                            <input
                              name={item.name}
                              value={item.name}
                              key={item.name}
                              type='checkbox'
                              checked={item.flag}
                              style={{
                                WebkitAppearance: `checkbox`,
                                width: `20px`,
                                marginRight: 10,
                              }}
                              onChange={() => {}}
                              onClick={(event) =>
                                this.onCheckboxClick(event, item)
                              }
                            />
                            <div>{item.name}</div>
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>
              <div>
                <label>Set Default</label>
                <div style={{ marginBottom: "2em", marginTop: 8 }}>
                  <ToggleSwitch
                    isOn={setDefault}
                    toggleButton={() => this.toggleButton()}
                  />
                </div>
              </div>
            </div>

            <Tabs
              labels={tabLabels}
              activeTarget={this.state.activeTab}
              handleTabChange={this.handleTabChange}
              optionalInTabClasses='mg-tabs-tab'
            />

            <div
              className={`input-container ${templateError ? " error" : ""}`}
              style={{ paddingLeft: 0 }}
            >
              <label htmlFor='Schedule'>Template</label>
              <div className='multi-template-list'>
                {this.state.activeTab == 1 ? (
                  <Multiselect
                    options={this.getTemplates(
                      profileData.method_templet_list,
                      1
                    )}
                    displayValue='name'
                    placeholder='Add Template'
                    onSelect={this.onTemplate1Select}
                    onRemove={this.onTemplate1Select}
                    key={this.state.activeTab}
                    selectedValues={this.state.templateList1}
                  />
                ) : this.state.activeTab == 2 ? (
                  <Multiselect
                    options={this.getTemplates(
                      profileData.method_templet_list,
                      2
                    )}
                    displayValue='name'
                    placeholder='Add Template'
                    onSelect={this.onTemplate2Select}
                    onRemove={this.onTemplate2Select}
                    key={this.state.activeTab}
                    selectedValues={this.state.templateList2}
                  />
                ) : (
                  <Multiselect
                    options={[]}
                    displayValue='name'
                    placeholder='Add Template'
                    onSelect={this.onTemplateSelect}
                    key={this.state.activeTab}
                  />
                )}
              </div>
              {templateError ? this.renderErrorMessage("template") : null}
            </div>

            <label>Assign Organizations</label>
            <div
              className={`input-container half multi-org-select`}
              style={{ marginBottom: 20 }}
            >
              <Multiselect
                options={profileData.organizations}
                displayValue='name'
                placeholder='Add Organization'
                onSelect={this.onOrgSelect}
                onRemove={this.onOrgSelect}
                selectedValues={this.state.selectedOrgList}
              />
            </div>

            <div className='modal-actions' style={{ marginBottom: "1em" }}>
              <a
                className='cta mg-brand2-color'
                onClick={(event) => this.handleSubmit(event)}
                style={{ paddingLeft: "3em", paddingRight: "3em" }}
              >
                Submit
              </a>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

export default AddTemplateProfileModal;
