import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
// Components
import AppCanvasLoader from "../../../components/App/AppCanvasLoader";
// Utils
import * as API from "../../../utils/api";
import Cookies from "universal-cookie";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

const BlueOnGreenTooltip = withStyles({
  tooltip: {
    color: "black",
    backgroundColor: "white",
    fontSize: "12px",
    fontWeight: "bold",
  },
})(Tooltip);

class ConnectToAccountView extends Component {
  state = {
    isLoading: false,
    isConnectedToQB: true,
    OrgclientData: {},
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
  };
  style = {
    pText: {
      fontSize: "14px",
      color: "#2b2828",
      margin: "0px",
    },
  };
  componentDidMount = () => {
    this.getOrganizationalDetails();
  };

  getOrganizationalDetails = () => {
    const cookies = new Cookies();
    let authuser = cookies.get("accessToken", { path: "/", secure: true });
    let user = {
      user_auth_token: authuser,
      organization_key: this.props.orgKey,
    };
    this.setState({ isLoading: true });
    if (user && user != null && authuser != null && authuser != undefined) {
      API.getOrgDetails(user).then((data) => {
        if (data && data.status_code == 200) {
          this.setState({
            OrgclientData: data.data,
            isConnectedToQB: data.data.organization.integrated_act_system === "quickbooks_online" ? false : true,
            isLoading: false,
          });
        } else {
          this.setState({ isLoading: false });
        }
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  handleAccSystemCheck = (check) => {
    this.setState((state) => ({
      ...state,
      isConnectedToQB: check,
    }));
  };

  handleSubmitToNextPage = () => {
    let { isConnectedToQB } = this.state;
    this.setState({ isLoading: true });

    API.updateAccountingSystem(this.props.user, {
      is_qb_connected: !isConnectedToQB,
    }).then((data) => {
      if (data && data.status_code == 200) {
        this.props.updateModalState(true, "SUCCESS", {
          message: data.message,
        });
        API.getAccountingSystem().then((data) => {
          if (data && data.status_code == 200) {
            if (data.data === "standalone") {
              this.props.updateModalState(true, "SUCCESS", {
                message: "You are using MakeGood as a standalone accounts receivable management system",
              });
            }
            if (isConnectedToQB) {
              this.props.handleAction("INVITE_TEAM_MEMBERS");
            } else {
              this.props.handleAction("SYNC_ACCOUNT");
            }
          } else {
            this.props.updateModalState(true, "ERROR", {
              message: data.message,
            });
          }
          this.setState({
            isLoading: false,
          });
        });
      } else {
        this.props.updateModalState(true, "ERROR", {
          message: data.message,
        });
        this.setState({ isLoading: false });
      }
    });
  };
  render() {
    const { appName } = this.props;
    const { isLoading, error, isConnectedToQB } = this.state;

    return isLoading ? (
      <div style={{ marginTop: "20%", marginLeft: "40%" }}>
        <AppCanvasLoader />
      </div>
    ) : (
      <div
        className={
          this.state.isRenderQb
            ? "consumer-help-qbsync"
            : "consumer-help" + " mg-wt-100"
        }
      >
        <Helmet title={appName + " | QuickBooks Preferences"}></Helmet>
        <div className="small-device-qbview tp-sps setting-side-canvas col1-scroll scrollbar mg-small-device-qb">
          <div
            className="mg-wt-100"
            style={{ backgroundColor: "white", padding: "20px" }}
          >
            <div
              className="mg-qb-header"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <span style={{ flex: "1" }}>
                <h3
                  style={{
                    fontWeight: "bold",
                    fontFamily: "Open Sans ,sans-serif",
                    color: "#000000",
                  }}
                  className="mg-steps-header"
                >
                  Connect to an Accounting System
                </h3>{" "}
              </span>
            </div>
            <div>
              <h7 className={"subheader-desc-div"}>
                Use MakeGood as a standalone accounts receivable management
                system or connect to your Accounting system.
              </h7>
            </div>
            <div className="main_step5_main_div">
              <div
                style={{
                  display: "flex",
                  paddingTop: "10px",
                  flexWrap: "wrap",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <input
                    type="radio"
                    name="accSystem"
                    id='use_makegood_as_stand_alone'
                    style={{
                      height: "10px",
                      width: "15px",
                      appearance: "auto",
                    }}
                    onClick={() => {
                      this.handleAccSystemCheck(true);
                    }}
                    checked={isConnectedToQB}
                  />
                  <span
                    style={{
                      fontFamily: "Open Sans",
                      fontWeight: "bold",
                      fontSize: "18px",
                      marginLeft: "18px",
                      marginRight: "18px",
                    }}
                  >
                    Use MakeGood as a Standalone AR Management System
                  </span>
                  <BlueOnGreenTooltip
                    title={
                      "You will be able to connect to Accounting system later, if desired"
                    }
                  >
                    <InfoIcon
                      style={{
                        width: "20px",
                        height: "20px",
                        color: "#0ab894",
                      }}
                    />
                  </BlueOnGreenTooltip>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <input
                    type="radio"
                    id='connect_to_quickbook_online'
                    name="accSystem"
                    style={{
                      height: "10px",
                      width: "15px",
                      appearance: "auto",
                    }}
                    onClick={() => {
                      this.handleAccSystemCheck(false);
                    }}
                    checked={!isConnectedToQB}
                  />
                  <span
                    style={{
                      fontFamily: "Open Sans",
                      fontWeight: "bold",
                      fontSize: "18px",
                      marginLeft: "18px",
                    }}
                  >
                    Connect to QuickBooks Online
                  </span>
                </div>
              </div>
            </div>
            <div className="bottum-btns">
              {/* <div className="sync-btn mg-qbbtns"> */}
              <div className="button-left">
                <input
                  type="submit"
                  value="Go Back"
                  id='account_system_go_back_button'
                  style={{
                    borderRadius: "0.5em",
                    width: "200px",
                    height: "50px",
                    marginTop: "20px",
                    border: "2px solid",
                    float: "left",
                  }}
                  onClick={() => this.props.handleAction("ADD_ORG")}
                />
                <span className="go-back-text">
                  <p style={this.style.pText}>Go back to</p>
                  <p style={this.style.pText}>"Organization’s Settings"</p>
                </span>
              </div>
              <div className="button-right">
                <div>
                  <input
                    type="submit"
                    value={"Next"}
                    id='account_system_next_button'
                    className="cta mg-brand2-color renderqb-btn mg-checkprefbtn"
                    style={{
                      width: "200px",
                      height: "50px",
                      borderRadius: "0.5em",
                      marginTop: "20px",
                      marginLeft: "0px",
                      // marginRight: '10px',
                      float: "right",
                    }}
                    onClick={() => {
                      this.handleSubmitToNextPage();
                    }}
                  />
                  <span
                    className="next-text"
                    style={{
                      // float: "right",
                      marginRight: "20px",
                      marginTop: "20px",
                    }}
                  >
                    <p style={this.style.pText}>Continue to</p>
                    <p style={this.style.pText}>
                      {isConnectedToQB
                        ? '"Team Management"'
                        : '"Check Account Sync Preferences"'}
                    </p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ConnectToAccountView;
