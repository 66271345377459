import React, { Component } from "react";
import ReactGA from "react-ga";
import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  PostalCodeElement,
} from "react-stripe-elements";

// Components

class ConsumerAddAccountForm extends Component {
  state = {
    input: {
      nameOnCard: {
        value: "",
        empty: true,
        hasError: false,
      },
      cardNumber: {
        empty: true,
        hasError: false,
      },
      cardExpirationDate: {
        empty: true,
        hasError: false,
      },
      cvc: {
        empty: true,
        hasError: false,
      },
      cardZip: {
        empty: true,
        hasError: false,
      },
    },
  };

  checkActiveButtonTab = (check) => {
    if (check) {
      return this.props.primary_color;
    } else {
      return "#0ab894";
    }
  };

  handleInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      },
    }));
  }

  clearCardData = () => {
    this.state.input.nameOnCard.value = "";
    if (this._elementNo) {
      this._elementNo.clear();
    }
    if (this._elementExDate) {
      this._elementExDate.clear();
    }
    if (this._elementCVV) {
      this._elementCVV.clear();
    }
    if (this._elementZip) {
      this._elementZip.clear();
    }
    this.setState({ input: this.state.input });
  };

  handleCreditCardSubmit = (event) => {
    event.preventDefault();
    ReactGA.pageview("Add Account");

    if (this.validateForm()) {
      // $('#submit-form').replaceWith('<div class="spinner"></div>');
      this.props.stripe
        .createToken({ name: this.state.input.nameOnCard.value })
        .then(({ token }) => {
          this.props.handleFormSubmit(token.id);
          this.clearCardData();
        });
    } else {
      this.setFormErrorState();
    }
  };

  validateForm = () => {
    let newInputState = { ...this.state.input };
    let errorsArePresent = false;

    Object.entries(newInputState).forEach(([key, value]) => {
      if (value.empty || value.error) {
        errorsArePresent = true;
      }
    });

    let { input } = this.state;
    let checkErrors =
      input.cardNumber.hasError ||
      input.cardExpirationDate.hasError ||
      input.cvc.hasError ||
      input.cardZip.hasError;
    if (checkErrors) {
      errorsArePresent = true;
    }

    if (errorsArePresent) {
      return false;
    } else {
      return true;
    }
  };

  setFormErrorState = () => {
    let newInputState = { ...this.state.input };
    let errorsArePresent = false;
    Object.entries(newInputState).forEach(([key, value]) => {
      if (value.empty || value.error) {
        newInputState[key].hasError = true;
        errorsArePresent = true;
      }
    });

    if (errorsArePresent) {
      this.setState((state) => ({
        ...state,
        input: newInputState,
      }));
    }
  };

  renderErrorMessage(input) {
    let message = "";
    if (input === "nameOnCard") {
      message = "Please enter valid name";
    } else if (input === "cardNumber") {
      message = "Please enter valid credit card number";
    } else if (input === "cardExpirationDate") {
      message = "Please enter valid expiration date";
    } else if (input === "cvc") {
      message = "Please enter valid CVV";
    } else if (input === "cardZip") {
      message = "Please enter valid zip code";
    }

    return <div className='input-error-message'>{message}</div>;
  }

  render() {
    let { input } = this.state;
    const { showEditCard, isSubmit } = this.props;

    const style = {
      base: {
        fontSize: "16px",
        lineHeight: "1.875em",
        color: "#252525",
        fontFamily:
          "HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica",
        "::placeholder": {
          color: "#9B9B9B",
        },
      },
      invalid: {
        color: "#fb5c82",
        ":focus": {
          color: "#252525",
        },
      },
    };

    return (
      <form
        name='consumerCardForm'
        className='consumer-profile-form'
        onSubmit={(event) => this.handleCreditCardSubmit(event)}
      >
        <div className='admin-form-row'>
          <div
            className={`input-container cc-name  ${
              input.nameOnCard.hasError ? " error" : ""
            }`}
          >
            <label htmlFor='phone'> Name on Card</label>
            <input
              name='nameOnCard'
              placeholder='Enter Name'
              type='text'
              disabled={true}
              value={input.nameOnCard.value}
              onChange={(event) =>
                this.handleInputChange({
                  nameOnCard: { value: event.target.value, hasError: false },
                })
              }
            />
            {input.nameOnCard.hasError
              ? this.renderErrorMessage("nameOnCard")
              : null}
          </div>
        </div>
        <div className='admin-form-row'>
          <div
            className={`input-container cc-name ${
              input.cardNumber.hasError ? " error" : ""
            }`}
          >
            <label className='stripe-label'>
              Credit Card Number
              <CardNumberElement
                style={style}
                disabled={true}
                placeholder='1234 5678 1234 5678'
                onChange={(event) =>
                  this.handleInputChange({
                    cardNumber: { empty: event.empty, hasError: event.error },
                  })
                }
                elementRef={(element) => (this._elementNo = element)}
              />
            </label>
            {input.cardNumber.hasError
              ? this.renderErrorMessage("cardNumber")
              : null}
          </div>
        </div>
        <div className='consumer-input-form'>
          <div
            className={`input-container  ${
              input.cardExpirationDate.hasError ? " error" : ""
            }`}
          >
            <label className='stripe-label'>
              Expiration Date
              <CardExpiryElement
                style={style}
                disabled={true}
                placeholder='01/20'
                onChange={(event) =>
                  this.handleInputChange({
                    cardExpirationDate: {
                      empty: event.empty,
                      hasError: event.error,
                    },
                  })
                }
                elementRef={(element) => (this._elementExDate = element)}
              />
            </label>
            {input.cardExpirationDate.hasError
              ? this.renderErrorMessage("cardExpirationDate")
              : null}
          </div>
          <div
            className={`input-container  ${input.cvc.hasError ? " error" : ""}`}
          >
            <label className='stripe-label'>
              CVV
              <CardCVCElement
                style={style}
                disabled={true}
                placeholder='***'
                onChange={(event) =>
                  this.handleInputChange({
                    cvc: { empty: event.empty, hasError: event.error },
                  })
                }
                elementRef={(element) => (this._elementCVV = element)}
              />
            </label>
            {input.cvc.hasError ? this.renderErrorMessage("cvc") : null}
          </div>
        </div>
        <div className='consumer-input-form'>
          <div
            className={`input-container  ${
              input.cardZip.hasError ? " error" : ""
            }`}
          >
            <label className='stripe-label'>
              Billing Zip Code
              <PostalCodeElement
                style={style}
                disabled={true}
                placeholder='12345'
                onChange={(event) =>
                  this.handleInputChange({
                    cardZip: { empty: event.empty, hasError: event.error },
                  })
                }
                elementRef={(element) => (this._elementZip = element)}
              />
            </label>
            {input.cardZip.hasError ? this.renderErrorMessage("cardZip") : null}
          </div>
        </div>
        {isSubmit ? (
          <div className='spinner'></div>
        ) : (
          <input
            className='cta submit-btn-align cta-col7 mg-brand2-color'
            id='submit-form'
            type='submit'
            disabled={true}
            value='Save Card'
            style={{ backgroundColor: this.checkActiveButtonTab(true) }}
          />
        )}
      </form>
    );
  }
}

export default injectStripe(ConsumerAddAccountForm);
