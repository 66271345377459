import moment from "moment";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import shortid from "shortid";
import ReactGA from "react-ga";
import viewIcon from "../../assets/icons/view.png";
//import companyData from "../../assets/styles/theme.json";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import ClientsCardView from "../../components/CardView/ClientsCardView";
import ClientsInvoiceSidebar from "../../components/Clients/ClientsInvoiceSidebar";
import InvoiceHistory from "../../components/Clients/Invoice/invoiceHistory";
import InvoiceTasks from "../../components/Clients/Invoice/invoiceTasks";
import InvoiceAndLineDetails from "../../components/ObjectList/ClientsAccounts/InvoiceAndLineDetails";
import HistoryObjectList from "../../components/ObjectList/ClientsAccounts/HistoryObjectList";
import InvoiceObjectList from "../../components/ObjectList/ClientsAccounts/InvoiceObjectList";
import * as API from "../../utils/api";
import downloadImg from "../../assets/icons/download.png";
import deleteImg from "../../assets/icons/delete.png";
import editImg from "../../assets/icons/edit_blue.svg";
import EditIcon from "../../assets/icons/edit_white.svg";
import deleteIcon from "../../assets/icons/delete.png";
import HistoryIcon from "../../assets/icons/history.svg";
import {
  getDollarAmount,
  displayPlanStatus,
  getCalculatedPlanAmount,
} from "../../utils/helpers";
import Emitter from "../../utils/event-emitter";

class ClientsPaymentPlanView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentPlanDetails: {},
      paymentSummary: {},
      taskCategory: "Comms",
      planInvoicesData: [],
      isDeletable: false,
      selectedButton: 1,
      isShowHistory: false,
      expandPayment: false,
      expandDoc: false,
      isLoading: true,
      showLoader: false,
      paymentApplied: 0.0,
      error: {
        hasAnyError: false,
        statusCode: 200,
      },
      accountDetail: {
        user: {},
      },
      planList: {},
    };
  }
  style = {
    eligbleForPayment: {
      padding: "7px",
      borderRadius: "7px",
      backgroundColor: "#0ab894",
      color: "#ffffff",
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontWeight: 400,
    },
    inCollection: {
      padding: "7px",
      borderRadius: "7px",
      backgroundColor: "#db2424",
      color: "#ffffff",
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontWeight: 400,
    },
  };
  componentDidMount() {
    this.getOrganizationData();
    this.getPlanDetails();
    ReactGA.pageview("/ClientsInvoicesView");
  }

  componentDidUpdate(props) {
    if (this.props.match.params.id != props.match.params.id) {
      // this.getInvoiceDetails();
    }
  }

  getOrganizationData = () => {
    let { location } = this.props;
    let { accountDetail } = this.state;
    let data =
      location && location.state && location.state.accountData
        ? location.state.accountData
        : "";
    if (data) {
      let temp = {
        display_name: data.display_name,
        company_name: data.company_name,
        first_name: data.billing_first_name,
        last_name: data.billing_last_name,
        phone_number: data.billing_phone_no,
        email: data.email,
        street_address_primary: data.street_address_primary,
        street_address_secondary: data.street_address_secondary,
        zipcode: data.shipping_address_zipcode,
        state: data.state,
        city: data.city,
      };
      accountDetail.user = temp;
      this.setState({ accountDetail });
    }
  };

  getPlanInvoiceList = (data) => {
    let headings = [],
      columns = [];
    headings = [
      {
        name: "Invoice #",
        style: {
          width: "20%",
          display: "flex",
          justifyContent: "flex-start",
        },
      },
      {
        name: "Invoice Date",
        style: {
          width: "15%",
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        name: "Due Date",
        style: {
          width: "15%",
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        name: "Total",
        style: {
          width: "20%",
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        name: "Balance",
        style: {
          width: "20%",
          display: "flex",
          justifyContent: "center",
        },
      },
    ];

    columns = data.map((row, index) => {
      return {
        emptyPrimary: true,
        secondaryColumns: [
          {
            key: "Invoice #",
            title: row.invoice_number ? row.invoice_number : "n/a",
            style: {
              width: "20%",
              display: "flex",
              justifyContent: "flex-start",
            },
          },
          {
            key: "Invoice Date",
            title: row.txn_date && moment(row.txn_date).format("MM/DD/YYYY"),
            style: {
              width: "15%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "Due Date",
            title: row.due_date && moment(row.due_date).format("MM/DD/YYYY"),
            style: {
              width: "15%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "Total",
            title: getDollarAmount(row.total_amount && row.total_amount),
            style: {
              width: "20%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "Balance",
            title: getDollarAmount(row.balance),
            style: {
              width: "20%",
              display: "flex",
              justifyContent: "center",
            },
          },
        ],
      };
    });

    return {
      headings,
      columns,
    };
  };

  renderPlanInvoices = () => {
    const { planInvoicesData } = this.state;

    if (planInvoicesData && planInvoicesData.length > 0) {
      return (
        <div>
          <div className='invoice-line-header'>{"Invoice Details"}</div>
          <div>
            <ClientsCardView data={this.getPlanInvoiceList(planInvoicesData)} />
            <InvoiceObjectList
              data={this.getPlanInvoiceList(planInvoicesData)}
              hideForMobile={true}
              optionalTableClasses={"client-inv-line"}
            />
          </div>
        </div>
      );
    }
  };

  getPlanDetails = (isReload = false) => {
    let { user } = this.props;
    const planId = this.props.match.params.id;
    this.setState({ isLoading: !isReload });
    API.getPaymentPlanLById(user, planId).then((data) => {
      if ((data != undefined || data != null) && data.message === "success") {
        if (data.data) {
          if (parseFloat(data.data.balance_as_currency) > 0) {
            this.setState({
              isDeletable: true,
            });
          }
          data.data.user = this.state.accountDetail.user;
          if (data.data.recent_activities.length) {
            data.data.recent_activities.map((item) => {
              item.date = item.complete_date;
            });
          }
          if (data.data.upcoming_activities.length) {
            data.data.upcoming_activities.map((item) => {
              item.date = item.due_date;
            });
          }

          if (data.data.get_payment_plan_invoices.length) {
            this.setState({
              planInvoicesData: data.data.get_payment_plan_invoices,
            });
          }
          data.data.isPaymentPlan = true;
          this.setState({ paymentPlanDetails: data.data }, () => {
            let payeeAmt = 0.0;
            let list = data.data.payment_plans_payments.map((item, index) => {
              item.paymentNumber = index + 1;

              item.dueDate = item.due_date;
              item.paidDate = item.paid_date;
              item.paymentAmt = item.payment_amount;
              item.paid_amt =
                item.paid_amount === null
                  ? 0.0
                  : parseFloat(item.paid_amount.replace(",", "")).toFixed(2);
              payeeAmt =
                item.paid_amount != null && item.paid_amount != ""
                  ? payeeAmt + parseFloat(item.paid_amount.replace(",", ""))
                  : payeeAmt;
              return item;
            });
            let temp = [
              {
                planAmt: getCalculatedPlanAmount(
                  data.data.total_in_dollars_as_currency,
                  data.data.adjustment_amount,
                  data.data.is_credit_memo_applied,
                  data.data.status,
                  "Plan_amt"
                ),
                noOfMonths: data.data.number_of_payments,
                paymentAmt: data.data.monthly_payment_amount_as_currency,
                firstPaymentDate: data.data.start_date,
                finalPaymentDate:
                  data.data.payment_plans_payments[
                    data.data.payment_plans_payments.length - 1
                  ].due_date,
              },
            ];

            this.setState({
              paymentApplied: payeeAmt,
              paymentSummary: temp,
              planList: list,
              isLoading: false,
            });
          });
        }
        this.setState({ isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  updateInvoiceData = () => {
    let { paymentPlanDetails } = this.state;
    if (
      paymentPlanDetails &&
      paymentPlanDetails.invoice_lines &&
      paymentPlanDetails.invoice_lines.length
    ) {
      paymentPlanDetails.invoice_lines.map((line, i) => {
        if (line.item_name == "Late fee") {
          paymentPlanDetails.tax_and_discount_line.push(line);
          // paymentPlanDetails.invoice_lines.splice(i, 1); //C
        }
      });
    }
    this.setState({ paymentPlanDetails });
  };

  handleReImportInvoice = () => {
    this.props.updateModalState(true, "CONFIRM_ALERT", {
      message: "Are you sure you want to reimport invoice?",
      // confirmClick: this.ReImportInvoice,
      closeOnClickOutside: true,
    });
  };

  ReImportInvoice = (event) => {
    const { paymentPlanDetails } = this.state;
    const { user } = this.props;
    this.setState({ showLoader: true });
    API.reImportInvoice(user, paymentPlanDetails.id).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        if (data.status_code && data.status_code == 400) {
          this.setState({ showLoader: false });
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        } else if (data.status_code == 422) {
          Emitter.emit("NOTIFICATION_ALERT", true);
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        } else if (data.data) {
          this.setState({ paymentPlanDetails: data.data });
          this.setState({ showLoader: false });
          this.props.updateModalState(true, "SUCCESS", {
            message: "Invoice imported successfully.",
          });
        }
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
          showLoader: false,
        }));
      }
    });
  };

  downloadAttachedDocuement = (doc_id, fileName) => {
    const { user } = this.props;
    API.getDownloadPaymentDocument(user, doc_id, "payment_plan_document").then(
      (data) => {
        if (data && data.status_code && data.status_code == 200) {
          if (data.data) {
            let flag = data.data.includes("base64");
            if (!flag) {
              data.data = "data:application/pdf;base64," + data.data;
            }
            let url = data.data;
            let a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
          }
        } else {
          this.setState((state) => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.message && data.message == "Access denied."
                    ? 401
                    : 500) ||
                  500
                : 500,
            },
            showLoader: false,
          }));
        }
      }
    );
  };

  deleteAttachment = (doc_id) => {
    let message = "Do you want to delete document?";
    this.props.updateModalState(true, "CONFIRM_ALERT", {
      message: message,
      confirmClick: () => {
        API.deleteVerificationDocument(
          this.props.user,
          doc_id,
          "payment_plan_document"
        ).then((data) => {
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message,
          });

          this.updateDocument(data.data);
        });
      },
      closeOnClickOutside: true,
    });
  };

  handleEditClick = (e) => {
    let { history } = this.props;
    history.push({
      pathname: `/client/add/invoices`,
      state: {
        paymentPlanDetails: this.state.paymentPlanDetails,
        backPath: `/client/invoices/${this.props.match.params.id}`,
      },
    });
  };

  handleVoidInvoice = () => {
    this.props.updateModalState(true, "CONFIRM_ALERT", {
      message: "Are you sure you want to void invoice?",
      confirmClick: this.deleteInvoice,
      closeOnClickOutside: true,
    });
  };

  deleteInvoice = () => {
    const { paymentPlanDetails } = this.state;
    this.setState({ isLoading: true });
    API.clientDeleteInvoice(this.props.user, {
      invoice_id: paymentPlanDetails.id,
    }).then((data) => {
      if (data) {
        if (data.status_code === 200) {
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message,
          });
          // this.getpaymentPlanDetails();
        } else if (data.status_code === 422) {
          Emitter.emit("NOTIFICATION_ALERT", true);
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        } else {
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        }
        this.setState({ isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  updateDocument = (data) => {
    // let { paymentPlanDetails } = this.state;
    this.reloadData();
  };

  attchDocument = (editData, isEditable) => {
    let props = {
      rowData: this.state.paymentPlanDetails,
      consumer:
        this.state.accountDetail && this.state.accountDetail.user
          ? this.state.accountDetail.user
          : "",
      editData: editData,
      isEditable: isEditable,
      reloadData: this.reloadData,
      from: "payment_plan",
      updateDocument: this.reloadData,
      mgModalTitle: editData ? "Edit Document" : "Upload Document",
    };
    this.props.updateModalState(true, "VALIDATION_DOCUMENT", props);
  };

  showHistory = (isShowHistory) => {
    this.setState({ isShowHistory });
  };

  reloadData = () => {
    this.getPlanDetails(true);
  };

  selectedButton = (item, index) => {
    this.setState({ selectedButton: index + 1 });
  };

  handleHeaderExpander = (event, type) => {
    if (type === "Payment") {
      this.setState({ expandPayment: !this.state.expandPayment });
    } else {
      this.setState({ expandDoc: !this.state.expandDoc });
    }
  };

  viewValidationDoc = (row) => {
    let props = {
      // image: row.document,
      type: row.document_name,
      doc_name: row.document_name,
      doc_id: row.id,
      arrange_pdf: true,
      user: this.props.user,
      from: "payment_plan",
    };
    this.props.updateModalState(true, "SHOW_IMAGE", props);
  };

  getDate = (date) => {
    if (date) {
      return moment(date).format("MM/DD/YYYY");
    }
    return "n/a";
  };

  documentAction = (row) => {
    let imgExtension = row.document_name && row.document_name.split(".").pop();
    let fileSizeCheck =
      row.file_size_in_mb && row.file_size_in_mb > 3 ? true : false;
    let flag =
      imgExtension &&
      (imgExtension.toLowerCase() === "xls" ||
        imgExtension.toLowerCase() === "xlsx" ||
        imgExtension.toLowerCase() === "doc" ||
        imgExtension.toLowerCase() === "docx")
        ? false
        : true;
    flag = fileSizeCheck ? false : flag;
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <a
          className='make-payment-button'
          style={{ cursor: "pointer", marginRight: 15 }}
          title='Download'
          onClick={() =>
            this.downloadAttachedDocuement(row.id, row.document_name)
          }
        >
          <img src={downloadImg} width='16' height='16' />
        </a>
        <button
          title='Edit'
          style={{
            background: "transparent",
            cursor: "pointer",
            marginRight: 15,
          }}
          onClick={() => this.attchDocument(row, true)}
        >
          <img src={editImg} width='16' height='16' />
        </button>
        <button
          title='Delete'
          style={{
            background: "transparent",
            cursor: "pointer",
            marginRight: 15,
          }}
          onClick={() => this.deleteAttachment(row.id)}
        >
          <img src={deleteImg} width='25' height='25' />
        </button>
        {flag && (
          <button
            title='View'
            className='view-button'
            style={{
              background: "transparent",
              cursor: "pointer",
            }}
            onClick={() => this.viewValidationDoc(row)}
          >
            <img src={viewIcon} width='16' height='16' />
          </button>
        )}
      </div>
    );
  };

  getPaidAmount = (value) => {
    let temp = value.replace(",", "");
    let amount = 0.0;
    if (value) {
      amount = parseFloat(temp);
    }
    return amount.toFixed(2);
  };

  getObjectListData = (data, type) => {
    let headings = [],
      columns = [];
    headings =
      type === "Payment"
        ? [
            "Plan Amt",
            "# Wks/Months",
            "Pymt Amt",
            "First Pymt Due",
            "Final Pymt Date",
          ]
        : type === "Document"
        ? ["Name", "Description", "Type", "Action"]
        : type === "PaymentDetails"
        ? [
            "Payment #",
            "Status",
            "Due Date",
            "Paid Date",
            "Payment Amt",
            "Paid Amt",
            "Balance",
          ]
        : type === "note"
        ? ["Name", "Date", "Note"]
        : [];

    columns =
      type === "Document"
        ? data.map((row) => {
            row.name = row.document_name;
            row.document_id = row.id;
            return {
              emptyPrimary: true,
              secondaryColumns: [
                {
                  key: "Name",
                  title: row.document_name,
                },
                {
                  key: "Description",
                  title: row.description,
                },
                {
                  key: "Type",
                  title: row.document_type ? row.document_type.name : "",
                },
                {
                  key: "Action",
                  title: this.documentAction(row),
                },
              ],
            };
          })
        : type === "Payment"
        ? data.map((row) => {
            return {
              emptyPrimary: true,
              secondaryColumns: [
                {
                  key: "Plan Amt",
                  title: `$  ${this.getPaidAmount(row.planAmt)}`,
                },
                {
                  key: "# Wks/Months",
                  title: row.noOfMonths,
                },
                {
                  key: "Payment Amount",
                  title: `$ ${this.getPaidAmount(row.paymentAmt)}`,
                },
                {
                  key: "First Payment Due",
                  title: row.firstPaymentDate
                    ? this.renderLatestPaymentDate(row.firstPaymentDate)
                    : "--/--/----",
                },
                {
                  key: "Final Payment Date",
                  title: row.finalPaymentDate
                    ? this.renderLatestPaymentDate(row.finalPaymentDate)
                    : "--/--/----",
                },
              ],
            };
          })
        : type === "PaymentDetails"
        ? data.map((row) => {
            return {
              emptyPrimary: true,
              secondaryColumns: [
                {
                  key: "Payment #",
                  title: row.paymentNumber,
                },
                {
                  key: "Status",
                  title: displayPlanStatus(row.status),
                },
                {
                  key: "Due Date",
                  title: row.dueDate
                    ? this.renderLatestPaymentDate(row.dueDate)
                    : "--/--/----",
                },
                {
                  key: "Paid Date",
                  title: row.paidDate
                    ? this.renderLatestPaymentDate(row.paidDate)
                    : "--/--/----",
                },
                {
                  key: "Payment Amt",
                  title: `$ ${this.getPaidAmount(row.paymentAmt)}`,
                },
                {
                  key: "Paid Amt",
                  title: `$ ${
                    row.paid_amt
                      ? parseFloat(row.paid_amt.replace(",", "")).toFixed(2)
                      : "0.00"
                  }`,
                },
                {
                  key: "Balance",
                  title: `$ ${this.getPaidAmount(row.balance)}`,
                },
              ],
            };
          })
        : type === "note"
        ? data.map((row) => {
            return {
              emptyPrimary: true,
              secondaryColumns: [
                {
                  key: "Name",
                  title: row.display_name,
                },
                {
                  key: "Date",
                  title: row.created_at
                    ? this.renderLatestPaymentDate(row.created_at)
                    : "--/--/----",
                },
                {
                  key: "Note",
                  title: row.note,
                },
              ],
            };
          })
        : [];

    return {
      headings,
      columns,
    };
  };
  renderLatestPaymentDate = (date) => {
    return moment.utc(date).format("MM/DD/YYYY");
  };

  getObjectListDataForAdditionalInformation = (data, type) => {
    let { paymentPlanDetails } = this.state;
    let headings = [],
      columns = [];
    headings = ["Plan Create Date", "Plan Status"];
    columns = [
      {
        emptyPrimary: true,
        secondaryColumns: [
          {
            key: "Plan Create Date",
            title: this.renderLatestPaymentDate(paymentPlanDetails.created_at),
          },
          {
            key: "Plan Status",
            title: displayPlanStatus(paymentPlanDetails.status),
          },
        ],
      },
    ];

    return {
      headings,
      columns,
    };
  };
  getClickableHeader = (header, type, data) => {
    let { expandDoc, expandPayment } = this.state;
    let count = data && data.length ? data.length : 0;
    let flag =
      expandDoc && type === "Document"
        ? true
        : expandPayment && type === "Payment"
        ? true
        : false;
    return (
      <div className='in-new-btn'>
        <div className='ic-wt-fields'>
          <div className='he-hed he-sec-hed'>{header}</div>
          {/* <a
            key={shortid.generate()}
            className={`client-caret expander-caret${
              flag ? " down-caret" : ""
            }`}
            style={{ margin: "12px 0px 0px 12px" }}
            onClick={(event) => this.handleHeaderExpander(event, type)}
          /> */}
        </div>
        {type == "Document" ? (
          <button className='attach-btn'>
            {" "}
            {/*onClick={() => this.attchDocument()} */}
            Attach
          </button>
        ) : null}
      </div>
    );
  };

  getHeaderUtilsForDocument = (header, type, data, count) => {
    let { expandDoc, expandPayment } = this.state;

    let flag =
      expandDoc && type === "Document"
        ? true
        : expandPayment && type === "Payment"
        ? true
        : false;
    return (
      <div className='in-new-btn'>
        <div className='ic-wt-fields'>
          <div className='he-hed he-sec-hed'>
            {header} {` ( ${count} )`}
          </div>
          <a
            key={shortid.generate()}
            className={`client-caret expander-caret${
              flag ? " down-caret" : ""
            }`}
            style={{ margin: "12px 0px 0px 12px" }}
            onClick={(event) => this.handleHeaderExpander(event, type)}
          />
        </div>
        {type == "Document" ? (
          <button className='attach-btn' onClick={() => this.attchDocument()}>
            {" "}
            {/* */}
            Attach
          </button>
        ) : null}
      </div>
    );
  };

  renderPaymentAndDoc = (data, header, type, emptyMsg) => {
    let newData = [];
    newData = data;
    return (
      <div>
        {type === "Document"
          ? this.getHeaderUtilsForDocument(header, type, newData)
          : this.getClickableHeader(header, type, newData)}
        <div style={{ maxHeight: "30em", overflowY: "auto" }}>
          {data && data.length ? (
            <div>
              <ClientsCardView data={this.getObjectListData(newData, type)} />
              <HistoryObjectList
                data={this.getObjectListData(newData, type)}
                hideForMobile={true}
                optionalTableClasses={type === "Document" ? "invoice-doc" : ""}
                optionalClasses='mg-row-pl-15'
              />
            </div>
          ) : (
            this.renderEmpty(emptyMsg)
          )}
        </div>
      </div>
    );
  };

  renderDocuments = (data, header, type, emptyMsg) => {
    let newData = [];
    newData = data;
    let { expandDoc } = this.state;
    let flag = expandDoc && type === "Document" ? true : false;
    let count = data && data.length ? data.length : 0;
    return (
      <div style={{ marginBottom: "100px" }}>
        {type === "Document"
          ? this.getHeaderUtilsForDocument(header, type, newData, count)
          : this.getClickableHeader(header, type, newData)}
        {flag ? (
          <div>
            {data && data.length ? (
              <div>
                <ClientsCardView data={this.getObjectListData(newData, type)} />
                <HistoryObjectList
                  data={this.getObjectListData(newData, type)}
                  hideForMobile={true}
                  optionalTableClasses={
                    type === "Document" ? "invoice-doc" : ""
                  }
                  optionalClasses='mg-row-pl-15'
                />
              </div>
            ) : (
              this.renderEmpty(emptyMsg)
            )}
          </div>
        ) : null}
      </div>
    );
  };

  headerUtilities = () => {
    const { paymentPlanDetails } = this.state;
    return (
      <div
        className='app-section-header-utilities'
        style={{ marginBottom: "1.2em" }}
      >
        <div className='admin-search-input-container main-header-search'>
          <div>
            Source:{" "}
            <span style={{ fontWeight: 600 }}>
              {paymentPlanDetails && paymentPlanDetails.qb_invoice_id
                ? "QuickBooks"
                : "MakeGood"}
            </span>
          </div>
        </div>
      </div>
    );
  };

  renderEmpty = (msg) => {
    return (
      <div
        className='admin-admins-empty-state'
        style={{ padding: "2rem 2rem 1rem 2rem", backgroundColor: "#FFF" }}
      >
        <p>{msg}</p>
      </div>
    );
  };

  showTotalsDetails = (data) => {
    let filterdData = [];
    let { paymentPlanDetails, paymentApplied } = this.state;

    filterdData.push({
      key: "Original Balance",
      value: `$ ${getCalculatedPlanAmount(
        paymentPlanDetails.total_in_dollars_as_currency,
        paymentPlanDetails.adjustment_amount,
        paymentPlanDetails.is_credit_memo_applied,
        paymentPlanDetails.status,
        "org_balance"
      )}`,
      flag: "",
    });
    filterdData.push({
      key: "Discounts Applied",
      value: `$ ${this.getPaidAmount(paymentPlanDetails.adjustment_amount)}`,
      flag: "",
    });

    filterdData.push({
      key: "Payments Applied",
      value: `$ ${
        paymentApplied ? parseFloat(paymentApplied).toFixed(2) : "0.00"
      }`,
      flag: "",
    });
    filterdData.push({
      key: "Outstanding Balance",
      value: `$ ${this.getPaidAmount(paymentPlanDetails.balance_as_currency)}`,
      flag: "",
    });

    return filterdData && filterdData.length ? (
      <div className={`panel tax-discount-content`}>
        {filterdData.map((item, index) => {
          return (
            <div
              key={index.toString()}
              className={`tax-details ${
                index + 1 === filterdData.length ? "no-border" : ""
              }`}
            >
              <div
                className={`tax-details-key tax-details-total highlighted-key"
              `}
              >
                {item.key}
              </div>
              <div className={`tax-details-value`}>
                <div>
                  {item.flag ? item.flag : ""}
                  {item.value}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    ) : null;
  };
  handleAddTaskNote = (note) => {
    const { user } = this.props;
    const { paymentPlanDetails } = this.state;
    let payload = {
      payment_plan_id: paymentPlanDetails.id,
      note: note,
    };
    API.addPaymentPlanNotes(user, payload).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        //close modal
        this.props.updateModalState(false, "ACCEPT_REJECT_NOTES");
        this.reloadData();
      } else {
        //close modal
        this.props.updateModalState(false, "ACCEPT_REJECT_NOTES");
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };
  addNewwNote = () => {
    let optionalProps = {
      action: "AddTaskNote",
      handleAddTaskNote: this.handleAddTaskNote,
    };
    this.props.updateModalState(true, "ACCEPT_REJECT_NOTES", optionalProps);
  };

  deletePaymentPlan = (id) => {
    API.clientDeletePaymentPlan(this.props.user, id).then((data) => {
      if ((data != undefined || data != null) && data.status_code === 200) {
        this.props.updateModalState(true, "SUCCESS", {
          message: data.message,
        });
        this.props.history.goBack();
      }
    });
  };

  handleDeletePaymentPlan = (id) => {
    const { paymentPlanDetails } = this.state;
    this.props.updateModalState(true, "CONFIRM_ALERT", {
      message: `Are you sure you want to delete payment plan ${paymentPlanDetails.payment_plan_number}?`,
      confirmClick: () => this.deletePaymentPlan(id),
      closeOnClickOutside: true,
    });
  };

  render() {
    const { appName, user, location } = this.props;
    const {
      isLoading,
      planList,
      paymentSummary,
      paymentPlanDetails,
      planInvoicesData,
      accountDetail,
      error,
      isShowHistory,
      showLoader,
    } = this.state;
    let backPath =
      location && location.state && location.state.backPath
        ? location.state.backPath
        : "/client/invoices";

    const props = this.props;
    if (error.hasAnyError) {
      if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return isLoading ? (
      <AppCanvasLoader />
    ) : (
      <div className='client-scroll-dashboard scrollbar'>
        <Helmet>
          <title>
            {appName ? appName : ""} | Payment Plan#{" "}
            {paymentPlanDetails.invoice_number
              ? paymentPlanDetails.invoice_number
              : ""}
          </title>
        </Helmet>
        <div className='app-sidebar-layout-canvas'>
          <ClientsInvoiceSidebar
            {...props}
            user={user}
            backPath={backPath}
            invoiceDetails={paymentPlanDetails}
            title={"tex"}
            isShowHistory={this.state.isShowHistory}
            showHistory={this.showHistory}
          />

          {isShowHistory ? (
            <div className='app-sidebar-layout-canvas-content setting-side-canvas big-device-grad col1-scroll scrollbar'>
              <InvoiceHistory
                user={this.props.user}
                invoiceDetails={paymentPlanDetails}
                goBack={() => this.showHistory(false)}
              />
            </div>
          ) : showLoader ? (
            <div className='app-sidebar-layout-canvas-content'>
              <AppCanvasLoader />
            </div>
          ) : (
            <div className='app-sidebar-layout-canvas-content setting-side-canvas big-device-grad col1-scroll scrollbar'>
              <div className='main-header'>
                <div className=' edit-invoice'>
                  <div className='new-he-hed'>
                    Payment Plan # {paymentPlanDetails.payment_plan_number}
                  </div>
                  <div className='edit-circle'>
                    <img
                      src={EditIcon}
                      height={15}
                      width={15}
                      // onClick={(event) => this.handleEditClick(event)}
                    />
                  </div>
                  <div className='history-circle'>
                    <img
                      src={HistoryIcon}
                      height={30}
                      width={31}
                      // onClick={(event) => this.showHistory(true)}
                    />
                  </div>
                  <div style={{ padding: "3px 0 0 8px", cursor: "pointer" }}>
                    {this.state.isDeletable && (
                      <img
                        title='Delete'
                        src={deleteIcon}
                        height={35}
                        width={35}
                        onClick={() =>
                          this.handleDeletePaymentPlan(
                            this.props.match.params.id
                          )
                        }
                      />
                    )}
                  </div>
                </div>
                {/* {this.headerUtilities()} */}
              </div>

              <div className='main-invoice-wrapper'>
                <div
                  className='sub-invoice-wrapper1'
                  style={{ marginTop: "15px" }}
                >
                  <ClientsCardView
                    data={this.getObjectListDataForAdditionalInformation(
                      "newData",
                      "type"
                    )}
                  />
                  <HistoryObjectList
                    data={this.getObjectListDataForAdditionalInformation(
                      "newData",
                      "type"
                    )}
                    hideForMobile={true}
                    // optionalTableClasses={
                    //   type === "Document" ? "invoice-doc" : ""
                    // }
                    optionalClasses='mg-row-pl-15'
                  />
                  {
                    <div style={{ marginTop: "1rem" }}>
                      {this.renderPlanInvoices()}
                    </div>
                  }
                  {this.renderPaymentAndDoc(paymentSummary, "", "Payment", "")}
                </div>

                <div className='sub-invoice-wrapper2'>
                  {this.showTotalsDetails()}
                </div>
              </div>
              <div>
                {this.renderPaymentAndDoc(
                  planList,
                  "Payment Details",
                  "PaymentDetails",
                  ""
                )}
              </div>
              <div className='in-new-btn'>
                <div className='he-hed he-sec-hed'>
                  Notes {`( ${paymentPlanDetails.notes.length} )`}
                </div>
                <button
                  onClick={() => {
                    this.addNewwNote();
                  }}
                >
                  New Note
                </button>
              </div>
              {paymentPlanDetails.notes.length > 0 ? (
                <div>
                  <ClientsCardView
                    data={this.getObjectListData(
                      paymentPlanDetails.notes,
                      "note"
                    )}
                  />
                  <HistoryObjectList
                    data={this.getObjectListData(
                      paymentPlanDetails.notes,
                      "note"
                    )}
                    hideForMobile={true}
                    optionalTableClasses='in-task'
                  />
                </div>
              ) : (
                this.renderEmpty(`There are currently no notes to review.`)
              )}

              {this.renderDocuments(
                paymentPlanDetails.documents,
                "Documents",
                "Document",
                "No documents available"
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ClientsPaymentPlanView;
