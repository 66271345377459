import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import shortid from "shortid";
import ArrowLink from "../../components/ArrowLink";
import HeaderMenuItem from "../../components/HeaderMenuItem";
import MenuItem from "../../components/MenuItem";
import HeaderOverflowMenu from "../../components/ObjectList/HeaderOverflowMenu";
import * as API from "../../utils/api";
import { showToast, checkValueInKey } from "../../utils/helpers";
import SignOutIcon from "../../assets/icons/sign_out.svg";
import manageUser from "../../assets/images/header/manage-user.png";
import settings from "../../assets/images/header/settings.png";
import BellIcon from "../../assets/images/header/ic_bell.svg";
import sync_icon from "../../assets/images/header/sync-blue.gif";
import sync_icon_static from "../../assets/images/header/sync-blue.png";
import Emitter from "../../utils/event-emitter";
import Logo from "../Logo";
import NotificationModal from "../../components/App/Modals/Client/NotificationModal";
import getIcon from "../../assets/icons/svg_icons_list";
import BottomScrollListener from "react-bottom-scroll-listener";

class AppClientHeader extends Component {
  static propTypes = {
    path: PropTypes.string,
    user: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      showSearchHeader: false,
      searchOptions: [],
      id: "",
      dropdownIsActive: false,
      showNtfcn: false,

      notificationList: [],
      showListCount: true,
      showListCountNumber: 0,
      showCount: false,
      rowAction: [],
      error: {
        hasAnyError: false,
        statusCode: 200,
      },
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    Emitter.on("NOTIFICATION_ALERT", (isActive) => {
      this.setState({ showListCount: isActive });
      this.updateNotificationData();
    });
    //calls when event emitted
    Emitter.on("IS_QUICKBOOK_CONNECTED", (isConnected) => {
      this.setState({ showCount: !isConnected }, () => {
        this.setRowActions();
      });
    });
    this.setRowActions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
    Emitter.off("IS_QUICKBOOK_CONNECTED");
    Emitter.off("NOTIFICATION_ALERT");
  }

  async updateNotificationData() {
    let { user } = this.props;
    let { notificationList } = this.state;
    let checkMoreDataInList = true;
    let tempOffsetValue = 0;
    let UpdatedDataList = [];
    await API.fetchNotificationHistory(user, null, "unread").then((data) => {
      let isAccessDenied =
        data && data.message && data.message == "Access denied." ? true : false;
      if (
        (typeof data != "undefined" || data != null) &&
        data.status_code === 200 &&
        !isAccessDenied
      ) {
        let newData = data.notifications;
        UpdatedDataList = UpdatedDataList.concat(
          newData.filter((item) => !item.is_addressed)
        );
        checkMoreDataInList = data.notifications.length < 20 ? false : true;
        tempOffsetValue = tempOffsetValue + 1;
        this.setState((state) => ({
          ...state,
          notificationList: UpdatedDataList,
          showListCountNumber: UpdatedDataList.length,
        }));
        this.setRowActions();
      } else {
        checkMoreDataInList = false;
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  }

  updateNotificationHistory = (id) => {
    let { user } = this.props;
    API.updateNotificationHistory(user, id).then((data) => {});
  };
  updateDimensions = () => {};

  handleKeyPress = (event) => {
    this.handleSearch(event, this.state.id);
  };

  handleKeyDown = (e) => {
    let { searchOptions } = this.state;
    if (
      e.keyCode == 13 &&
      searchOptions &&
      searchOptions.length &&
      searchOptions.length == 1
    ) {
      this.handleClickAction(searchOptions[0]);
    }
  };

  showNotification = () => {
    this.setState({ showNtfcn: true });
  };
  handleClickOnMessage = () => {
    let { history } = this.props;
    localStorage.setItem("NOTIFICATION", true);
    this.setState({ showNtfcn: false });
    history.push("/client/history");
  };

  setRowActions = () => {
    let { user, disableNav } = this.props;
    let data = [
      {
        title: disableNav ? "" : this.getFullName(user),
        dataBehavior: "user-profile",
        icon: (
          <div
            className={`app-client-header-user-avatar app-client-header-icon`}
          >
            <span>{disableNav ? "" : this.renderUserInitials(user)}</span>
          </div>
        ),
      },
      {
        title: "Notifications",
        dataBehavior: "notifications",
        icon: (
          <div className='app-client-header-icon n-icon-mobile'>
            {(this.state.showListCountNumber > 0 ||
              (user.accounting_system !== "standalone" &&
                this.state.showCount)) && (
              <div className='notification-count'>
                {this.state.showListCountNumber}
              </div>
            )}
            <img src={BellIcon} width='20' height='20' />
          </div>
        ),
      },
      {
        title: "Sign Out",
        dataBehavior: "sign-out",
        icon: (
          <div className='app-client-header-icon'>
            <img src={SignOutIcon} width='20' height='20' />
          </div>
        ),
      },
    ];

    if (this.props.hideHeadNavOpt) {
      data = [
        {
          title: "Sign Out",
          dataBehavior: "sign-out",
          icon: (
            <div className='app-client-header-icon'>
              <img src={SignOutIcon} width='20' height='20' />
            </div>
          ),
        },
      ];
    }
    this.setState({ rowAction: data });
  };

  handleSearch = (event, id) => {
    event.preventDefault();
    if (id !== "") {
      this.setState(
        {
          id,
        },
        () => {
          API.searchInvoiceId(this.props.user, this.state.id).then((data) => {
            if ((typeof data != "undefined" || data != null) && !data.error) {
              if (data.data) {
                this.setState({
                  searchOptions: data.data,
                  dropdownIsActive: true,
                });

                //added for search icon click
                if (Object.keys(event).length == 0) {
                  let item = {
                    id: data.data[0].id,
                  };
                  this.handleClickAction(item);
                }
              } else {
                this.setState({ searchOptions: [], dropdownIsActive: true });
              }
            } else {
              this.setState({ searchOptions: [] });
              this.setState((state) => ({
                ...state,
                error: {
                  hasAnyError: true,
                  statusCode: data
                    ? data.status ||
                      (data.message && data.message == "Access denied."
                        ? 401
                        : 500) ||
                      500
                    : 500,
                },
              }));
            }
          });
        }
      );
    } else {
      this.setState({ id, searchOptions: [], dropdownIsActive: false });
    }
  };

  getFullName = (user) => {
    let fullName = "";
    if (user.first_name) {
      fullName = user.first_name;
    }
    if (user.last_name) {
      fullName = fullName ? fullName + " " + user.last_name : user.last_name;
    }
    return fullName;
  };

  renderPrevLink(prevLink) {
    return (
      <ArrowLink
        label='Back'
        arrow='left'
        path={prevLink}
        optionalClassName='onboarding-header-prev-link'
      />
    );
  }

  // User Info
  renderUserInitials = (user) => {
    let initials = "";
    if (user.first_name && user.last_name) {
      initials = user.first_name.charAt(0) + user.last_name.charAt(0);
    }
    return initials.toUpperCase();
  };

  renderUserName = (user) => {
    let userName = user.first_name + " " + user.last_name;

    return userName;
  };

  handleClickAction = (item) => {
    let { history } = this.props;
    if (item && item.id) {
      this.setState(
        { showSearchHeader: false, id: "", searchOptions: [] },
        () => {
          history.push(`/client/invoices/${item.id}`);
          this.clearData();
        }
      );
    }
  };

  handleClick = (event, rowAction) => {
    let { history } = this.props;
    const actionBehavior =
      rowAction && rowAction.dataBehavior ? rowAction.dataBehavior : "";

    switch (actionBehavior) {
      case "user-profile":
        history.push(`/client/profile`);
        break;
      case "notifications":
        this.showNotification();
        break;
      case "settings":
        history.push(`/client/settings`);
        break;
      case "manage-user":
        history.push(`/client/team`);
        break;
      case "sign-out":
        history.push(`/sign-out`);
        break;
      default:
        alert("Error: Something went wrong");
    }
  };

  handleSearchClick = (event, flag) => {
    this.setState({ showSearchHeader: flag, id: "", searchOptions: [] });
  };

  handleShutDownIntercom = () => {
    window.intercom("shutdown");
  };

  overflowMenu = () => {
    return (
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <a
          onClick={(event) => this.handleSearchClick(event, true)}
          className='app-client-header-mobile-search'
        />

        <div className='app-client-header-user'>
          {this.props.isSyncQb ? (
            <img
              onClick={() => {
                this.props.backTosyncData();
              }}
              src={sync_icon}
              style={{ marginRight: "60px" }}
              width='40'
              height='40'
            />
          ) : this.props.disableNav ? (
            <img
              src={sync_icon_static}
              style={{ marginRight: "60px" }}
              width='40'
              height='40'
            />
          ) : null}
        </div>

        <HeaderOverflowMenu showThreeDots={true}>
          {this.state.rowAction.map((rowAction) => {
            return (
              <HeaderMenuItem
                key={shortid.generate()}
                rowAction={rowAction}
                dataBehavior={rowAction.dataBehavior}
                handleClick={(event, rowAction) =>
                  this.handleClick(event, rowAction)
                }
                optionalClasses='drop-down-options'
              >
                {rowAction}
              </HeaderMenuItem>
            );
          })}
        </HeaderOverflowMenu>
      </div>
    );
  };

  clearData = () => {
    this.setState({ id: "", dropdownIsActive: false });
  };

  showSearchDropDown = () => {
    return (
      <HeaderOverflowMenu
        dropdownIsActive={this.state.dropdownIsActive}
        optionalClasses='search-menu'
        innerOptionalClasses='inner-menu-list scrollbar'
      >
        {this.state.searchOptions && this.state.searchOptions.length ? (
          this.state.searchOptions.map((item) => {
            return (
              <MenuItem
                key={shortid.generate()}
                handleClick={(event) => {
                  this.handleClickAction(item);
                }}
              >
                {item.invoice_number}
              </MenuItem>
            );
          })
        ) : (
          <div className='search-empty-list'>
            <p>Invoice id not found</p>
          </div>
        )}
      </HeaderOverflowMenu>
    );
  };

  renderSearchHeader = () => {
    let { hideNav } = this.props;
    return (
      <header
        className={`app-client-header header-search-height${
          hideNav ? " consumer-mobile-header" : ""
        }`}
      >
        <a
          className='back-link'
          onClick={(event) => this.handleSearchClick(event, false)}
        />
        <form autoComplete='off'>
          <input
            autoComplete='search_by_number'
            type='text'
            id='search_by_number'
            name='search_by_invoice_number'
            placeholder='Search By Invoice Number'
            value={this.state.id}
            className='client-search-header'
            onKeyDown={this.handleKeyDown}
            onChange={(event) => {
              this.handleSearch(event, event.target.value);
            }}
          />
        </form>
        {this.state.id && this.showSearchDropDown()}
      </header>
    );
  };

  handleHereClick = () => {
    let { history } = this.props;
    this.setState({ showNtfcn: false });
    history.push(`/client/settings`, { openQB: true });
  };

  handleClickOutside = () => {
    this.setState({ showNtfcn: false });
  };

  renderNotificationList = () => {
    let { notificationList } = this.state;
    return (
      <NotificationModal
        handleClickOutside={this.handleClickOutside}
        forHeader={true}
      >
        {
          <div
            class={` ${
              (this.props.user.accounting_system === "quickbooks_online" &&
                this.state.showCount) ||
              this.state.showListCountNumber > 0
                ? "notification-list-view dropdown-menu-notification active notification-view"
                : ""
            } `}
          >
            {this.state.showCount &&
              this.props.user.accounting_system === "quickbooks_online" && (
                <div className='notification-text notification-box'>
                  Your connection to QuickBooks has been disconnected. Click{" "}
                  <a
                    onClick={this.handleHereClick}
                    style={{ cursor: "pointer" }}
                  >
                    here
                  </a>{" "}
                  to reconnect.
                </div>
              )}
            {notificationList.length > 0 &&
              notificationList.map((item, i) => {
                if (!item.is_addressed) {
                  return (
                    <div className='notification-text chip-box'>
                      <div
                        onClick={this.handleClickOnMessage}
                        className='message-box'
                      >
                        Unable&nbsp;to&nbsp;sync&nbsp;
                        {checkValueInKey(item.entity_type) && item.entity_type}
                        {checkValueInKey(item.mg_entity_number) &&
                          " #" + item.mg_entity_number}
                      </div>
                      <div
                        className='cross-icon-box'
                        onClick={() => {
                          this.updateNotificationHistory(item.id);
                          notificationList[i].is_addressed = true;
                          let newListCheck = notificationList.filter(
                            (item) => !item.is_addressed
                          );
                          this.setState({
                            notificationList: notificationList,
                            showListCount: newListCheck.length > 0,
                            showListCountNumber: newListCheck.length,
                          });
                        }}
                      >
                        {getIcon("cross-icon")}
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        }
      </NotificationModal>
    );
  };

  render() {
    let {
      user,
      prevLink,
      logoPath,
      isWelcomeScreen,
      appName,
      hideNav,
      imageResolution,
      disableNav,
    } = this.props;
    let { showSearchHeader, error, showListCountNumber, showCount } =
      this.state;
    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    if (showSearchHeader) {
      return this.renderSearchHeader();
    }

    if (user) {
      window.intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: user.intercom_id,
        name: user.first_name, // Full name
        email: user.email, // Email address
        created_at: new Date(user.verified_at).getTime(), // Signup date as a Unix timestamp
        user_hash: user.user_hash,
      };

      // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/mmxnsa15'
      (function () {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === "function") {
          ic("reattach_activator");
          ic("update", w.intercomSettings);
        } else {
          var d = document;
          var i = function () {
            i.c(arguments);
          };
          i.q = [];
          i.c = function (args) {
            i.q.push(args);
          };
          w.Intercom = i;
          var l = function () {
            var s = d.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = `https://widget.intercom.io/widget/${user.intercom_id}`;
            var x = d.getElementsByTagName("script")[0];
            x.parentNode.insertBefore(s, x);
          };
          if (document.readyState === "complete") {
            l();
          } else if (w.attachEvent) {
            w.attachEvent("onload", l);
          } else {
            w.addEventListener("load", l, false);
          }
        }
      })();
    }

    return (
      <header
        className={`app-client-header${
          hideNav ? " consumer-mobile-header" : ""
        }${disableNav || user.client_admin ? " client-admin-header" : ""}`}
        style={{ padding: "22px 40px 22px 14px", zIndex: "10" }}
      >
        {prevLink && this.renderPrevLink(prevLink)}
        {this.state.showNtfcn && this.renderNotificationList()}
        <Logo
          user={this.props.user}
          logoUrl={this.props.logoUrl}
          isDynamicLogo={isWelcomeScreen}
          linkTitle={appName}
          hideNav={hideNav}
          imageResolution={imageResolution}
        />
        <div className={`app-client-header-content`}>
          <div className='overflow-menu'>{this.overflowMenu()}</div>
          <div className='app-menu'>
            {disableNav ? (
              <div></div>
            ) : (
              <div className='app-menu'>
                <div className='client-search-input-container'>
                  <form autoComplete='off'>
                    <input
                      autoComplete='search_by_number'
                      type='text'
                      id='search_by_number'
                      name='search_by_invoice_number'
                      placeholder='Search By Invoice Number'
                      value={this.state.id}
                      className='client-search-input'
                      onKeyDown={this.handleKeyDown}
                      onChange={(event) => {
                        this.handleSearch(event, event.target.value);
                      }}
                    />
                  </form>
                  <span
                    className='client-search-icon'
                    onClick={(event) => this.handleKeyPress(event)}
                  />
                  {this.state.id && this.showSearchDropDown()}
                </div>
                <div className='app-client-header-user'>
                  <a
                    title='Profile'
                    className={`app-client-header-user-avatar`}
                    href='/client/profile'
                  >
                    <span>
                      {disableNav ? "" : this.renderUserInitials(user)}
                    </span>
                  </a>
                </div>
                <div className='app-client-header-menu'>
                  {
                    <a
                      title='Notifications'
                      className='social-link header-link'
                      onClick={
                        this.state.showListCount
                          ? this.showNotification
                          : () => {}
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <img src={BellIcon} width='24' height='24' />
                      {showListCountNumber > 0 ||
                      (showCount &&
                        this.props.user.accounting_system ===
                          "quickbooks_online") ? (
                        <div className='notification-count'>
                          {showListCountNumber > 0 ? showListCountNumber : ""}
                        </div>
                      ) : null}
                    </a>
                  }
                  {this.props.user.client_admin && (
                    <>
                      {/* <a
                        title="Settings"
                        className="social-link header-link"
                        href="/client/settings"
                      >
                        <img src={settings} width="20" height="20" />
                      </a> */}
                      {/* <a
                        title="Manage User"
                        className="social-link header-link"
                        href="/client/team"
                      >
                        <img src={manageUser} width="20" height="20" />
                      </a> */}
                    </>
                  )}
                </div>
              </div>
            )}

            <div className='app-client-header-menu'>
              <div
                className='app-client-header-user'
                title='Rotating arrows indicates QuickBooks Online sync is in progress.'
              >
                {this.props.isSyncQb ? (
                  <img
                    onClick={() => {
                      this.props.backTosyncData();
                    }}
                    src={sync_icon}
                    style={{ marginRight: "30px" }}
                    width='40'
                    height='40'
                  />
                ) : disableNav ? (
                  <img
                    src={sync_icon_static}
                    style={{ marginRight: "30px" }}
                    width='40'
                    height='40'
                  />
                ) : null}
              </div>
              <Link
                className='app-client-header-user-nav-link cl-nav-link'
                to='/sign-out'
                onClick={() => {
                  this.handleShutDownIntercom();
                }}
              >
                Sign Out
              </Link>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default withRouter(AppClientHeader);
