import React, { Component } from "react";
import ReactGA from "react-ga";
import Heap from "react-heap";
import { Helmet } from "react-helmet";
import { Link, Redirect, withRouter } from "react-router-dom";
import { instanceOf } from "prop-types";
import { Cookies, withCookies } from "react-cookie";
import Logo from "../../components/Logo";
import LoginForm from "../../components/Sessions/LoginForm";
import signInWithIntuit from "../../assets/images/landing/Sign_in_transparent_btn_tall_default.png";
import signInWithIntuitHover from "../../assets/images/landing/Sign_in_transparent_btn_tall_hover.png";
// Components
import SessionsModal from "../../components/Sessions/SessionsModal";
import * as API from "../../utils/api";
import { getZohoPagesense } from "../../utils/helpers";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";

class Login extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      companyData: {},
      appThemeData: {},
      loading: true,
      isAppConfig: false,
      signInWithIntuitImg: "",
      check: true,
    };
  }

  componentWillMount() {
    this.getOrganizationData();
    this.isThirdPartyAppConfigured();
    getZohoPagesense();
    ReactGA.pageview("/Login");
    this.getLogoColorsForPayment();
  }

  getOrganizationData() {
    API.getOrganizationData().then((data) => {
      this.setState({
        companyData: data,
      });
    });
  }

  updateLoaderState = (flag) => {
    this.setState({
      loading: flag,
    });
  };

  getLogoColorsForPayment() {
    API.getLogoColorsForPayment()
      .then((response) => {
        this.setState({
          appThemeData: response.data,
          check: !(response.data.logo_url === undefined),
        });
      })
      .catch(() => {});
  }

  componentDidMount() {
    const { cookies } = this.props;
    this.setState({ signInWithIntuitImg: signInWithIntuit });
    let url = new URL(window.location.href);
    let searchParams = new URLSearchParams(url.search);
    this.updateLoaderState(
      searchParams.size !== 0 && searchParams.get("username")
    );
    if (searchParams.size !== 0 && searchParams.get("username")) {
      const userCandidate = {
        email: searchParams.get("username"),
        password: this.decodeString(searchParams.get("pk")),
        organization_key: searchParams.get("org_key"),
      };
      API.setOauthObj(userCandidate);
      API.login(userCandidate).then((data) => {
        if ((typeof data != "undefined" || data != null) && !data.error) {
          if (!data.errors) {
            const user = {
              first_name: data.first_name,
              email: data.email,
              last_name: data.last_name,
              user_id: data.id,
              user_auth_token: data.auth_token,
              is_onboarding_completed: data.is_onboarding_completed,
              admin: data.admin,
              is_employee: data.is_employee,
              super_admin: data.super_admin,
              onboarding_complete: data.accepted_on ? true : false,
              client_admin: data.client_admin,
              verified: data.verified,
              verified_at: data.verified_at,
              user_hash: data.user_hash,
              intercom_id: data.intercom_id,
            };
            this.props.updateUserLogin(user);
            this.updateLoaderState(false);
          } else {
            this.setState((state) => ({
              ...state,
              showFlash: true,
              errorMsg: data.errors,
            }));
            this.updateLoaderState(false);
          }
        } else {
          this.setState((state) => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
                : 500,
            },
          }));
          this.updateLoaderState(false);
        }
      });
    }
  }

  decodeString = (str) => {
    let mypass = str;
    for (let i = 0; i < 16; i++) {
      mypass = Buffer.from(mypass, "base64").toString("binary");
    }
    return mypass;
  };

  isThirdPartyAppConfigured = () => {
    API.isThirdPartyAppConfigured().then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.errors) {
        if (data.isAppConfig) {
          this.setState((state) => ({
            ...state,
            isAppConfig: data.isAppConfig,
          }));
          return true;
        }
      }
    });
  };

  signInWithIntuit = () => {
    API.signInWithIntuit(this.props.history).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.errors) {
        window.open(data.data, "_self");
      }
    });
  };

  render() {
    const { isLoggedIn, user, location, heapEmail, heapAppId } = this.props;
    const { check, loading } = this.state;
    // heap 1119463531
    if (isLoggedIn && !user.verified) {
      return (
        <Redirect
          to={{
            pathname: "/confirm-account/phone",
            state: { from: location },
          }}
        />
      );
    } else if (isLoggedIn && user.super_admin) {
      return (
        <Redirect
          to={{
            pathname: "/super-admin/accounts",
            state: { from: location },
          }}
        />
      );
    } else if (isLoggedIn && user.admin) {
      return (
        <Redirect
          to={{
            pathname: "/admin/accounts",
            state: { from: location },
          }}
        />
      );
    } else if (user.is_onboarding_completed) {
      if (
        isLoggedIn &&
        !user.admin &&
        !user.super_admin &&
        !user.client_admin &&
        !user.is_employee &&
        user.onboarding_complete
      ) {
        return (
          <Redirect
            to={{
              pathname: "/dashboard/accounts/invoices",
              state: { from: location },
            }}
          />
        );
      } else if (isLoggedIn && !user.onboarding_complete) {
        return (
          <Redirect
            to={{
              pathname: "/onboarding/user-agreement",
              state: { from: location },
            }}
          />
        );
      }
      else if ((user.client_admin || user.is_employee) && !user.verified) {
        return (
          <Redirect
            to={{
              pathname: `/users/${user.user_id}/set_password`,
              state: { from: location },
            }}
          />
        );
      } else if (isLoggedIn && (user.client_admin || user.is_employee)) {
        return (
          <Redirect
            to={{
              pathname: "/client/dashboard",
              state: { from: location },
            }}
          />
        );
      }
    } else if (isLoggedIn && user.onboarding_complete === false) {
      if (user.client_admin) {
        return (
          <Redirect
            to={{
              pathname: "/eula",
              state: { from: location },
            }}
          />
        );
      } else {
        return (
          <Redirect
            to={{
              pathname: "/onboarding/user-agreement",
              state: { from: location },
            }}
          />
        );
      }
    } else if (isLoggedIn && user.is_onboarding_completed === false) {
      return (
        <Redirect
          to={{
            pathname: "/welcome-screen",
            state: { from: location },
          }}
        />
      );
    }

    let { appName } = this.props;

    return loading ? (
      <div className="loader_alignment">
        <div style={{ marginTop: "20%" }}>
          <AppCanvasLoader />
        </div>
      </div>
    ) : (
      <div
        className="sessions fill-screen mg-session"
        style={{ backgroundColor: "#f5f7f8" }}
      >
        <Helmet>
          <title>{appName} | Login</title>
        </Helmet>
        {heapAppId && heapEmail ? (
          <Heap appId={heapAppId} userId={heapEmail} />
        ) : null}

        <SessionsModal title="Sign into your account">
          <div className="sessions-modal-logo-container mg-logo-container">
            {check && (
              <Logo
                isDynamicLogo={true}
                logoUrl={this.state.appThemeData.logo_url}
              />
            )}
          </div>
          <div className="sessions-modal-content">
            <h1
              className="sessions-modal-heading"
              style={{ color: this.state.appThemeData.primary_color }}
            >
              Sign into your account
            </h1>
            <LoginForm
              updateUserLogin={this.props.updateUserLogin}
              updateModalState={this.props.updateModalState}
              primary_color={this.state.appThemeData.primary_color}
            />
            <div className="sessions-modal-alternative-actions">
              <span>Don't have an account?</span>
              <Link to="/register" className="text-link mg-text-link">
                Sign Up Now
              </Link>
            </div>
          </div>

          <div style={{ marginTop: "1rem" }}>
            <button
              className="sign-in-with-button"
              onClick={(event) => {
                this.signInWithIntuit();
              }}
            >
              <img
                src={this.state.signInWithIntuitImg}
                onMouseEnter={() => {
                  this.setState({
                    signInWithIntuitImg: signInWithIntuitHover,
                  });
                }}
                onMouseOut={() => {
                  this.setState({
                    signInWithIntuitImg: signInWithIntuit,
                  });
                }}
              />
            </button>
          </div>
        </SessionsModal>
      </div>
    );
  }
}

export default withRouter(withCookies(Login));
