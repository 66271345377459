import currencyFormatter from "currency-formatter";
import moment from "moment";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import shortid from "shortid";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import * as API from "../../utils/api";
import Panel from "../../components/App/Panel";
import editIcon from "../../assets/icons/edit_white.svg";
import editGreenIcon from "../../assets/icons/edit_green.svg";
import doneIcon from "../../assets/icons/checked_icon.svg";

class ClientsSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountNumber: "",
      isAccountEditMode: false,
      isAccountError: false,
      accountErrorText: "",
      isSubmitted: false,
      isLoading: false,
      isAccountBalance: 0,
    };
    this.textInput = React.createRef();
    this.focusTextInput = this.focusTextInput.bind(this);
  }
  componentDidMount() {
    this.setState({ accountNumber: this.props.account.account_number });
    this.getAccountAmount();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.account.account_number !== prevProps.account.account_number
    ) {
      this.setState({ accountNumber: this.props.account.account_number });
    }
    this.getAccountAmount();
  }

  focusTextInput() {
    this.setState({ isAccountEditMode: true }, () => {
      this.textInput.current.focus();
    });
  }

  getDate = (date) => {
    if (date) {
      return moment(date).format("MM/DD/YYYY") + " - ";
    }
    return "";
  };

  emptyView = (activities) => {
    return (
      <div className='empty-activity'>
        <p>There are currently no {activities} to review.</p>
      </div>
    );
  };

  onAccountNumberChange = (e) => {
    e.preventDefault();
    this.setState({ accountNumber: e.target.value });
    if (this.state.isSubmitted) {
      this.validateAccount(e.target.value);
    }
  };

  //get payment preferences data
  updateAccountNumber = (data) => {
    API.clientUpdateAccountNumber(this.props.user, {}, data).then((data) => {
      this.handleCommonResponse(data);
    });
  };

  handleCommonResponse = (data) => {
    if (data && data.status_code) {
      if (data.status_code == 200) {
        this.setState({
          isSubmitted: false,
          isAccountEditMode: false,
          isLoading: false,
        });
        this.props.updateModalState(true, "SUCCESS", {
          message: data.message,
        });
      } else {
        this.setState({ isLoading: false });
        this.props.updateModalState(true, "ERROR", {
          message: data.message,
        });
      }
      //this.setState({ isLoading: false });
    } else {
      this.setState({ isLoading: false });
      this.handleError(data);
    }
  };

  onSubmitAccountNo = () => {
    this.setState({ isSubmitted: true, isLoading: true });
    if (this.validateAccount(this.state.accountNumber)) {
      let data = {
        id: this.props.account.id,
        account_number: this.state.accountNumber
          ? this.state.accountNumber
          : "",
      };
      this.updateAccountNumber(data);
    } else {
      this.setState({ isLoading: false });
    }
  };

  validateAccount = (data) => {
    let status = true;
    let errorMsg = "";
    let accountRegex = /^(?!(0))(?!(-))(?!(_))[A-Za-z0-9-_]{1,10}$/g;
    /*if(data === '' || !data){
      status = false;
      errorMsg = 'Please enter account number.';
      this.setState({isAccountError: true, accountErrorText: errorMsg})
    }*/
    if (data && !accountRegex.test(data)) {
      status = false;
      errorMsg = "Please enter valid account number.";
      this.setState({ isAccountError: true, accountErrorText: errorMsg });
    }

    if (status === true) {
      this.setState({ isAccountError: false, accountErrorText: "" });
    }
    return status;
  };

  renderActivities = () => {
    let { upcoming_activities, recent_activities } = this.props;
    return (
      <div>
        <div
          className='panel-heading-secondary'
          style={{ marginBottom: "1em", marginTop: "2em" }}
        >
          Activity Feed
        </div>
        <Panel
          heading='Upcoming Activities'
          optionalClasses='activity-panel scrollbar'
        >
          {upcoming_activities && upcoming_activities.length ? (
            <div className='content activity-text'>
              <ul>
                {upcoming_activities.map((item, index) => {
                  return (
                    <div
                      className={`${index % 2 == 0 ? "alt-row" : ""}`}
                      key={"up" + shortid.generate()}
                    >
                      <li>{this.getDate(item.due_date) + item.text}</li>
                    </div>
                  );
                })}
              </ul>
            </div>
          ) : (
            this.emptyView("Upcoming Activities")
          )}
        </Panel>
        <Panel
          heading='Recent Activities'
          optionalClasses='activity-panel scrollbar'
        >
          {recent_activities && recent_activities.length ? (
            <div className='content activity-text'>
              <ul>
                {recent_activities.map((item, index) => {
                  return (
                    <div
                      className={`${index % 2 == 0 ? "alt-row" : ""}`}
                      key={"rec" + shortid.generate()}
                    >
                      <li>{this.getDate(item.due_date) + item.text}</li>
                    </div>
                  );
                })}
              </ul>
            </div>
          ) : (
            this.emptyView("Recent Activities")
          )}
        </Panel>
      </div>
    );
  };

  renderAddress(account) {
    if (
      account.street_address_primary ||
      account.street_address_secondary ||
      account.city ||
      account.state ||
      account.zipcode
    ) {
      return (
        <h4 className='ic-sp'>
          {account.street_address_primary ? account.street_address_primary : ""}
          {account.street_address_primary && <br />}
          {account.street_address_secondary
            ? account.street_address_secondary
            : ""}
          {account.street_address_secondary && <br />}
          {account.city ? account.city : ""}
          {account.city && account.state ? ", " : ""}
          {account.state ? account.state : ""}
          {account.state && account.zipcode ? " " : ""}
          {account.zipcode ? account.zipcode : ""}
        </h4>
      );
    }
  }

  getTitle = (user) => {
    let fullname = "";
    if (user.company_name) {
      fullname = user.company_name;
    } else if (user.display_name) {
      fullname = user.display_name;
    }
    return fullname;
  };

  getAccountAmount = () => {
    const { account, totalAccountCredit } = this.props;
    let amountOwed = 0.0;

    if (
      account &&
      account.hasOwnProperty("total_debt_owed") &&
      account.total_debt_owed
    ) {
      const debtOwed = parseFloat(account.total_debt_owed.replace(/,/g, ""));
      amountOwed = debtOwed - parseFloat(totalAccountCredit);
    } else {
      amountOwed = -parseFloat(totalAccountCredit);
    }

    // Only update state if the new amountOwed is different from the current state
    if (this.state.isAccountBalance !== amountOwed) {
      this.setState({
        isAccountBalance: amountOwed,
      });
    }
  };

  render() {
    const {
      optionalClassNames,
      account,
      backPath,
      backText,
      totalAccountCredit,
    } = this.props;
    let amountOwed;
    const id = this.props.match.params.id;
    const title = this.getTitle(account);

    let firstName = account.first_name ? account.first_name : "";
    let lastName = account.last_name ? account.last_name : "";

    return (
      <div className='app-sidebar app-sidebar-left accounts-view-sidebar clients-side-bar col1-scroll scrollbar'>
        <div
          className={`app-section-header client-section-header${
            optionalClassNames ? " " + optionalClassNames : ""
          }`}
          style={{ flexDirection: "column", alignItems: "flex-start" }}
        >
          <div className='app-section-header-content'>
            <Link to={backPath} className='app-section-header-back-link'>
              « {backText}
            </Link>
            <div
              className='app-section-header-heading side-hed-col account-wrapper'
              style={{ fontSize: "2em" }}
            >
              <span>{title}</span>
              <img
                src={editIcon}
                onClick={() => this.props.handleEditSetting()}
              />
            </div>
          </div>
        </div>

        <div className='invoice-amt-owed'>
          {currencyFormatter.format(this.state.isAccountBalance, {
            code: "USD",
          })}
        </div>
        <div className='invoice-sidebar' style={{ marginTop: "1.5em" }}>
          {account && (firstName || lastName) ? (
            <h4
              className='panel-heading-secondary'
              style={{ fontSize: "1em", fontWeight: 600 }}
            >
              {account && firstName + " " + lastName}
            </h4>
          ) : null}
          {account && account.email ? (
            <h4 className='panel-heading-secondary ic-sp'>{account.email}</h4>
          ) : null}
          {account && account.phone_number ? (
            <h4 className='panel-heading-secondary ic-sp'>
              {account.phone_number}
            </h4>
          ) : null}
          {account ? this.renderAddress(account) : null}

          {/* Read/Edit Account number*/}
          <div className='account-number-wrapper'>
            <div className='account-number-field-div'>
              <label htmlFor='accountNo'>Account Number</label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <input
                  type='text'
                  ref={this.textInput}
                  value={
                    this.state.accountNumber ? this.state.accountNumber : ""
                  }
                  name='accountNo'
                  id='accountNo'
                  maxLength={10}
                  onChange={(e) => this.onAccountNumberChange(e)}
                  disabled={this.state.isAccountEditMode ? false : true}
                  className={`account-input ${
                    this.state.isAccountEditMode &&
                    this.state.isSubmitted &&
                    this.state.isAccountError
                      ? "error-mode"
                      : this.state.isAccountEditMode
                      ? "edit-mode"
                      : ""
                  }`}
                />
                <div className='action-wrapper'>
                  {this.state.isAccountEditMode && !this.state.isLoading ? (
                    <img
                      src={doneIcon}
                      className='save-icon'
                      title={"Save Account Number"}
                      onClick={this.onSubmitAccountNo}
                    />
                  ) : this.state.isAccountEditMode && this.state.isLoading ? (
                    <AppCanvasLoader />
                  ) : (
                    <img
                      src={editGreenIcon}
                      className='edit-icon'
                      title={"Edit Account Number"}
                      onClick={this.focusTextInput}
                    />
                  )}
                </div>
              </div>
            </div>
            {this.state.isAccountEditMode &&
            this.state.isSubmitted &&
            this.state.isAccountError ? (
              <div className='account-error'>{this.state.accountErrorText}</div>
            ) : null}
          </div>

          {account.terms_name ? (
            <div>
              <label htmlFor='accountNo'>Default Term</label>
              <div className='invoice-amt-owed' style={{ fontSize: "18px" }}>
                {account.terms_name !== "" ? account.terms_name : ""}
              </div>
            </div>
          ) : null}

          {this.renderActivities()}
          {/* <div className="history-btn">
            <button
              style={{ marginLeft: 0 }}
              onClick={() => this.props.handleEditSetting()}
            >
              Edit Settings
            </button>
          </div> */}
        </div>
      </div>
    );
  }
}

export default ClientsSidebar;
