import $ from "jquery";
import { Multiselect } from "multiselect-react-dropdown";
import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import Select from "react-select";
import "react-select/dist/react-select.css";
// API
import * as API from "../../../../utils/api";
import { showToast, validateEmail } from "../../../../utils/helpers";
import AppCanvasLoader from "../../../App/AppCanvasLoader";
import Modal from "../Modal";
import PreviewTemplateModal from "./PreviewTemplateModal";

class SendNewMailModal extends Component {
  state = {
    consumerData: {},
    templateData: [],
    filterdTemplateData: [],
    mergeVaribales: [],
    mergeVaribalesValues: {},
    checkArray: [],
    options: [],
    optionalKeys: [
      "ORG_PAYABLE_TO",
      "ORG_PAYMENT_ADDRESS_LINE1",
      "ORG_PAYMENT_ADDRESS_CITY",
      "ORG_PAYMENT_ADDRESS_STATE",
      "ORG_PAYMENT_ADDRESS_ZIP",
      "ORG_BANK_NAME_ACH",
      "ORG_BANK_ADDR_LINE1_ACH",
      "ORG_BANK_ADDR_CITY_ACH",
      "ORG_BANK_ADDR_STATE_ACH",
      "ORG_BANK_ADDR_ZIP_ACH",
      "ORG_BANK_ABA_ACH",
      "ORG_BANK_ACCT_ACH",
      "ORG_BANK_NAME_DOM_WIRE",
      "ORG_BANK_ADDR__LINE1_DOM_WIRE",
      "ORG_BANK_ADDR_CITY_DOM_WIRE",
      "ORG_BANK_ADDR_STATE_DOM_WIRE",
      "ORG_BANK_ADDR_ZIP_DOM_WIRE",
      "ORG_BANK_ABA_DOM_WIRE",
      "ORG_BANK_ACCT_DOM_WIRE",
    ],
    document_ids: [],
    dropdownIsActive: false,
    mergeVaribalesValuesError: false,
    isLoading: true,
    missingMergeVariable: "",
    isUpload: false,
    isPreview: false,
    isTempleteDisable: false,
    toArray: [],
    ccArray: [],
    orgMergeData: [],
    input: {
      name: {
        value: "",
        hasError: false,
      },
      email: {
        value: "",
        hasError: false,
      },
      cc_email: {
        value: "",
        hasError: false,
      },
    },
    message: {
      value: "",
      hasError: false,
    },
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
    showFlash: false,
    flashMessage: "",
    isMultiTaskInvoiceSelected: false,
    defaultSelected: [],
    bool: true,
  };

  componentDidMount() {
    let { optionalProps } = this.props;
    let {
      invoiceDetails,
      isMultipleInvoices,
      multipleInvoiceData,
      isPaymentPlanModel,
      isPaymentPlanCommModel,
      consumer,
      doc_data,
    } = optionalProps;
    let me = this;
    if (invoiceDetails && invoiceDetails.user) {
      this.setState({ consumerData: invoiceDetails.user }, () => {
        this.getContactDetails(this.state.consumerData.user_id);
        this.getOrgData(this.state.consumerData.user_id);
      });
    }
    if (isMultipleInvoices) {
      this.setState({ isTempleteDisable: true });
      this.getMultiIncoiceDocumentData(multipleInvoiceData.invoiceListArray);
    } else if (isPaymentPlanCommModel) {
      this.setState({ consumerData: consumer }, () => {
        this.getContactDetails(this.state.consumerData.id);
        this.setOptionsData(doc_data);

        this.getOrgData(this.state.consumerData.id);
      });
    } else {
      this.getIncoiceDocumentData();
    }
    if (isPaymentPlanModel) {
      this.getMultiIncoiceDocumentData(optionalProps.payload.invoice_ids);
    }

    if (isPaymentPlanModel || isPaymentPlanCommModel) {
      API.getPaymentPlansTemplate(this.props.user).then((data) => {
        if ((typeof data != "undefined" || data != null) && !data.error) {
          //   if (data.error) {
          //     showToast(data.error);
          //   } else {
          //     if (data.data && data.data.length > 0) {
          if (
            optionalProps.hasOwnProperty("payload") &&
            optionalProps.payload
          ) {
            this.setState({ templateData: data.list }, () => {
              this.setTemplateData();
            });

            this.setState(
              {
                message: {
                  value: optionalProps.payload.is_accepted
                    ? "settlement-offer-accepted-payment-plan-active"
                    : "mg-settlement-offer-initial-offer-email",
                  hasError: false,
                },
              },
              () => {
                this.getMergeVariables();
              }
            );
          } else {
            this.setState({ templateData: data.list }, () => {
              this.setTemplateData();
            });
          }
          //         this.setTemplateData();
          //         if (isPaymentPlanModel) {
          //           this.setState(
          //             {
          //               message: {
          //                 value: "mg-settlement-offer-initial-offer-email",
          //                 hasError: false,
          //               },
          //             },
          //             () => {
          //               this.getMergeVariables();
          //             }
          //           );
          //         }
          //         if (isMultipleInvoices) {
          //           this.setState(
          //             {
          //               message: {
          //                 value: "mg-send-multiple-invoices",
          //                 hasError: false,
          //               },
          //             },
          //             () => {
          //               this.getMergeVariables();
          //             }
          //           );
          //         }
          //       });
          //     }
          //   }
          this.setState({ isLoading: false });
        } else {
          this.setState((state) => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.message && data.message == "Access denied."
                    ? 401
                    : 500) ||
                  500
                : 500,
            },
          }));
        }
      });
    } else {
      API.getMandrillTemplate(
        this.props.user,
        isMultipleInvoices ? "mg-send-multiple-invoices" : "",
        this.props.optionalProps.type
      ).then((data) => {
        if ((typeof data != "undefined" || data != null) && !data.error) {
          if (data.error) {
            showToast(data.error);
          } else {
            if (data.data && data.data.length > 0) {
              this.setState({ templateData: data.data }, () => {
                this.setTemplateData();
                if (isPaymentPlanModel) {
                  this.setState(
                    {
                      message: {
                        value: "mg-settlement-offer-initial-offer-email",
                        hasError: false,
                      },
                    },
                    () => {
                      this.getMergeVariables();
                    }
                  );
                }
                if (isMultipleInvoices) {
                  this.setState(
                    {
                      message: {
                        value: "mg-send-multiple-invoices",
                        hasError: false,
                      },
                    },
                    () => {
                      this.getMergeVariables();
                    }
                  );
                }
              });
            }
          }
          this.setState({ isLoading: false });
        } else {
          this.setState((state) => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.message && data.message == "Access denied."
                    ? 401
                    : 500) ||
                  500
                : 500,
            },
          }));
        }
      });
    }
  }

  getMultiDocListData = () => {
    let selectedList = [];
    this.state.options.map((item) => {
      if (item.is_selected) {
        selectedList.push(item);
      }
    });
    if (selectedList.length > 0) {
      this.onSelect(selectedList);
    }
  };

  getTasksList = () => {
    let me = this;
    API.getTasksList(this.props.user).then((data) => {
      //Emitter.emit('IS_QUICKBOOK_CONNECTED', data.qb_connected);
      if (
        typeof data != "undefined" ||
        (data != null && data.status_code && data.status_code === 200)
      ) {
        if (data.data) {
          data.data.map((taskListData) => {
            if (taskListData.name == "Send Multiple Invoices To Client") {
              taskListData.document_types.map((docTypeData) => {
                if (docTypeData.name == "Invoice") {
                  let selectedList = [];
                  me.state.options.map((item) => {
                    if (item.description == "MakeGood Invoice PDF") {
                      selectedList.push(item);
                    }
                  });
                  if (selectedList.length > 0) {
                    me.onSelect(selectedList);
                    this.setDefaultOptionsData(selectedList);
                  }
                }
              });
            }
          });
        }
        this.setState({ isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
          showLoader: false,
        }));
      }
    });
  };

  setDefaultOptionsData(data) {
    let { optionalProps } = this.props;
    let { isPaymentPlanCommModel, isPaymentPlanModel } = optionalProps;

    if (data && data.length) {
      data.map((item) => {
        item.id = item.document_id;
        item.name = item.name;
      });
      this.setState({ defaultSelected: data });
    } else {
      this.setState({ defaultSelected: [] });
    }
  }

  getContactDetails = (id) => {
    API.getContactSettings(this.props.user, id).then((data) => {
      if (data && data.status_code == 200 && data.data) {
        this.setState(
          {
            toArray: data.data.to_emails ? data.data.to_emails : [],
            ccArray: data.data.cc_emails ? data.data.cc_emails : [],
          },
          () => {
            this.setConsumerData();
          }
        );
      } else {
        this.setConsumerData();
      }
    });
  };

  getOrgData = (id) => {
    API.getOrgMergeVeriableData(this.props.user, id).then((data) => {
      if (data && data.status_code == 200 && data.data) {
        let dataArray = [];
        dataArray.push(data.data);
        this.restructOrgData(dataArray);
      } else {
      }
    });
  };

  getIncoiceDocumentData() {
    const invoiceNumber = this.props.optionalProps.invoiceDetails.id;
    API.getInvoiceDocuments(this.props.user, invoiceNumber).then((data) => {
      let isAccessDenied =
        data && data.message && data.message == "Access denied." ? true : false;
      if (data && data.data && !isAccessDenied) {
        this.setOptionsData(data.data);
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  }

  getMultiIncoiceDocumentData(ids) {
    let payload = {
      invoice_ids: ids,
    };
    API.getMultiInvoiceDocuments(this.props.user, payload).then((data) => {
      let isAccessDenied =
        data && data.message && data.message == "Access denied." ? true : false;
      if (data && data.data && !isAccessDenied) {
        this.setOptionsData(data.data, true);
        this.getMultiDocListData();
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  }

  restructOrgData = (data) => {
    let tempArray = [];
    Object.entries(data).map((item) => {
      Object.entries(item[1]).map((itemx) => {
        if (Array.isArray(itemx[1])) {
          itemx[1].map((val, valIndex) => {
            tempArray.push(val);
          });
        } else {
          let obj = {
            variable_key: "ORG_LOGO",
            value: itemx[1],
          };
          tempArray.push(obj);
        }
      });
    });
    this.setState({ orgMergeData: tempArray });
  };

  setOptionsData = (data, isMultiInvoice) => {
    let { optionalProps } = this.props;
    if (isMultiInvoice) {
      if (data && data.length) {
        data.map((item) => {
          item.id = item.document_id;
          item.name = item.name + "- #" + item.invoice_number;
        });
        this.setState({ options: data });
      }
    } else if (
      optionalProps.hasOwnProperty("isPaymentPlanCommModel") &&
      optionalProps.isPaymentPlanCommModel
    ) {
      if (data && data.length) {
        data.map((item) => {
          item.name = item.document_name;
          item.document_id = item.id;
        });
        this.onSelect(data);

        this.setState({ options: data });
      }
    } else {
      if (data && data.length) {
        data.map((item) => {
          item.id = item.document_id;
          item.name = item.name;
        });
        this.setState({ options: data });
      }
    }
  };

  getMergeVariables() {
    this.setState({ isUpload: true });
    API.getMergeVariables(this.props.user, this.state.message.value).then(
      (data) => {
        if ((typeof data != "undefined" || data != null) && !data.error) {
          if (data.error) {
            showToast(data.error);
          } else {
            if (data && data.data) {
              this.setState({ mergeVaribales: data.data }, () => {
                this.autoFillMergeVariablies();
              });
            }
          }
          this.setState({ isUpload: false });
        } else {
          this.setState((state) => ({
            ...state,
            isUpload: false,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.message && data.message == "Access denied."
                    ? 401
                    : 500) ||
                  500
                : 500,
            },
          }));
        }
      }
    );
  }

  onSelect = (selectedList) => {
    this.setDefaultOptionsData(selectedList);
    let temp_id = [];
    if (selectedList && selectedList.length > 0) {
      selectedList.map((item) => {
        temp_id.push(item.document_id);
      });

      this.setState((state) => ({
        ...state,
        document_ids: [...new Set(temp_id)],
      }));
    }
  };

  onRemove = (selectedList, removedItem) => {
    let { document_ids } = this.state;
    if (removedItem.hasOwnProperty("document_id")) {
      const index = document_ids.indexOf(removedItem.document_id);
      if (index > -1) {
        document_ids.splice(index, 1);
      }
      this.setState({ document_ids: document_ids });
    }
    this.setDefaultOptionsData(selectedList);
  };

  setConsumerData() {
    let { consumerData, toArray, ccArray } = this.state;
    if (consumerData.email) {
      if (toArray & toArray.length) {
        let i = toArray.findIndex((email) => email == consumerData.email);
        if (i == -1) {
          toArray.push(consumerData.email);
        }
      }
    }
    if (consumerData.cc_email) {
      if (ccArray & ccArray.length) {
        let i = ccArray.findIndex((email) => email == consumerData.cc_email);
        if (i == -1) {
          ccArray.push(consumerData.cc_email);
        }
      }
    }
    this.state.input.name.value =
      this.state.consumerData.first_name +
      " " +
      this.state.consumerData.last_name;
    this.setState({
      input: this.state.input,
      toArray,
      ccArray,
    });
  }

  autoFillMergeVariablies = (flag, value) => {
    let { optionalProps } = this.props;
    let { name } = this.state.input;
    let { multipleInvoiceData, isPaymentPlanCommModel, isPaymentPlanModel } =
      optionalProps;

    if (this.state.mergeVaribales) {
      this.state.mergeVaribales.map((item, i) => {
        if (item === "FNAME") {
          this.handleMergeVariableValue(
            flag
              ? value
              : isPaymentPlanCommModel
              ? optionalProps.first_name
              : this.state.input.name.value,
            item,
            true,
            i
          );
        } else if (item === "INVOICE_NUMBER" || item === "INVOICE_NUMBERS") {
          if (
            optionalProps.hasOwnProperty("isMultipleInvoices") &&
            optionalProps.isMultipleInvoices
          ) {
            this.handleMergeVariableValue(
              optionalProps.multipleInvoiceData.invoiceListArray,
              item,
              true
            );
          } else {
            this.handleMergeVariableValue(
              optionalProps.invoiceDetails.invoice_number,
              item,
              true
            );
          }
        } else if (item == "NAME") {
          this.handleMergeVariableValue(
            this.props.optionalProps.invoiceDetails.user.first_name +
              " " +
              this.props.optionalProps.invoiceDetails.user.first_name,
            item,
            true
          );
        } else if (item == "EMAIL") {
          this.handleMergeVariableValue(
            this.props.optionalProps.invoiceDetails.user.email,
            item,
            true
          );
        } else if (item == "PHONE_NUMBER") {
          this.handleMergeVariableValue(
            this.props.optionalProps.invoiceDetails.user.mobile_number
              ? this.props.optionalProps.invoiceDetails.user.mobile_number
              : " - - ",
            item,
            true
          );
        } else if (item == "DUE_DATE") {
          this.handleMergeVariableValue(
            this.props.optionalProps.invoiceDetails.due_date,
            item,
            true
          );
        } else if (item == "BALANCE_DUE") {
          this.handleMergeVariableValue(
            this.props.optionalProps.invoiceDetails.balance,
            item,
            true
          );
        } else if (item == "DAYS_OVERDUE") {
          let date1 = new Date(
            this.props.optionalProps.invoiceDetails.due_date
          );
          let date2 = new Date();
          let time_difference = date2.getTime() - date1.getTime();
          let due_days = parseInt(time_difference / (1000 * 60 * 60 * 24));
          this.handleMergeVariableValue(
            due_days >= 0 ? due_days : 0,
            item,
            true
          );
        } else if (item == "AMOUNT") {
          this.handleMergeVariableValue(
            this.props.optionalProps.invoiceDetails.total_amount,
            item,
            true
          );
        } else if (item == "PAYMENT_AMOUNT") {
          this.handleMergeVariableValue(
            isPaymentPlanCommModel
              ? optionalProps.payment_amount
              : optionalProps.isPaymentPlanModel
              ? optionalProps.payload.monthly_payment_amount
              : optionalProps.invoiceDetails.balance,
            item,
            true
          );
        } else if (item == "PLAN_AMOUNT") {
          this.handleMergeVariableValue(
            isPaymentPlanCommModel
              ? optionalProps.plan_amount
              : optionalProps.payload.payment_plan_amount,
            item,
            true
          );
        } else if (item == "PAYMENT_AMOUNT_DUE") {
          this.handleMergeVariableValue(
            optionalProps.payment_amount_due,
            item,
            true
          );
        } else if (item == "PAYMENT_PLAN_NUMBER") {
          this.handleMergeVariableValue(
            optionalProps.payment_plan_number,
            item,
            true
          );
        } else if (item == "PAYMENT_DUE_DATE") {
          this.handleMergeVariableValue(
            optionalProps.payment_due_date,
            item,
            true
          );
        } else if (item == "PAYMENT_PLAN_STATUS") {
          this.handleMergeVariableValue(
            optionalProps.payment_plan_status,
            item,
            true
          );
        } else if (item == "PAYMENT_PLAN_PAYMENT_NUMBER") {
          this.handleMergeVariableValue(
            optionalProps.payment_plan_payment_number,
            item,
            true
          );
        } else if (item == "REPORT_NAME") {
          this.handleMergeVariableValue("REPORT_NAME", item, true);
        } else if (item == "DATE_TIME") {
          this.handleMergeVariableValue("DATE:", item, true);
        } else if (item == "ACCOUNT_BALANCE") {
          this.handleMergeVariableValue(
            this.props.optionalProps.invoiceDetails.user.account_balance,
            item,
            true
          );
        } else if (item === "CUSTOM_URL") {
          let url =
            window && window.location && window.location.origin
              ? window.location.origin
              : "";
          this.handleMergeVariableValue(url, item, true);
        } else if (item == "NUMBER_OF_MONTHS_OR_WEEKS") {
          this.handleMergeVariableValue(
            isPaymentPlanCommModel
              ? this.props.optionalProps.number_of_payments
              : this.props.optionalProps.hasOwnProperty("payload") &&
                this.props.optionalProps.payload.hasOwnProperty(
                  "number_of_payments"
                )
              ? this.props.optionalProps.payload.number_of_payments
              : null,
            item,
            true
          );
        } else if (item == "WEEKS_OR_MONTHS") {
          this.handleMergeVariableValue(
            isPaymentPlanCommModel &&
              this.props.optionalProps.hasOwnProperty("is_months_plan") &&
              this.props.optionalProps.is_months_plan
              ? "months"
              : isPaymentPlanModel &&
                this.props.optionalProps.payload.is_months_plan
              ? "months"
              : "weeks",
            item,
            true
          );
        } else if (item == "FIRST_PAYMENT_DUE") {
          this.handleMergeVariableValue(
            isPaymentPlanCommModel
              ? this.props.optionalProps.start_date
              : this.props.optionalProps.hasOwnProperty("payload") &&
                this.props.optionalProps.payload.hasOwnProperty("start_date")
              ? this.props.optionalProps.payload.start_date
              : null,
            item,
            true
          );
        }
      });
    }
    this.setOrgMergeVeriables();
    this.setState({ bool: false });
  };

  setOrgMergeVeriables = () => {
    const { orgMergeData } = this.state;
    let flagToCheckORGpaymentLink = false;
    let flagToCheckEXPRESSpaymentLink = false;
    let idx = 0;
    let { optionalProps } = this.props;
    let { multipleInvoiceData } = optionalProps;
    if (this.state.mergeVaribales) {
      //colors
      orgMergeData.map((val, index) => {
        this.state.mergeVaribales.map((item, i) => {
          if (item === val.variable_key) {
            if (
              val.variable_key === "ALT_TEXT_COLOR" ||
              val.variable_key === "BACKGROUND_COLOR"
            ) {
              let value = "";
              if (val.value === null) {
                value = val.default_value;
              } else {
                value = val.value;
              }
              this.handleMergeVariableValue(value, item, true, i);
            } else {
              this.handleMergeVariableValue(val.value, item, true, i);
            }
          } else if (item == "PAYMENT_LINK") {
            if (val.name == "Payment Portal URL") {
              let payment_url = val.value;
              this.handleMergeVariableValue(payment_url, item, true);
            }
          } else if (item == "IF:ORG_PAYMENT_ADDRESS_ZIP") {
            this.handleMergeVariableValue(val.value, item, true);
          } else if (item == "ELSE") {
            this.handleMergeVariableValue(val.value, item, true);
          } else if (item === "INVOICE_NUMBERS") {
            if (
              multipleInvoiceData &&
              multipleInvoiceData.hasOwnProperty("invoiceListSTR")
            ) {
              this.handleMergeVariableValue(
                multipleInvoiceData.invoiceListSTR,
                item,
                true
              );
            } else {
              this.handleMergeVariableValue(
                this.props.optionalProps.invoiceDetails.invoice_number,
                item,
                true
              );
            }
          }
          if (val.name == "Payment Portal URL" && item == "ORG_PAYMENT_LINK") {
            idx = i;
          }
          if (
            val.name == "Payment Portal URL" &&
            val.value !== null &&
            val.value !== "" &&
            item == "ORG_PAYMENT_LINK" &&
            !this.props.optionalProps.hasOwnProperty("isPaymentPlanModel")
          ) {
            flagToCheckORGpaymentLink = true;
            let payment_url = `${val.value}?invoice_no=${this.props.optionalProps.invoiceDetails.invoice_number}&amt=${this.props.optionalProps.invoiceDetails.total_amount}`;
            this.handleMergeVariableValue(payment_url, item, true, i);
          }
          if (
            val.name == "Express" &&
            val.value !== null &&
            val.value !== "" &&
            item == "ORG_PAYMENT_LINK" &&
            !this.props.optionalProps.hasOwnProperty("isPaymentPlanModel")
          ) {
            flagToCheckEXPRESSpaymentLink = true;
            this.handleMergeVariableValue(val.value, item, true, i);
          }
          if (
            !flagToCheckORGpaymentLink &&
            !flagToCheckEXPRESSpaymentLink &&
            !this.props.optionalProps.hasOwnProperty("isPaymentPlanModel") &&
            !this.props.optionalProps.hasOwnProperty(
              "isPaymentPlanCommModel"
            ) &&
            !this.props.optionalProps.hasOwnProperty("isMultipleInvoices") &&
            item === "ORG_PAYMENT_LINK"
          ) {
            this.handleMergeVariableValue(null, "ORG_PAYMENT_LINK", true, idx);
          }
        });
      });
    }
  };

  setTemplateData() {
    if (this.state.templateData) {
      this.state.templateData.map((item, index) => {
        let data = {
          label: item.name ? item.name : "",
          value: item.slug ? item.slug : "",
          data: item ? item : "",
        };
        this.state.filterdTemplateData.push(data);
        this.setState({ filterdTemplateData: this.state.filterdTemplateData });
      });
    }
  }

  handleInputChange(newPartialInput, flag, value) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      },
    }));
    if (
      this.props.optionalProps.isPaymentPlanModel ||
      this.props.optionalProps.isPaymentPlanCommModel
    ) {
      if (flag) {
        this.autoFillMergeVariablies(true, value);
      }
    }
  }

  handleInputChangeForMsg(msg, data) {
    this.setState(
      {
        ...this.state.message.value,
        ...msg,
      },
      () => {
        this.getMergeVariables();
      }
    );
  }

  handleSubmit = () => {
    let { isPaymentPlanModel, payload, user } = this.props.optionalProps;
    if (isPaymentPlanModel) {
      if (this.validateForm()) {
        API.createPaymentPlan(user, payload).then((data) => {
          if (data && data.status_code && data.status_code == 200) {
            this.props.optionalProps.handleModalClose();
            this.handleSubmitSendEmail();

            this.props.updateModalState(true, "SUCCESS", {
              message: data.message,
              title: "SUCCESS",
            });
          } else if (
            (data && data.status_code == 400) ||
            data.status_code == 500 ||
            data.status_code == 404
          ) {
            this.props.optionalProps.handleModalClose();
            this.props.updateModalState(true, "ERROR", {
              message: data.message,
              title: "Error!",
            });
          } else {
            this.props.optionalProps.handleModalClose();
            this.props.updateModalState(true, "ERROR", {
              message: "Access denied.",
              title: "Error!",
            });
            this.setState((state) => ({
              ...state,
              error: {
                hasAnyError: true,
                statusCode: data
                  ? data.status ||
                    (data.message && data.message == "Access denied."
                      ? 401
                      : 500) ||
                    500
                  : 500,
              },
              isLoading: false,
            }));
          }
          this.props.optionalProps.handleModalCloseBackToPaymentPlans();
        });
      }
    } else {
      this.handleSubmitSendEmail();
    }
  };

  handleSubmitSendEmail = () => {
    const {
      input,
      message,
      mergeVaribalesValues,
      document_ids,
      toArray,
      ccArray,
    } = this.state;
    let { optionalProps } = this.props;
    let { isMultipleInvoices, invoiceDetails, isPaymentPlanCommModel } =
      optionalProps;
    let payload = [];
    if (isPaymentPlanCommModel) {
      payload = {
        name: input.name.value,
        email: toArray,
        cc_email: ccArray,
        template: message.value,
        merge_variables: mergeVaribalesValues,
        payment_plan_id: optionalProps.payment_plan_id,
        pp_document_id: this.state.document_ids,
      };
    } else {
      payload = {
        name: input.name.value,
        email: toArray,
        cc_email: ccArray,
        template: message.value,
        merge_variables: mergeVaribalesValues,
        document_ids: this.state.document_ids,
        invoice_id: invoiceDetails.id,
        account_balance: invoiceDetails.user.account_balance,
      };
    }
    if (this.validateForm()) {
      // $("#send-new-mail").replaceWith('<div class="spinner"></div>');
      if (isMultipleInvoices) {
        this.sendMultipleInvoiceEmail(payload);
      } else if (isPaymentPlanCommModel) {
        API.sendPaymentPlanEmail(this.props.user, payload).then((data) => {
          if ((typeof data != "undefined" || data != null) && !data.error) {
            if (data.status_code != 200) {
              // this.handleModalClose();
              this.props.updateModalState(true, "ERROR", {
                message: data.message,
                title: "Error!",
              });
            } else {
              this.props.hideModal();
              this.props.updateModalState(true, "SUCCESS", {
                message: data.message,
              });
            }
          } else {
            this.setState((state) => ({
              ...state,
              error: {
                hasAnyError: true,
                statusCode: data
                  ? data.status ||
                    (data.message && data.message == "Access denied."
                      ? 401
                      : 500) ||
                    500
                  : 500,
              },
            }));
          }
        });
      } else {
        API.sendMandrillTemplate(
          this.props.user,
          payload,
          this.props.optionalProps.consumerId
        ).then((data) => {
          if ((typeof data != "undefined" || data != null) && !data.error) {
            if (data.status_code != 200) {
              // this.handleModalClose();
              this.props.updateModalState(true, "ERROR", {
                message: data.message,
                title: "Error!",
              });
            } else {
              if (!optionalProps.isPaymentPlanModel) {
                this.handleModalClose();
                optionalProps.reloadData();
              }
              this.props.updateModalState(true, "SUCCESS", {
                message: data.message,
              });
            }
          } else {
            this.setState((state) => ({
              ...state,
              error: {
                hasAnyError: true,
                statusCode: data
                  ? data.status ||
                    (data.message && data.message == "Access denied."
                      ? 401
                      : 500) ||
                    500
                  : 500,
              },
            }));
          }
        });
      }
    }
  };

  sendMultipleInvoiceEmail = (payload) => {
    API.sendMultipleMandrillTemplate(
      this.props.user,
      payload,
      this.props.optionalProps.consumerId
    ).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        if (data.error) {
          showToast(data.error);
        } else {
          this.handleModalClose();
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message,
          });
          this.props.optionalProps.closeOpenInvoiceView();
        }
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  validateForm = () => {
    let errorsArePresent = false;
    let { toArray, ccArray } = this.state;

    // Determine which input group to check for errors
    let input = { ...this.state.input };
    let message = { ...this.state.message };

    Object.entries(input).forEach(([key, value]) => {
      if (key === "name") {
        if (
          value.value === "" ||
          value.value === null ||
          value.value === undefined
        ) {
          input[key].hasError = true;
          errorsArePresent = true;
        }
      }
      if (key === "email") {
        if (
          value.value === "" ||
          value.value === null ||
          value.value === undefined ||
          typeof value.value === undefined
        ) {
          if (toArray.length <= 0) {
            input[key].hasError = true;
            errorsArePresent = true;
          }
        }
        if (value.value) {
          if (validateEmail(value.value)) {
            toArray.push(value.value);
            value.value = "";
            input[key].hasError = false;
          } else {
            input[key].hasError = true;
            errorsArePresent = true;
          }
        }
      }
      if (key === "cc_email") {
        if (
          value.value === "" ||
          value.value === null ||
          value.value === undefined ||
          typeof value.value === undefined
        ) {
          if (!ccArray.length <= 0) {
            input[key].hasError = false;
          }
        }
        if (value.value) {
          if (validateEmail(value.value)) {
            ccArray.push(value.value);
            value.value = "";
          } else {
            input[key].hasError = true;
            errorsArePresent = true;
          }
        }
      }
      if (
        value.isRequired &&
        (value.value === "" ||
          value.value === null ||
          value.value === undefined)
      ) {
        input[key].hasError = true;
        errorsArePresent = true;
      }
    });

    this.setState({ input, toArray, ccArray });

    if (message.value == "") {
      message.hasError = true;
      errorsArePresent = true;
    }
    if (
      !this.state.mergeVaribalesValues ||
      !(this.state.checkArray.length === this.state.mergeVaribales.length)
    ) {
      const missingVariables = this.state.mergeVaribales.filter((variable) => {
        return !this.state.checkArray.some(
          (item) => Object.keys(item)[0] === variable
        );
      });
      this.setState({
        mergeVaribalesValuesError: true,
        missingMergeVariable: missingVariables.map((item) => ` ${item}`),
      });
      errorsArePresent = true;
    }

    if (errorsArePresent) {
      this.setState((state) => ({
        ...state,
        input: input,
        message: message,
      }));

      return false;
    } else {
      return true;
    }
  };

  renderErrorMessage(input) {
    let message = "";

    if (input === "name") {
      message = "Please enter name";
    } else if (input === "email") {
      message = "Please enter a valid email";
    } else if (input === "cc_email") {
      message = "Please enter a valid email";
    } else if (input === "message") {
      message = "Please select template";
    } else {
      message = "Please complete this field";
    }

    return <div className='input-error-message'>{message}</div>;
  }

  handleModalClose = () => {
    this.props.hideModal();
  };

  handleClosePaymentPlan = () => {
    let { isPaymentPlanModel } = this.props.optionalProps;
    this.props.hideModal();
    if (isPaymentPlanModel) {
      this.props.optionalProps.handleModalCloseBackToPaymentPlans();
    }
  };
  dismissFlash = () => {
    this.handleModalClose();
    this.setState((state) => ({
      ...state,
      showFlash: false,
      flashMessage: "",
    }));
  };

  handleMergeVariableValue = (value, item, isReadOnly, i) => {
    let data = {};
    data[item] = value;
    let index = -1;
    let obj = {
      value: "",
      isReadOnly: false,
    };
    index = this.state.checkArray.findIndex((o) => Object.keys(o)[0] === item);
    if (index === -1) {
      if (isReadOnly) {
        obj.value = value;
        obj.isReadOnly = true;
      }
      data[item] = obj;
      this.state.checkArray.push(data);
    }
    if (
      (value === "" || value === null || value === undefined) &&
      item !== "ORG_PAYMENT_LINK"
    ) {
      this.state.checkArray.splice(index, 1);
      let check = this.state.optionalKeys.includes(item);
      if (check) {
        this.state.mergeVaribales.splice(i, 1);
        return;
      } else {
      }
    }
    if (isReadOnly) {
      if (value === null && item === "ORG_PAYMENT_LINK") {
        obj.value = null;
        obj.isReadOnly = true;
      } else {
        obj.value = value && value != null ? value : "";
        obj.isReadOnly = true;
      }
    } else {
      obj.value = value && value != null ? value : "";
      obj.isReadOnly = false;
    }
    this.state.mergeVaribalesValues[item] = obj;
    if (this.state.mergeVaribalesValues[item]) {
      this.setState((state) => ({
        ...state,
        mergeVaribalesValuesError: false,
        missingMergeVariable: "",
      }));
    }

    this.setState((state) => ({
      ...state,
      mergeVaribalesValues: this.state.mergeVaribalesValues,
    }));
  };

  renderMergeVaribles(item, index) {
    let { mergeVaribalesValues, mergeVaribalesValuesError } = this.state;
    return (
      <div className='admin-form-row' key={item}>
        <div className={`input-container`}>
          <label htmlFor='name'>{item}</label>
          <input
            name={item}
            placeholder={"Enter " + item}
            type='text'
            disabled={
              mergeVaribalesValues &&
              mergeVaribalesValues[item] &&
              mergeVaribalesValues[item].isReadOnly
                ? mergeVaribalesValues[item].isReadOnly
                : false
            }
            readOnly={
              mergeVaribalesValues &&
              mergeVaribalesValues[item] &&
              mergeVaribalesValues[item].isReadOnly
                ? mergeVaribalesValues[item].isReadOnly
                : false
            }
            value={
              mergeVaribalesValues[item] && mergeVaribalesValues[item].value
                ? mergeVaribalesValues[item].value
                : ""
            }
            onChange={(event) =>
              this.handleMergeVariableValue(
                event.target.value,
                item,
                false,
                index
              )
            }
          />
        </div>
      </div>
    );
  }

  toggleDropdown = (event) => {
    event.preventDefault();

    this.setState({
      dropdownIsActive: !this.state.dropdownIsActive,
    });
  };

  handleClickOutside = (event) => {
    if (this.state.dropdownIsActive) {
      this.toggleDropdown(event);
    }
  };

  handlePreviewClick = () => {
    let { checkArray, mergeVaribales, mergeVaribalesValues } = this.state;
    if (
      !mergeVaribalesValues ||
      !(checkArray.length === mergeVaribales.length)
    ) {
      const missingVariables = mergeVaribales.filter((variable) => {
        return !checkArray.some((item) => Object.keys(item)[0] === variable);
      });
      this.setState({
        mergeVaribalesValuesError: true,
        missingMergeVariable: missingVariables.map((item) => ` ${item}`),
      });
    } else {
      this.setState({ isPreview: true });
    }
  };

  hidePreviewModal = () => {
    this.setState({ isPreview: false });
  };

  handleKeyPress = (e, type) => {
    let { input, toArray, ccArray } = this.state;
    let isEnterKey = e.keyCode || e.which;
    if (isEnterKey == 13) {
      if (type === "to" && input.email.value) {
        if (!validateEmail(input.email.value)) {
          input.email.hasError = true;
        } else {
          toArray.push(input.email.value);
          input.email.hasError = false;
          input.email.value = "";
        }
      } else if (type == "cc" && input.cc_email.value) {
        if (!validateEmail(input.cc_email.value)) {
          input.cc_email.hasError = true;
        } else {
          ccArray.push(input.cc_email.value);
          input.cc_email.hasError = false;
          input.cc_email.value = "";
        }
      }
    }
    this.setState({ input });
  };

  removeEmail = (i, type) => {
    let { toArray, ccArray } = this.state;
    if (type == "to" && toArray.length) {
      toArray.splice(i, 1);
    } else if (type == "cc" && ccArray.length) {
      ccArray.splice(i, 1);
    }
    this.setState({ toArray, ccArray });
  };

  showEmails = (email, index, type) => {
    return (
      <div
        className='template-col-name'
        style={{ marginBottom: "0.5em" }}
        key={email}
      >
        <span
          style={{
            marginTop: 1,
            fontFamily: "sofia_pro_regular",
            fontSize: 13,
          }}
        >
          {email}
        </span>
        <span
          className='assigned-tem-card-cross'
          onClick={() => this.removeEmail(index, type)}
        >
          x
        </span>
      </div>
    );
  };

  render() {
    let { isPaymentPlanModel } = this.props.optionalProps;
    let {
      input,
      message,
      error,
      isLoading,
      isUpload,
      isPreview,
      toArray,
      ccArray,
      defaultSelected,
    } = this.state;
    let styels = { marginRight: `10px`, maxWidth: `30rem`, marginBottom: 0 };

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    if (isPreview) {
      return (
        <PreviewTemplateModal
          user={this.props.user}
          hideModal={this.hidePreviewModal}
          template={message.value}
          merge_variables={this.state.mergeVaribalesValues}
        />
      );
    }

    return (
      <Modal
        optionalClasses='add-account-modal send-new-email-modal scroll'
        title='Send New Email'
        closeOnClickOutside={true}
        hideModal={this.handleClosePaymentPlan}
      >
        {isLoading ? (
          <AppCanvasLoader />
        ) : (
          <>
            <form
              className='admin-form'
              onClick={(event) => {
                this.handleClickOutside(event);
              }}
            >
              <div className='app-sidebar-layout-canvas'>
                <div className='app-sidebar app-sidebar-left' style={styels}>
                  <div>
                    <div className='admin-form-row'>
                      <div
                        className={`input-container ${
                          input.name.hasError ? " error" : ""
                        }`}
                      >
                        <label htmlFor='name'>Name:</label>
                        <input
                          name='name'
                          guide={"true"}
                          placeholder='Enter name'
                          type='text'
                          value={input.name.value}
                          onChange={(event) =>
                            this.handleInputChange(
                              {
                                name: {
                                  value: event.target.value,
                                  hasError: false,
                                },
                              },
                              true,
                              event.target.value
                            )
                          }
                        />
                        {input.name.hasError
                          ? this.renderErrorMessage("name")
                          : null}
                      </div>
                    </div>

                    <div className='admin-form-row'>
                      <div
                        className={`input-container ${
                          input.email.hasError ? " error" : ""
                        }`}
                      >
                        <label htmlFor='email'>
                          Email:{"  "}
                          {toArray.length
                            ? toArray.map((email, i) =>
                                this.showEmails(email, i, "to")
                              )
                            : null}
                        </label>
                        <input
                          name='email'
                          placeholder='address@email.com'
                          type='text'
                          value={input.email.value}
                          onKeyPress={(e) => this.handleKeyPress(e, "to")}
                          onChange={(event) =>
                            this.handleInputChange({
                              email: {
                                value: event.target.value,
                                hasError: false,
                              },
                            })
                          }
                        />
                        {input.email.hasError
                          ? this.renderErrorMessage("email")
                          : null}
                      </div>
                    </div>

                    <div className='admin-form-row'>
                      <div
                        className={`input-container ${
                          input.cc_email.hasError ? " error" : ""
                        }`}
                      >
                        <label htmlFor='email'>
                          CC Email:{"  "}
                          {ccArray.length
                            ? ccArray.map((email, i) =>
                                this.showEmails(email, i, "cc")
                              )
                            : null}
                        </label>
                        <input
                          name='cc_email'
                          placeholder='address@email.com'
                          type='text'
                          value={input.cc_email.value}
                          onKeyPress={(e) => this.handleKeyPress(e, "cc")}
                          onChange={(event) =>
                            this.handleInputChange({
                              cc_email: {
                                value: event.target.value,
                                hasError: false,
                              },
                            })
                          }
                        />
                        {input.cc_email.hasError
                          ? this.renderErrorMessage("cc_email")
                          : null}
                      </div>
                    </div>

                    <label>Attach Documents:</label>
                    <div
                      className={`input-container half`}
                      style={{ marginBottom: 20 }}
                    >
                      <Multiselect
                        options={this.state.options}
                        selectedValues={defaultSelected}
                        displayValue='name'
                        placeholder='Select attach documents'
                        onSelect={this.onSelect}
                        onRemove={this.onRemove}
                      />
                    </div>

                    {
                      <div
                        style={{
                          display:
                            this.state.filterdTemplateData.length > 0
                              ? this.state.filterdTemplateData[0].data.name ==
                                "MG - SEND MULTIPLE INVOICES"
                                ? "none"
                                : "visibility"
                              : "none",
                        }}
                      >
                        <label>Template:</label>
                        <div
                          className={`input-container half${
                            message.hasError ? " error" : ""
                          }`}
                          style={{ marginBottom: 20 }}
                        >
                          <Select
                            inputProps={{
                              autoComplete: "none",
                              autoCorrect: "off",
                              spellCheck: "off",
                            }}
                            className='form-select'
                            name='message'
                            disabled={
                              isPaymentPlanModel
                                ? true
                                : this.state.isTempleteDisable
                            }
                            placeholder='Select a template'
                            options={this.state.filterdTemplateData}
                            value={message.value}
                            onChange={(event) => {
                              this.setState({ checkArray: [], bool: true });
                              this.handleInputChangeForMsg({
                                message: {
                                  value: event.value,
                                  hasError: false,
                                },
                              });
                            }}
                          />
                          {message.hasError
                            ? this.renderErrorMessage("message")
                            : null}
                        </div>
                      </div>
                    }
                    {this.state.mergeVaribalesValuesError ? (
                      <div
                        style={{
                          fontSize: "1em",
                          color: "#FF0000",
                          fontFamily: "sans-serif",
                        }}
                      >
                        {`Below values are missing from the org: `}
                        <br />
                        <span
                          style={{ fontSize: "0.8em" }}
                        >{`${this.state.missingMergeVariable}.`}</span>
                      </div>
                    ) : // ?this.renderErrorMessage("message")
                    null}
                  </div>
                </div>
              </div>
            </form>
            <div className='modal-actions double-btns2'>
              <button
                className={`cta mg-brand2-color`}
                onClick={this.handlePreviewClick}
                disabled={this.state.bool}
                style={{ marginBottom: "15px" }}
              >
                Preview
              </button>

              <button
                className='cta mg-brand2-color'
                disabled={this.state.bool}
                onClick={this.handleSubmit}
                style={{ marginBottom: "15px" }}
              >
                Submit
              </button>
            </div>
          </>
        )}
      </Modal>
    );
  }
}

export default withRouter(SendNewMailModal);
