import moment from "moment";
import React, { Component } from "react";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import currencyFormatter from "currency-formatter";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import ClientsLogo from "../../components/Consumer/ClientsLogo";
import ConsumerInvoiceDetails from "../../components/Consumer/ConsumerInvoiceDetails";
import ConsumerSidebar from "../../components/Consumer/ConsumerSidebar";
import * as API from "../../utils/api";
import { getZohoPagesense } from "../../utils/helpers";
import ConsumerPayment from "./ConsumerPayment";

class ConsumerAccountsView extends Component {
  state = {
    invoiceDetails: [],
    showPaymentScreen: false,
    isLoading: false,
    textColor: "",
    showLoader: false,
    isAutoPopulateBalanceDue: false,
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
    appThemeData: {},
  };

  getLogoColorsForPayment() {
    this.setState({ isLoading: true });
    API.getLogoColorsForPayment()
      .then((response) => {
        this.setState({
          appThemeData: response.data,
        });
      })
      .catch(() => {});
    this.setState({ isLoading: false });
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const paymentIntent = params.get("payment_intent");
    this.getLogoColorsForPayment();
    this.getMiscellaneousData();
    // Set Previous Path for use with App Header's "Back" Link
    this.props.updatePrevPath("/dashboard/accounts/invoices");
    getZohoPagesense();
    ReactGA.pageview("/ConsumerAccountsView");
    if (paymentIntent) {
      this.attemptCount = 0;
      this.get3DCardPaymentConfirmation(paymentIntent);
    } else {
      this.getInvoiceDetails();
    }
  }

  get3DCardPaymentConfirmation = (intentID) => {
    this.setState({ showLoader: true, isLoading: true });
    const MAX_ATTEMPTS = 10;
    if (this.attemptCount < MAX_ATTEMPTS) {
      this.attemptCount++;
      API.getCardPaymentConfirmation(intentID).then((res) => {
        if (res && res.status_code === 200 && res.is_updated) {
          if (res.status === "SUCCESS") {
            this.props.updateModalState(true, "SUCCESS", {
              message: "Payments has been made successfully",
            });
          } else {
            this.props.updateModalState(true, "ERROR", {
              message: "Payments has failed",
            });
          }
          setTimeout(() => {
            this.getInvoiceDetails();
          }, 2000);
        } else {
          setTimeout(() => {
            this.get3DCardPaymentConfirmation(intentID);
          }, 2000);
        }
      });
    } else {
      this.getInvoiceDetails();
    }
  };

  getMiscellaneousData = () => {
    API.getConsumerMiscellaneousInSettings(this.props.user).then((data) => {
      if (typeof data != "undefined" || data != null) {
        if (data.data) {
          if (data.data[0].data[0].is_enable) {
            this.setState({
              isAutoPopulateBalanceDue: data.data[0].data[0].is_enable,
            });
          }
        }
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            message: data && data.error ? data.error : "",
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  getInvoiceDetails = () => {
    const invoiceId = this.props.match.params.id;
    API.fetchConsumerInvoiceDetails(this.props.user, invoiceId).then((data) => {
      if (
        (typeof data != "undefined" || data != null) &&
        data.data != null &&
        data.status_code == 200
      ) {
        this.setState({
          invoiceDetails: data.data,
          isLoading: false,
          showLoader: false,
        });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          showLoader: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  getSubTitle = (userData) => {
    let fullname = "";
    if (userData.first_name) {
      fullname = userData.first_name;
    }
    if (userData.last_name && fullname) {
      fullname = fullname + " " + userData.last_name;
    } else {
      fullname = userData.last_name;
    }
    return "Welcome, " + fullname + "!";
  };

  checkInvoicePastDue = (date) => {
    return moment(date).isBefore(new Date());
  };

  getAccountType = () => {
    const { invoiceDetails } = this.state;
    let flag = this.checkInvoicePastDue(invoiceDetails.due_date);
    if (flag) {
      return "past due";
    } else {
      return "current";
    }
  };

  handlePayNow = () => {
    this.setState({ showPaymentScreen: !this.state.showPaymentScreen });
  };

  sidebarContent = () => {
    const { showPaymentScreen, invoiceDetails } = this.state;
    return (
      <div className='consumer-sidebar'>
        <div
          className='sidebar-primary-text'
          style={{ marginBottom: "2em", display: "flex", color: "#FFFFFF" }}
        >
          This invoice is {this.getAccountType()}
        </div>
        <label style={{ marginBottom: "0.3em", color: "#FFFFFF" }}>
          Balance Due
        </label>
        <div
          className='invoice-amt-owed payment-balance'
          style={{ marginBottom: "1em", color: "#000000" }}
        >
          {invoiceDetails.balance
            ? currencyFormatter.format(invoiceDetails.balance, { code: "USD" })
            : "0.00"}
        </div>
      </div>
    );
  };

  handlePayPaymentPlan = (id, number) => {
    let { history } = this.props;
    this.props.updateModalState(true, "CONFIRM_ALERT", {
      message: `This invoice is part of payment plan #${number}.\nClick YES to switch to the payment plan payment page, or NO to return to the invoice details.`,
      confirmClick: () =>
        history.push(`/dashboard/accounts/pay-payment-plans/${id}`),
      closeOnClickOutside: true,
    });
  };

  render() {
    const { appName, user, stripePubKey } = this.props;

    const {
      isLoading,
      error,
      invoiceDetails,
      showLoader,
      showPaymentScreen,
      textColor,
    } = this.state;
    const props = this.props;
    const invoiceId = this.props.match.params.id;

    if (error.hasAnyError) {
      if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    if (showPaymentScreen) {
      return (
        <ConsumerPayment
          primary_color={this.props.appThemeData.primary_color}
          user={user}
          appName={appName}
          stripePubKey={stripePubKey}
          invoiceDetails={invoiceDetails}
          getInvoiceDetails={this.getInvoiceDetails}
          updateModalState={this.props.updateModalState}
          BackLink={`/dashboard/accounts/invoices/${invoiceId}`}
          isAutoPopulateBalanceDue={this.state.isAutoPopulateBalanceDue}
        />
      );
    }

    return isLoading ? (
      <AppCanvasLoader />
    ) : (
      <div className='client-scroll-dashboard scrollbar'>
        <Helmet>
          <title>
            {`${appName} | Invoice# ${invoiceDetails.invoice_number}`}
          </title>
        </Helmet>
        <div className='app-sidebar-layout-canvas'>
          <ConsumerSidebar
            primary_color={this.props.appThemeData.primary_color}
            user={this.props.user}
            subTitle={this.getSubTitle(this.props.user)}
            sidebarContent={this.sidebarContent()}
            BackLink={"/dashboard/accounts/invoices"}
          />

          {showLoader ? (
            <div className='app-sidebar-layout-canvas-content'>
              <AppCanvasLoader />
            </div>
          ) : (
            <div
              className='app-sidebar-layout-canvas-content setting-side-canvas big-device-grad col1-scroll scrollbar'
              style={{ paddingBottom: 0 }}
            >
              <ClientsLogo />
              <div className='header-make-payment'>
                <h1
                  className='app-section-consumer-heading'
                  style={{ color: this.props.appThemeData.primary_color }}
                >
                  Invoice# {invoiceDetails.invoice_number}
                </h1>
                {invoiceDetails &&
                invoiceDetails.balance &&
                invoiceDetails.balance > 0 ? (
                  <button
                    className='cta make-payment-btn mg-brand2-color-common'
                    onClick={() =>
                      invoiceDetails && invoiceDetails.payment_plan_id
                        ? this.handlePayPaymentPlan(
                            invoiceDetails.payment_plan_id,
                            invoiceDetails.payment_plan_number
                          )
                        : this.handlePayNow()
                    }
                    style={{
                      fontFamily: "Open Sans",
                      backgroundColor: this.props.appThemeData.primary_color,
                      border: "1px solid #a1a1a1",
                    }}
                  >
                    Make Payment
                  </button>
                ) : null}
              </div>
              <ConsumerInvoiceDetails
                primary_color={this.props.appThemeData.primary_color}
                user={user}
                invoiceDetails={invoiceDetails}
                updateModalState={this.props.updateModalState}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ConsumerAccountsView;
