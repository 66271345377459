import React, { Component } from "react";
import "react-select/dist/react-select.css";
import * as API from "../../../utils/api";
import AppCanvasLoader from "../../../components/App/AppCanvasLoader";
import ColorPicker from "./colorPicker";
class CustomizeWorkFlowStep7 extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  state = {
    consumerinformation: {
      inputTypePrimaryColor: {
        value: "#000000",
      },
      inputTypeSecondaryColor: {
        value: "#000000",
      },
      sketchPiker: false,
      sketchPikerSecondary: false,
    },
    input: {
      fileSizeInMB: "",
      fileExtension: "",
      document: "",
    },
    hasError: false,
    isPdf: true,
    isPdfSelected: false,
    isUploadedPdf: false,
    logoFile: "",
    fileSizehasError: false,
    isValidImage: true,
    mergeVarData: "",
    isLoading: true,
    colorPicker: false,
  };

  style = {
    pText: {
      fontSize: "14px",
      color: "#2b2828",
      margin: "0px",
    },
  };

  componentDidMount() {
    this.getMergeVariablesList();
  }
  getMergeVariablesList = () => {
    let { location, organizationDetails } = this.props;
    const accountId = organizationDetails.organization.id;
    API.getMergeVariablesListClient(this.props.user, accountId).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.message) {
        this.setState(
          (state) => ({
            ...state,
            mergeVarData: data.data,
            isLoading: false,
          }),
          () => this.getdefaultColorsValue()
        );
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };
  getdefaultColorsValue = () => {
    let default_primary_value = this.state.mergeVarData.Colors[""][0].value
      ? this.state.mergeVarData.Colors[""][0].value
      : this.state.mergeVarData.Colors[""][0].default_value;
    let default_secondary_value = this.state.mergeVarData.Colors[""][1].value
      ? this.state.mergeVarData.Colors[""][1].value
      : this.state.mergeVarData.Colors[""][1].default_value;
    this.setState((state) => ({
      ...state,
      consumerinformation: {
        ...state.consumerinformation,
        inputTypePrimaryColor: {
          ...state.consumerinformation.inputTypePrimaryColor,
          value: `${default_primary_value}`,
        },
        inputTypeSecondaryColor: {
          ...state.consumerinformation.inputTypeSecondaryColor,
          value: `${default_secondary_value}`,
        },
      },
      colorPicker: true,
    }));
  };
  updateOrgLogo = (data1) => {
    let payload = { data: data1 };
    if (data1) {
      API.mergeVariables_org_logo(this.props.user, data1).then((data) => {
        const img = new Image();
        img.src = data.data;
        img.onload = function () {
          if (this.width > 500 && this.height > 500) {
            localStorage.setItem("imgResolution", "moreThan500px");
          } else {
            localStorage.setItem("imgResolution", "lessThan500px");
          }
        };
        this.props.updateLogo(data.data);
        if ((typeof data != "undefined" || data != null) && !data.error) {
          if (data.status_code == 200) {
            if (data.modify_count != 0) {
              this.props.updateModalState(true, "SUCCESS", {
                message: data.message,
              });
            }
          } else if (data.status_code !== 200) {
            this.setState((state) => ({
              ...state,
              hasAnyError: true,
            }));
            this.props.updateModalState(true, "ERROR", {
              message: data.message,
            });
          }
        }
      });
    }
  };

  inputPrimaryColorChangeValue = (color) => {
    this.state.consumerinformation.inputTypePrimaryColor.value = color.hex;
    this.setState({ consumerinformation: this.state.consumerinformation });

    this.updateMergeVariablesColor("primarycolor");
  };
  inputSecondaryColorChangeValue = (color) => {
    this.state.consumerinformation.inputTypeSecondaryColor.value = color.hex;
    this.setState({ consumerinformation: this.state.consumerinformation });

    this.updateMergeVariablesColor("secondarycolor");
  };
  updateMergeVariablesColor = (res, hex) => {
    let { organizationDetails } = this.props;
    let payload;
    if (res === "primaryColor") {
      payload = {
        merge_vars: [
          {
            organization_id: organizationDetails.organization.id,
            merge_variable_id:
              this.state.mergeVarData.Colors[""][0].merge_variable_id,
            value: hex.slice(0, 7),
          },
        ],
      };
    } else if (res === "secondaryColor") {
      payload = {
        merge_vars: [
          {
            organization_id: organizationDetails.organization.id,
            merge_variable_id:
              this.state.mergeVarData.Colors[""][1].merge_variable_id,
            value: hex.slice(0, 7),
          },
        ],
      };
    }

    API.updateMergeVariablesClient(this.props.user, payload).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        if (data.status_code == 200) {
          //show toast only if any field update
          if (data.modify_count != 0) {
            // this.props.updateModalState(true, "SUCCESS", {
            //   message: data.message,
            // });
          }
        } else if (data.status_code !== 200) {
          this.setState((state) => ({
            ...state,
            hasAnyError: true,
          }));
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        }
      }
    });
  };
  sketchPikerSelect = () => {
    let { consumerinformation } = this.state;
    consumerinformation.sketchPiker = !consumerinformation.sketchPiker;
    consumerinformation.sketchPikerSecondary = false;
    this.setState({
      consumerinformation,
    });
  };
  sketchPikerSelectSecondary = () => {
    let { consumerinformation } = this.state;
    consumerinformation.sketchPikerSecondary =
      !consumerinformation.sketchPikerSecondary;
    consumerinformation.sketchPiker = false;
    this.setState({
      consumerinformation,
    });
  };
  closeOnClickOutside = () => {
    let { consumerinformation } = this.state;
    consumerinformation.sketchPiker = false;
    consumerinformation.sketchPikerSecondary = false;
    this.setState({ consumerinformation });
  };
  handleFileChange = (e) => {
    let files = e.target.files;
    if (e.target.files[0].size < 1000000) {
      if (files && files.length) {
        this.setState({ logoFile: files[0], fileSizehasError: false }, () =>
          this.getFile()
        );
      }
    } else {
      this.setState({
        fileSizehasError: true,
      });
    }
  };
  getFile = () => {
    let files = this.state.logoFile;
    let imgExtension = files.name.split(".")[1];
    let extensionFlag = false;
    if (
      imgExtension.toLowerCase() === "png" ||
      imgExtension.toLowerCase() === "jpg" ||
      imgExtension.toLowerCase() === "jpeg"
    ) {
      extensionFlag = true;
      this.setState({ isValidImage: true });
    } else if (files.size > 1000000) {
      extensionFlag = false;
    } else if (
      imgExtension.toLowerCase() !== "png" ||
      imgExtension.toLowerCase() !== "jpg" ||
      imgExtension.toLowerCase() !== "jpeg"
    ) {
      extensionFlag = false;
      this.setState({ isValidImage: false });
    }
    if (extensionFlag) {
      var data = new FormData();
      var imagedata = files;
      data.append("data", imagedata);
      this.updateOrgLogo(data);
    }
  };
  PreviwPdf = () => {
    API.mergeVar_pdfPreview(this.props.user).then((data) => {
      if (typeof data != "undefined" || (data != null && !data.error)) {
        let url = window.URL.createObjectURL(data);
        let a = document.createElement("img");
        a.src = url;
        let props = {
          image: url,
          user: this.props.user,
          fileType: "PDF",
          type: "pdf",
        };
        this.props.updateModalState(true, "SHOW_IMAGE", props);
        this.setState({ isSubmit: false });
      } else {
        this.setState({ isSubmit: false });
        this.handleError(data);
      }
    });
  };
  handleChangePrimary = (value) => {
    const hex = value.hex;
    this.setState({
      ...this.state,
      consumerinformation: {
        ...this.state.consumerinformation,
        inputTypePrimaryColor: {
          value: `#${hex}`,
        },
      },
    });
    this.updateMergeVariablesColor("primarycolor");
  };
  handleChangeSecondary = (value) => {
    const hex = value.hex;
    this.setState({
      ...this.state,
      consumerinformation: {
        ...this.state.consumerinformation,
        inputTypeSecondaryColor: {
          value: `#${hex}`,
        },
      },
    });
    this.updateMergeVariablesColor("secondarycolor");
  };
  render() {
    const { consumerinformation, colorPicker } = this.state;
    return this.state.isLoading ? (
      <div className='loader_alignment'>
        <div style={{ marginTop: "20%" }}>
          <AppCanvasLoader />
        </div>
      </div>
    ) : (
      <>
        <div className='app-sidebar-layout-canvas-content setting-side-canvas col1-scroll mg-col1-scroll scrollbar'>
          {/* <h3 className="mg-steps-header mg-step-head mg-l-0 mg-ml-10" style={{ color: "black", left: "100px",fontWeight:"bold",width:"90%" }}>
            Customize your Workflow: Step 6 of 9
          </h3> */}
          <div className='header-sub-div'>
            <h3
              style={{
                color: "black",
                fontWeight: "bold",
                fontFamily: "Open Sans",
                fontSize: "30px",
                lineHeight: "20px",
              }}
            >
              Brand Your Invoicing & Communications
            </h3>
            <br />
            <h7 style={{ fontFamily: "Open Sans", fontSize: "16px" }}>
              Upload your logo and select your colors to create a look that is
              consistent with your brand. Use the Preview button to see what the
              invoices will look like.
            </h7>
          </div>

          <div
            className='step6-app-sidebar-main mg-width-100'
            style={{ width: "100%" }}
          >
            <div className='main-content mg-dir-rcol'>
              <div className='main-content-subdive1 mg-width-100 mg-ml-0 mg-p-10'>
                <div
                  className='sub-content-div'
                  style={{ fontFamily: "Open Sans" }}
                >
                  Upload Your Logo
                </div>
                <div
                  className='admin-form-row address-document'
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <button
                    className='cta mg-brand2-color W9form '
                    style={{
                      width: "200px",
                      height: "50px",
                      // paddingTop: "12px",
                      borderRadius: "0.5em",
                      fontFamily: "Open Sans",
                      marginTop: "20px",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                    acceptDoc='.pdf'
                    onClick={() => {
                      this.inputRef.current.click();
                    }}
                  >
                    Choose File{" "}
                  </button>
                  <div style={{ width: "203px" }}>
                    {this.state.logoFile.name && this.state.logoFile.name ? (
                      <p
                        style={{
                          marginBottom: "3px",
                          marginLeft: "15px",
                          fontFamily: "Open Sans",
                        }}
                      >
                        {this.state.logoFile.name.slice(0, 20)}
                      </p>
                    ) : this.state.mergeVarData.logo_url ? (
                      <p
                        style={{
                          marginBottom: "3px",
                          marginLeft: "30px",
                          fontFamily: "Open Sans",
                        }}
                      >
                        Logo File Uploaded
                      </p>
                    ) : (
                      <p
                        style={{
                          marginBottom: "3px",
                          marginLeft: "43px",
                          fontFamily: "Open Sans",
                          color: "darkgrey",
                        }}
                      >
                        No file selected
                      </p>
                    )}
                    {this.state.fileSizehasError ? (
                      <p
                        style={{
                          color: "darkgrey",
                          marginLeft: "15px",
                          fontWeight: "bold",
                          marginBottom: "3px",
                          fontSize: "15px",
                          fontFamily: "Open Sans",
                          width: "150px",
                        }}
                      >
                        File size must be less than 1 MB
                      </p>
                    ) : (
                      ""
                    )}

                    <p
                      style={{
                        // color: this.state.isValidImage ? "darkgrey" : "#fb5c82",
                        color: "darkgrey",
                        marginLeft: "15px",
                        marginTop: "10px",
                        fontFamily: "Open Sans",
                      }}
                    >
                      Logo should be in .png,.jpg or .jpeg format and less than
                      1 MB
                    </p>
                  </div>
                  <div className='logofle-input'>
                    <input
                      ref={this.inputRef}
                      type='file'
                      id='upload_logo_file'
                      accept='.jpg, .jpeg, .png'
                      onChange={this.handleFileChange.bind(this)}
                      multiple={false}
                    />
                  </div>
                </div>
              </div>
              <div className='mg-hide-show'>
                <div className='mg-ml-10 mg-ml-100'>
                  <div
                    style={{
                      fontWeight: "bold",
                      marginTop: "50px",
                      fontFamily: "Open Sans",
                    }}
                  >
                    Select Your Colors
                  </div>
                  <div
                    style={{
                      fontWeight: "bold",
                      marginTop: "15px",
                      fontFamily: "Open Sans",
                    }}
                  >
                    Primary Color
                  </div>

                  <div
                    style={{
                      right: "30px",
                      position: "relative",
                      marginTop: "5px",
                    }}
                  >
                    {colorPicker && (
                      <ColorPicker
                        updateMergeVariablesColor={
                          this.updateMergeVariablesColor
                        }
                        colorPicker={"primaryColor"}
                        defaultColor={
                          consumerinformation.inputTypePrimaryColor.value
                        }
                      />
                    )}
                  </div>
                  <div
                    style={{
                      fontWeight: "bold",
                      marginTop: "50px",
                      fontFamily: "Open Sans",
                    }}
                  >
                    Secondary Color
                  </div>
                  <div
                    style={{
                      right: "30px",
                      position: "relative",
                      marginTop: "5px",
                    }}
                  >
                    {colorPicker && (
                      <ColorPicker
                        updateMergeVariablesColor={
                          this.updateMergeVariablesColor
                        }
                        colorPicker={"secondaryColor"}
                        defaultColor={
                          consumerinformation.inputTypeSecondaryColor.value
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className='mg-ml-10'>
                <input
                  type='submit'
                  value={"Preview"}
                  id='preview_button'
                  className='cta mg-brand2-color mg-nextbtn mg-ml-0'
                  style={{
                    width: "200px",
                    height: "50px",
                    borderRadius: "0.5em",
                    fontFamily: "Open Sans",
                    fontWeight: "700",
                    marginTop: "85px",
                    marginLeft: "15px",
                  }}
                  onClick={this.PreviwPdf}
                />
              </div>
            </div>
          </div>
          <div
            className='bottum-btns'
            style={{ padding: "20px", paddingBottom: "50px" }}
          >
            <div className='button-left'>
              <input
                type='submit'
                id='upload_logo_go_back'
                value='Go Back'
                className='btns-font mg-ml-0'
                style={{
                  borderRadius: "0.5em",
                  width: "200px",
                  height: "50px",
                  marginTop: "20px",
                  border: "2px solid",
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  fontWeight: "500",
                  float: "left",
                }}
                onClick={() => this.props.handleBack("step6")}
              />
              <span className='go-back-text'>
                <p style={this.style.pText}>Go back to</p>
                <p style={this.style.pText}>"Set Your Account Placement</p>
                <p style={this.style.pText}>Eligibility Preferences"</p>
              </span>
            </div>
            <div className='button-right'>
              <input
                type='submit'
                id='upload_logo_next'
                value={"Next"}
                className='cta mg-brand2-color  mg-ml-0'
                style={{
                  width: "200px",
                  height: "50px",
                  borderRadius: "0.5em",
                  marginTop: "20px",
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
                onClick={() => {
                  this.props.handleNext("step8");
                }}
              />
              <span className='next-text'>
                <p style={this.style.pText}>Continue to</p>
                <p style={this.style.pText}>
                  "Choose Your Communication Preferences"
                </p>
              </span>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CustomizeWorkFlowStep7;
