import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import ClientsDashboardChart from "../../components/Clients/ClientsDashboardChart";
import ClientsDashboardSidebar from "../../components/Clients/ClientsDashboardSidebar";
import TruncatedName from "../../components/TruncatedName";
import * as API from "../../utils/api";
import { getDollarAmount } from "../../utils/helpers";
import Emitter from "../../utils/event-emitter";
import InvoiceObjectList from "../../components/ObjectList/ClientsAccounts/InvoiceObjectList";
import ClientsCardView from "../../components/CardView/ClientsCardView";

class ClientsDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortHeader: "Account",
      sort_order: "ASC",
      arReportData: null,
      sortType: true,
      totalData: {},
      AllMonths: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      upcoming_activities: [],
      recent_activities: [],
      mostAtRiskAccount: [],
      AcpAddCeiReport: [],
      arAgingData: {},
      ACP: [],
      ADD: [],
      months: [],
      year: [],
      cei: 0,
      isLoading: true,
      error: {
        hasAnyError: false,
        statusCode: 200,
      },
    };
    this.minRange = 0;
    this.maxRange = 20;
  }

  componentDidMount() {
    //get initial dashboard details
    this.dashboardUpcomingEvents();
    // this.ArAgingReport();
    this.getArReportData();
    this.getAcpAddCeiReport();
    this.getMostAtRiskReport();
    Emitter.emit("NOTIFICATION_ALERT", true);
  }

  dashboardUpcomingEvents = () => {
    API.dashboardUpcomingEvents(this.props.user).then((data) => {
      Emitter.emit("IS_QUICKBOOK_CONNECTED", data.qb_connected);
      if (typeof data != "undefined" || (data != null && !data.error)) {
        if (data.data) {
          let { recent_activities, upcoming_activities } = data.data;

          //sort recent activities
          if (recent_activities && recent_activities.length) {
            recent_activities.sort(function (a, b) {
              return new Date(b.due_date) - new Date(a.due_date);
            });
          }

          //sort upcoming activities
          if (upcoming_activities && upcoming_activities.length) {
            upcoming_activities.sort(function (a, b) {
              return new Date(a.due_date) - new Date(b.due_date);
            });
          }

          this.setState({ recent_activities, upcoming_activities });
        }
        this.setState({
          isLoading: false,
        });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  ArAgingReport = () => {
    API.ArAgingReport(this.props.user).then((data) => {
      if (typeof data != "undefined" || (data != null && !data.error)) {
        if (data.data) {
          this.setState({ arAgingData: data.data });
        }
        this.setState({
          isLoading: false,
        });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  getAcpAddCeiReport = () => {
    API.getAcpAddCeiReport(this.props.user).then((data) => {
      if (
        (typeof data != "undefined" || data != null) &&
        data.message === "success"
      ) {
        if (data.data) {
          this.setState({ AcpAddCeiReport: data.data }, () => {
            this.setGraphData();
          });
        }
        this.setState({
          isLoading: false,
        });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  getMostAtRiskReport = () => {
    API.getMostAtRiskReport(this.props.user).then((data) => {
      if (
        (typeof data != "undefined" || data != null) &&
        data.message &&
        data.message === "success"
      ) {
        if (data.data) {
          this.setState({ mostAtRiskAccount: data.data });
        }
        this.setState({
          isLoading: false,
        });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  //get rounded number for graph
  getRoundedNumber = (num) => {
    let value = 0;
    if (num) {
      num = num.toString().substr(0, 5);
      value = Math.round(num);
    }
    return value;
  };

  //get formatted data for graph like cei value, ACP, ADD, minRange, maxRange, arrays, year and month
  setGraphData = () => {
    let { AcpAddCeiReport } = this.state;
    this.setState({
      cei:
        AcpAddCeiReport.cei && AcpAddCeiReport.cei > 0
          ? AcpAddCeiReport.cei
          : 0,
    });

    if (AcpAddCeiReport && AcpAddCeiReport.acp_add) {
      AcpAddCeiReport.acp_add.map((item, index) => {
        item.average_deliquent_days = item.average_deliquent_days
          ? item.average_deliquent_days
          : 0;
        item.days_sales_outstanding = item.days_sales_outstanding
          ? item.days_sales_outstanding
          : 0;
        this.state.ACP.push(this.getRoundedNumber(item.days_sales_outstanding));
        this.state.ADD.push(this.getRoundedNumber(item.average_deliquent_days));
        this.state.months.push(this.state.AllMonths[item.month - 1]);
        let i = this.state.year.findIndex((o) => o === item.year);
        if (i === -1) {
          this.state.year.push(item.year);
        }
      });
      this.setState(
        {
          ACP: this.state.ACP,
          ADD: this.state.ADD,
          months: this.state.months,
          year: this.state.year,
        },
        () => {
          this.setMinRange(this.state.ACP);
          this.setMaxRange([...this.state.ADD, ...this.state.ACP]);
        }
      );
    }
  };

  //get min value for ACP and ADD
  setMinRange = (data) => {
    if (data && data.length) {
      data.map((item) => {
        if (item) {
          if (parseInt(item) < parseInt(this.minRange)) {
            this.minRange = item;
          }
        }
      });
    }
  };

  //get max value for ACP and ADD
  setMaxRange = (data) => {
    if (data && data.length) {
      data.map((item) => {
        if (item) {
          if (parseInt(item) > parseInt(this.maxRange)) {
            this.maxRange = item;
          }
        }
      });
    }
  };

  //most at risk, row click redirects to consumer account
  handleExpanderClick = (e, d) => {
    e.preventDefault();
    let { history } = this.props;
    const consumerId = d.user_id;
    if (consumerId) {
      history.push(`/client/accounts/${consumerId}`);
    }
  };

  //AR Agging report, get total values
  totalOpenBalance = (arAgingData) => {
    let total = 0;
    if (arAgingData.current) {
      total = parseFloat(total) + arAgingData.current;
    }
    if (arAgingData.report1to30) {
      total = parseFloat(total) + parseFloat(arAgingData.report1to30);
    }
    if (arAgingData.report30to60) {
      total = parseFloat(total) + parseFloat(arAgingData.report30to60);
    }
    if (arAgingData.report60to90) {
      total = parseFloat(total) + parseFloat(arAgingData.report60to90);
    }
    if (arAgingData.report90to120) {
      total = parseFloat(total) + parseFloat(arAgingData.report90to120);
    }
    if (arAgingData.report121plus) {
      total = parseFloat(total) + parseFloat(arAgingData.report121plus);
    }
    return total.toFixed(2);
  };

  //get floatting value like 12.00
  getDecimals = (value) => {
    if (value) {
      return parseFloat(value).toFixed(2);
    }
    return value;
  };

  getArReportData = () => {
    let sort_key = this.getSortKey();
    let sort_by = this.state.sortType ? "ASC" : "DESC";

    API.dashobardArReport(this.props.user, sort_key, sort_by).then((res) => {
      this.setState({
        arReportData: res.data,
        totalData: {
          rowHead: "Total",
          openInvoices: res.data.open_invoices,
          currentBalnc: getDollarAmount(res.data.current),
          report1to30: getDollarAmount(res.data.report1to30),
          report30to60: getDollarAmount(res.data.report30to60),
          report60to90: getDollarAmount(res.data.report60to90),
          report90to120: getDollarAmount(res.data.report90to120),
          report121plus: getDollarAmount(res.data.report121plus),
          total: getDollarAmount(res.data.total_balance),
        },
      });
    });
  };

  sortInvoiceData = (header, type) => {
    this.setState(
      {
        sortHeader: header,
        sortType: type,
        offset: 0,
      },
      () => {
        this.getArReportData();
      }
    );
  };

  getSortKey = () => {
    let { sortHeader } = this.state;
    if (sortHeader === "Account") {
      return "display_name";
    } else if (sortHeader === "Total # of Invoices") {
      return "open_invoices_count";
    } else if (sortHeader === "Total Balance") {
      return "total_balance";
    } else if (sortHeader === "Current") {
      return "current_sum";
    } else if (sortHeader === "0-30 Days") {
      return "sum_1_to_30";
    } else if (sortHeader === "31-60 Days") {
      return "sum_30_to_60";
    } else if (sortHeader === "61-90 Days") {
      return "sum_60_to_90";
    } else if (sortHeader === "91-120 Days") {
      return "sum_90_to_120";
    } else if (sortHeader === "121+ Days") {
      return "sum_121_plus";
    }
  };

  getArReportList = (data) => {
    let headings = [],
      columns = [];
    headings = [
      { name: "Account" },
      { name: "Total # of Invoices" },
      { name: "Total Balance" }, //total_balance
      { name: "Current" },
      { name: "0-30 Days" },
      { name: "31-60 Days" },
      { name: "61-90 Days" },
      { name: "91-120 Days" },
      { name: "121+ Days" },
    ];

    columns =
      data &&
      data.account_list &&
      data.account_list.map((row, index) => {
        return {
          emptyPrimary: true,
          secondaryColumns: [
            {
              key: "Account",
              title: row.display_name && row.display_name,
            },
            {
              key: "Total # of Invoices",
              title: row.open_invoices_count && row.open_invoices_count,
            },
            {
              key: "Total Balance",
              title: getDollarAmount(row.total_balance && row.total_balance),
            },
            {
              key: "Current",
              title: getDollarAmount(row.current_sum && row.current_sum),
            },
            {
              key: "0-30 Days",
              title: getDollarAmount(row.sum_1_to_30 && row.sum_1_to_30),
            },
            {
              key: "31-60 Days",
              title: getDollarAmount(row.sum_30_to_60 && row.sum_30_to_60),
            },
            {
              key: "61-90 Days",
              title: getDollarAmount(row.sum_60_to_90 && row.sum_60_to_90),
            },
            {
              key: "91-120 Days",
              title: getDollarAmount(row.sum_90_to_120 && row.sum_90_to_120),
            },
            {
              key: "121+ Days",
              title: getDollarAmount(row.sum_121_plus && row.sum_121_plus),
            },
          ],
        };
      });

    return {
      headings,
      columns,
    };
  };

  //render AR Aging Report
  renderReportTable = () => {
    let { arReportData, totalData } = this.state;
    // let isSmallDevice = window.innerWidth <= 680 ? true : false;
    return (
      <>
        <div className='panel-heading-secondary report-heading-space'>
          AR Aging Report
        </div>
        {
          // isSmallDevice ? (
          //   <div className='big-device-grad invoice-line'>
          //     <div className='scrollbar in-sc'>
          //       <ClientsCardView
          //         data={this.getArReportList(arReportData)}
          //         rowActionClick={(event) => this.handleRowActionClick(event)}
          //         isStatus={true}
          //       />
          //     </div>
          //   </div>
          // ) :
          <div style={{ marginBottom: "1em" }}>
            <InvoiceObjectList
              data={this.getArReportList(arReportData)}
              rowActionClick={(event) => this.handleRowActionClick(event)}
              isStatus={true}
              // hideForMobile={true}
              isInvoiceListData={true}
              optionalClasses='scrollbar in-sc'
              sortHeaders={[this.state.sortHeader, this.state.sortType]}
              sortInvoiceData={this.sortInvoiceData}
              isBordered={true}
              arAgingData={totalData}
            />
          </div>
        }
      </>
    );
  };

  //render Most At-Risk Accounts
  renderRiskAccount = () => {
    let { mostAtRiskAccount } = this.state;
    mostAtRiskAccount = mostAtRiskAccount.slice(0, 6);
    return (
      <div className='at-most'>
        <div className='panel-heading-secondary report-heading-space'>
          Most At-Risk Accounts
        </div>
        <div className={`panel panel-shadow`}>
          <h3 className='panel-heading report-panel-heading'>
            <div className='heading-key'>Account</div>
            <div className='heading-value'>Amount</div>
            <div className='heading-expander'>Go to Account</div>
          </h3>
          {mostAtRiskAccount && mostAtRiskAccount.length ? (
            mostAtRiskAccount.map((item, index) => {
              return (
                <div
                  key={index.toString()}
                  className={`panel-content report-panel-content ${
                    index % 2 == 0 ? "alt-row" : ""
                  }`}
                >
                  <TruncatedName name={item.display_name} />
                  <div className='content-value' style={{ paddingRight: 10 }}>
                    {getDollarAmount(item.amount)}
                  </div>
                  <div className='content-expander'>
                    <a
                      className={`object-row-expander`}
                      style={{ display: "flex", margin: "auto" }}
                      onClick={(event) => this.handleExpanderClick(event, item)}
                    >
                      <div className={`client-caret expander-caret`} />
                    </a>
                  </div>
                </div>
              );
            })
          ) : (
            <div
              className='admin-admins-empty-state'
              style={{ padding: "3em 2em" }}
            >
              <p>There are currently no Accounts available</p>
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    const { appName } = this.props;
    const {
      isLoading,
      error,
      upcoming_activities,
      recent_activities,
      ACP,
      ADD,
      months,
      year,
      cei,
    } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return isLoading ? (
      <AppCanvasLoader />
    ) : (
      <div className='client-scroll-dashboard scrollbar'>
        <Helmet>
          <title>{appName} | Dashboard</title>
        </Helmet>
        <div className='app-sidebar-layout-canvas'>
          <ClientsDashboardSidebar
            {...this.props}
            user={this.props.user}
            title={"Dashboard"}
            upcoming_activities={upcoming_activities}
            recent_activities={recent_activities}
          />
          <div className='app-sidebar-layout-canvas-content setting-side-canvas big-device-grad col1-scroll scrollbar'>
            <ClientsDashboardChart
              months={months}
              year={year}
              ACP={ACP}
              ADD={ADD}
              cei={cei}
              minRange={this.minRange}
              maxRange={this.maxRange}
            />
            <div className='dashboard-report'>
              <div>{this.renderReportTable()}</div>
              <div>{this.renderRiskAccount()}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ClientsDashboard;
