import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { withRouter, Redirect } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import moment from "moment";
import BottomScrollListener from "react-bottom-scroll-listener";
import ReactGA from "react-ga";

// API
import * as API from "../../utils/api";

import { getZohoPagesense } from "../../utils/helpers";

import {
  networkMessage,
  showToast,
  showConfirmAlert,
} from "../../utils/helpers";

// Components
import AppSectionHeader from "../../components/App/AppSectionHeader";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import ObjectListAdminAccount from "../../components/ObjectList/ObjectListAdminAccount";
import DropdownButton from "../../components/DropdownButton";
import MenuItem from "../../components/MenuItem";
import FlashMessage from "../../components/FlashMessage";

class EmployeeAccounts extends Component {
  constructor() {
    super();
    this.onCheckboxClick = this.onCheckboxClick.bind(this);
    this.headerCheckBoxClick = this.headerCheckBoxClick.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.state = {
      consumers: [],
      fromChild: "",
      isLoading: true,
      isUploading: false,
      showFlash: false,
      totalPendingOffers: 0,
      error: {
        hasAnyError: false,
        statusCode: 200,
      },
      input: {
        search: {
          value: "",
        },
      },
      isAllSelected: false,
      hasMoreData: false,
      offset: 0,
      showLoader: false,
    };
  }

  componentDidMount() {
    this.fetchConsumers(true);
    getZohoPagesense();
    ReactGA.pageview("/ClientsAccounts");
  }

  fetchConsumers = (reload) => {
    this.setState((state) => ({ ...state, showLoader: true }));
    API.fetchAllConsumerForEmployee(this.props.user, this.state.offset).then(
      (data) => {
        let isAccessDenied =
          data && data.status && data.status_code === 401 ? true : false;

        if (
          (typeof data != "undefined" || data != null) &&
          !data.error &&
          !isAccessDenied
        ) {
          let consumers = reload ? this.state.consumers : false;
          let newData = consumers
            ? consumers.concat(data.consumers)
            : data.consumers;
          this.setState((state) => ({
            ...state,
            consumers: newData,
            defaultConsumers: newData,
            totalPendingOffers: data.total_pending_offers,
            isLoading: false,
            isUploading: false,
            hasMoreData: data.consumers.length < 10 ? false : true,
            showLoader: false,
          }));
        } else {
          this.setState((state) => ({
            ...state,
            isLoading: false,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.message && data.message == "Access denied."
                    ? 401
                    : 500) ||
                  500
                : 500,
            },
            showLoader: false,
          }));
        }
      }
    );
  };

  handleContainerOnBottom = () => {
    if (this.state.hasMoreData) {
      let offsetCount = parseInt(this.state.offset) + 1;
      this.setState(
        (state) => ({
          ...state,
          offset: offsetCount,
        }),
        () => {
          this.fetchConsumers(true);
        }
      );
    }
  };

  handleKeyPress(event) {
    if (event.key === "Enter") {
      this.setState(
        (state) => ({
          ...state,
          offset: 0,
        }),
        () => {
          this.handleSearch();
        }
      );
    }
  }

  handleInputChange(newPartialInput) {
    this.setState(
      (state) => ({
        ...state,
        input: {
          ...newPartialInput,
        },
      }),
      () => {
        if (newPartialInput.search.value.length === 0) {
          this.handleKeyPress({ key: "Enter" });
        }
      }
    );
  }

  setConsumersData = (dataSet = []) => {
    this.setState((state) => ({
      ...state,
      consumers: dataSet,
    }));
  };

  handleSearch = () => {
    let queryString = this.state.input.search.value;
    if (queryString) {
      API.employeeSearchConsumers(
        this.props.user,
        queryString,
        this.state.offset
      ).then((data) => {
        if ((typeof data != "undefined" || data != null) && !data.error) {
          if (!data.results) {
            this.setConsumersData();
          } else {
            this.setConsumersData(data.results);
          }
        } else {
          this.setState((state) => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.message && data.message == "Access denied."
                    ? 401
                    : 500) ||
                  500
                : 500,
            },
          }));
        }
      });
    } else {
      this.setConsumersData(this.state.defaultConsumers);
    }
  };

  handleAddAccountClick = (event) => {
    event.preventDefault();
    this.props.updateModalState(true, "EMPLOYEE_ADD_CONSUMER_ACCOUNT");
  };

  handleAddAdminAccountClick = (event) => {
    event.preventDefault();
    this.props.updateModalState(true, "ADD_ADMIN_ACCOUNT");
  };

  handleAddCreditorClick = (event) => {
    event.preventDefault();
    this.props.updateModalState(true, "Add_NEW_CREDITOR", this.props);
  };

  handleUploadClick = () => {
    this.refs.fileUploader.click();
  };

  uploadFile = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    let formData = new FormData();
    formData.append("file", file);

    this.setState(
      (state) => ({
        ...state,
        isUploading: true,
      }),
      () => {
        API.uploadClientCSV(this.props.user, formData).then((data) => {
          if ((typeof data != "undefined" || data != null) && !data.error) {
            if (data.message) {
              this.setState((state) => ({
                ...state,
                showFlash: true,
                flashMessage: data.message,
              }));
            }
            this.fetchConsumers(false);
          } else {
            this.setState((state) => ({
              ...state,
              error: {
                hasAnyError: true,
                statusCode: data
                  ? data.status ||
                    (data.message && data.message == "Access denied."
                      ? 401
                      : 500) ||
                    500
                  : 500,
              },
            }));
          }
        });
      }
    );
  };

  onCheckboxClick(event) {
    const consumerId = event.target.name;

    this.state.consumers.map((consumer) => {
      if (consumer.id == consumerId) {
        if (event.target.checked) {
          consumer.isSelected = true;
        } else {
          consumer.isSelected = false;
          this.state.isAllSelected = false;
        }
      }
    });

    this.setState({
      consumers: this.state.consumers,
      isAllSelected: this.state.isAllSelected,
    });
  }

  headerCheckBoxClick(event) {
    if (event.target.checked) {
      this.state.consumers.map((consumer) => {
        consumer.isSelected = true;
        this.state.isAllSelected = true;
      });
    } else {
      this.state.consumers.map((consumer) => {
        consumer.isSelected = false;
        this.state.isAllSelected = false;
      });
    }

    this.setState({
      consumers: this.state.consumers,
      isAllSelected: this.state.isAllSelected,
    });
  }

  handleClickAction = (action) => {
    if (this.state.consumers) {
      let consumerIds = [];
      this.state.consumers.map((consumer) => {
        if (consumer.isSelected) {
          consumerIds.push(consumer.id);
        }
      });

      if (consumerIds.length > 0) {
        switch (action) {
          case "delete":
            this.confirmAnction(
              "delete",
              () => {
                this.deleteConsumers(consumerIds);
              },
              () => {}
            );
            break;
          case "accept":
            this.confirmAnction(
              "accept",
              () => {
                this.acceptConsumerOffers(consumerIds);
              },
              () => {}
            );
            break;
          case "reject":
            this.confirmAnction(
              "reject",
              () => {
                this.rejectConsumerOffers(consumerIds);
              },
              () => {}
            );
            break;
          case "setThreshold":
            this.renderNegotiationModal(consumerIds, "");
            break;
          case "skipPayment":
            this.rendeSkipPaymentModal(consumerIds, "");
            break;
          default:
            alert("Error: Something went wrong");
        }
      } else {
        this.props.updateModalState(true, "ERROR", {
          message: "Please select consumers.",
        });
      }
    }
  };

  confirmAnction(action, sucessCallBack, errorCallBack) {
    return confirmAlert({
      // title: 'Confirm',
      message:
        "Please confirm you want to " + action + " this " + action === "delete"
          ? "account"
          : "offer" + "?",
      buttons: [
        {
          label: "Yes",
          onClick: () => sucessCallBack(),
        },
        {
          label: "Cancel",
          onClick: () => errorCallBack(),
        },
      ],
    });
  }

  startAutomatedMails(consumerId) {
    if (consumerId) {
      API.startAutomatedMails(this.props.user, consumerId).then((data) => {
        let isAccessDenied =
          data && data.status && data.status_code === 401 ? true : false;

        if (
          (typeof data != "undefined" || data != null) &&
          !data.error &&
          !isAccessDenied
        ) {
          this.props.updateModalState(true, "SUCCESS", {
            message: "Mail send successfully.",
          });
          this.releoadTableData();
        } else {
          this.setState((state) => ({
            ...state,
            isLoading: false,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.message && data.message == "Access denied."
                    ? 401
                    : 500) ||
                  500
                : 500,
            },
            showLoader: false,
          }));
        }
      });
    }
  }

  deleteConsumers(consumerIds) {
    API.deleteClientsConsumer(this.props.user, consumerIds).then(
      (success) => {
        this.fetchConsumers(false);
      },
      (error) => {}
    );
  }

  acceptConsumerOffers(consumerIds) {
    API.acceptClientsConsumerOffer(this.props.user, consumerIds).then(
      (success) => {
        this.fetchConsumers(false);
      },
      (error) => {}
    );
  }

  rejectConsumerOffers(consumerIds) {
    API.rejectClientsConsumerOffer(this.props.user, consumerIds).then(
      (success) => {
        this.fetchConsumers(false);
      },
      (error) => {}
    );
  }

  renderNewLetterModal(consumerId) {
    let props = {
      consumerId: consumerId,
      type: "Account",
    };
    this.props.updateModalState(true, "SEND_NEW_LETTER", props);
  }

  renderSendMailModal(consumerId) {
    this.startAutomatedMails(consumerId);
  }

  renderDebtAddModal(consumerId, addDebtData) {
    let props = {
      consumerId: consumerId,
      releoadTableData: this.releoadTableData,
      addDebtData: addDebtData,
    };
    this.props.updateModalState(true, "ADD_CONSUMER_DEBT", props);
  }

  renderNegotiationModal(consumerId, thresholdData) {
    let props = {
      consumerId: consumerId,
      releoadTableData: this.releoadTableData,
      thresholdData: thresholdData,
    };
    this.props.updateModalState(true, "AUTOMATIC_NEGOTIATION", props);
  }

  rendeSkipPaymentModal(consumerId, thresholdData) {
    let props = {
      consumerId: consumerId,
      releoadTableData: this.releoadTableData,
      thresholdData: thresholdData,
    };
    this.props.updateModalState(true, "SKIP_PAYMENT", props);
  }

  releoadTableData = () => {
    this.fetchConsumers(false);
  };

  handleRowActionClick = (event) => {
    let { history } = this.props;
    const actionBehavior = event.target.getAttribute("data-behavior");
    const consumerId = event.target.getAttribute("data-target");
    const thresholdData = JSON.parse(
      event.target.getAttribute("data-threshold")
    );
    const addDebtData = JSON.parse(event.target.getAttribute("data-debt"));

    switch (actionBehavior) {
      case "account-debt":
        this.renderDebtAddModal([consumerId], thresholdData);
        break;
      case "account-view":
        history.push(`/employee/accounts/${consumerId}`);
        break;
      case "account-edit":
        history.push(`/employee/accounts/${consumerId}/edit`);
        break;
      case "account-delete":
        showConfirmAlert("Are you sure want to delete account?", () => {
          this.deactivateClientsConsumer(consumerId);
        });
        break;
      case "view-conversation":
        history.push(`/employee/view/conversation/${consumerId}`);
        break;
      case "approve-address":
        history.push(`/employee/accounts/${consumerId}/addressDocument`);
        break;
      case "automatic-negotiation":
        this.renderNegotiationModal([consumerId], thresholdData);
        break;
      case "send-letter":
        this.renderNewLetterModal(consumerId);
        break;
      case "send-mail":
        this.renderSendMailModal(consumerId);
        break;
      default:
        alert("Error: Something went wrong");
    }
  };

  deactivateClientsConsumer = (id) => {
    let consumers = [...this.state.consumers];
    let targetId = parseInt(id, 10);
    let newConsumers = consumers.filter((consumer) => {
      return consumer.id !== targetId;
    });

    if (newConsumers.length < consumers.length) {
      API.deactivateClientsConsumer(this.props.user, id);
      this.setState((state) => ({
        ...state,
        consumers: newConsumers,
      }));
    }
  };

  dismissFlash = () => {
    this.setState((state) => ({
      ...state,
      showFlash: false,
    }));
  };

  getSectionHeaderUtilities() {
    const { input } = this.state;
    let app_api_url = process.env.REACT_APP_API_URL;

    return (
      <div className='app-section-header-utilities'>
        <div className='admin-search-input-container'>
          <input
            type='text'
            name='search'
            placeholder='Search'
            value={input.search.value}
            className='admin-search-input override-serch-input'
            onKeyDown={this.handleKeyPress}
            onChange={(event) =>
              this.handleInputChange({ search: { value: event.target.value } })
            }
          />
          <span
            className='search-icon'
            onClick={() => this.handleKeyPress({ key: "Enter" })}
          />
        </div>
        <DropdownButton
          title='More Options'
          primaryLink='/employee/accounts'
          overrideStyle={true}
          //onPrimaryClick={(event) => this.handleClickAction('delete')}
        >
          <MenuItem handleClick={(event) => this.handleClickAction("accept")}>
            Accept Offer
          </MenuItem>
          <MenuItem handleClick={(event) => this.handleClickAction("reject")}>
            Reject Offer
          </MenuItem>
          <MenuItem handleClick={(event) => this.handleClickAction("delete")}>
            Delete Account
          </MenuItem>
          <MenuItem
            handleClick={(event) => this.handleClickAction("setThreshold")}
          >
            Set Threshold
          </MenuItem>
          <MenuItem
            handleClick={(event) => this.handleClickAction("skipPayment")}
          >
            Skip Payment
          </MenuItem>
        </DropdownButton>
        <DropdownButton
          title='+ Add Consumer'
          primaryLink='/employee/accounts/add'
          overrideStyle={true}
          onPrimaryClick={(event) => this.handleAddAccountClick(event)}
        >
          <MenuItem handleClick={this.handleUploadClick}>
            Upload Multiple Accounts (CSV)
          </MenuItem>
          <li>
            <a
              href={app_api_url + "/debt-import-template.csv"}
              rel='noopener noreferrer'
              target='_blank'
            >
              Download CSV Template
            </a>
          </li>
          <MenuItem handleClick={(event) => this.handleAddCreditorClick(event)}>
            Add New Creditor
          </MenuItem>
        </DropdownButton>
        <input
          type='file'
          id='file'
          accept='.csv'
          ref='fileUploader'
          style={{ display: "none" }}
          onChange={(event) => this.uploadFile(event)}
        />
      </div>
    );
  }

  getAccountsObjectListData = () => {
    const { consumers, isAllSelected } = this.state;

    return {
      headings: [
        "Name",
        "Offer(s)",
        "Total Debts",
        "Total Owed",
        "Last Payment",
      ],
      isAllSelected: isAllSelected,
      columns: consumers.map((consumer) => {
        return {
          primaryColumn: {
            title: consumer.user_address_document_status
              ? consumer.first_name +
                " " +
                consumer.last_name +
                " (" +
                consumer.user_address_document_status[0] +
                ")"
              : consumer.first_name + " " + consumer.last_name,
            titleLinkPath: `/employee/accounts/${consumer.id}`,
            secondaryInfo: consumer.email,
          },
          secondaryColumns: [
            {
              title: `${consumer.total_pending_offers_count}`,
            },
            {
              title: `${consumer.total_debt_count} Debts`,
              optionalClasses: "row-inner-data-utility account-rows",
            },
            {
              title: `$${consumer.total_debt_owed}`,
              optionalClasses: "row-inner-data-utility account-rows",
            },
            {
              title: `${
                consumer.last_payment_date
                  ? this.renderLatestPaymentDate(consumer.last_payment_date)
                  : "n/a"
              }`,
              optionalClasses: "row-inner-data-utility account-rows",
            },
          ],
          rowLinkPath: `/employee/accounts/${consumer.id}`,
          rowType: "overflowMenu",
          consumerId: consumer.id,
          isSelected: consumer.isSelected ? consumer.isSelected : false,
          rowActions: [
            {
              title: "Add New Debt",
              dataBehavior: "account-debt",
              dataTarget: consumer.id,
            },
            {
              title: "Account Details",
              dataBehavior: "account-view",
              dataTarget: consumer.id,
            },
            {
              title: "Edit Account",
              dataBehavior: "account-edit",
              dataTarget: consumer.id,
            },
            {
              title: "Delete Account",
              optionalClasses: "alert",
              dataBehavior: "account-delete",
              dataTarget: consumer.id,
            },
            /* {
               title: 'View Conversations',
               optionalClasses: 'alert',
               dataBehavior: 'view-conversation',
               dataTarget: consumer.id
             },*/
            {
              title: "Approve Address",
              optionalClasses: "alert",
              dataBehavior: "approve-address",
              dataTarget: consumer.id,
            },
            {
              title: "Set Threshold",
              optionalClasses: "alert",
              dataBehavior: "automatic-negotiation",
              dataTarget: consumer.id,
              dataThreshold: consumer.threshold ? consumer.threshold[0] : "",
            },
            {
              title: "Send New Letter",
              optionalClasses: "alert",
              dataBehavior: "send-letter",
              dataTarget: consumer.id,
            },
            {
              title: "Send Automated Mails",
              optionalClasses: "alert",
              dataBehavior: "send-mail",
              dataTarget: consumer.id,
              verified: consumer.verified ? consumer.verified : false,
              startMails: consumer.start_automated_mail_at
                ? consumer.start_automated_mail_at
                : "",
            },
          ],
        };
      }),
    };
  };

  renderLatestPaymentDate = (date) => {
    return moment(date).format("MM/DD/YYYY");
  };

  render() {
    const { appName } = this.props;
    const { isLoading, isUploading, error } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return isLoading || isUploading ? (
      <AppCanvasLoader />
    ) : (
      <div>
        <Helmet>
          <title>{appName} | Accounts </title>
        </Helmet>
        {this.state.showFlash ? (
          <FlashMessage
            message={this.state.flashMessage}
            handleFlashDismiss={this.dismissFlash}
          />
        ) : null}

        <AppSectionHeader
          title='Accounts'
          utilities={this.getSectionHeaderUtilities()}
        />
        {this.state.consumers ? (
          this.state.consumers.length ? (
            <div>
              <BottomScrollListener
                offset={50}
                onBottom={this.handleContainerOnBottom}
                debounce={0}
              >
                {(scrollRef) => (
                  <div>
                    <ObjectListAdminAccount
                      optionalClasses={"accounts"}
                      data={this.getAccountsObjectListData()}
                      rowActionClick={(event) =>
                        this.handleRowActionClick(event)
                      }
                      rowCheckListHandler={this.onCheckboxClick}
                      handleHeaderCheckboxClick={this.headerCheckBoxClick}
                      scrollRef={scrollRef}
                    />
                    {this.state.showLoader && (
                      <div>
                        <AppCanvasLoader />
                      </div>
                    )}
                  </div>
                )}
              </BottomScrollListener>
            </div>
          ) : this.state.input.search.value.length ? (
            <div className='admin-admins-empty-state'>
              <p>No results found for:</p>
              <h3 className='admin-admins-empty-state-heading'>
                {this.state.input.search.value}
              </h3>
            </div>
          ) : (
            <div className='admin-admins-empty-state'>
              <h3 className='admin-admins-empty-state-heading'>
                No Account Activity
              </h3>
              <p>There are currently no collections accounts in progress</p>
            </div>
          )
        ) : (
          <div className='admin-admins-empty-state'>
            <h3 className='admin-admins-empty-state-heading'>
              No Account Activity
            </h3>
            <p>There are currently no collections accounts in progress</p>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(EmployeeAccounts);
