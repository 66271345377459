import $ from "jquery";
import { Multiselect } from "multiselect-react-dropdown";
import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import Select from "react-select";
import "react-select/dist/react-select.css";
// API
import * as API from "../../../../utils/api";
import { showToast, validateEmail } from "../../../../utils/helpers";
import AppCanvasLoader from "../../AppCanvasLoader";
import Modal from "../Modal";
import PreviewTemplateModal from "./PreviewTemplateModal";
import CKEditor from "react-ckeditor-component";
import FileBase64 from "react-file-base64";
import FileBase64Component from "../../AppInputToBase64";
import moment from "moment";
class SendFreeFormEMailModal extends Component {
  state = {
    consumerData: {},
    Custom_Message: "",
    templateData: [],
    filterdTemplateData: [],
    mergeVaribales: [],
    mergeVaribalesValues: {},
    checkArray: [],
    options: [],
    document_ids: [],
    dropdownIsActive: false,
    mergeVaribalesValuesError: false,
    isLoading: true,
    isUpload: false,
    isPreview: false,
    customMsgError: false,
    isTempleteDisable: false,
    toArray: [],
    ccArray: [],
    content: "content",
    orgMergeData: [],
    input: {
      name: {
        value: "",
        hasError: false,
      },
      fileSizeInMB: "",
      email: {
        value: [],
        hasError: false,
      },
      cc_email: {
        value: [],
        hasError: false,
      },
      document: [],
      description: {
        value: "",
        hasError: false,
      },
      documentType: {
        id: "",
        value: "",
        hasError: false,
      },
    },
    message: {
      value: "",
      hasError: false,
    },
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
    showFlash: false,
    flashMessage: "",
    isMultiTaskInvoiceSelected: false,
    defaultSelected: [],
    filesError: false,
    firstNameError: false,
  };

  componentDidMount() {
    this.getMergeVariables();
    let { optionalProps } = this.props;
    let { invoiceDetails, isMultipleInvoices, multipleInvoiceData } =
      optionalProps;
    let me = this;
    if (invoiceDetails && invoiceDetails.user) {
      this.setState({ consumerData: invoiceDetails.user }, () => {
        this.getContactDetails(this.state.consumerData.user_id);
        this.getOrgData(this.state.consumerData.user_id);
      });
    }
    if (isMultipleInvoices) {
      this.setState({ isTempleteDisable: true });
      this.getMultiIncoiceDocumentData(multipleInvoiceData.invoiceListArray);
    } else {
      this.getIncoiceDocumentData();
    }
    API.getMandrillTemplate(
      this.props.user,
      isMultipleInvoices ? "mg-send-multiple-invoices" : ""
    ).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        if (data.error) {
          showToast(data.error);
        } else {
          if (data.data && data.data.length > 0) {
            this.setState({ templateData: data.data }, () => {
              this.setTemplateData();
              if (isMultipleInvoices) {
                this.setState(
                  {
                    message: {
                      value: "mg-send-multiple-invoices",
                      hasError: false,
                    },
                  },
                  () => {
                    this.getMergeVariables();
                  }
                );
              }
            });
          }
        }
        this.setState({ isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  }

  getMultiDocListData = () => {
    let selectedList = [];
    this.state.options.map((item) => {
      if (item.is_selected) {
        selectedList.push(item);
      }
    });
    if (selectedList.length > 0) {
      this.onSelect(selectedList);
      this.setDefaultOptionsData(selectedList);
    }
  };

  getTasksList = () => {
    let me = this;
    API.getTasksList(this.props.user).then((data) => {
      //Emitter.emit('IS_QUICKBOOK_CONNECTED', data.qb_connected);
      if (
        typeof data != "undefined" ||
        (data != null && data.status_code && data.status_code === 200)
      ) {
        if (data.data) {
          data.data.map((taskListData) => {
            if (taskListData.name == "Send Multiple Invoices To Client") {
              taskListData.document_types.map((docTypeData) => {
                if (docTypeData.name == "Invoice") {
                  let selectedList = [];
                  me.state.options.map((item) => {
                    if (item.description == "MakeGood Invoice PDF") {
                      selectedList.push(item);
                    }
                  });
                  if (selectedList.length > 0) {
                    me.onSelect(selectedList);
                    this.setDefaultOptionsData(selectedList);
                  }
                }
              });
            }
          });
        }
        this.setState({ isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
          showLoader: false,
        }));
      }
    });
  };

  setDefaultOptionsData(data) {
    if (data && data.length) {
      data.map((item) => {
        item.id = item.document_id;
        item.name = item.name;
      });
      this.setState({ defaultSelected: data });
    } else {
      this.setState({ defaultSelected: [] });
    }
  }

  getContactDetails = (id) => {
    API.getContactSettings(this.props.user, id).then((data) => {
      if (data && data.status_code == 200 && data.data) {
        this.setState(
          {
            toArray: data.data.to_emails ? data.data.to_emails : [],
            ccArray: data.data.cc_emails ? data.data.cc_emails : [],
          },
          () => {
            this.setConsumerData();
          }
        );
      } else {
        this.setConsumerData();
      }
    });
  };

  getOrgData = (id) => {
    API.getOrgMergeVeriableData(this.props.user, id).then((data) => {
      if (data && data.status_code == 200 && data.data) {
        let dataArray = [];
        dataArray.push(data.data);
        this.restructOrgData(dataArray);
      } else {
      }
    });
  };

  getIncoiceDocumentData() {
    const invoiceNumber = this.props.optionalProps.invoiceDetails.id;
    API.getInvoiceDocuments(this.props.user, invoiceNumber).then((data) => {
      let isAccessDenied =
        data && data.message && data.message == "Access denied." ? true : false;
      if (data && data.data && !isAccessDenied) {
        this.setOptionsData(data.data);
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  }

  getMultiIncoiceDocumentData(ids) {
    const invoiceNumber = this.props.optionalProps.invoiceDetails.id;
    let payload = {
      invoice_ids: ids,
    };
    API.getMultiInvoiceDocuments(this.props.user, payload).then((data) => {
      let isAccessDenied =
        data && data.message && data.message == "Access denied." ? true : false;
      if (data && data.data && !isAccessDenied) {
        this.setOptionsData(data.data, true);
        this.getMultiDocListData();
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  }

  restructOrgData = (data) => {
    let tempArray = [];
    Object.entries(data).map((item) => {
      Object.entries(item[1]).map((itemx) => {
        if (Array.isArray(itemx[1])) {
          itemx[1].map((val, valIndex) => {
            tempArray.push(val);
          });
        } else {
          let obj = {
            variable_key: "ORG_LOGO",
            value: itemx[1],
          };
          tempArray.push(obj);
        }
      });
    });
    this.setState({ orgMergeData: tempArray });
  };

  setOptionsData = (data, isMultiInvoice) => {
    if (isMultiInvoice) {
      if (data && data.length) {
        data.map((item) => {
          item.id = item.document_id;
          item.name = item.name + "- #" + item.invoice_number;
        });
        this.setState({ options: data });
      }
    } else {
      if (data && data.length) {
        data.map((item, index) => {
          item.id = item.document_id;
          item.name = index + 1 + "- " + item.name;
        });
        this.setState({ options: data });
      }
    }
  };

  getMergeVariables() {
    this.setState({ isUpload: true });
    API.getMergeVariables(this.props.user, "mg-stage-custom-email").then(
      (data) => {
        if ((typeof data != "undefined" || data != null) && !data.error) {
          if (data.error) {
            showToast(data.error);
          } else {
            if (data && data.data) {
              this.setState({ mergeVaribales: data.data }, () => {
                this.autoFillMergeVariablies();
              });
            }
          }
          this.setState({ isUpload: false });
        } else {
          this.setState((state) => ({
            ...state,
            isUpload: false,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.message && data.message == "Access denied."
                    ? 401
                    : 500) ||
                  500
                : 500,
            },
          }));
        }
      }
    );
  }

  onSelect = (selectedList) => {
    this.setDefaultOptionsData(selectedList);
    let temp_id = [];
    if (selectedList && selectedList.length > 0) {
      selectedList.map((item) => {
        temp_id.push(item.document_id);
      });
      this.setState((state) => ({
        ...state,
        document_ids: [...new Set(temp_id)],
      }));
    }
  };

  onRemove = (selectedList, removedItem) => {
    let { document_ids } = this.state;
    if (removedItem.hasOwnProperty("document_id")) {
      const index = document_ids.indexOf(removedItem.document_id);
      if (index > -1) {
        document_ids.splice(index, 1);
      }
      this.setState({ document_ids: document_ids });
    }
    this.setDefaultOptionsData(selectedList);
  };

  setConsumerData() {
    let { consumerData, toArray, ccArray } = this.state;
    if (consumerData.email) {
      if (toArray & toArray.length) {
        let i = toArray.findIndex((email) => email == consumerData.email);
        if (i == -1) {
          toArray.push(consumerData.email);
        }
      }
    }
    if (consumerData.cc_email) {
      if (ccArray & ccArray.length) {
        let i = ccArray.findIndex((email) => email == consumerData.cc_email);
        if (i == -1) {
          ccArray.push(consumerData.cc_email);
        }
      }
    }
    // this.state.input.name.value =
    //   this.state.consumerData.first_name +
    //   " " +
    //   this.state.consumerData.last_name;
    this.setState({
      // input: this.state.input,
      toArray,
      ccArray,
    });
  }

  autoFillMergeVariablies() {
    let { optionalProps } = this.props;
    let { multipleInvoiceData } = optionalProps;
    if (this.state.mergeVaribales && this.state.mergeVaribales) {
      this.state.mergeVaribales.map((item) => {
        if (item === "FNAME") {
          this.handleMergeVariableValue(
            this.state.consumerData.first_name,
            item,
            true
          );
        } else if (item === "CUSTOM_URL") {
          let url =
            window && window.location && window.location.origin
              ? window.location.origin
              : "";
          this.handleMergeVariableValue(url, item, true);
        } else if (item === "CUSTOM_BODY_TEXT") {
          let url = this.state.Custom_Message;
          this.handleMergeVariableValue(url, item, true);
        } else if (item === "INVOICE_NUMBERS") {
          this.handleMergeVariableValue(
            multipleInvoiceData.invoiceListSTR,
            item,
            true
          );
        }
      });
    }
    this.setOrgMergeVeriables();
  }

  setOrgMergeVeriables = () => {
    const { orgMergeData } = this.state;
    if (this.state.mergeVaribales && this.state.mergeVaribales) {
      //colors
      orgMergeData.map((val, index) => {
        this.state.mergeVaribales.map((item, i) => {
          if (item === val.variable_key) {
            if (item === "ALT_TEXT_COLOR" || item === "BACKGROUND_COLOR") {
              let value = "";
              if (val.value === null) {
                value = val.default_value;
              } else {
                value = val.value;
              }
              this.handleMergeVariableValue(value, item, true, i);
            } else {
              this.handleMergeVariableValue(val.value, item, true, i);
            }
          }
        });
      });
    }
  };

  setTemplateData() {
    if (this.state.templateData) {
      this.state.templateData.map((item, index) => {
        let data = {
          label: item.name ? item.name : "",
          value: item.slug ? item.slug : "",
          data: item ? item : "",
        };
        this.state.filterdTemplateData.push(data);
        this.setState({ filterdTemplateData: this.state.filterdTemplateData });
      });
    }
  }

  handleInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      },
    }));
  }

  handleInputChangeForMsg(msg, data) {
    this.setState(
      {
        ...this.state.message.value,
        ...msg,
      },
      () => {
        this.getMergeVariables();
      }
    );
  }
  handleSubmit = (event) => {
    if (
      this.state.input.fileSizeInMB < 20.0 ||
      this.state.input.fileSizeInMB == ""
    ) {
      if (this.state.Custom_Message == "") {
        this.setState({ customMsgError: true });
        this.setState({ filesError: false });
      } else if (this.state.Custom_Message == " ") {
        this.setState({ Custom_Message: "" });
      } else {
        this.setState({ customMsgError: false });

        event.preventDefault();
        const { document_ids, Custom_Message, input, toArray, ccArray } =
          this.state;
        let { optionalProps } = this.props;
        if (
          this.validateForm() &&
          this.validateFormateError() &&
          this.validateFirstName() &&
          !this.state.input.document.hasError
        ) {
          let payload = {
            email: toArray,
            cc_email: ccArray,
            custom_text: Custom_Message,
            document_ids: document_ids,
            first_name: this.state.input.name.value,
            invoice_id: this.props.optionalProps.invoiceDetails.id,
            other_document: this.state.input.document,
          };
          $("#send-new-mail").replaceWith('<div class="spinner"></div>');
          API.freeFormEmail(this.props.user, payload).then((data) => {
            if ((typeof data != "undefined" || data != null) && !data.error) {
              if (data.status_code != 200) {
                this.handleModalClose();
                this.props.updateModalState(true, "ERROR", {
                  message: data.message,
                  title: "Error!",
                });
              } else {
                this.handleModalClose();
                optionalProps.reloadData();
                this.props.updateModalState(true, "SUCCESS", {
                  message: data.message,
                });
              }
            } else {
              this.setState((state) => ({
                ...state,
                error: {
                  hasAnyError: true,
                  statusCode: data
                    ? data.status ||
                      (data.message && data.message == "Access denied."
                        ? 401
                        : 500) ||
                      500
                    : 500,
                },
              }));
            }
          });
          this.setState({ filesError: false });
        }
      }
    } else {
      this.setState({ filesError: true });
    }
  };
  sendMultipleInvoiceEmail = (payload) => {
    API.sendMultipleMandrillTemplate(this.props.user, payload).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        if (data.error) {
          showToast(data.error);
        } else {
          this.handleModalClose();
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message,
          });
          this.props.optionalProps.closeOpenInvoiceView();
        }
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  handleModalClose = () => {
    this.props.hideModal();
  };

  dismissFlash = () => {
    this.handleModalClose();
    this.setState((state) => ({
      ...state,
      showFlash: false,
      flashMessage: "",
    }));
  };

  handleMergeVariableValue(value, item, isReadOnly) {
    let data = {};
    data[item] = value;
    let index = -1;
    let obj = {
      value: "",
      isReadOnly: false,
    };
    index = this.state.checkArray.findIndex((o) => Object.keys(o)[0] === item);

    if (index === -1) {
      if (isReadOnly) {
        obj.value = value;
        obj.isReadOnly = true;
      }
      data[item] = obj;
      this.state.checkArray.push(data);
    }
    if (value === "" || value === null || value === undefined) {
      this.state.checkArray.splice(index, 1);
    }
    if (isReadOnly) {
      obj.value = value;
      obj.isReadOnly = true;
    } else {
      obj.value = value;
      obj.isReadOnly = false;
    }

    this.state.mergeVaribalesValues[item] = obj;

    if (!this.state.mergeVaribalesValues[item]) {
      // this.setState({ mergeVaribalesValuesError: true });
    } else {
      this.setState({
        mergeVaribalesValuesError: false,
      });
    }

    this.setState({
      mergeVaribalesValues: this.state.mergeVaribalesValues,
    });
  }

  renderMergeVaribles(item, index) {
    let { mergeVaribalesValues, mergeVaribalesValuesError } = this.state;
    return (
      <div className='admin-form-row' key={item}>
        <div className={`input-container`}>
          <label htmlFor='name'>{item}</label>
          <input
            name={item}
            placeholder={"Enter " + item}
            type='text'
            disabled={
              mergeVaribalesValues &&
              mergeVaribalesValues[item] &&
              mergeVaribalesValues[item].isReadOnly
                ? mergeVaribalesValues[item].isReadOnly
                : false
            }
            readOnly={
              mergeVaribalesValues &&
              mergeVaribalesValues[item] &&
              mergeVaribalesValues[item].isReadOnly
                ? mergeVaribalesValues[item].isReadOnly
                : false
            }
            value={
              mergeVaribalesValues[item] && mergeVaribalesValues[item].value
                ? mergeVaribalesValues[item].value
                : ""
            }
            onChange={(event) =>
              this.handleMergeVariableValue(event.target.value, item, false)
            }
          />
        </div>
      </div>
    );
  }

  toggleDropdown = (event) => {
    event.preventDefault();

    this.setState({
      dropdownIsActive: !this.state.dropdownIsActive,
    });
  };

  handleClickOutside = (event) => {
    if (this.state.dropdownIsActive) {
      this.toggleDropdown(event);
    }
  };

  handlePreviewClick = () => {
    let { checkArray, mergeVaribales, mergeVaribalesValues } = this.state;
    if (!mergeVaribalesValues) {
      this.setState({ mergeVaribalesValuesError: true });
    } else {
      if (this.state.Custom_Message == "") {
        this.setState({ customMsgError: true });
      } else if (this.state.Custom_Message == " ") {
        this.setState({ Custom_Message: "" });
      } else {
        this.setState({ isPreview: true });
        this.setState({ customMsgError: false });
      }
    }
  };

  hidePreviewModal = () => {
    this.setState({ isPreview: false });
  };

  handleKeyPress = (e, type) => {
    let { input, toArray, ccArray } = this.state;
    let isEnterKey = e.keyCode || e.which;
    if (isEnterKey == 13) {
      if (type == "to" && input.email.value) {
        if (!validateEmail(input.email.value)) {
          input.email.hasError = true;
        } else {
          toArray.push(input.email.value);
          input.email.hasError = false;
          input.email.value = "";
        }
      } else if (type == "cc" && input.cc_email.value) {
        if (!validateEmail(input.cc_email.value)) {
          input.cc_email.hasError = true;
        } else {
          ccArray.push(input.cc_email.value);
          input.cc_email.hasError = false;
          input.cc_email.value = "";
        }
      }
    }
    this.setState({ input });
  };

  removeEmail = (i, type) => {
    let { toArray, ccArray } = this.state;
    if (type == "to" && toArray.length) {
      toArray.splice(i, 1);
    } else if (type == "cc" && ccArray.length) {
      ccArray.splice(i, 1);
    }
    this.setState({ toArray, ccArray });
  };

  showEmails = (email, index, type) => {
    return (
      <div
        className='template-col-name'
        style={{ marginBottom: "0.5em" }}
        key={email}
      >
        <span
          style={{
            marginTop: 1,
            fontFamily: "sofia_pro_regular",
            fontSize: 13,
          }}
        >
          {email}
        </span>
        <span
          className='assigned-tem-card-cross'
          onClick={() => this.removeEmail(index, type)}
        >
          x
        </span>
      </div>
    );
  };
  validateFiles = (files) => {
    let isValidate = true;
    let ct = 0;
    let imgExtension;
    files.forEach((file, index) => {
      if (!imgExtension) {
        imgExtension = files[0].name.split(".").pop();
      }
      if (
        imgExtension.toLowerCase() === "xls" ||
        imgExtension.toLowerCase() === "xlsx" ||
        imgExtension.toLowerCase() === "doc" ||
        imgExtension.toLowerCase() === "pdf" ||
        imgExtension.toLowerCase() === "image.png" ||
        imgExtension.toLowerCase() === "docx" ||
        imgExtension.toLowerCase() === "image/png" ||
        imgExtension.toLowerCase() === "image/jpg" ||
        imgExtension.toLowerCase() === "image/jpeg" ||
        imgExtension.toLowerCase() === "image/pdf" ||
        imgExtension.toLowerCase() === "application/pdf"
      ) {
        ct++;
      }
    });
    if (ct == files.length) {
      isValidate = true;
    } else {
      isValidate = false;
    }
    return isValidate;
  };
  getFiles(files) {
    let newInputState = { ...this.state.input };
    let imgExtension = files[0].type;
    let fileName = moment(new Date()).format("MMDDYYYY");
    let extensionFlag = false;
    if (!imgExtension) {
      imgExtension = files[0].name.split(".").pop();
    }
    let extensionFromName = files[0].name.split(".").pop();
    if (
      extensionFromName &&
      (extensionFromName.toLowerCase() === "xls" ||
        extensionFromName.toLowerCase() === "xlsx" ||
        extensionFromName.toLowerCase() === "doc" ||
        extensionFromName.toLowerCase() === "png" ||
        extensionFromName.toLowerCase() === "jpg" ||
        extensionFromName.toLowerCase() === "jpeg" ||
        extensionFromName.toLowerCase() === "docx")
    ) {
      extensionFlag = true;
    }
    if (this.validateFiles(files)) {
      extensionFlag = true;
    }
    // if (files[0].file && files[0].file.size > 20000000) {
    //   extensionFlag = false;
    // }
    else {
      let file_size =
        files[0].file && files[0].file.size
          ? files[0].file.size / (1024 * 1024)
          : 0;
      this.state.input.fileSizeInMB = parseFloat(file_size).toFixed(2);
    }
    if (imgExtension.toLowerCase() === "image/png") {
      this.state.input.fileExtension = `${
        files[0].name ? files[0].name : fileName + ".png"
      }`;
    } else if (
      imgExtension.toLowerCase() === "image/jpg" ||
      imgExtension.toLowerCase() === "image/jpeg"
    ) {
      this.state.input.fileExtension = `${
        files[0].name ? files[0].name : fileName + ".jpeg"
      }`;
    } else if (
      imgExtension.toLowerCase() === "image/pdf" ||
      imgExtension.toLowerCase() === "application/pdf" ||
      imgExtension.toLowerCase() === "application/jpeg"
    ) {
      this.state.input.fileExtension = `${
        files[0].name ? files[0].name : fileName + ".pdf"
      }`;
    } else if (
      imgExtension.toLowerCase() === "xls" ||
      (extensionFromName && extensionFromName.toLowerCase() === "xls")
    ) {
      this.state.input.fileExtension = `${
        files[0].name ? files[0].name : fileName + ".xls"
      }`;
    } else if (
      imgExtension.toLowerCase() === "xlsx" ||
      (extensionFromName && extensionFromName.toLowerCase() === "xlsx")
    ) {
      this.state.input.fileExtension = `${
        files[0].name ? files[0].name : fileName + ".xlsx"
      }`;
    } else if (
      imgExtension.toLowerCase() === "doc" ||
      (extensionFromName && extensionFromName.toLowerCase() === "doc")
    ) {
      this.state.input.fileExtension = `${
        files[0].name ? files[0].name : fileName + ".doc"
      }`;
    } else if (
      imgExtension.toLowerCase() === "docx" ||
      (extensionFromName && extensionFromName.toLowerCase() === "docx")
    ) {
      this.state.input.fileExtension = `${
        files[0].name ? files[0].name : fileName + ".docx"
      }`;
    }
    if (extensionFlag) {
      let documents = [];
      files &&
        files.map((item) => {
          documents.push({
            document: item.base64,
            document_name: item.file.name,
            file_size_in_mb: parseFloat(item.file.size / (1024 * 1024)),
          });
        });
      this.state.input.document = documents;
    } else {
      newInputState.document.hasError = true;
      this.setState((state) => ({
        ...state,
        input: newInputState,
      }));
    }
    this.setState({ input: this.state.input });
  }

  onChange = (evt) => {
    var newContent = evt.editor.getData();
    this.setState({
      content: newContent,
      Custom_Message: evt.sender._.data,
    });
  };

  onBlur = (evt) => {};

  afterPaste = (evt) => {};
  validateForm = () => {
    let errorsArePresent = false;
    let { toArray, ccArray } = this.state;

    // Determine which input group to check for errors
    let input = { ...this.state.input };
    let message = { ...this.state.message };
    Object.entries(input).forEach(([key, value]) => {
      if (key === "email") {
        if (
          value.value == "" ||
          value.value == null ||
          typeof value.value == undefined
        ) {
          if (!toArray.length) {
            input[key].hasError = true;
            errorsArePresent = true;
          }
        } else if (value.value) {
          if (validateEmail(value.value)) {
            toArray.push(value.value);
            value.value = "";
          } else {
            input[key].hasError = true;
            errorsArePresent = true;
          }
        }
      } else if (key === "cc_email") {
        if (value.value) {
          if (validateEmail(value.value)) {
            ccArray.push(value.value);
            value.value = "";
          }
        }
      }
    });
    this.setState({ input, toArray, ccArray });
    let check =
      this.state.checkArray.length === this.state.mergeVaribales.length;
    if (errorsArePresent) {
      this.setState((state) => ({
        ...state,
        input: input,
        message: message,
      }));

      return false;
    } else {
      return true;
    }
  };

  validateFirstName = () => {
    if (
      this.state.input.name.value == "" ||
      this.state.input.name.value == null ||
      this.state.input.name.value == undefined
    ) {
      this.setState((state) => ({
        ...state,
        firstNameError: true,
      }));
      return false;
    } else {
      this.setState((state) => ({
        ...state,
        firstNameError: false,
      }));
      return true;
    }
  };

  validateFormateError = (isUpdate) => {
    let errorsArePresent = false;
    let newInputState = this.state.input;
    Object.entries(newInputState).forEach(([key, value]) => {
      if (key === "document") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      }
    });
    if (errorsArePresent) {
      this.setState((state) => ({
        ...state,
        input: newInputState,
      }));
    } else {
      return true;
    }
  };

  renderErrorMessage(input) {
    let message = "";

    if (input === "name") {
      message = "Please enter name";
    } else if (input === "email") {
      message = "Please enter a valid email";
    } else if (input === "cc_email") {
      message = "Please enter a valid email";
    } else {
      message = "Please complete this field";
    }

    return <div className='input-error-message'>{message}</div>;
  }
  renderPreview() {
    const { mergeVaribalesValues } = this.state;
    return (
      <PreviewTemplateModal
        user={this.props.user}
        hideModal={this.hidePreviewModal}
        template={"mg-stage-custom-email"}
        merge_variables={mergeVaribalesValues}
      />
    );
  }
  render() {
    const {
      input,
      message,
      error,
      isLoading,
      isUpload,
      isPreview,
      toArray,
      ccArray,
      mergeVaribalesValues,
      mergeVaribalesValuesError,
      firstNameError,
      defaultSelected,
    } = this.state;
    let { optionalProps } = this.props;
    let styels = { marginRight: `10px`, maxWidth: `30rem`, marginBottom: 0 };

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    // if (isPreview) {
    //   return (
    //     <PreviewTemplateModal
    //       user={this.props.user}
    //       hideModal={this.hidePreviewModal}
    //       template={"mg-stage-custom-email"}
    //       merge_variables={mergeVaribalesValues}
    //     />
    //   );
    // }
    return (
      <>
        {isPreview && this.renderPreview()}
        <div style={{ display: isPreview ? "none" : "block" }}>
          <Modal
            optionalClasses='add-account-modal send-new-email-modal scroll'
            title='Send Custom Email'
            closeOnClickOutside={isPreview ? false : true}
            hideModal={this.props.hideModal}
          >
            {isLoading ? (
              <AppCanvasLoader />
            ) : (
              <form
                className='admin-form'
                onClick={(event) => {
                  this.handleClickOutside(event);
                }}
              >
                <div className='app-sidebar-layout-canvas'>
                  <div className='app-sidebar app-sidebar-left' style={styels}>
                    <div>
                      <div className='admin-form-row'>
                        <div
                          className={`input-container ${
                            input.name.hasError ? " error" : ""
                          }`}
                        >
                          <label htmlFor='name'>First Name:</label>
                          <input
                            name='name'
                            // guide={"true"}
                            placeholder='Enter name'
                            type='text'
                            value={input.name.value}
                            onChange={(event) =>
                              this.handleInputChange({
                                name: {
                                  value: event.target.value,
                                  hasError: false,
                                },
                              })
                            }
                          />
                          {firstNameError ? (
                            <div
                              style={{
                                fontSize: "1em",
                                color: "#fb5c82",
                                fontFamily: "Open Sans",
                                fontWeight: 600,
                                marginTop: "0.6em",
                                float: "left",
                              }}
                            >
                              Please enter first name.
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className='admin-form-row'>
                        <div
                          className={`input-container ${
                            input.email.hasError ? " error" : ""
                          }`}
                        >
                          <label htmlFor='email'>
                            Email:{"  "}
                            {toArray.length
                              ? toArray.map((email, i) =>
                                  this.showEmails(email, i, "to")
                                )
                              : null}
                          </label>
                          <input
                            name='email'
                            placeholder='address@email.com'
                            type='text'
                            value={input.email.value}
                            onKeyPress={(e) => this.handleKeyPress(e, "to")}
                            onChange={(event) =>
                              this.handleInputChange({
                                email: {
                                  value: event.target.value,
                                  hasError: false,
                                },
                              })
                            }
                          />
                          {input.email.hasError
                            ? this.renderErrorMessage("email")
                            : null}
                        </div>
                      </div>

                      <div className='admin-form-row'>
                        <div
                          className={`input-container ${
                            input.cc_email.hasError ? " error" : ""
                          }`}
                        >
                          <label htmlFor='email'>
                            CC Email:{"  "}
                            {ccArray.length
                              ? ccArray.map((email, i) =>
                                  this.showEmails(email, i, "cc")
                                )
                              : null}
                          </label>
                          <input
                            name='cc_email'
                            placeholder='address@email.com'
                            type='text'
                            value={input.cc_email.value}
                            onKeyPress={(e) => this.handleKeyPress(e, "cc")}
                            onChange={(event) =>
                              this.handleInputChange({
                                cc_email: {
                                  value: event.target.value,
                                  hasError: false,
                                },
                              })
                            }
                          />
                          {input.cc_email.hasError
                            ? this.renderErrorMessage("cc_email")
                            : null}
                        </div>
                      </div>
                      <label>Attach Documents:</label>
                      <div
                        className={`input-container half`}
                        style={{ marginBottom: 20 }}
                      >
                        <Multiselect
                          options={this.state.options}
                          selectedValues={defaultSelected}
                          displayValue='name'
                          placeholder='Select attach documents'
                          onSelect={this.onSelect}
                          onRemove={this.onRemove}
                        />
                      </div>

                      <div className='in-new-btn'>
                        <div>
                          {isLoading ? (
                            <AppCanvasLoader />
                          ) : (
                            <form style={{ marginTop: "1rem" }}>
                              <div className='admin-form-row'>
                                <div
                                  className={`input-container ${
                                    input.name.hasError ? " error" : ""
                                  }`}
                                >
                                  {input.name.hasError
                                    ? this.renderErrorMessage("name")
                                    : null}

                                  <label> Other Documents: </label>
                                  {optionalProps &&
                                  optionalProps.isEditable ? null : (
                                    <div
                                      className='admin-form-row address-document'
                                      style={{ marginTop: "1rem" }}
                                    >
                                      <div
                                        className={`input-container error`}
                                        style={{ width: "62%" }}
                                      >
                                        <FileBase64Component
                                          multiple={true}
                                          input={this.state.input}
                                          onDone={this.getFiles.bind(this)}
                                          acceptDoc='.png, .jpg, .jpeg, .pdf, .doc, .docx, .xls, .xlsx'
                                        />
                                        <div
                                          className=' input-error-message'
                                          style={{ color: "darkgrey" }}
                                        >
                                          Image upload format (.png, .jpg,
                                          .jpeg, .pdf, .doc, .docx, .xls, .xlsx)
                                        </div>
                                        {this.state.input.document.hasError ? (
                                          <div
                                            className=' input-error-message'
                                            style={{ color: "darkgrey" }}
                                          >
                                            Please select valid upload format
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </form>
                          )}
                        </div>
                      </div>
                      <label style={{ marginBottom: "20px" }}>
                        Custom Message:
                      </label>
                      <div>
                        <CKEditor
                          activeClass='p10'
                          content={this.state.Custom_Message}
                          events={{
                            blur: this.onBlur,
                            afterPaste: this.afterPaste,
                            change: this.onChange,
                          }}
                          config={{
                            removeButtons:
                              "Cut,Copy,Paste,Link,Maximize,Spell,PasteFromWord,Image,Table,Anchor,PasteText,Scayt,SpecialChar,About",
                          }}
                        />
                      </div>

                      {this.state.customMsgError ? (
                        <div
                          style={{
                            fontSize: "1em",
                            color: "#fb5c82",
                            fontFamily: "Open Sans",
                            fontWeight: 600,
                            marginTop: "0.6em",
                          }}
                        >
                          Please Type the custom message.
                        </div>
                      ) : null}

                      <div
                        id='send-new-mail'
                        className='modal-actions double-btns'
                      >
                        <a
                          className={`cta mg-brand2-color`}
                          onClick={(event) => {
                            this.handlePreviewClick();
                            this.autoFillMergeVariablies();
                          }}
                        >
                          Preview
                        </a>
                        <a
                          className='cta mg-brand2-color'
                          onClick={(event) => this.handleSubmit(event)}
                        >
                          Submit
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Modal>
        </div>
      </>
    );
  }
}

export default withRouter(SendFreeFormEMailModal);
