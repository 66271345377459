// Packages
import React, { Component } from "react";
import ReactGA from "react-ga";
import moment from "moment";
import currencyFormatter from "currency-formatter";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import ClientsLogo from "../../components/Consumer/ClientsLogo";
import ConsumerSidebar from "../../components/Consumer/ConsumerSidebar";

// API
import * as API from "../../utils/api";
// Utils
import { getZohoPagesense } from "../../utils/helpers";
import ConsumerExpressPaymentForPaymentPlan from "./Payment/ConsumerExpressPaymentForPaymentPlan";

class PayPaymentPlans extends Component {
  state = {
    amount: "",
    user: "",
    cardList: [],
    paymentMethod: "CC",
    amountError: false,
    isLoading: false,
    isSubmit: false,
    paymentPlanDetails: {},
    duePaymentData: {
      dueDate: "",
      amount: 0,
    },
    paymentDueAmount: 0.0,
    paymentDueDate: "",
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
  };

  checkPrimaryColor = (props) => {
    return props.hasOwnProperty("primary_color")
      ? props.primary_color
      : "#0ab894";
  };

  componentDidMount() {
    getZohoPagesense();
    const params = new URLSearchParams(window.location.search);
    const paymentIntent = params.get("payment_intent");
    ReactGA.pageview("/ConsumerAccounts");
    API.getUser(this.props.user).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.errors) {
        this.setState((state) => ({
          user: data.user,
          // isLoading: false,
        }));
      } else {
        this.props.updateModalState(true, "ERROR", { message: data.message });
      }
    });

    if (paymentIntent) {
      this.attemptCount = 0;
      this.get3DCardPaymentConfirmation(paymentIntent);
    } else {
      this.getPlanDetails();
    }
  }

  get3DCardPaymentConfirmation = (intentID) => {
    this.setState({ showLoader: true, isLoading: true });
    const MAX_ATTEMPTS = 10;
    if (this.attemptCount < MAX_ATTEMPTS) {
      this.attemptCount++;
      API.getCardPaymentConfirmation(intentID).then((res) => {
        if (res && res.status_code === 200 && res.is_updated) {
          if (res.status === "SUCCESS") {
            this.props.updateModalState(true, "SUCCESS", {
              message: "Payments has been made successfully",
            });
          } else {
            this.props.updateModalState(true, "ERROR", {
              message: "Payments has failed",
            });
          }
          setTimeout(() => {
            this.getPlanDetails();
          }, 2000);
        } else {
          setTimeout(() => {
            this.get3DCardPaymentConfirmation(intentID);
          }, 2000);
        }
      });
    } else {
      this.getPlanDetails();
    }
  };

  getDateFormat = (date) => {
    let tempdate = new Date(date).getTime();
    return tempdate;
  };

  getPlanDetails = () => {
    const invoiceId = this.props.match.params.id;
    this.setState({ isLoading: true });
    API.getConsumerPaymentPlanDetails(this.props.user, invoiceId).then(
      (data) => {
        if (
          (typeof data != "undefined" || data != null) &&
          data.data != null &&
          data.status_code == 200
        ) {
          let dummyData = data.data;
          this.setState({ paymentPlanDetails: dummyData }, () => {
            let payeeAmt = 0.0;
            let currentDate = new Date();
            let isNextPlan = false;
            dummyData.payment_plans_payments.map((item, index) => {
              item.paymentNumber = index + 1;
              if (
                this.getDateFormat(currentDate) <
                  this.getDateFormat(item.due_date) &&
                (item.status === "not_due" ||
                  item.status === "partially_paid") &&
                !isNextPlan
              ) {
                payeeAmt = item.balance
                  ? parseFloat(item.balance.replace(",", "")) +
                    parseFloat(payeeAmt)
                  : parseFloat(payeeAmt);
                isNextPlan = true;
              }
              if (item.status === "due") {
                payeeAmt = item.balance
                  ? parseFloat(item.balance.replace(",", "")) +
                    parseFloat(payeeAmt)
                  : parseFloat(payeeAmt);
              }
            });
            let finalPaymentDate =
              dummyData.payment_plans_payments[
                dummyData.payment_plans_payments.length - 1
              ].due_date;
            let firstNonZeroBalancePayment =
              dummyData.payment_plans_payments &&
              dummyData.payment_plans_payments.find(
                (payment) => parseFloat(payment.balance) > 0
              );

            this.setState({
              duePaymentData: {
                dueDate:
                  firstNonZeroBalancePayment &&
                  firstNonZeroBalancePayment.due_date,
                amount:
                  firstNonZeroBalancePayment &&
                  parseFloat(firstNonZeroBalancePayment.balance).toFixed(2),
              },
              paymentDueAmount: payeeAmt,
              paymentDueDate: finalPaymentDate,
              // planList: list,
              isLoading: false,
            });
          });
        } else {
          this.setState((state) => ({
            ...state,
            isLoading: false,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.status_code && data.status_code == 401 ? 401 : 500) ||
                  500
                : 500,
            },
          }));
        }
      }
    );
  };

  getPaidAmount = (invoice) => {
    let amount = 0;
    if (invoice) {
      amount = invoice.total_amount
        ? invoice.balance
          ? parseFloat(invoice.total_amount) - parseFloat(invoice.balance)
          : invoice.total_amount
        : invoice.total_amount;
    }
    return amount ? amount.toFixed(2) : "0.00";
  };

  renderErrorMessage(input) {
    let message = "";
    if (input === "amount") {
      message = "Please enter a valid amount";
    }
    return <div className='input-error-message'>{message}</div>;
  }

  sidebarContent = () => {
    const { paymentDueAmount, paymentDueDate, duePaymentData } = this.state;

    return (
      <div className='consumer-sidebar'>
        <label
          style={{ marginBottom: "0.3em", fontSize: 16, color: "#ffffff" }}
        >
          Payment Amount Due
        </label>
        <div
          className='invoice-amt-owed payment-balance'
          style={{ marginBottom: "0.6em", color: "#000000" }}
        >
          {duePaymentData.amount > 0
            ? currencyFormatter.format(duePaymentData.amount, {
                code: "USD",
              })
            : "$ 0.00"}
        </div>
        <label
          style={{ marginBottom: "0.3em", fontSize: 16, color: "#ffffff" }}
        >
          Due Date
        </label>
        <div
          className='invoice-amt-owed payment-balance'
          style={{ marginBottom: "1em", color: "#000000" }}
        >
          {duePaymentData.dueDate
            ? moment(duePaymentData.dueDate).format("MM/DD/YYYY")
            : ""}
        </div>

        {/* {isDiscountApplied ? (
          <div>
            <label style={{ marginBottom: "0.3em", fontSize: 16 }}>
              Discount till
            </label>
            <div
              className="invoice-amt-owed payment-balance"
              style={{ marginBottom: "1em", color: "#000000" }}
            >
              {invoiceDetails.criteria_discount_days
                ? moment(discountDate).format("MM/DD/YYYY")
                : "NA"}
            </div>
          </div>
        ) : null} */}
      </div>
    );
  };

  render() {
    const { appName, stripePubKey, user, primary_color } = this.props;
    const {
      isLoading,
      error,
      paymentPlanDetails,
      cardList,
      isSubmit,
      duePaymentData,
    } = this.state;

    let BackLink = "/dashboard/accounts/payment-plans";

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    if (isLoading) {
      return <AppCanvasLoader />;
    } else {
      return (
        <div className='scrollbar consumer-scroll-accounts'>
          <Helmet>
            <title>{appName} | Payment</title>
          </Helmet>
          <div className='app-sidebar-layout-canvas'>
            <ConsumerSidebar
              primary_color={primary_color}
              user={this.props.user}
              title={"Payments"}
              sidebarContent={this.sidebarContent()}
              BackLink={BackLink}
            />
            <div className='app-sidebar-layout-canvas-content consumer-right-canvas big-device-grad'>
              <ClientsLogo />
              <h1
                className='app-section-consumer-heading consumer-brand'
                style={{ color: this.checkPrimaryColor(this.props) }}
              >
                Payment Plan # {paymentPlanDetails.payment_plan_number}
              </h1>
              <div className='consumer-payment-form consumer-scroll-payment scrollbar'>
                <ConsumerExpressPaymentForPaymentPlan
                  primary_color={primary_color}
                  stripePubKey={stripePubKey}
                  user={user}
                  paymentPlanDetails={paymentPlanDetails}
                  reloadData={this.getPlanDetails}
                  totalAmount={duePaymentData.amount}
                  // totalAmount={this.state.paymentDueAmount}
                  finalDueDate={duePaymentData.dueDate}
                  updateModalState={this.props.updateModalState}
                  getInvoiceDetails={this.props.getInvoiceDetails}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default PayPaymentPlans;
