import React, { useState, useEffect } from "react";
import styles from "./step9.module.scss";
import * as API from "../../../utils/api";
import Emitter from "../../../utils/event-emitter";
import DynamicTable from "../../../components/CustomTable/DynamicTable";
import AppCanvasLoader from "../../../components/App/AppCanvasLoader";

const style = {
  pText: {
    fontSize: "14px",
    color: "#2b2828",
    margin: "0px",
  },
  loader: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    backgroundColor: "#fff",
    opacity: 0.3,
    zIndex: 10,
  },
};

const CustomizeWorkFlowStep9 = (props) => {
  const [activeTab, setActiveTab] = useState("Invoices");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState({ hasAnyError: false, statusCode: null });
  const [miscellaneousData, setMiscellaneousData] = useState([]);
  const [mergeVariablesData, setMergeVariablesData] = useState({});
  // tabs data
  const [invoices, setInvoices] = useState([]);
  const [reminders, setReminders] = useState([]);
  const [dueDate, setDueDate] = useState([]);
  const [pastDueNotifications, setPastDueNotifications] = useState([]);
  const [escalatedPursuit, setEscalatedPursuit] = useState({
    serviceTermination: [],
    collections: [],
  });
  const [paymentPlans, setPaymentPlans] = useState([]);
  const [statements, setStatements] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const clearData = () => {
    setInvoices([]);
    setReminders([]);
    setDueDate([]);
    setPastDueNotifications([]);
    setEscalatedPursuit({ serviceTermination: [], collections: [] });
    setStatements([]);
  };

  const tabData = {
    Invoices: invoices,
    Reminders: reminders,
    DueDate: dueDate,
    PastDueNotifications: pastDueNotifications,
    EscalatedPursuit: escalatedPursuit,
    PaymentPlans: paymentPlans,
    Statements: statements,
  };

  const renderTabContent = () => {
    const data = tabData[activeTab];
    let newData = transformData(data);

    return (
      data && (
        <DynamicTable
          headers={header}
          data={newData}
          handleToggle={handleToggle}
          handleDayChange={handleDayChange}
          handleEyeClick={handleEyeClick}
          handleEditRow={handleEditClick}
          activeTab={activeTab}
        />
      )
    );
  };

  const getTasksList = () => {
    setIsLoading(true);
    API.getPaymentPlPlanTaskList(props.user).then((response) => {
      setPaymentPlans([]);
      const tempData = response.data.map((task) => ({
        ...task,
        isPaymentPlanTask: true,
      }));
      setPaymentPlans(tempData);
    });

    API.getTasksList(props.user)
      .then((data) => {
        Emitter.emit("IS_QUICKBOOK_CONNECTED", data.qb_connected);
        // clearData();
        let invoicesData = [];
        let remindersData = [];
        let dueDateData = [];
        let pastDueNotificationsData = [];
        let escalatedPursuitData = {
          serviceTermination: [],
          collections: [],
        };
        let statementsData = [];
        


        data.data.map((item) => {
          if (item.name === "Send Invoice To Client") {
            invoicesData.push(item);
          } else if (
            item.name === "Invoice Due - 1st Reminder" ||
            item.name === "Invoice Due - 2nd Reminder" ||
            item.name === "Invoice Due - 3rd Reminder"
          ) {
            remindersData.push(item);
          } else if (item.name === "Send Due Date Reminder") {
            dueDateData.push(item);
          } else if (
            item.name === "Invoice Past Due - 1st Notification" ||
            item.name === "Invoice Past Due - 2nd Notification" ||
            item.name === "Invoice Past Due - 3rd Notification"
          ) {
           pastDueNotificationsData.push(item);
          } else if (
            item.name === "Service Termination Warning" ||
            item.name === "Service Terminated"
          ) {
            escalatedPursuitData.serviceTermination.push(item);
            
          } else if (
            item.name === "Demand For Payment" ||
            item.name === "Send To Collections"
          ) {
            escalatedPursuitData.collections.push(item);
          } else if (item.name === "Send Monthly Statements") {
            statementsData.push(item);
          }
        });

        setInvoices(invoicesData);
        setReminders(remindersData);
        setDueDate(dueDateData);
        setPastDueNotifications(pastDueNotificationsData);
        setEscalatedPursuit(escalatedPursuitData);
        setStatements(statementsData);
        
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getMiscellaneousSettingStatus = () => {
    API.getMiscellaneousInSettings(props.user)
      .then((data) => {
        if (typeof data !== "undefined" || data != null) {
          if (data.data) {
            setMiscellaneousData(data.data);
          }
        } else {
          setError({
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code === 401 ? 401 : 500) ||
                500
              : 500,
          });
        }
      })
      .catch(() => {})
      .finally(() => {});
  };
  const refreshData = () => {
    // clearData();
    getTasksList();
    getMiscellaneousSettingStatus();
    getAllMergeVariables();
  };

  const getAllMergeVariables = () => {
    getTasksList();

    API.getOrgDetails(props.user)
      .then((data) => {
        if (data && data.data) {
          return API.getMergeVariablesListClient(
            props.user,
            data.data.organization.id
          );
        } else {
          throw new Error("Invalid organization data");
        }
      })
      .then((data) => {
        let mergeVariablesValuesObj = {};

        Object.keys(data.data).forEach((key) => {
          if (
            key === "Colors" ||
            key === "Payment Address" ||
            key === "Payment Information" ||
            key === "Sign In and Payment URLs"
          ) {
            if (key === "Colors") {
              Object.keys(data.data[key]).forEach((key2) => {
                data.data[key][key2].forEach((item) => {
                  mergeVariablesValuesObj[item.variable_key] =
                    item.value === null ||
                    item.value === "" ||
                    item.value === undefined
                      ? item.default_value
                      : item.value;
                });
              });
            } else {
              Object.keys(data.data[key]).forEach((key2) => {
                data.data[key][key2].forEach((item) => {
                  mergeVariablesValuesObj[item.variable_key] =
                    item.value === null ||
                    item.value === "" ||
                    item.value === undefined
                      ? item.default_value
                      : "";
                });
              });
            }
          } else if (key === "logo_url") {
            mergeVariablesValuesObj["ORG_LOGO"] = data.data[key];
          }
        });

        setMergeVariablesData(mergeVariablesValuesObj);
      })
      .catch(() => {})
      .finally(() => {});
  };

  const header = [
    { name: "Task", info: null },
    {
      name: "Task Status",
      info: "Toggling on will activate the communication task for use in your workflow. Toggling off will disable the communication task.",
    },
    {
      name: "Auto Send",
      info: "Toggling on will set the communication task to automatically send. Toggling off will keep the communication task live in your workflow but will need to be manually sent by a Team Member.",
    },
    {
      name: "Internal Contact",
      info: "Team Members assigned as an Internal Contact will be included in the communications with this toggle on. Internal Contacts can be set at the client level so they only receive communications for clients they are associated with.",
    },
    {
      name: "Delivery Schedule",
      info: "The number of days defined in the Delivery Schedule is based on the Invoice Due Date.",
    },
    {
      name: "Delivery Method",
      info: "Delivery Method defines the method used for sending the communication. Choose Email Only, Letter Only or Both.",
    },
    {
      name: "Document Type",
      info: "Include multiple document types in each communication task or associate specific document types with certain communications. For example, the Send Monthly Statements communication task will have the Statement document type associated with it.",
    },

    {
      name: "Contact Pref.",
      info: "If you don't want certain clients to receive communications for a specific communication task then you can exclude them with Client Contact Preferences.",
    },
  ];

  const transformData = (apiResponses) => {
    const transformTask = (task) => {
      return {
        id: task.id,
        Task: task.name,
        "Task Status": task.state ? "Yes" : "No",
        "Auto Send": task.auto_send ? "Yes" : "No",
        "Internal Contact":
          activeTab === "PaymentPlans" ? "--" : task.internal ? "Yes" : "No",
        "Delivery Schedule": `${task.reminder_schedule} Days Before Due Date`,
        "Delivery Method":
          activeTab === "PaymentPlans"
            ? task.delivery_method
            : task.delivery_method.includes("and")
            ? "Email, Letter"
            : task.delivery_method,
        "Document Type":
          activeTab === "PaymentPlans"
            ? ""
            : task.document_types.map((doc) => doc.name).join(", "),
        "Contact Pref.": task.is_all_client ? "All Clients" : "Selected",
        "Email Slug":
          activeTab === "PaymentPlans"
            ? task.email_slug
            : task.templates.map(
                (temp) => temp.method_name === "Email" && temp.slug
              ),
        fullInfo: task,
      };
    };

    const transformCategory = (categoryName, tasks) => {
      return {
        category: categoryName,
        items: tasks.map(transformTask),
      };
    };

    const categoryData = [];
    const categoryData1 = [];

    if (Array.isArray(apiResponses)) {
      apiResponses.forEach((task) => {
        const transformedTask = transformTask(task);
        categoryData1.push(transformedTask);
      });
    } else {
      const serviceTerminationTasks = apiResponses.serviceTermination || [];
      const collectionsTasks = apiResponses.collections || [];

      if (serviceTerminationTasks.length > 0) {
        categoryData.push(
          transformCategory("Service Termination", serviceTerminationTasks)
        );
      }

      if (collectionsTasks.length > 0) {
        categoryData.push(transformCategory("Collections", collectionsTasks));
      }
    }

    if (categoryData1.length > 0) {
      return [{ items: categoryData1 }, ...categoryData];
    } else {
      return categoryData;
    }
  };

  const handleToggle = (id, header, value) => {
    let payload = {
      id,
      [header]: value === "Yes" ? false : true,
    };
    updateData(payload);
  };

  const handleDayChange = (id, header, value) => {
    let payload = {
      id,
      [header]: value,
    };
    updateData(payload);
  };

  const handleEyeClick = (id, slug, info) => {
    let data = {
      selectedTemp: info,
      mergeVairablesData: mergeVariablesData,
    };
    props.updateModalState(true, "PREVIEW_TASK_CONFIG", data);
  };

  const handleEditClick = (item) => {
    setSelectedRow(item);
    let data = {
      item: item,
      reload: getTasksList,
      user: props.user,
      internalContact: miscellaneousData[1].data[0].is_enable,
      statementAutoSend: miscellaneousData[3].data[0].is_enable,
      statementAutoSendMonthly:
        activeTab === "Statements" ? "Send Monthly Statements" : "",
    };
    props.updateModalState(true, "EDIT_TASK_CONFIG", data);
  };

  const updateData = (payload) => {
    setIsLoading(true);

    let updatedPayload = { task_id: payload.id };
    const keyMapping = {
      "Auto Send": "auto_send",
      "Task Status": "state",
      "Internal Contact": "internal",
      "Delivery Schedule": "reminder_schedule",
    };

    Object.keys(payload).forEach((key) => {
      const mappedKey = keyMapping[key];
      if (mappedKey) {
        updatedPayload[mappedKey] = payload[key];
      }
    });

    if (activeTab === "PaymentPlans") {
      updatedPayload = { ...updatedPayload, id: payload.id };
      delete updatedPayload.task_id;
      API.paymentPlanTaskUpdate(props.user, updatedPayload).then((res) => {
        refreshData();
      });
    } else {
      API.updateTaskSetting(props.user, updatedPayload).then((res) => {
        refreshData();
      });
    }
  };

  useEffect(() => {
    refreshData();
  }, [props.user]);

  return (
    <div className='app-sidebar-layout-canvas-content setting-side-canvas col1-scroll scrollbar md-marginLeft'>
      {isLoading && (
        <div style={style.loader}>
          <AppCanvasLoader />
        </div>
      )}
      <div className='step9' style={{ padding: "2rem" }}>
        {/* heading */}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h2
            style={{
              color: "black",
              fontSize: "30px",
              fontFamily: "Open Sans, sans-serif",
              fontWeight: "bold",
            }}
          >
            Schedule Your Communications
          </h2>
          <p
            style={{
              fontFamily: "Open Sans, sans-serif",
              fontSize: "15px",
              fontWeight: 600,
              lineHeight: "20.43px",
            }}
          >
            Finalize the configurations of your communications and define each
            communication's schedule.
          </p>
        </div>
        {/* buttons */}
        <div className={styles.tabContainer}>
          <button
            className={`${styles.tab} ${
              activeTab === "Invoices" ? styles.active : ""
            }`}
            onClick={() => handleTabChange("Invoices")}
          >
            Invoices
          </button>
          <button
            className={`${styles.tab} ${
              activeTab === "Reminders" ? styles.active : ""
            }`}
            onClick={() => handleTabChange("Reminders")}
          >
            Reminders
          </button>
          <button
            className={`${styles.tab} ${
              activeTab === "DueDate" ? styles.active : ""
            }`}
            onClick={() => handleTabChange("DueDate")}
          >
            Due Date
          </button>
          <button
            className={`${styles.tab} ${
              activeTab === "PastDueNotifications" ? styles.active : ""
            }`}
            onClick={() => handleTabChange("PastDueNotifications")}
          >
            Past Due Notifications
          </button>
          <button
            className={`${styles.tab} ${
              activeTab === "EscalatedPursuit" ? styles.active : ""
            }`}
            onClick={() => handleTabChange("EscalatedPursuit")}
          >
            Escalated Pursuit
          </button>
          <button
            className={`${styles.tab} ${
              activeTab === "PaymentPlans" ? styles.active : ""
            }`}
            onClick={() => handleTabChange("PaymentPlans")}
          >
            Payment Plans
          </button>
          <button
            className={`${styles.tab} ${
              activeTab === "Statements" ? styles.active : ""
            }`}
            onClick={() => handleTabChange("Statements")}
          >
            Statements
          </button>
        </div>
        {/* Content */}
        <div style={{ flex: "1 1 auto" }}>{renderTabContent()}</div>

        {/* Page navigation buttons */}
        <div>
          <div
            className='bottum-btns'
            style={{ padding: "20px", paddingBottom: "50px" }}
          >
            <div className='button-left'>
              <input
                className=''
                type='submit'
                id='schedule_communication_go_back'
                value='Go Back'
                style={{
                  borderRadius: "0.5em",
                  width: "200px",
                  height: "50px",
                  marginTop: "20px",
                  border: "2px solid",
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
                onClick={() => props.handleBack("step8")}
              />
              <span className='go-back-text'>
                <p style={style.pText}>Go back to</p>
                <p style={style.pText}>
                  "Choose Your Communication Preferences"
                </p>
              </span>
            </div>
            <div className='button-right'>
              <input
                type='submit'
                value={"Next"}
                id='schedule_communication_next'
                className='cta mg-brand2-color'
                style={{
                  width: "200px",
                  height: "50px",
                  borderRadius: "0.5em",
                  marginTop: "20px",
                  float: "right",
                  padding: "0px",
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
                onClick={() => props.handleNext("step10")}
              />
              <span className='next-text'>
                <p style={style.pText}>Continue to</p>
                <p style={style.pText}>"Schedule Your Go Live Date"</p>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomizeWorkFlowStep9;
