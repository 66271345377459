// Packages
import moment from "moment";
import React, { Component } from "react";
import BottomScrollListener from "react-bottom-scroll-listener";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import companyData from "../../assets/styles/theme.json";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import statusIcon from "../../assets/icons/delinquent.png";

import CardView from "../../components/CardView/CardView";
import ClientsLogo from "../../components/Consumer/ClientsLogo";
import ConsumerSidebar from "../../components/Consumer/ConsumerSidebar";
import InvoiceObjectList from "../../components/ObjectList/ClientsAccounts/InvoiceObjectList";
import ACHPlaidLink from "../../components/Consumer/ACHPlaidLink";
import dollorIcon from "../../assets/icons/dollar-teal.png";
import DollerIcon from "../../assets/images/dollerIcon.png";
import Tabs from "../../components/Tabs";
// API
import * as API from "../../utils/api";
// Utils
import {
  displayPlanStatus,
  getDollarAmount,
  getZohoPagesense,
} from "../../utils/helpers";
import Emitter from "../../utils/event-emitter";
import DropdownButton from "../../components/DropdownButton";
import MenuItem from "../../components/MenuItem";
import PayInvoices from "./PayInvoices";

class ConsumerAccounts extends Component {
  state = {
    activeTab: 1,
    accountType: "current",
    consumerData: [],
    invoiceData: [],
    consumerInvoice: [],
    hasMoreData: false,
    isLoading: true,
    showLoader: false,
    leftLoader: false,
    closeDropDown: false,
    isInvoiceSelected: false,
    isPayInvoices: false,
    textColor: "",
    selectedFilter: "Open",
    isAutoPopulateBalanceDue: false,
    paymentPlanList: [],

    offset: 0,
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
    input: {
      search: {
        value: "",
      },
    },
  };

  componentDidMount() {
    this.getMiscellaneousForConsumer();
    if (this.props.match.params.tab === "invoices") {
      this.setState({
        ...this.state,
        activeTab: 1,
      });
    }
    if (this.props.match.params.tab === "payment-plans") {
      this.setState({
        ...this.state,
        activeTab: 2,
      });
    }
    const params = new URLSearchParams(window.location.search);
    const paymentIntent = params.get("payment_intent");
    if (paymentIntent) {
      this.attemptCount = 0;
      this.get3DCardPaymentConfirmation(paymentIntent);
    } else {
      this.getPaymentPlansList();
      this.getInvoiceData();
    }
    Emitter.on("Notification_Clicked", (isClicked) => {
      this.handleTabChange(2);
    });
    getZohoPagesense();
    ReactGA.pageview("/ConsumerAccounts");
    API.getLogoColors(this.props.user)
      .then((response) => {
        this.setState({
          ...this.state,
          textColor: response.data.primary_color,
        });
      })
      .catch(() => {});
  }

  get3DCardPaymentConfirmation = (intentID) => {
    this.setState({ showLoader: true, isLoading: true });
    const MAX_ATTEMPTS = 10;
    if (this.attemptCount < MAX_ATTEMPTS) {
      this.attemptCount++;
      API.getCardPaymentConfirmation(intentID).then((res) => {
        if (res && res.status_code === 200 && res.is_updated) {
          if (res.status === "SUCCESS") {
            this.props.updateModalState(true, "SUCCESS", {
              message: "Payments has been made successfully",
            });
          } else {
            this.props.updateModalState(true, "ERROR", {
              message: "Payments has failed",
            });
          }
          setTimeout(() => {
            this.getPaymentPlansList();
            this.getInvoiceData();
          }, 2000);
        } else {
          setTimeout(() => {
            this.get3DCardPaymentConfirmation(intentID);
          }, 2000);
        }
      });
    } else {
      this.getPaymentPlansList();
      this.getInvoiceData();
    }
  };

  getMiscellaneousForConsumer = () => {
    API.getConsumerMiscellaneousInSettings(this.props.user).then((data) => {
      if (typeof data != "undefined" || data != null) {
        if (data.data) {
          if (data.data[0].data[0].is_enable) {
            this.setState({
              isAutoPopulateBalanceDue: data.data[0].data[0].is_enable,
            });
          }
        }
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            message: data && data.error ? data.error : "",
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  componentWillUnmount() {
    // window.removeEventListener("resize", this.updateDimensions);
    Emitter.off("Notification_Clicked");
  }
  getInvoiceData = () => {
    let { offset, selectedFilter } = this.state;

    let is_open = selectedFilter === "Open" ? true : false;
    API.fetchAllConsumerInvoices(this.props.user, offset, is_open).then(
      (data) => {
        if (
          (typeof data != "undefined" || data != null) &&
          data.data != null &&
          data.status_code == 200
        ) {
          Emitter.emit("HAS_OFFER", data.has_offers);
          let temp = data.data.invoices.map((item) => {
            item.checkForPay = false;
            return item;
          });
          this.setState((state) => ({
            ...state,
            consumerInvoice: data.data,
            invoiceData: temp,
            hasMoreData: data.data.invoices.length < 15 ? false : true,
            isLoading: false,
            leftLoader: false,
          }));
        } else {
          this.setState((state) => ({
            ...state,
            isLoading: false,
            leftLoader: false,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.status_code && data.status_code == 401 ? 401 : 500) ||
                  500
                : 500,
            },
          }));
        }
      }
    );
  };

  getPaymentPlansList = () => {
    let { offset, selectedFilter } = this.state;
    this.setState((state) => ({
      ...state,
      leftLoader: true,
    }));
    API.getConsumerPaymentPlans(this.props.user).then((data) => {
      if (
        (typeof data != "undefined" || data != null) &&
        data.status_code == 200
      ) {
        Emitter.emit("HAS_OFFER", data.has_offers);

        this.setState((state) => ({
          ...state,
          paymentPlanList: data.payment_plans,
          isLoading: false,
          leftLoader: false,
        }));
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          leftLoader: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  // getPaymentPlanList(id) {
  //   const accountId = id ? id : this.props.match.params.id;
  //   API.getPaymentPlanList(this.props.user, accountId).then((data) => {
  //     let isAccessDenied =
  //       data && data.status_code && data.status_code == 401 ? true : false;
  //     // let checkData = data.payment_plans.length > 0;
  //     // Emitter.emit("IS_QUICKBOOK_CONNECTED", data.qb_connected);
  //     if ((typeof data != "undefined" || data != null) && !isAccessDenied) {
  //       let tempData = [...data.payment_plans];
  //       if (tempData && tempData.length) {
  //         tempData.forEach((ele) => {
  //           ele.emailCheck = false;
  //         });
  //       }
  //       this.setState((state) => ({
  //         ...state,

  //         paymentPlanList: tempData,
  //         isTableLoading: false,
  //       }));
  //     } else {
  //       this.setState((state) => ({
  //         ...state,
  //         // isLoading: false,
  //         isTableLoading: false,
  //         error: {
  //           hasAnyError: true,
  //           statusCode: data
  //             ? data.status ||
  //               (data.status_code && data.status_code == 401 ? 401 : 500) ||
  //               500
  //             : 500,
  //         },
  //       }));
  //     }
  //   });
  // }

  consumerInvoicesPagination = (reload) => {
    let { offset, activeTab, isPayInvoices, selectedFilter } = this.state;
    let is_open = selectedFilter === "Open" ? true : false;
    // let is_open = activeTab === 1 ? true : false;
    let filterBy = isPayInvoices ? "non_payment_plan_invoices" : "";
    API.consumerInvoicesPagination(
      this.props.user,
      offset,
      is_open,
      filterBy
    ).then((data) => {
      if (
        (typeof data != "undefined" || data != null) &&
        data.data != null &&
        data.status_code == 200
      ) {
        let invoice = reload ? this.state.invoiceData : false;
        let newData = invoice ? invoice.concat(data.data) : data.data;
        this.setState((state) => ({
          ...state,
          invoiceData: newData,
          hasMoreData: data.data.length < 15 ? false : true,
          showLoader: false,
          leftLoader: false,
        }));
      } else {
        this.setState((state) => ({
          ...state,
          showLoader: false,
          leftLoader: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  handleContainerOnBottom = () => {
    if (this.state.hasMoreData) {
      let offsetCount = parseInt(this.state.offset) + 1;
      this.setState(
        (state) => ({
          ...state,
          offset: offsetCount,
          showLoader: true,
        }),
        () => {
          this.consumerInvoicesPagination(true);
        }
      );
    }
  };

  handleRowActionClick = (event) => {
    const rowId = event.rowData.id;
    let { history } = this.props;
    if (this.state.activeTab === 1) {
      if (rowId) {
        history.push(`/dashboard/accounts/invoices/${rowId}`);
      }
    } else {
      history.push(`/dashboard/accounts/payment-plans/${rowId}`);
    }
  };

  handleNeedsReviewClick = (id) => {
    let { history } = this.props;
    history.push(`/dashboard/accounts/payment-plans/${id}/review`);
  };

  handleInputChange(newPartialInput) {
    const re = /[0-9]+/g;
    if (
      (newPartialInput.search.value === "" ||
        re.test(newPartialInput.search.value)) &&
      newPartialInput.search.value !== this.state.input.search.value
    ) {
      this.setState(
        (state) => ({
          ...state,
          input: {
            ...newPartialInput,
          },
        }),
        () => {
          if (this.state.input.search.value === "") {
            this.getInvoiceData();
          } else {
            this.handleKeyPress({ key: "Enter" });
          }
        }
      );
    }
  }

  handleDollerClick = (row) => {
    let { history } = this.props;
    if (
      row.status === "active" ||
      row.status === "past_due" ||
      row.status === "on_time"
    ) {
      history.push(`/dashboard/accounts/pay-payment-plans/${row.id}`);
    } else {
      let message = "";
      if (row.status === "offer_sent") {
        message = "This payment plan is not accepted yet.";
      } else if (row.status === "closed") {
        message = "This payment plan is closed.";
      }
      this.props.updateModalState(true, "ERROR", {
        message: message,
      });
    }
  };
  renderLatestPaymentDate = (date) => {
    return moment.utc(date).format("MM/DD/YYYY");
  };
  getPaymentPlanListData = (data, theme) => {
    let headings = [],
      columns = [];

    headings = [
      {
        name: "Payment Plan #",
        style: {
          minWidth: "10%",
          display: "flex",
          justifyContent: "flex-start",
          // whiteSpace: "nowrap",
        },
      },
      {
        name: "Status",
        style: { minWidth: "10%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Plan Date",
        style: { minWidth: "10%", display: "flex", justifyContent: "center" },
      },
      {
        name: "# of Invoices",
        style: { minWidth: "10%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Plan Amount",
        style: { minWidth: "10%", display: "flex", justifyContent: "center" },
      },
      {
        name: "# of Months",
        style: { minWidth: "10%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Monthly Payment Amt",
        style: { minWidth: "10%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Current Balance",
        style: { minWidth: "10%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Review Status",
        style: { minWidth: "10%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Action",
        style: { minWidth: "5%", display: "flex", justifyContent: "center" },
      },
      {
        name: "expander",
        style: { minWidth: "5%", display: "flex", justifyContent: "center" },
      },
    ];

    columns = data.map((debt, index) => {
      return {
        emptyPrimary: true,
        secondaryColumns: [
          {
            key: "Payment Plan #",
            title: <>{debt.payment_plan_number}</>,
            style: {
              minWidth: "10%",
              display: "flex",
              justifyContent: "flex-start",
            },
          },
          {
            key: "Status",
            title: <>{displayPlanStatus(debt.status)}</>,
            style: {
              minWidth: "10%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "Plan Date",
            title: this.renderLatestPaymentDate(debt.created_at),
            style: {
              minWidth: "10%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "# of Invoices",
            title: <>{debt.get_payment_plan_invoices.length}</>,
            style: {
              minWidth: "10%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "Plan Amount",
            title: "$ " + debt.total_in_dollars_as_currency,
            style: {
              minWidth: "10%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "# of Months",
            title: <>{debt.number_of_payments}</>,
            style: {
              minWidth: "10%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "Monthly Payment Amt",
            title: "$ " + debt.monthly_payment_amount_as_currency,
            style: {
              minWidth: "10%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "Current Balance",
            title: "$ " + debt.balance_as_currency,
            style: {
              minWidth: "10%",
              display: "flex",
              justifyContent: "center",
              // marginRight: "1%",
            },
          },
          {
            key: "Review Status",
            title: (
              <button
                style={{
                  padding: "5px",
                  borderRadius: "7px",
                  backgroundColor: theme.primary_color,
                  color: "#ffffff",
                  fontFamily: "Open Sans",
                  fontSize: "12px",
                  fontWeight: 400,
                  width: "75px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (debt.status === "offer" || debt.status === "offer_sent") {
                    this.handleNeedsReviewClick(debt.id);
                  }
                }}
              >
                {debt.status === "offer" || debt.status === "offer_sent"
                  ? "Needs Review"
                  : "Plan Accepted"}{" "}
              </button>
            ),
            style: {
              minWidth: "10%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "Action",
            title: (
              <button
                title='Make Payment'
                style={{
                  background: "transparent",
                  cursor: "pointer",
                }}
                onClick={() => this.handleDollerClick(debt)}
              >
                <img src={dollorIcon} width='16' height='16' />
              </button>
            ),
            style: {
              minWidth: "5%",
              display: "flex",
              justifyContent: "center",
              // marginRight: "3%",
            },
          },
          {
            rowType: "arrow-click",
            optionalClasses: "expander",
          },
        ],
        status: null,
        // taskStatus:
        //   debt.debt_invoice.balance && debt.debt_invoice.balance <= 0
        //     ? true
        //     : false,
        rowData: debt,
      };
    });

    return {
      headings,
      columns,
    };
  };

  getPaymentStatus = (invoice) => {
    if (invoice === "Paid") {
      return <div style={StyleSheet.paymentStatusCss}>Paid</div>;
    }
    let is_past_due = this.checkInvoicePastDue(invoice.due_date);
    let statusStyle = is_past_due
      ? { color: "#fb5c82", fontFamily: "Open Sans", fontWeight: 600 }
      : { color: "#409D57", fontFamily: "Open Sans", fontWeight: 600 };
    if (
      parseFloat(invoice.balance).toFixed(2) ==
      (
        parseFloat(invoice.total_amt) +
        parseFloat(invoice.tax_amount) +
        parseFloat(invoice.slow_payer_amount)
      ).toFixed(2)
    ) {
      return <div style={statusStyle}>Unpaid</div>;
    } else {
      return <div style={statusStyle}>Partially Paid</div>;
    }
  };

  checkInvoicePastDue = (date) => {
    return moment(date).isBefore(new Date());
  };

  getPaidAmount = (invoice) => {
    let amount = 0;
    if (invoice) {
      let total_amount = parseFloat(invoice.total_amt);
      let tax_amount = parseFloat(invoice.tax_amount);
      let slow_payer = parseFloat(invoice.slow_payer_amount);
      let balance = parseFloat(invoice.balance);
      amount = invoice.total_amt
        ? invoice.balance
          ? (total_amount + tax_amount + slow_payer).toFixed(2) -
            balance.toFixed(2)
          : invoice.total_amt
        : invoice.total_amt;
    }
    return amount.toFixed(2);
  };
  onCheckboxChange = (e, item, index) => {
    let { invoiceData } = this.state;

    invoiceData[index].checkForPay = e.target.checked;
    this.setState({ invoiceData }, () => {
      let temp = invoiceData.filter((item) => item.checkForPay);
      this.setState({ isInvoiceSelected: temp.length > 0 ? true : false });
    });
  };

  handlePaymentPlanClick = (id) => {
    return (
      <Redirect
        exact
        from='/dashboard/accounts/invoices'
        to={`/dashboard/accounts/payment-plans/${id}`}
        push
      />
    );
  };

  getInvoiceListData = (data) => {
    let { selectedFilter } = this.state;
    let { history } = this.props;

    let headings = [],
      columns = [];
    headings = [
      {
        name: "Inv. #",
        style: {
          width: "13%",
          display: "flex",
          justifyContent: "flex-start",
          padding: 0,
        },
      },
      {
        name: "Inv. Date",
        style: { width: "13%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Due Date",
        style: { width: "13%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Inv. Total",
        style: {
          width: "13%",
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        name: "Amount Paid",
        style: { width: "13%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Discount",
        style: { width: "9%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Balance",
        style: {
          width: "13%",
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        name: "Status",
        style: { width: "10%", display: "flex", justifyContent: "center" },
      },
      {
        name: "expander",
        style: { width: 0, display: "flex", justifyContent: "center" },
      },
    ];

    columns = data.map((row) => {
      if (selectedFilter === "Close") {
        row.status = true;
      }
      return {
        emptyPrimary: true,
        secondaryColumns: [
          {
            key: "Inv. #",
            title: (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>{row.invoice_number ? row.invoice_number : ""}</div>
                {row.payment_plan_id && (
                  <img
                    src={DollerIcon}
                    width={"20px"}
                    height={"20px"}
                    title='In Payment Plan'
                    style={{ margin: "4px 6px", cursor: "pointer" }}
                    onClick={() =>
                      history.push(
                        `/dashboard/accounts/payment-plans/${row.payment_plan_id}`
                      )
                    }
                  />
                )}
              </div>
            ),
            style: {
              width: "13%",
              display: "flex",
              justifyContent: "flex-start",
            },
          },
          {
            key: "Inv. Date",
            title: row.txn_date
              ? moment(row.txn_date).format("MM/DD/YYYY")
              : "n/a",
            style: {
              width: "13%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "Due Date",
            title: row.due_date
              ? moment(row.due_date).format("MM/DD/YYYY")
              : "n/a",
            style: {
              width: "13%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "Inv. Total",
            title: row.total_amt
              ? "$" +
                (
                  parseFloat(row.total_amt) + parseFloat(row.tax_amount)
                ).toFixed(2)
              : "$0.00",
            style: {
              width: "13%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "Amount Paid",
            title: `$${this.getPaidAmount(row)}`,
            style: {
              width: "13%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "Discount",
            title: row.adjustment
              ? "$" + parseFloat(row.adjustment).toFixed(2)
              : "$0.00",
            style: {
              width: "9%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "Balance",
            title: row.balance
              ? "$" + parseFloat(row.balance).toFixed(2)
              : "$0.00",
            style: {
              width: "13%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "Status",
            title:
              this.state.selectedFilter === "Close"
                ? this.getPaymentStatus("Paid")
                : this.getPaymentStatus(row),
            style: {
              width: "10%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            rowType: "arrow-click",
            optionalClasses: "expander",
          },
        ],
        status: this.getAccountStatus(row),
        rowData: row,
      };
    });

    return {
      headings,
      columns,
    };
  };

  getInvoiceListWithCheckbox = (data) => {
    let headings = [],
      columns = [];
    headings = [
      {
        name: "Inv. #",
        style: {
          width: "13%",
          display: "flex",
          justifyContent: "start",
        },
      },
      {
        name: "Inv. Date",
        style: { width: "15%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Due Date",
        style: { width: "15%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Inv. Total",
        style: {
          width: "15%",
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        name: "Amount Paid",
        style: { width: "15%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Balance",
        style: {
          width: "12%",
          display: "flex",
          justifyContent: "center",
          marginRight: "1%",
        },
      },
      {
        name: "Status",
        style: { width: "14%", display: "flex", justifyContent: "center" },
      },
    ];

    columns = data.map((row, index) => {
      return {
        emptyPrimary: true,
        secondaryColumns: [
          {
            key: "Inv. #",
            title: (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className='email-value-wrapper'>
                  <input
                    className='email-checkbox'
                    type='checkbox'
                    id={`tax00${index}`}
                    name={"tax"}
                    value={"tax"}
                    style={{ marginLeft: "2px" }}
                    checked={row.checkForPay}
                    onChange={(e) => this.onCheckboxChange(e, row, index)}
                  />
                </div>
                {/* <div style={{ display: "flex", alignItems: "center" }}> */}
                {row.invoice_number ? row.invoice_number : ""}
                {/* </div> */}
              </div>
            ),
            style: {
              width: "13%",
              display: "flex",
              justifyContent: "flex-start",
            },
          },
          {
            key: "Inv. Date",
            title: row.txn_date
              ? moment(row.txn_date).format("MM/DD/YYYY")
              : "n/a",
            style: {
              width: "15%",
              display: "flex",
              justifyContent: "center",
            },
          },

          {
            key: "Due Date",
            title: row.due_date
              ? moment(row.due_date).format("MM/DD/YYYY")
              : "n/a",
            style: {
              width: "15%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "Inv. Total",
            title: row.total_amt
              ? "$" +
                (
                  parseFloat(row.total_amt) + parseFloat(row.tax_amount)
                ).toFixed(2)
              : "$0.00",
            style: {
              width: "15%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "Amount Paid",
            title: `$${this.getPaidAmount(row)}`,
            style: {
              width: "14%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "Balance",
            title: row.balance
              ? "$" + parseFloat(row.balance).toFixed(2)
              : "$0.00",
            style: {
              width: "13%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "Status",
            title: this.getPaymentStatus(row),
            style: {
              width: "14%",
              display: "flex",
              justifyContent: "flex-end",
            },
          },
        ],
        status: this.getAccountStatus(row),
        rowData: row,
      };
    });

    return {
      headings,
      columns,
    };
  };
  getInvoiceListWithCheckboxForCardview = (data) => {
    let headings = [],
      columns = [];
    headings = [
      "lex",
      "Inv. #",
      "Inv. Date",
      "Due Date",
      "Inv. Total",
      "Amount Paid",
      "Balance",
      "Status",
    ];

    columns = data.map((row, index) => {
      return {
        emptyPrimary: true,
        secondaryColumns: [
          {
            key: "lex",
            title: (
              <div className='checkbox-mobile-view'>
                <input
                  className='email-checkbox'
                  type='checkbox'
                  id={`tax00${index}`}
                  name={"tax"}
                  value={"tax"}
                  checked={row.checkForPay}
                  onChange={(e) => this.onCheckboxChange(e, row, index)}
                />
              </div>
            ),
          },
          {
            key: "Inv. #",
            title: row.invoice_number ? row.invoice_number : "",
          },
          {
            key: "Inv. Date",
            title: row.txn_date
              ? moment(row.txn_date).format("MM/DD/YYYY")
              : "n/a",
          },
          {
            key: "Due Date",
            title: row.due_date
              ? moment(row.due_date).format("MM/DD/YYYY")
              : "n/a",
          },
          {
            key: "Inv. Total",
            title: row.total_amt
              ? "$" +
                (
                  parseFloat(row.total_amt) + parseFloat(row.tax_amount)
                ).toFixed(2)
              : "$0.00",
          },
          {
            key: "Amount Paid",
            title: `$${this.getPaidAmount(row)}`,
          },
          {
            key: "Balance",
            title: row.balance
              ? "$" + parseFloat(row.balance).toFixed(2)
              : "$0.00",
          },
          {
            key: "Status",
            title: this.getPaymentStatus(row),
          },
        ],
        status: this.getAccountStatus(row),
        rowData: row,
      };
    });

    return {
      headings,
      columns,
    };
  };

  renderInvoiceTable = (appThemeData) => {
    const {
      activeTab,
      invoiceData,
      paymentPlanList,
      isLoading,
      showLoader,
      selectedFilter,
      leftLoader,
      isPayInvoices,
    } = this.state;
    if (leftLoader) {
      return (
        <div className='mobile-loader'>
          <AppCanvasLoader />
        </div>
      );
    }
    if (invoiceData && invoiceData.length > 0) {
      return (
        <BottomScrollListener
          onBottom={this.handleContainerOnBottom}
          debounce={0}
          offset={100}
        >
          {(scrollRef) => (
            <div
              ref={scrollRef}
              className='consumer-scroll-accounts-list scrollbar margin-bottom'
            >
              <div className='big-device-grad invoice-line'>
                <CardView
                  data={
                    isPayInvoices
                      ? this.getInvoiceListWithCheckboxForCardview(invoiceData)
                      : this.getInvoiceListData(invoiceData)
                    // : this.getPaymentPlanListData(
                    //     paymentPlanList,
                    //     appThemeData
                    //   )
                  }
                  isStatus={activeTab === 1 ? true : false}
                  rowActionClick={(event) => this.handleRowActionClick(event)}
                />
              </div>
              <InvoiceObjectList
                appThemeData={appThemeData}
                data={
                  isPayInvoices
                    ? this.getInvoiceListWithCheckbox(invoiceData)
                    : this.getInvoiceListData(invoiceData)
                  // : this.getPaymentPlanListData(paymentPlanList, appThemeData)
                }
                rowActionClick={(event) => this.handleRowActionClick(event)}
                isStatus={activeTab === 1 ? true : false}
                isClose={selectedFilter === "Open" ? false : true}
                hideForMobile={true}
                isPaymentPlansData={activeTab === 2 ? true : false}
                optionalClasses='in-sc'
                fromConsumer={activeTab === 2 || isPayInvoices ? false : true}
                portal={"consumer"}
                isPayInvoices={isPayInvoices}
              />
              {this.state.showLoader && (
                <div>
                  <AppCanvasLoader />
                </div>
              )}
            </div>
          )}
        </BottomScrollListener>
      );
    } else if (this.state.input.search.value.length) {
      return (
        <div className='admin-admins-empty-state'>
          <p>No results found for:</p>
          <h3 className='admin-admins-empty-state-heading empty-mesg-mg'>
            {this.state.input.search.value}
          </h3>
        </div>
      );
    } else {
      if (!isLoading && !showLoader && !leftLoader) {
        return (
          <div
            className='consumer-accounts-empty-state'
            style={StyleSheet.invoiceStatusCss}
          >
            <h3 className='consumer-accounts-empty-state-heading consumer-brand'>
              {"No Invoices"}
            </h3>
            <p>{`You currently have no invoices.`}</p>
          </div>
        );
      }
    }
  };

  renderPaymentPlanTable = (data, appThemeData) => {
    const {
      activeTab,
      paymentPlanList,
      selectedFilter,
      leftLoader,
      isPayInvoices,
      isLoading,
      showLoader,
    } = this.state;
    if (leftLoader) {
      return (
        <div className='mobile-loader'>
          <AppCanvasLoader />
        </div>
      );
    }
    if (paymentPlanList && paymentPlanList.length > 0) {
      return (
        <BottomScrollListener
          onBottom={this.handleContainerOnBottom}
          debounce={0}
          offset={100}
        >
          {(scrollRef) => (
            <div
              ref={scrollRef}
              className='consumer-scroll-accounts-list scrollbar margin-bottom'
            >
              <div className='big-device-grad invoice-line'>
                <CardView
                  data={data}
                  isStatus={activeTab === 1 ? true : false}
                  rowActionClick={(event) => this.handleRowActionClick(event)}
                />
              </div>
              <InvoiceObjectList
                appThemeData={appThemeData}
                data={data}
                rowActionClick={(event) => this.handleRowActionClick(event)}
                isStatus={activeTab === 1 ? true : false}
                isClose={selectedFilter === "Open" ? false : true}
                hideForMobile={true}
                isPaymentPlansData={activeTab === 2 ? true : false}
                optionalClasses='in-sc'
                fromConsumer={activeTab === 2 || isPayInvoices ? false : true}
                portal={"consumer"}
                isPayInvoices={isPayInvoices}
              />
              {this.state.showLoader && (
                <div>
                  <AppCanvasLoader />
                </div>
              )}
            </div>
          )}
        </BottomScrollListener>
      );
    } else {
      if (!isLoading && !showLoader && !leftLoader) {
        return (
          <div
            className='consumer-accounts-empty-state'
            style={StyleSheet.invoiceStatusCss}
          >
            <h3 className='consumer-accounts-empty-state-heading consumer-brand'>
              {"No Payment Plans"}
            </h3>
            <p>{`You currently have no payment plans.`}</p>
          </div>
        );
      }
    }
  };

  getAccountStatus = (rowData) => {
    let flag = this.checkInvoicePastDue(rowData.due_date);
    if (flag) {
      if (this.state.accountType != "past due") {
        this.setState({ accountType: "past due" });
      }
    }
    // let adjustment = rowData.adjustment ? rowData.adjustment : 0;
    // if (
    //   (rowData.balance > 0 && adjustment == 0) ||
    //   (adjustment > 0 && rowData.balance != adjustment)
    // ) {
    //   flag = true;
    // } else if (
    //   (rowData.balance == 0 && adjustment == 0) ||
    //   (adjustment > 0 && adjustment == rowData.balance)
    // ) {
    //   flag = false;
    //   this.setState({ accountType: "past due" });
    // }
    return !flag;
  };

  sidebarContent = () => {
    const { consumerInvoice, accountType } = this.state;

    return (
      <div className='consumer-sidebar'>
        <div
          className='sidebar-primary-text consumer-account-type'
          style={StyleSheet.head1}
        >
          Your account is {accountType}
        </div>

        <div className='consumer-sidebar-panel-content'>
          <div className='consumer-amount-details'>Account Balance</div>
          <div className='consumer-amount-details'>
            $
            {consumerInvoice && consumerInvoice.account_balance
              ? parseFloat(consumerInvoice.account_balance).toFixed(2)
              : "0.00"}
          </div>
        </div>
        <div className='consumer-sidebar-panel-content'>
          <div className='consumer-amount-details'>Account Past Due</div>
          <div className='consumer-amount-details'>
            $
            {consumerInvoice && consumerInvoice.account_past_due
              ? parseFloat(consumerInvoice.account_past_due).toFixed(2)
              : "0.00"}
          </div>
        </div>
        <div className='consumer-sidebar-panel-content'>
          <div className='consumer-amount-details'>No. of Open Invoices</div>
          <div className='consumer-amount-details'>
            {consumerInvoice && consumerInvoice.open_invoices
              ? consumerInvoice.open_invoices
              : 0}
          </div>
        </div>
      </div>
    );
  };

  getSubTitle = (userData) => {
    let fullname = "";
    if (userData.first_name) {
      fullname = userData.first_name;
    }
    if (userData.last_name && fullname) {
      fullname = fullname + " " + userData.last_name;
    } else {
      fullname = userData.last_name;
    }
    if (fullname !== null) {
      return "Welcome, " + fullname + "!";
    } else {
      return "Welcome, ";
    }
  };

  handleTabChange = (tabIndex) => {
    let { activeTab } = this.state;
    let { history } = this.props;
    if (activeTab != tabIndex) {
      let input = {
        search: {
          value: "",
        },
      };
      this.setState(
        {
          activeTab: tabIndex,
          offset: 0,
          leftLoader: true,
          input: input,
          isPayInvoices: false,
        },
        () => {
          if (tabIndex == 1) {
            history.push(`/dashboard/accounts/invoices`);
            this.getInvoiceData();
          } else {
            history.push(`/dashboard/accounts/payment-plans`);
            this.getPaymentPlansList();
          }
        }
      );
    }
  };
  handleMoreOptionClick = (tabIndex) => {
    let input = {
      search: {
        value: "",
      },
    };
    this.setState(
      {
        activeTab: tabIndex,
        offset: 0,
        leftLoader: true,
        selectedFilter: "Open",
        isInvoiceSelected: false,
        input: input,
        isPayInvoices: tabIndex == 1 ? !this.state.isPayInvoices : false,
      },
      () => {
        if (tabIndex == 1) {
          this.consumerInvoicesPagination(false);
          // this.getInvoiceData();
        } else {
          this.getPaymentPlansList();
          this.setState({ leftLoader: false });
        }
      }
    );
  };
  handleFilter = (filter) => {
    this.setState(
      { selectedFilter: filter, leftLoader: true, offset: 0 },
      () => {
        this.getInvoiceData();
      }
    );
    this.setState({
      closeDropDown: true,
    });
    setTimeout(() => {
      this.setState({ closeDropDown: false });
    }, 200);
  };
  setClose = () => {
    this.setState({ closeDropDown: false });
  };

  getSectionHeaderUtilities(appThemeData) {
    const { activeTab, closeDropDown, isPayInvoices } = this.state;
    const tabLabels = ["Invoices", "Payment Plans"];
    return (
      <div className='consumer-account-header-section-tabs'>
        <Tabs
          labels={tabLabels}
          activeTarget={activeTab}
          handleTabChange={this.handleTabChange}
          appThemeData={appThemeData}
          optionalClasses='consumer-pay-tab tp-sp con-fit'
          optionalInTabClasses='mg-consumer-tabs-tab-common'
        />
      </div>
    );
  }
  handleSuccessInvoicePayment = (data) => {
    this.handleFilter("Open");
  };
  handleMakePayment = () => {
    let { invoiceData, isAutoPopulateBalanceDue } = this.state;
    let { history } = this.props;
    let temp = invoiceData.filter((item) => item.checkForPay);

    const totalEarlyPayerDiscount = temp.reduce((accumulator, invoice) => {
      return accumulator + (invoice.early_payer_discount || 0);
    }, 0);
    let total = 0;
    temp.map((item) => {
      total = total + parseFloat(item.balance);
    });

    history.push({
      pathname: `/dashboard/accounts/invoices/pay-invoices`,
      state: {
        totalAmount: total,
        isAutoPopulateBalanceDue: isAutoPopulateBalanceDue,
        earlyPayerDiscount: totalEarlyPayerDiscount,
        // dueDate: temp[0].due_date,
        invoiceList: temp,
      },
    });
    // let props = {
    //   fromConsumer: true,
    //   totalBalance: total,
    //   handleSuccess: this.handleSuccessInvoicePayment,
    //   invoiceList: temp,
    //   appThemeData: appThemeData,
    // };
    // this.props.updateModalState(true, "MAKE_PAYMENT", props);
  };

  renderMoreOptions = (appThemeData) => {
    let { isInvoiceSelected, isPayInvoices, closeDropDown, activeTab } =
      this.state;
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className='header-section-right-button'>
          {isPayInvoices && (
            <button
              className='consumer-mg-left'
              onClick={(e) => isInvoiceSelected && this.handleMakePayment(e)}
              style={{
                opacity: isInvoiceSelected ? "1" : "0.7",
                cursor: "pointer",
                backgroundColor: appThemeData.primary_color,
              }}
            >
              Make Payment
            </button>
          )}
          <div className='right-section mg-mt-10'>
            <DropdownButton
              title='+ More Options'
              primaryLink='/dashboard/accounts/invoices'
              overrideStyle={true}
              parentOptClasses={"account-re-down-mob mg-mt-10"}
              buttonInnerClasses={"down-inner"}
              forFilter={true}
              clientMobile={true}
              totalFilter={true}
              appThemeData={appThemeData}
              onPrimaryClick={(event) => {}}
            >
              {this.state.isPayInvoices ? (
                <MenuItem
                  handleClick={() => {
                    this.handleMoreOptionClick(1);
                  }}
                >
                  Back to Invoices List
                </MenuItem>
              ) : (
                <MenuItem
                  handleClick={() => {
                    this.handleMoreOptionClick(1);
                  }}
                >
                  Pay Invoice(s)
                </MenuItem>
              )}
              <MenuItem
                handleClick={() => {
                  this.handleMoreOptionClick(2);
                }}
              >
                Pay Payment Plan(s)
              </MenuItem>
            </DropdownButton>
          </div>
        </div>
        {activeTab === 1 && !isPayInvoices && (
          <div className='consumer-filter-div-2'>
            <DropdownButton
              title={`Filter By: `}
              historyFilter={true}
              appThemeData={appThemeData}
              style={{ minWidth: 180, marginTop: 0 }}
              primaryLink='/client/accounts'
              clientMobile={true}
              closeDropDown={closeDropDown}
              setClose={this.setClose}
            >
              <MenuItem
                handleClick={(event) => this.handleFilter("Open")}
                optionalClasses={
                  this.state.selectedFilter === "Open" ? " filter-select" : " "
                }
              >
                Open
              </MenuItem>
              <MenuItem
                handleClick={(event) => this.handleFilter("Close")}
                optionalClasses={
                  this.state.selectedFilter === "Close" ? " filter-select" : " "
                }
              >
                Closed
              </MenuItem>
            </DropdownButton>
          </div>
        )}
      </div>
    );
  };

  render() {
    const { appName, appThemeData } = this.props;
    const {
      isLoading,
      error,
      textColor,
      closeDropDown,
      activeTab,
      isPayInvoices,
    } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    if (isLoading) {
      return <AppCanvasLoader />;
    } else {
      return (
        <div className='scrollbar consumer-scroll-accounts'>
          <Helmet>
            <title>{appName} | Payment Portal</title>
          </Helmet>
          <div className='app-sidebar-layout-canvas'>
            <ConsumerSidebar
              primary_color={this.props.appThemeData.primary_color}
              user={this.props.user}
              subTitle={this.getSubTitle(this.props.user)}
              sidebarContent={this.sidebarContent()}
            />
            <div className='app-sidebar-layout-canvas-content consumer-right-canvas big-device-grad'>
              <ClientsLogo />
              <h1
                className='app-section-consumer-heading'
                style={{ color: `${textColor}` }}
              >
                Payment Portal
              </h1>
              <div
                className='header-section-flex'
                style={{ margin: "20px 0px" }}
              >
                <div className='flex-utilities'>
                  <div className='additional-margin'>
                    {this.getSectionHeaderUtilities(appThemeData)}
                    <div>
                      {activeTab === 1 && !isPayInvoices && (
                        <div className='consumer-filter-div'>
                          <DropdownButton
                            title={`Filter By: `}
                            historyFilter={true}
                            appThemeData={appThemeData}
                            style={{ minWidth: 180, marginTop: 0 }}
                            primaryLink='/client/accounts'
                            clientMobile={true}
                            closeDropDown={closeDropDown}
                            setClose={this.setClose}
                          >
                            <MenuItem
                              handleClick={(event) => this.handleFilter("Open")}
                              optionalClasses={
                                this.state.selectedFilter === "Open"
                                  ? " filter-select"
                                  : " "
                              }
                            >
                              Open
                            </MenuItem>
                            <MenuItem
                              handleClick={(event) =>
                                this.handleFilter("Close")
                              }
                              optionalClasses={
                                this.state.selectedFilter === "Close"
                                  ? " filter-select"
                                  : " "
                              }
                            >
                              Closed
                            </MenuItem>
                          </DropdownButton>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {this.renderMoreOptions(appThemeData)}
              </div>
              {isPayInvoices && (
                <div style={{ marginLeft: "0.5em", fontWeight: 500 }}>
                  {"NOTE: These invoices are not part of any payment plan."}
                </div>
              )}
              <div className='header-section-flex'></div>
              {activeTab === 1
                ? this.renderInvoiceTable(appThemeData)
                : this.renderPaymentPlanTable(
                    this.getPaymentPlanListData(
                      this.state.paymentPlanList,
                      appThemeData
                    ),
                    appThemeData
                  )}
              <br />
            </div>
          </div>
        </div>
      );
    }
  }
}

export default ConsumerAccounts;

let StyleSheet = {
  head1: { marginBottom: "2em", display: "flex", color: "#FFFFFF" },
  paymentStatusCss: {
    color: "#409D57",
    fontFamily: "Open Sans",
    fontWeight: 600,
  },
  invoiceStatusCss: { height: "auto", padding: "6rem 2rem" },
};
