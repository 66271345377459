import * as API from "../../../utils/api";
import { Redirect } from "react-router-dom";
import InfoIcon from "../../../assets/icons/info_teal.png";
import AppCanvasLoader from "../../../components/App/AppCanvasLoader";
import { showToast } from "../../../utils/helpers";
import Cookies from "universal-cookie";
import { getBaseUrl } from "../../../utils/api";

import React, { useState, useEffect } from "react";
import { useCallback } from "react";

const style = {
  pText: {
    fontSize: "14px",
    color: "#2b2828",
    margin: "0px",
  },
};
const CustomizeWorkFlowStep7 = (props) => {
  const [prefData, setPrefData] = useState("");
  const [percentage, setPercentage] = useState(0);
  const [organizationInformation, setOrganizationInformation] = useState({
    checkSendemail: true,
    isSendLetter: false,
    sendEnvelopes: false,
    useAccountNo: false,
    includeSupportingDoc: false,
    sendStatement: false,
    includeInternalContact: false,
    autoPopulateBalanceDue: false,
  });
  const [error, setError] = useState({
    hasAnyError: false,
    statusCode: 200,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [organizationDetails, setOrganizationDetails] = useState("");

  const checkboxConfigs2 = [
    {
      heading: "Supporting Documents",
      info: "Typically, this will be an additional document with detailed information about the invoice being sent.",
      isDisabled: false,
      content: "Do you include supporting documents with your invoices?",
      isChecked: organizationInformation.includeSupportingDoc,
      inputName: "use_SupportingDoc",
      onChange: (event) => isCheckEnabled(event, "use_SupportingDoc"),
    },
    {
      heading: "Statements",
      info: "",
      isDisabled: false,
      content: "Do you want to send statements monthly to your customers?",
      isChecked: organizationInformation.sendStatement,
      inputName: "Send_Statement",
      onChange: (event) => isCheckEnabled(event, "Send_Statement"),
    },
    {
      heading: "Internal Contacts",
      info: "",
      isDisabled: false,
      content:
        "Do you want to include internal contacts on some or all of your automated email communications to your customers?",
      isChecked: organizationInformation.includeInternalContact,
      inputName: "internal_contact",
      onChange: (event) => isCheckEnabled(event, "internal_contact"),
    },
  ];
  const checkboxConfigs1 = [
    {
      heading: "Emails",
      info: "You will be able to turn off communications after account setup is complete.",
      isDisabled: true,
      content:
        "Send communications via email (you’ll want this so we’ll turn it on by default)",
      isChecked: organizationInformation.checkSendemail,
      inputName: "email",
      onChange: (event) => isCheckEnabled(event, "email"),
    },
    {
      heading: "Letters",
      info: "",
      isDisabled: false,
      content:
        "Send your choice of Invoices, Statements, and past due notifications via standard mail delivery automatically.",
      isChecked: organizationInformation.isSendLetter,
      inputName: "is_Letter",
      onChange: (event) => isCheckEnabled(event, "is_Letter"),
    },
    {
      heading: "Envelopes",
      info: "",
      isDisabled: !organizationInformation.isSendLetter,
      content: "Do you want to send return envelopes with your invoices?",
      isChecked: organizationInformation.sendEnvelopes,
      inputName: "send_Envelopes",
      onChange: (event) => isCheckEnabled(event, "send_Envelopes"),
    },
    {
      heading: "Account Numbers",
      info: "",
      isDisabled: false,
      content:
        "Do you use Account Numbers to identify your customers on your invoices and statements?",
      isChecked: organizationInformation.useAccountNo,
      inputName: "use_AccountNo",
      onChange: (event) => isCheckEnabled(event, "use_AccountNo"),
    },
    {
      heading: "Auto Populate Balance Due",
      info: "",
      isDisabled: false,
      content: "Do you want to auto populate due balance to your customers?",
      isChecked: organizationInformation.autoPopulateBalanceDue,
      inputName: "balance_due",
      onChange: (event) => isCheckEnabled(event, "balance_due"),
    },
  ];

  const getOrganizationalDetails = () => {
    const cookies = new Cookies();
    let authuser = cookies.get("accessToken", { path: "/", secure: true });
    let user = {
      user_auth_token: authuser,
    };
    API.getOrgDetails(user).then((data) => {
      if ((typeof data != "undefined" || data != null) && data.data) {
        let percent = data.data.organization.onboarding_percentage;
        setOrganizationDetails(data.data);
        // checkOrgCommonMethods(data.data);
      } else {
        setError((prevError) => ({
          ...prevError,
          hasAnyError: true,
          statusCode:
            data.status ||
            (data.status_code && data.status_code === 401 ? 401 : 500) ||
            500,
        }));
        setIsLoading(false);
      }
    });
  };

  const getMiscellaneousSettingStatus = () => {
    API.getMiscellaneousInSettings(props.user)
      .then((data) => {
        if (typeof data != "undefined" || data != null) {
          if (data.data) {
            setPrefData(data.data);
            setMiscellaneousData(data.data);
          }
        } else {
          setError((prevError) => ({
            ...prevError,
            hasAnyError: true,
            statusCode:
              data.status ||
              (data.status_code && data.status_code === 401 ? 401 : 500) ||
              500,
          }));
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  // const checkOrgCommonMethods = (res) => {
  //   let updatedOrganizationInformation = { ...organizationInformation };
  //   if (res.org_commem_methods[0].name === "Email") {
  //     updatedOrganizationInformation.checkSendemail = true;
  //     updatedOrganizationInformation.isSendLetter = false;
  //     if (
  //       res.org_commem_methods[1] &&
  //       res.org_commem_methods[1].name === "Letter"
  //     ) {
  //       updatedOrganizationInformation.isSendLetter = true;
  //       updatedOrganizationInformation.checkSendemail = true;
  //     }
  //   } else if (
  //     res.org_commem_methods[0].name === "Letter" ||
  //     (res.org_commem_methods[1] && res.org_commem_methods[1].name === "Letter")
  //   ) {
  //     updatedOrganizationInformation.isSendLetter = true;
  //     updatedOrganizationInformation.checkSendemail = false;
  //   }

  //   setOrganizationInformation({
  //     ...organizationInformation,
  //     checkSendemail: updatedOrganizationInformation.checkSendemail,
  //     isSendLetter: updatedOrganizationInformation.isSendLetter,
  //   });
  // };

  const setMiscellaneousData = (prefData) => {
    let updatedOrganizationInfo = { ...organizationInformation };

    prefData.forEach((item) => {
      item.data.forEach((value) => {
        if (value.name === "Hide Account Number from Invoices") {
          if (value.is_enable) {
            updatedOrganizationInfo.useAccountNo = true;
          } else {
            updatedOrganizationInfo.useAccountNo = false;
          }
        }

        if (
          value.name ===
          "Use Perforated Edges and Return Envelopes for Invoices"
        ) {
          if (value.is_enable) {
            updatedOrganizationInfo.isSendLetter = true;
          } else {
            updatedOrganizationInfo.isSendLetter = false;
          }
        }

        if (value.name === "Include Supporting Documents with Invoices") {
          if (value.is_enable) {
            updatedOrganizationInfo.includeSupportingDoc = true;
          } else {
            updatedOrganizationInfo.includeSupportingDoc = false;
          }
        }

        if (value.name === "Send Monthly Statements To Customers") {
          if (value.is_enable) {
            updatedOrganizationInfo.sendStatement = true;
          } else {
            updatedOrganizationInfo.sendStatement = false;
          }
        }

        if (
          value.name ===
          "Include Internal Contacts to Automated email communications"
        ) {
          if (value.is_enable) {
            updatedOrganizationInfo.includeInternalContact = true;
          } else {
            updatedOrganizationInfo.includeInternalContact = false;
          }
        }

        if (value.name === "Auto Populate Balance Due") {
          if (value.is_enable) {
            updatedOrganizationInfo.autoPopulateBalanceDue = true;
          } else {
            updatedOrganizationInfo.autoPopulateBalanceDue = false;
          }
        }
      });
    });

    setOrganizationInformation(updatedOrganizationInfo);
  };

  const updateProfile = () => {
    const payload = {
      org_url: getBaseUrl(),
      is_email: organizationInformation.checkSendemail,
      is_letter: organizationInformation.isSendLetter,
    };

    API.update_profile(getBaseUrl(), payload)
      .then((data) => {
        if (data && data.status_code === 200) {
          props.updateModalState(true, "SUCCESS", {
            message: "Communication preferences updated successfully.",
          });

          if (percentage >= 44) {
            API.updateOnboardingPercentage(props.user, 44.44).then((res) => {
              if (res.status_code === 200) {
                props.handleNext("step9");
              }
            });
          } else {
            props.handleNext("step9");
          }
        } else {
          props.updateModalState(true, "ERROR", {
            message: "Something went wrong.",
          });
        }
      })
      .catch((error) => {
        console.error("Error updating profile:", error);
        props.updateModalState(true, "ERROR", {
          message: "Something went wrong.",
        });
      });
  };

  const updatePaymentPreferences = (res, newData) => {
    let payload;
    if (res === "balance_due") {
      payload = {
        id: prefData[0].data[0].id,
        is_enable: newData.autoPopulateBalanceDue,
      };
    } else if (res === "use_AccountNo") {
      payload = {
        id: prefData[1].data[0].id,
        is_enable: newData
          ? newData.useAccountNo
          : organizationInformation.useAccountNo,
      };
    } else if (res === "internal_contact") {
      payload = {
        id: prefData[2].data[0].id,
        is_enable: newData.includeInternalContact,
      };
    } else if (res === "send_Envelopes") {
      payload = {
        id: prefData[3].data[0].id,
        is_enable: newData.sendEnvelopes,
      };
    } else if (res === "Send_Statement") {
      payload = {
        id: prefData[4].data[0].id,
        is_enable: newData.sendStatement,
      };
    } else if (res === "use_SupportingDoc") {
      payload = {
        id: prefData[5].data[0].id,
        is_enable: newData.includeSupportingDoc,
      };
    } else if (res === "is_Letter") {
      payload = {
        id: prefData[3].data[0].id,
        is_enable: newData
          ? newData.isSendLetter
          : organizationInformation.isSendLetter,
      };
    }
    API.clientUpdateMiscellaneousInSettings(props.user, payload).then(
      (data) => {
        if (typeof data != "undefined" || data != null) {
          if (data) {
            getMiscellaneousSettingStatus();
          }
        } else {
          setError((prevError) => ({
            ...prevError,
            hasAnyError: true,
            statusCode:
              data.status ||
              (data.status_code && data.status_code === 401 ? 401 : 500) ||
              500,
          }));
          setIsLoading(false);
        }
      }
    );
  };

  const isCheckEnabled = (e, res) => {
    const name = e.target.name;
    const newState = { ...organizationInformation };
    switch (name) {
      case "email":
        newState.checkSendemail = !organizationInformation.checkSendemail;
        break;
      case "is_Letter":
        newState.isSendLetter = !organizationInformation.isSendLetter;
        break;
      case "send_Envelopes":
        newState.sendEnvelopes = !organizationInformation.sendEnvelopes;
        break;
      case "use_AccountNo":
        newState.useAccountNo = !organizationInformation.useAccountNo;
        break;
      case "use_SupportingDoc":
        newState.includeSupportingDoc =
          !organizationInformation.includeSupportingDoc;
        break;
      case "Send_Statement":
        newState.sendStatement = !organizationInformation.sendStatement;
        break;
      case "internal_contact":
        newState.includeInternalContact =
          !organizationInformation.includeInternalContact;
        break;
      case "balance_due":
        newState.autoPopulateBalanceDue =
          !organizationInformation.autoPopulateBalanceDue;
        break;
      default:
        console.log(`Unhandled event target name: ${name}`);
        break;
    }

    setOrganizationInformation(newState);
    updatePaymentPreferences(name, newState);
  };

  useEffect(() => {
    getOrganizationalDetails();
    getMiscellaneousSettingStatus();
  }, []);

  return (
    <>
      {isLoading ? (
        <div className='loader_alignment'>
          <div style={{ marginTop: "20%" }}>
            <AppCanvasLoader />
          </div>
        </div>
      ) : (
        <div className='app-sidebar-layout-canvas-content setting-side-canvas col1-scroll scrollbar md-marginLeft'>
          <div className='main-communication-preferences'>
            <h1
              style={{
                fontSize: "30px",
                fontFamily: "Open Sans ,sans-serif",
                fontWeight: "bold",
                marginLeft: "20px",
                marginTop: "20px",
                marginBottom: "-8px",
              }}
            >
              Choose Your Communication Preferences
            </h1>
            <h4
              style={{
                width: "90%",
                marginTop: "35px",
                marginLeft: "20px",
                fontSize: "16px",
                fontFamily: "Open Sans ,sans-serif",
              }}
            >
              Select your methods of communication and define what you want
              included in your invoice.
            </h4>
            <div className='subdiv-communication-preferences'>
              <div className='subdiv-body1'>
                {checkboxConfigs1.map((config, index) => (
                  <CommunicationTab
                    key={index}
                    heading={config.heading}
                    disabled={config.isDisabled}
                    info={config.info}
                    content={config.content}
                    isChecked={config.isChecked}
                    inputName={config.inputName}
                    onChange={config.onChange}
                  />
                ))}
              </div>
              <div className='subdiv-body2'>
                {checkboxConfigs2.map((config, index) => (
                  <CommunicationTab
                    key={index}
                    heading={config.heading}
                    disabled={config.isDisabled}
                    info={config.info}
                    content={config.content}
                    isChecked={config.isChecked}
                    inputName={config.inputName}
                    onChange={config.onChange}
                  />
                ))}
              </div>
            </div>
            <div
              className='bottum-btns'
              style={{ padding: "20px", paddingBottom: "50px" }}
            >
              <div className='button-left'>
                <input
                  className=''
                  type='submit'
                  value='Go Back'
                  id='communication_preferences_go_back'
                  style={{
                    borderRadius: "0.5em",
                    width: "200px",
                    height: "50px",
                    marginTop: "20px",
                    border: "2px solid",
                    fontFamily: "Open Sans",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                  onClick={() => props.handleBack("step7")}
                />
                <span className='go-back-text'>
                  <p style={style.pText}>Go back to</p>
                  <p style={style.pText}>
                    "Brand Your Invoicing & Communications"
                  </p>
                </span>
              </div>
              <div className='button-right'>
                <input
                  type='submit'
                  id='communication_preferences_next'
                  value={"Next"}
                  className='cta mg-brand2-color'
                  style={{
                    width: "200px",
                    height: "50px",
                    borderRadius: "0.5em",
                    marginTop: "20px",
                    float: "right",
                    padding: "0px",
                    fontFamily: "Open Sans",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                  onClick={() => {
                    updateProfile();
                    updatePaymentPreferences("use_AccountNo");
                  }}
                />
                <span className='next-text'>
                  <p style={style.pText}>Continue to</p>
                  <p style={style.pText}>"Schedule Your Communications"</p>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const CommunicationTab = (props) => {
  const { heading, info, disabled, content, isChecked, inputName, onChange } =
    props;
  return (
    <div>
      {heading !== "Envelopes" && (
        <div className='preferences-subheading' style={{ width: "100%" }}>
          {heading}
          {info && <img id='Info-icon-step6' src={InfoIcon} title={info} />}
        </div>
      )}

      <div className='communications-via-email'>
        <p
          style={{
            width: "300px",
            color: "black",
            fontFamily: "unset",
            marginRight: "10px",
          }}
        >
          {content}
        </p>
        <input
          id='communication_preferences_radio_1'
          style={{ marginTop: "3px", width: "15px" }}
          className='radio-btn'
          type='radio'
          checked={isChecked}
          disabled={heading === "Emails" ? false : disabled}
          name={inputName}
          onChange={(event) => onChange(event, inputName)}
        />
        <span style={{ marginTop: "11px", paddingLeft: "10px" }}>Yes</span>
        <input
          style={{
            marginTop: "3px",
            marginLeft: "10px",
            pointerEvents: heading === "Emails" ? "none" : "auto",
          }}
          id='communication_preferences_radio_2'
          className='radio-btn'
          type='radio'
          disabled={heading === "Emails" ? false : disabled}
          checked={!isChecked}
          name={inputName}
          onChange={(event) => onChange(event, inputName)}
        />
        <span style={{ marginTop: "11px", paddingLeft: "10px" }}>No</span>
      </div>
    </div>
  );
};

export default CustomizeWorkFlowStep7;
