import moment from "moment";
import React, { Component } from "react";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import currencyFormatter from "currency-formatter";
import AppCanvasLoader from "../../../../components/App/AppCanvasLoader";
import ClientsLogo from "../../../../components/Consumer/ClientsLogo";
import ConsumerInvoiceDetails from "./ConsumerInvoiceDetails";
import ConsumerSidebar from "./ConsumerSliderStatic";

import ConsumerPayment from "./ConsumerPayment";

class ConsumerAccountsViewStatic extends Component {
  state = {
    invoiceDetails: [],
    showPaymentScreen: false,
    isLoading: false,
    showLoader: false,
    
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
  };

  componentDidMount() {
    
    localStorage.setItem("static", true);
  }

  componentWillUnmount() {
    localStorage.setItem("static", false);
  }

  getSubTitle = (userData) => {
    let fullname = "";
    if (userData.first_name) {
      fullname = userData.first_name;
    }
    if (userData.last_name && fullname) {
      fullname = fullname + " " + userData.last_name;
    } else {
      fullname = userData.last_name;
    }
    return "Welcome, Test User";
  };

  checkInvoicePastDue = (date) => {
    return moment(date).isBefore(new Date());
  };

  

  handlePayNow = () => {
    this.setState({ showPaymentScreen: !this.state.showPaymentScreen });
  };

  sidebarContent = () => {
    const { showPaymentScreen, invoiceDetails } = this.state;
    return (
      <div className="consumer-sidebar">
        <div
          className="sidebar-primary-text"
          style={{ marginBottom: "2em", display: "flex", color: "#FFFFFF" }}
        >
          This invoice is due
        </div>
        <label style={{ marginBottom: "0.3em", color: "#FFFFFF" }}>
          Balance Due
        </label>
        <div
          className="invoice-amt-owed payment-balance"
          style={{ marginBottom: "1em", color: "#000000" }}
        >
          {currencyFormatter.format(100, { code: "USD" })}
        </div>
      </div>
    );
  };

  render() {
    const { appName, user, stripePubKey } = this.props;
    const { isLoading, error, invoiceDetails, showLoader, showPaymentScreen } =
      this.state;
    const props = this.props;
    const invoiceId = this.props.match.params.id;
    if (error.hasAnyError) {
      if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    if (showPaymentScreen) {
      return (
        <ConsumerPayment
          user={user}
          primary_color={this.props.appThemeData.primary_color}
          appName={appName}
          stripePubKey={stripePubKey}
          invoiceDetails={invoiceDetails}
          updateModalState={this.props.updateModalState}
          BackLink={`/welcome-screen/accounts/000000`}
        />
      );
    }

    return isLoading ? (
      <AppCanvasLoader />
    ) : (
      <div className="client-scroll-dashboard scrollbar">
        <Helmet>
          <title>
            {appName} | Invoice# {"000000"}
          </title>
        </Helmet>
        <div className="app-sidebar-layout-canvas">
          <ConsumerSidebar
            user={this.props.user}
            primary_color={this.props.appThemeData.primary_color}
            subTitle={this.getSubTitle(this.props.user)}
            sidebarContent={this.sidebarContent()}
            hideBack={localStorage.getItem('isFromExpressTour') === 'true' ? false : true} 
            BackLink={
              this.state.isFromExpressTour
              ? "/welcome-screen/payment"
               : "/welcome-screen/accounts"
            }
          />
          <div
            className="app-sidebar-layout-canvas-content setting-side-canvas big-device-grad col1-scroll scrollbar"
            style={{ paddingBottom: 0 }}
          >
            <ClientsLogo />
            <div className="header-make-payment">
              <h1 className="app-section-consumer-heading consumer-brand">
                Invoice# {"000000"}
              </h1>
              <button
                className="cta make-payment-btn mg-brand2-common-color-static"
                style={{ fontFamily: "Open Sans",border: '3px solid #172ada' }}
                onClick={() => this.handlePayNow()}
                
              >
                Make Payment
              </button>
            </div>
            <ConsumerInvoiceDetails
              user={user}
              primary_color={this.props.appThemeData.primary_color}
              invoiceDetails={invoiceDetails}
              updateModalState={this.props.updateModalState}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ConsumerAccountsViewStatic;
