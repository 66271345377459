import React, { Component } from "react";
import "react-select/dist/react-select.css";
import FileBase64 from "react-file-base64";

import moment from "moment";
import * as API from "../../../utils/api";

import doneIcon from "../../../assets/icons/checked_icon.svg";
import tooltip from "../../../assets/icons/info_teal.png";
import editGreenIcon from "../../../assets/icons/edit_green.svg";
import AppCanvasLoader from "../../../components/App/AppCanvasLoader";
class CustomizeWorkFlowStep6 extends Component {
  constructor(props) {
    super(props);

    this.textInput = React.createRef();
    this.focusTextInput = this.focusTextInput.bind(this);
  }

  state = {
    isDaysPastMode: false,
    daysPastDue: "",
    daysPastDueOld: null,
    isDaysPastLoading: false,
    isDaysPastError: false,
    daysPastErrorText: "",
    isMinAmountMode: false,
    isMinAmountError: false,
    minAmount: "",
    minAmountErrorText: "Minimum Amount can not be 0",
    daysPastErrorText: "Days past due field should not be empty.",
    isLoading: false,
    minAmountOld: "",
    isMinAmountLoading: false,
  };

  style = {
    pText: {
      fontSize: "14px",
      color: "#2b2828",
      margin: "0px",
    },
  };

  componentDidMount() {
    this.getClientTermsList();
  }

  getClientTermsList = (message = undefined) => {
    API.getAccountDaysPastDue(this.props.user).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data) {
          if (message) {
            this.props.updateModalState(true, "SUCCESS", {
              message: message,
            });
          }

          this.setState({
            isDaysPastSubmitted: false,
            isDaysPastMode: false,
            isDaysPastLoading: false,
            isMinAmountSubmitted: false,
            isMinAmountMode: false,
            isMinAmountLoading: false,
            minAmount: data.data.account_min_amount
              ? this.decimal(data.data.account_min_amount)
              : "",
            minAmountOld: data.data.account_min_amount
              ? this.decimal(data.data.account_min_amount)
              : "",
            daysPastDue:
              data.data.days_past_due >= 0 && data.data.days_past_due != null
                ? data.data.days_past_due
                : null,
            daysPastDueOld:
              data.data.days_past_due >= 0 && data.data.days_past_due != null
                ? data.data.days_past_due
                : null,
          });
        }
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            message: data && data.error ? data.error : "",
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  updateAccountPlacementEligibility = (payload, flag) => {
    API.updateAccountPlacementEligibility(this.props.user, payload).then(
      (data) => this.handleCommonResponseDaysPastDue(data)
    );
  };

  handleCommonResponseDaysPastDue = (data) => {
    if (data && data.status_code) {
      if (data.status_code == 200) {
        this.getClientTermsList(data.message);
      } else {
        this.setState({ isMinAmountLoading: false, isDaysPastLoading: false });
        this.props.updateModalState(true, "ERROR", {
          message: data.message,
        });
      }
    } else {
      this.setState({ isMinAmountLoading: false, isDaysPastLoading: false });
    }
  };

  handleSubmit = () => {
    this.setState({ isLoading: true });
    API.getOrgDetails(this.props.user).then((data) => {
      if (data && data.status_code == 200) {
        let percent = data.data.organization.onboarding_percentage;

        if (percent == 33.33) {
          API.updateOnboardingPercentage(this.props.user, 33.33).then(
            (response) => {
              this.props.updateModalState(true, "SUCCESS", {
                message:
                  "Set billing details and credit terms step updated successfully",
              });
              if (response && response.status_code == 200) {
                this.props.handleNext("step7");
              }
            }
          );
        } else {
          this.props.handleNext("step7");
        }
      }
    });
  };

  onSubmitDaysPastDue = () => {
    let dueDaysFlag = "DAYS";
    if (!this.state.isDaysPastError) {
      this.setState({
        isDaysPastSubmitted: true,
        isDaysPastLoading: true,
      });

      let payload = {
        days_past_due: parseInt(this.state.daysPastDue),
      };
      this.updateAccountPlacementEligibility(payload, dueDaysFlag);
    }
    //   this.setState({ isDaysPastLoading: false });
    // }
  };

  onSubmitMinAmount = () => {
    let minAmountFlag = "AMT";

    this.setState({
      isMinAmountSubmitted: true,
      isMinAmountLoading: true,
    });
    // if (this.validateMinAmount(this.state.minAmount)) {

    let payload = {
      account_min_amount:
        this.state.minAmount.length > 0 ? this.state.minAmount : null,
    };
    this.updateAccountPlacementEligibility(payload, minAmountFlag);

    //   this.setState({ isMinAmountLoading: false });
    // }
  };

  decimal = (value) => {
    let decimalValue = parseFloat(value);
    decimalValue.toFixed(2);
    return decimalValue.toFixed(2);
  };

  focusTextInput(editMode) {
    this.setState({ ...editMode }, () => {
      this.textInput.current.focus();
    });
  }

  onInputDaysChange = (e) => {
    e.preventDefault();
    let input = e.target.value.trim();
    input = input.replace(/\D/g, "");

    if (input != "") {
      this.setState({ isDaysPastError: false, daysPastDue: input });
    } else {
      this.setState({
        daysPastDue: input,
        isDaysPastError: true,
      });
    }
  };

  onInputAmountChange = (e) => {
    e.preventDefault();
    let input = e.target.value;

    input = input.replace(/[^0-9.]/g, "");

    this.setState({ isMinAmountError: false, minAmount: input });

    // this.validateMinAmount(e.target.value.trim());
  };

  renderView = () => {
    return (
      <>
        <div>
          <div className="account-placement-eligibility">
            <div
              className="account-number-field-div"
              style={{ width: "200px" }}
            >
              <label
                htmlFor="daysPastDue"
                style={{
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                Days Past Due
              </label>
              <input
                type="text"
                placeholder="Enter # of Days"
                
                ref={this.textInput}
                value={
                  this.state.isDaysPastMode
                    ? this.state.daysPastDue != null
                      ? this.state.daysPastDue
                      : ""
                    : this.state.daysPastDueOld != null
                    ? `${this.state.daysPastDueOld}` + " Days Past Due"
                    : ""
                }
                name="daysPastDue"
                id="daysPastDue"
                maxLength={4}
                onChange={(e) => this.onInputDaysChange(e)}
                disabled={this.state.isDaysPastMode ? false : true}
                className={`account-input ${
                  this.state.isDaysPastMode &&
                  this.state.isDaysPastSubmitted &&
                  this.state.isDaysPastError
                    ? "error-mode"
                    : this.state.isDaysPastMode
                    ? "edit-mode"
                    : "main-container-step6"
                }`}
              />
            </div>
            <div className="action-wrapper">
              {this.state.isDaysPastMode && !this.state.isDaysPastLoading ? (
                <img
                  src={doneIcon}
                  className="save-icon"
                  title={"Save Days Past Due"}
                  onClick={this.onSubmitDaysPastDue}
                />
              ) : this.state.isDaysPastMode && this.state.isDaysPastLoading ? (
                <AppCanvasLoader />
              ) : (
                <img
                  src={editGreenIcon}
                  className="edit-icon"
                  title={"Edit Days Past Due"}
                  onClick={() => {
                    this.focusTextInput({
                      isDaysPastMode: true,
                      isMinAmountMode: false,
                      daysPastDue: this.state.daysPastDueOld,
                    });
                  }}
                />
              )}
            </div>
            <div className="action-icon-wrapper">
              <img
                src={tooltip}
                // className="tooltip-icon"
                style={{ width: "24px", height: "24px" }}
                title={
                  " Enter the days past due an account will reach to be eligible for placement with a professional collection agency. Entering “0” in the Days Past Due field will disable the Account Placement Eligibility feature."
                }
              />
            </div>
          </div>
          {this.state.isDaysPastMode && this.state.isDaysPastError ? (
            <div className="error-text">{this.state.daysPastErrorText}</div>
          ) : null}
          <br />
          <div className="account-placement-eligibility">
            <div
              className="account-number-field-div"
              style={{ width: "200px" }}
            >
              <label
                htmlFor="minAmt"
                style={{
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  fontWeight: "bold",
                  width: "251px",
                }}
              >
                Minimum Amount (Optional)
              </label>
              <input
                type="text"
                placeholder="Enter Amounts"
                ref={this.textInput}
                value={
                  this.state.isMinAmountMode
                    ? this.state.minAmount
                      ? this.state.minAmount
                      : ""
                    : this.state.minAmountOld
                    ? "$" + `${this.decimal(this.state.minAmountOld)}`
                    : ""
                }
                name="minAmt"
                id="minAmt"
                maxLength={20}
                onChange={(e) => this.onInputAmountChange(e)}
                disabled={this.state.isMinAmountMode ? false : true}
                className={`account-input ${
                  this.state.isMinAmountMode &&
                  this.state.isMinAmountSubmitted &&
                  this.state.isMinAmountError
                    ? "error-mode"
                    : this.state.isMinAmountMode
                    ? "edit-mode"
                    : "main-container-step6"
                }`}
              />
            </div>
            <div className="action-wrapper">
              {this.state.isMinAmountMode && !this.state.isMinAmountLoading ? (
                <img
                  src={doneIcon}
                  className="save-icon"
                  title={"Save Minimum Amount"}
                  onClick={this.onSubmitMinAmount}
                />
              ) : this.state.isMinAmountMode &&
                this.state.isMinAmountLoading ? (
                <AppCanvasLoader />
              ) : (
                <img
                  src={editGreenIcon}
                  className="edit-icon"
                  title={"Edit Minimum Amount"}
                  onClick={() => {
                    this.focusTextInput({
                      isDaysPastMode: false,
                      isMinAmountMode: true,
                      minAmount: this.state.minAmountOld,
                    });
                  }}
                />
              )}
            </div>
            <div className="action-icon-wrapper">
              <img
                src={tooltip}
                // className="tooltip-icon"
                style={{ width: "24px", height: "24px" }}
                title={
                  " Enter a minimum amount to exclude low balances from being eligible for placement with a professional collection agency. This field is optional. Entering $0.00 in the Minimum Amount field will disable the Minimum Amount criteria. If $0.00 is entered, only the Days Past Due field will be used to mark accounts Eligible for Placement."
                }
              />
            </div>
          </div>
          {this.state.isMinAmountMode && this.state.isMinAmountError ? (
            <div className="error-text">{this.state.minAmountErrorText}</div>
          ) : null}
        </div>
      </>
    );
  };

  render() {
    return this.state.isLoading ? (
      <div className="loader_alignment">
        <div style={{ marginTop: "20%" }}>
          <AppCanvasLoader />
        </div>
      </div>
    ) : (
      <>
        <div className="app-sidebar-layout-canvas-content setting-side-canvas col1-scroll mg-col1-scroll scrollbar">
          <div className="step3-app-sidebar-main">
            <div className="step-4-main-content">
              <div className="W9Form">
                <h3
                  style={{
                    color: "#000000",
                    // marginLeft: "145px",
                    // marginBottom: "5px",
                    width: "100%",
                  }}
                  className="mg-steps-header "
                >
                  Set Your Account Placement Eligibility Preferences
                </h3>
                <p className="">
                  Your customer accounts with invoices that have reached the
                  Account Placement Eligibility thresholds set here will
                  automatically be marked as Eligible for Placement. Utilizing
                  the Account Placement Eligibility feature allows your
                  organization to easily identify severely past due accounts and
                  place those accounts with a professional collections agency in
                  just a few clicks.
                </p>
                <div>{this.renderView()}</div>
              </div>
            </div>
          </div>
          <div className="bottum-btns-main-div-step4">
            <div className="button-left">
              <input
                className="btns-font"
                type="submit"
                id='account_placement_go_back'
                value="Go Back"
                style={{
                  borderRadius: "0.5em",
                  width: "200px",
                  height: "50px",
                  marginTop: "20px",
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  fontWeight: "500",
                  border: "2px solid",
                  float: "left",
                }}
                onClick={() => this.props.handleBack("step5")}
              />
              <span className="go-back-text">
                <p style={this.style.pText}>Go back to</p>
                <p style={this.style.pText}>
                  "Create Your Default Credit Terms"
                </p>
              </span>
            </div>
            <div className="button-right">
              <input
                type="submit"
                id='account_placement_next'
                value={"Next"}
                className="mg-brand2-color  mg-ml-0"
                style={{
                  width: "200px",
                  height: "50px",
                  borderRadius: "0.5em",
                  marginTop: "20px",
                  color: "white",
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  fontWeight: "500",
                  float: "right",
                }}
                onClick={() => this.handleSubmit()}
              />
              <span className="next-text">
                <p style={this.style.pText}>Continue to</p>
                <p style={this.style.pText}>
                  "Brand Your Invoicing & Communications"
                </p>
              </span>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CustomizeWorkFlowStep6;
