import moment from "moment";
import React, { Component } from "react";
import BottomScrollListener from "react-bottom-scroll-listener";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";
// Components
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import ClientsCardView from "../../components/CardView/ClientsCardView";
import HistoryObjectList from "../../components/ObjectList/ClientsAccounts/HistoryObjectList";
// API
import * as API from "../../utils/api";
import { getZohoPagesense, getDollarAmount } from "../../utils/helpers";

class PaymentHistoryOnline extends Component {
  constructor() {
    super();
    this.state = {
      savedData: [],
      fromChild: "",
      isLoading: true,
      hasMoreData: false,
      showFlash: false,
      sortHeader: "date",
      sortHeader: "Date/Time",
      sortType: false,
      type: {
        value: "Online",
        hasError: false,
        isRequired: true,
        offset: 0,
      },
      search: {
        value: "",
      },
      showLoader: false,
    };
  }

  componentDidMount() {
    this.getPaymentHistoryData();
    getZohoPagesense();
    ReactGA.pageview("/PaymentHistoryOnline");
  }

  getSortKey = () => {
    let { sortHeader } = this.state;
    if (sortHeader === "Inv #") {
      return "invoice_number";
    } else if (sortHeader === "Name") {
      return "display_name";
    } else if (sortHeader === "Pymt Method") {
      return "payment_type";
    } else if (sortHeader === "Amount") {
      return "amount";
    } else if (sortHeader === "Status") {
      return "status";
    } else if (sortHeader === "Date/Time") {
      return "date";
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.searchText !== this.props.searchText) {
      this.setState(
        (state) => ({
          ...state,
          savedData: [],
          isLoading: true,
          search: {
            value: nextProps.searchText,
          },
          type: {
            ...state.type,
            offset: 0,
          },
        }),
        () => {
          this.getPaymentHistoryData();
        }
      );
    }
  }

  sortPaymentData = (header, type) => {
    this.setState(
      {
        sortHeader: header,
        sortType: type,
        type: {
          offset: 0,
        },
      },
      () => {
        this.getPaymentHistoryData(false);
      }
    );
  };

  handleContainerOnBottom = () => {
    if (this.state.hasMoreData) {
      let offsetCount = parseInt(this.state.type.offset) + 1;
      this.setState(
        {
          type: {
            ...this.state.type,
            offset: offsetCount,
          },
        },
        () => {
          this.getPaymentHistoryData(true);
        }
      );
    }
  };

  getPaymentHistoryData(reload) {
    const { user } = this.props;
    let offset = this.state.type.offset;
    let searchText = this.state.search.value;
    let sort_key = this.getSortKey();
    let sort_by = this.state.sortType ? "asc" : "desc";

    this.setState((state) => ({ ...state, showLoader: true }));
    API.fetchPaymentHistory(
      user,
      "client",
      "Online",
      offset,
      searchText,
      sort_key,
      sort_by
    ).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.errors) {
        let savedData = this.state.savedData;
        let payments = reload ? this.state.savedData : false;
        let newData = payments ? savedData.concat(data.data) : data.data;
        this.setState((state) => ({
          savedData: newData,
          isLoading: false,
          hasMoreData: data.data.length < 20 ? false : true,
          showLoader: false,
        }));

        this.setState((state) => ({
          ...state,
          type: {
            ...state.type,
            offset: data.offset,
          },
        }));
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
          showLoader: false,
        }));
      }
    });
  }

  renderLatestPaymentDate = (date) => {
    return moment(date).format("MM/DD/YYYY HH:mm:ss A");
  };

  getFullName = (firstName, lastName) => {
    let fullName = "n/a";
    if (firstName || lastName) {
      fullName = firstName + " " + lastName;
    }
    return fullName;
  };

  getObjectListData = (data) => {
    let headings = [],
      columns = [];
    headings = [
      "Inv #",
      "Name",
      "Customer ID",
      "Date/Time",
      "Amount",
      "Status",
    ];

    columns = data.map((row) => {
      let statusPillClass = null;
      if (
        row.transaction_status === "REFUNDED" ||
        row.transaction_status === "VOIDED"
      ) {
        statusPillClass = "refund-message rm-scrll-w";
      } else if (
        row.transaction_status === "FAILED" ||
        row.transaction_status === "CANCELLED"
      ) {
        statusPillClass = "fail-message rm-scrll-w";
      } else if (
        row.transaction_status === "Complete" ||
        row.transaction_status === "SUCCESS"
      ) {
        statusPillClass = "success-message rm-scrll-w";
      }

      return {
        emptyPrimary: true,
        secondaryColumns: [
          {
            key: "Inv #",
            title: row.invoice_number,
          },
          {
            key: "Name",
            title: row.display_name,
          },
          {
            key: "Customer ID",
            title: `${row.stripe_id ? row.stripe_id : "n/a"}`,
          },
          {
            key: "Date/Time",
            title: row.date
              ? this.renderLatestPaymentDate(row.date)
              : row.created_at
              ? this.renderLatestPaymentDate(row.created_at)
              : "n/a",
          },
          {
            key: "Amount",
            title: getDollarAmount(
              row.is_cancelled ? row.cancel_amount : row.amount
            ),
          },
          {
            key: "Status",
            title: `${
              row.transaction_status
                ? String(row.transaction_status).toLocaleUpperCase()
                : "--"
            }`,
            optionalClasses: statusPillClass,
          },
        ],
      };
    });
    return {
      headings,
      columns,
    };
  };

  render() {
    const { isLoading } = this.state;
    let isSmallDevice = window.innerWidth <= 680 ? true : false;

    return isLoading ? (
      <div style={{ marginTop: `10rem` }}>
        <AppCanvasLoader />
      </div>
    ) : (
      <div>
        {this.state.savedData.length > 0 ? (
          <div>
            <BottomScrollListener
              offset={50}
              onBottom={this.handleContainerOnBottom}
              debounce={0}
            >
              {(scrollRef) => (
                <div>
                  {isSmallDevice ? (
                    <div className='big-device-grad invoice-line'>
                      <div ref={scrollRef} className='scrollbar in-sc'>
                        <ClientsCardView
                          data={this.getObjectListData(this.state.savedData)}
                          rowActionClick={(event) =>
                            this.handleRowActionClick(event)
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <div style={{ marginBottom: "1em" }}>
                      <HistoryObjectList
                        data={this.getObjectListData(this.state.savedData)}
                        rowActionClick={(event) =>
                          this.handleRowActionClick(event)
                        }
                        hideForMobile={true}
                        optionalClasses='scrollbar in-sc'
                        optionalTableClasses='payment-cols-online'
                        scrollRef={scrollRef}
                        sortHeaders={[
                          this.state.sortHeader,
                          this.state.sortType,
                        ]}
                        sortInvoiceData={this.sortPaymentData}
                      />
                    </div>
                  )}
                  {this.state.showLoader && <AppCanvasLoader />}
                </div>
              )}
            </BottomScrollListener>
          </div>
        ) : (
          <div className='admin-admins-empty-state'>
            <h3 className='empty-mesg-mg'>No payment history available.</h3>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(PaymentHistoryOnline);
