import MaskedTextInput from "react-text-mask";
import React, { Component } from "react";
import "react-select/dist/react-select.css";
import Select from "react-select";
import * as API from "../../../utils/api";
import {
  amountMask,
  getStrippedDollarAmount,
  quantityMask,
  percentMask,
} from "../../../utils/helpers";
import AppCanvasLoader from "../../../components/App/AppCanvasLoader";
import { BorderColor } from "@material-ui/icons";
import Emitter from "../../../utils/event-emitter";
class CustomizeWorkFlowStep5 extends Component {
  constructor(props) {
    super(props);
    this.getClientTermsList = this.getClientTermsList.bind(this);
    this.getClientTermsList();
  }
  state = {
    consumerInformation: {
      name: {
        value: "",
        hasError: false,
      },
      id: {
        value: "",
        hasError: false,
      },
      defaultDueDays: {
        value: "",
        hasError: false,
      },
      discountDays: {
        value: "",
        hasError: false,
      },
      discountPercent: {
        value: "",
        hasError: false,
      },
      discountAmount: {
        value: "",
        hasError: false,
      },
      lateFeePercent: {
        value: "",
        hasError: false,
      },
      lateFeeAmount: {
        value: "",
        hasError: false,
      },
    },
    isEarly_payer_discount_percent: false,
    isEarly_payer_discount_amount: false,
    late_fee_percent: false,
    late_fee_amount: false,
    termsList: [],
    termsListSelect: [],
    termsListFlag: true,
    newCreditTerms: false,
    isLoading: true,
    radioEarlyPayerDiscount: false,
    radioSlowPayerDiscount: false,
    radioEarlyPercent: false,
    radioSlowPercent: false,
    is_percent: false,
    hasAnyError: false,
    hasAnyErrors: false,
    errorDiscountDaysHigherDueDays: false,
  };

  style = {
    pText: {
      fontSize: "14px",
      color: "#2b2828",
      margin: "0px",
    },
  };

  checkCreditTerms() {
    if (
      this.props.orgData.organization.integrated_act_system === "standalone" &&
      this.state.termsList.length === 0
    ) {
      this.setState({
        ...this.props,
        termsListFlag: false,
        newCreditTerms: true,
      });
    }
  }

  componentDidMount() {
    this.getClientTermsList();
  }
  getClientTermsList = (reload, tabLoad) => {
    this.setState({ isLoading: true });
    if (tabLoad) this.setState({ tabLoader: true });

    API.getTermsListInSettings(this.props.user)
      .then((data) => {
        if (data && data.status_code && data.status_code === 200) {
          if (data.data) {
            let newData = data.data;
            this.setState(
              (state) => ({
                ...state,
                termsList: newData,
                isLoading: false,
              }),
              () => this.setTermsState(newData)
            );
          }
          this.setState({
            isLoading: false,
          });
        } else {
          this.setState((state) => ({
            ...state,
            isLoading: false,
            tabLoader: false,
            error: {
              message: data && data.error ? data.error : "",
              statusCode: data
                ? data.status ||
                  (data.message && data.message == "Access denied."
                    ? 401
                    : 500) ||
                  500
                : 500,
            },
          }));
        }
      })
      .catch((error) => {})
      .finally(() => {
        this.checkCreditTerms();
      });

    API.creditTermsSearch(this.props.user).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data && data.data.length) {
          data.data.map((service) => {
            service.label = service.name;
            service.value = service.name;
          });
          this.setState({ termsListSelect: data.data });
        }
      } else {
        this.handleError(data);
      }
    });
  };

  creditTermsSearch = (searchText, callback) => {
    API.creditTermsSearch(this.props.user, searchText).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data && data.data.length) {
          data.data.map((service) => {
            service.label = service.name;
            service.value = service.name;
          });
          this.setState({}, () => {
            //callback(data.data[0]);
            data.data.map((item, index) => {
              if (
                item.name &&
                item.name.toLowerCase().includes(searchText.toLowerCase())
              ) {
                callback(data.data[index]);
              }
            });
          });
          if (searchText === "") {
            this.setState({ termsListSelect: data.data });
          }
        }
      } else {
        this.handleError(data);
      }
    });
  };

  switchToTermList = (flag) => {
    this.setState((state) => ({
      ...state,
      consumerInformation: {
        ...state.consumerInformation,
        name: {
          value: "",
          hasError: false,
        },
        id: {
          value: "",
          hasError: false,
        },
        defaultDueDays: {
          value: "",
          hasError: false,
        },
        discountDays: {
          value: "",
          hasError: false,
        },
        discountPercent: {
          value: "",
          hasError: false,
        },
        discountAmount: {
          value: "",
          hasError: false,
        },
        lateFeePercent: {
          value: "",
          hasError: false,
        },
        lateFeeAmount: {
          value: "",
          hasError: false,
        },
      },
      termsListFlag: flag,
      newCreditTerms: !flag,
    }));
  };
  setTermsState = (termList) => {
    let {
      consumerInformation,
      radioEarlyPayerDiscount,
      radioSlowPayerDiscount,
      isEarly_payer_discount_percent,
      isEarly_payer_discount_amount,
      late_fee_percent,
      late_fee_amount,
    } = this.state;
    termList &&
      termList.map((item) => {
        if (item.is_default === true) {
          consumerInformation.id.value = item.id;
          consumerInformation.name.value = item.name;
          consumerInformation.defaultDueDays.value = item.due_days;
          consumerInformation.defaultDueDays.hasError = false;
          consumerInformation.discountDays.value =
            item.early_payer_discount_days;
          radioEarlyPayerDiscount =
            this.validateInputStringCheck(item.early_payer_discount_rate) ||
            this.validateInputStringCheck(item.early_payer_discount_days)
              ? true
              : false;
          radioSlowPayerDiscount = this.validateInputStringCheck(
            item.late_payer_penalty_rate
          )
            ? true
            : false;
          if (item.early_payer_discount_rate_percent_based) {
            consumerInformation.discountPercent.value =
              item.early_payer_discount_rate;
            isEarly_payer_discount_percent = true;
            isEarly_payer_discount_amount = false;
          } else {
            consumerInformation.discountAmount.value =
              item.early_payer_discount_rate;
            isEarly_payer_discount_percent = false;
            isEarly_payer_discount_amount = true;
          }
          if (item.late_payer_penalty_rate_percent_based) {
            consumerInformation.lateFeePercent.value =
              item.late_payer_penalty_rate;
            late_fee_percent = true;
            late_fee_amount = false;
          } else {
            consumerInformation.lateFeeAmount.value =
              item.late_payer_penalty_rate;
            late_fee_percent = false;
            late_fee_amount = true;
          }
          // item.early_payer_discount_rate_percent_based
          //   ? this.checkIspercent("is_percent")
          //   : this.checkIspercent("is_amount");
          // item.late_payer_penalty_rate_percent_based
          //   ? this.checkIspercent("is_percent_late_fee")
          //   : this.checkIspercent("is_amount_late_amount");
          this.setState(
            {
              consumerInformation,
              radioEarlyPayerDiscount,
              radioSlowPayerDiscount,
              isEarly_payer_discount_percent,
              isEarly_payer_discount_amount,
              late_fee_percent,
              late_fee_amount,
            },
            () => {
              this.CheckRadioBtn();
            }
          );
        }
      });
    this.checkIspercent();
  };
  handleConsumerInfoInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      consumerInformation: {
        ...state.consumerInformation,
        ...newPartialInput,
      },
    }));
  }

  defaultCreditTerms(e) {
    if (e !== null && e !== undefined) {
      this.setState((state) => ({
        ...state,
        consumerInformation: {
          name: {
            value: e.name,
            hasError: false,
          },
          id: {
            value: e.id,
            hasError: false,
          },
          defaultDueDays: {
            value: e.due_days,
            hasError: false,
          },
          discountDays: {
            value: e.early_payer_discount_days,
            hasError: false,
          },
          discountPercent: {
            value: e.early_payer_discount_rate_percent_based
              ? e.early_payer_discount_rate
              : "",
            hasError: false,
          },
          discountAmount: {
            value: e.early_payer_discount_rate_percent_based
              ? ""
              : e.early_payer_discount_rate,
            hasError: false,
          },
          lateFeePercent: {
            value: e.late_payer_penalty_rate_percent_based
              ? e.late_payer_penalty_rate
              : "",
            hasError: false,
          },
          lateFeeAmount: {
            value: e.late_payer_penalty_rate_percent_based
              ? ""
              : e.late_payer_penalty_rate,
            hasError: false,
          },
        },
        isEarly_payer_discount_percent:
          e.early_payer_discount_rate_percent_based,
        isEarly_payer_discount_amount:
          !e.early_payer_discount_rate_percent_based,
        late_fee_percent: e.late_payer_penalty_rate_percent_based,
        late_fee_amount: !e.late_payer_penalty_rate_percent_based,
      }));
    } else {
      this.setState((state) => ({
        ...state,
        consumerInformation: {
          ...state.consumerInformation,
          name: {
            value: "",
            hasError: false,
          },
        },
      }));
    }
  }

  validateDueAndDiscountDaysComparison = () => {
    let { consumerInformation } = this.state;
    if (
      parseInt(consumerInformation.defaultDueDays.value) <
      parseInt(consumerInformation.discountDays.value)
    ) {
      this.setState((state) => ({
        ...state,
        consumerInformation: {
          ...state.consumerInformation,
          discountDays: {
            value: consumerInformation.discountDays.value,
            hasError: true,
          },
        },
        errorDiscountDaysHigherDueDays: true,
      }));
    } else {
      {
        this.setState((state) => ({
          ...state,
          discountDays: {
            value: consumerInformation.discountDays.value,
            hasError: false,
          },
          errorDiscountDaysHigherDueDays: false,
        }));
      }
    }
  };

  handleDefaultDueDaysChange(value) {
    let { consumerInformation } = this.state;
    let obj = { ...this.state.consumerInformation };
    this.setState((state) => ({
      ...state,
      consumerInformation: {
        ...state.consumerInformation,
        ...value,
        discountDays: {
          value: obj.discountDays.value,
          hasError: false,
        },
      },
      errorDiscountDaysHigherDueDays: false,
      hasAnyErrors: false,
    }));
  }
  handleDiscountDaysChange(value) {
    this.setState((state) => ({
      ...state,
      consumerInformation: {
        ...state.consumerInformation,
        ...value,
      },
      errorDiscountDaysHigherDueDays: false,
      hasAnyErrors: false,
    }));
  }

  validate = () => {
    let { consumerInformation } = this.state;
    let obj = { ...this.state.consumerInformation };
    let errorPresent = false;
    if (
      consumerInformation.name.value === "" ||
      consumerInformation.name.value === null ||
      consumerInformation.name.value === undefined ||
      consumerInformation.name.value.trim() === ""
    ) {
      errorPresent = true;
      this.setState((state) => ({
        ...state,
        consumerInformation: {
          ...state.consumerInformation,
          name: { hasError: true },
        },
      }));
    }
    if (
      consumerInformation.defaultDueDays.value === "" ||
      consumerInformation.defaultDueDays.value === null ||
      consumerInformation.defaultDueDays.value === undefined
    ) {
      errorPresent = true;
      this.setState((state) => ({
        ...state,
        consumerInformation: {
          ...state.consumerInformation,
          defaultDueDays: {
            value: obj.defaultDueDays.value,
            hasError: true,
          },
        },
      }));
    }

    if (this.state.radioEarlyPayerDiscount) {
      if (
        consumerInformation.discountDays.value === "" ||
        consumerInformation.discountDays.value === null ||
        consumerInformation.discountDays.value === undefined
      ) {
        errorPresent = true;
        this.setState((state) => ({
          ...state,
          consumerInformation: {
            ...state.consumerInformation,
            discountDays: {
              value: obj.discountDays.value,
              hasError: true,
            },
          },
        }));
      }
      if (
        parseInt(consumerInformation.defaultDueDays.value) <
        parseInt(consumerInformation.discountDays.value)
      ) {
        errorPresent = true;
        this.setState((state) => ({
          ...state,
          consumerInformation: {
            ...state.consumerInformation,
            discountDays: {
              value: obj.discountDays.value,
              hasError: true,
            },
          },
          errorDiscountDaysHigherDueDays: true,
        }));
      }
      if (
        this.state.isEarly_payer_discount_percent &&
        (consumerInformation.discountPercent.value === "" ||
          consumerInformation.discountPercent.value === null ||
          consumerInformation.discountPercent.value === undefined)
      ) {
        errorPresent = true;
        this.setState((state) => ({
          ...state,
          consumerInformation: {
            ...state.consumerInformation,
            discountPercent: { hasError: true },
          },
        }));
      }
      if (
        this.state.isEarly_payer_discount_amount &&
        (consumerInformation.discountAmount.value === "" ||
          consumerInformation.discountAmount.value === null ||
          consumerInformation.discountAmount.value === undefined)
      ) {
        errorPresent = true;
        this.setState((state) => ({
          ...state,
          consumerInformation: {
            ...state.consumerInformation,
            discountAmount: { hasError: true },
          },
        }));
      }
    }

    if (this.state.radioSlowPayerDiscount) {
      if (
        this.state.late_fee_amount &&
        (consumerInformation.lateFeeAmount.value === "" ||
          consumerInformation.lateFeeAmount.value === null ||
          consumerInformation.lateFeeAmount.value === undefined)
      ) {
        errorPresent = true;
        this.setState((state) => ({
          ...state,
          consumerInformation: {
            ...state.consumerInformation,
            lateFeeAmount: { hasError: true },
          },
        }));
      }
      if (
        this.state.late_fee_percent &&
        (consumerInformation.lateFeePercent.value === "" ||
          consumerInformation.lateFeePercent.value === null ||
          consumerInformation.lateFeePercent.value === undefined)
      ) {
        errorPresent = true;
        this.setState((state) => ({
          ...state,
          consumerInformation: {
            ...state.consumerInformation,
            lateFeePercent: { hasError: true },
          },
        }));
      }
    }
    if (errorPresent) {
      return false;
    } else {
      return true;
    }
  };

  CheckRadioBtn = () => {
    let { consumerInformation } = this.state;
    if (
      consumerInformation.discountPercent.value !== "0.0" &&
      consumerInformation.discountPercent.value !== null &&
      consumerInformation.discountPercent.value !== undefined &&
      consumerInformation.discountAmount.value !== "0.0" &&
      consumerInformation.discountAmount.value !== null &&
      consumerInformation.discountAmount.value !== undefined
    ) {
      this.state.radioEarlyPayerDiscount = true;
      this.setState({
        radioEarlyPayerDiscount: true,
      });
    }
    if (
      consumerInformation.lateFeePercent.value !== "0.0" &&
      consumerInformation.lateFeePercent.value !== null &&
      consumerInformation.lateFeePercent.value !== undefined &&
      consumerInformation.lateFeeAmount.value !== "0.0" &&
      consumerInformation.lateFeeAmount.value !== null &&
      consumerInformation.lateFeeAmount.value !== undefined
    ) {
      this.setState({
        radioSlowPayerDiscount: true,
      });
    }
  };

  removeEarlyPayerDiscount = () => {
    let {
      radioEarlyPayerDiscount,
      isEarly_payer_discount_percent,
      isEarly_payer_discount_amount,
    } = this.state;
    radioEarlyPayerDiscount = false;
    isEarly_payer_discount_percent = false;
    isEarly_payer_discount_amount = false;
    this.setState((state) => ({
      ...state,
      radioEarlyPayerDiscount,
      isEarly_payer_discount_percent,
      isEarly_payer_discount_amount,
    }));
    this.setState((state) => ({
      ...state,
      consumerInformation: {
        ...state.consumerInformation,
        discountDays: {
          value: "",
          hasError: false,
        },
        discountAmount: {
          value: "",
          hasError: false,
        },
        discountPercent: {
          value: "",
          hasError: false,
        },
      },
    }));
  };

  checkEarlyPayerDiscount = (event) => {
    let {
      radioEarlyPayerDiscount,
      isEarly_payer_discount_percent,
      isEarly_payer_discount_amount,
      consumerInformation,
    } = this.state;
    if (radioEarlyPayerDiscount) {
      if (
        this.validateInputStringCheck(consumerInformation.discountDays.value) ||
        this.validateInputStringCheck(
          consumerInformation.discountPercent.value
        ) ||
        this.validateInputStringCheck(consumerInformation.discountAmount.value)
      ) {
        this.props.updateModalState(true, "WARN_MESSAGE", {
          message:
            "Early payer discount data will be lost, are you sure you want to continue?",
          handleSubmit: this.removeEarlyPayerDiscount,
        });
      } else {
        this.removeEarlyPayerDiscount();
      }
    } else {
      radioEarlyPayerDiscount = true;
      isEarly_payer_discount_percent = true;
      isEarly_payer_discount_amount = false;
      this.setState(
        (state) => ({
          ...state,
          radioEarlyPayerDiscount,
          isEarly_payer_discount_percent,
          isEarly_payer_discount_amount,
        })
        // () => this.removeDiscountData(this.state.radioEarlyPayerDiscount)
      );
    }
  };

  removeSlowPayerPenalty = () => {
    let { radioSlowPayerDiscount, late_fee_percent, late_fee_amount } =
      this.state;
    radioSlowPayerDiscount = false;
    late_fee_percent = false;
    late_fee_amount = false;
    this.setState((state) => ({
      ...state,
      radioSlowPayerDiscount,
      late_fee_percent,
      late_fee_amount,
      consumerInformation: {
        ...state.consumerInformation,
        lateFeePercent: {
          value: "",
          hasError: "",
        },
        lateFeeAmount: {
          value: "",
          hasError: "",
        },
      },
    }));
  };

  checkSlowPayerPenalty = () => {
    let {
      radioSlowPayerDiscount,
      late_fee_percent,
      late_fee_amount,
      consumerInformation,
    } = this.state;

    if (radioSlowPayerDiscount) {
      if (
        this.validateInputStringCheck(
          consumerInformation.lateFeePercent.value
        ) ||
        this.validateInputStringCheck(consumerInformation.lateFeeAmount.value)
      ) {
        this.props.updateModalState(true, "WARN_MESSAGE", {
          message:
            "Slow Payer Penalty data will be lost, are you sure you want to continue?",
          handleSubmit: this.removeSlowPayerPenalty,
        });
      } else {
        this.removeSlowPayerPenalty();
      }
    } else {
      radioSlowPayerDiscount = true;
      late_fee_percent = true;
      late_fee_amount = false;
      this.setState(
        (state) => ({
          ...state,
          radioSlowPayerDiscount,
          late_fee_percent,
          late_fee_amount,
        })
        // () => this.removeDiscountData(this.state.radioEarlyPayerDiscount)
      );
    }
  };

  checkIspercent = (type) => {
    let {
      is_percent,
      isEarly_payer_discount_percent,
      isEarly_payer_discount_amount,
      consumerInformation,
    } = this.state;
    if (type === "is_percent") {
      let { consumerInformation } = this.state;
      this.setState((state) => ({
        ...state,
        isEarly_payer_discount_amount: false,
        isEarly_payer_discount_percent: true,
        consumerInformation: {
          ...state.consumerInformation,
          discountAmount: {
            value: consumerInformation.discountAmount.value,
            hasError: false,
          },
        },
      }));
    } else if (type === "is_amount") {
      let { consumerInformation } = this.state;
      this.setState((state) => ({
        ...state,
        isEarly_payer_discount_amount: true,
        isEarly_payer_discount_percent: false,
        consumerInformation: {
          ...state.consumerInformation,
          discountPercent: {
            value: consumerInformation.discountPercent.value,
            hasError: false,
          },
        },
      }));
    } else if (type === "is_percent_late_fee") {
      this.setState((state) => ({
        ...state,
        late_fee_percent: true,
        late_fee_amount: false,
        consumerInformation: {
          ...state.consumerInformation,
          lateFeeAmount: {
            value: consumerInformation.lateFeeAmount.value,
            hasError: false,
          },
        },
      }));
    } else if (type === "is_amount_late_amount") {
      let { consumerInformation } = this.state;
      this.setState((state) => ({
        ...state,
        late_fee_percent: false,
        late_fee_amount: true,
        consumerInformation: {
          ...state.consumerInformation,
          lateFeePercent: {
            value: consumerInformation.lateFeePercent.value,
            hasError: false,
          },
        },
      }));
    } else if (type === "no_early") {
      this.setState((state) => ({
        ...state,
        isEarly_payer_discount_amount: false,
        isEarly_payer_discount_percent: false,
        consumerInformation: {
          ...state.consumerInformation,
          discountPercent: {
            value: "",
            hasError: false,
          },
          discountAmount: {
            value: "",
            hasError: false,
          },
        },
      }));
    } else if (type === "no_slow") {
      this.setState((state) => ({
        ...state,
        late_fee_percent: false,
        late_fee_amount: false,
        consumerInformation: {
          ...state.consumerInformation,
          lateFeePercent: {
            value: "",
            hasError: false,
          },
          lateFeeAmount: {
            value: "",
            hasError: false,
          },
        },
      }));
    }
  };
  removeDiscountData = (res) => {
    let {
      consumerInformation,
      isEarly_payer_discount_percent,
      isEarly_payer_discount_amount,
      late_fee_percent,
      late_fee_amount,
    } = this.state;
    if (res === true) {
      let { consumerInformation, termsList } = this.state;
      termsList &&
        termsList.map((item) => {
          if (item.is_default === true) {
            consumerInformation.discountAmount.value =
              item.early_payer_discount_rate;
            consumerInformation.discountDays.value =
              item.early_payer_discount_days;
            item.early_payer_discount_rate_percent_based
              ? (isEarly_payer_discount_percent = true)
              : (isEarly_payer_discount_amount = true);

            this.setState({
              consumerInformation,
              isEarly_payer_discount_percent,
              isEarly_payer_discount_amount,
              late_fee_percent,
              late_fee_amount,
            });
          }
        });
    } else if (res === false) {
      this.checkIspercent("no_early");
      consumerInformation.discountAmount.value = "";
      consumerInformation.discountDays.value = "";
      this.setState({
        consumerInformation,
      });
    }
  };
  removeSlowPayerDiscountData = (res) => {
    let { consumerInformation } = this.state;
    if (res === true) {
      let {
        consumerInformation,
        termsList,
        late_fee_percent,
        late_fee_amount,
      } = this.state;
      termsList &&
        termsList.map((item) => {
          if (item.is_default === true) {
            item.late_payer_penalty_rate_percent_based
              ? (late_fee_percent = true)
              : (late_fee_amount = true);
            consumerInformation.lateFeeAmount.value =
              item.late_payer_penalty_rate;
            this.setState({
              consumerInformation,
              late_fee_percent,
              late_fee_amount,
            });
          }
        });
    } else if (res === false) {
      this.checkIspercent("no_slow");
      consumerInformation.lateFeeAmount.value = "";
      this.setState({
        consumerInformation,
      });
    }
  };
  handleSubmitmodal = () => {
    if (this.validate() && !this.state.hasAnyError) {
      this.props.updateModalState(true, "Default_TERM", {
        message:
          "This will be set as your default terms for your invoices created in MakeGood. You can add or change your default terms at anytime.",
        handleSubmit: this.handleSubmit,
      });
    }
  };
  handleSubmit = () => {
    let { consumerInformation, termsList } = this.state;
    if (this.validate()) {
      let tempAmt = consumerInformation.discountAmount.value
        ? consumerInformation.discountAmount.value.includes("$")
          ? consumerInformation.discountAmount.value.replace("$", "")
          : consumerInformation.discountAmount.value
        : consumerInformation.discountAmount.value;

      let tempPercent = consumerInformation.discountPercent.value
        ? consumerInformation.discountPercent.value.includes("%")
          ? consumerInformation.discountPercent.value.replace("%", "")
          : consumerInformation.discountPercent.value
        : consumerInformation.discountPercent.value;
      let payload = {
        id: consumerInformation.id.value,
        is_default: true,
        name: consumerInformation.name.value,
        due_days: consumerInformation.defaultDueDays.value,
        early_payer_discount_days: consumerInformation.discountDays.value,
        early_payer_discount_rate_percent_based:
          this.state.isEarly_payer_discount_percent,
        early_payer_discount_rate: this.state.isEarly_payer_discount_percent
          ? consumerInformation.discountPercent.value
            ? tempPercent
            : ""
          : consumerInformation.discountAmount.value
          ? tempAmt
          : "",
        late_payer_penalty_rate_percent_based: this.state.late_fee_percent,
        late_payer_penalty_rate: this.state.late_fee_percent
          ? consumerInformation.lateFeePercent.value
            ? consumerInformation.lateFeePercent.value.replace("%", "")
            : ""
          : consumerInformation.lateFeeAmount.value
          ? consumerInformation.lateFeeAmount.value.replace("$", "")
          : "",
      };
      let is_got_default = false;
      termsList.length > 0 &&
        termsList.map((item) => {
          if (item.is_default) {
            is_got_default = true;
          }
        });
      if (!this.state.newCreditTerms) {
        termsList.length > 0 && is_got_default
          ? termsList.map((item) => {
              if (item.is_default) {
                is_got_default = true;
                item.name !== "" && item.name !== null
                  ? this.UpdateClientTermAPI(payload)
                  : API.clientCreateTermsInSettings(
                      this.props.user,
                      payload
                    ).then((data) => {
                      this.handleCommonResponse(data);
                    });
              }
            })
          : this.state.termsListFlag
          ? this.UpdateClientTermAPI(payload)
          : API.clientCreateTermsInSettings(this.props.user, payload).then(
              (data) => {
                this.handleCommonResponse(data);
              }
            );
      } else {
        let newPayload = {
          is_default: true,
          name: consumerInformation.name.value,
          due_days: consumerInformation.defaultDueDays.value,
          early_payer_discount_days: consumerInformation.discountDays.value,
          early_payer_discount_rate_percent_based:
            this.state.isEarly_payer_discount_percent,
          early_payer_discount_rate: this.state.isEarly_payer_discount_percent
            ? consumerInformation.discountPercent.value
              ? tempPercent
              : ""
            : consumerInformation.discountAmount.value
            ? tempAmt
            : "",
          late_payer_penalty_rate_percent_based: this.state.late_fee_percent,
          late_payer_penalty_rate: this.state.late_fee_percent
            ? consumerInformation.lateFeePercent.value
              ? consumerInformation.lateFeePercent.value.replace("%", "")
              : ""
            : consumerInformation.lateFeeAmount.value
            ? consumerInformation.lateFeeAmount.value.replace("$", "")
            : "",
        };
        API.clientCreateTermsInSettings(this.props.user, newPayload).then(
          (data) => {
            this.handleCommonResponse(data);
          }
        );
      }
    }
  };
  handleCommonResponse = (data) => {
    if (data && data.status_code) {
      if (data.status_code == 200) {
        this.props.updateModalState(
          true,
          "SUCCESS",
          {
            message: data.message,
          },
          this.props.handleNext("step6")
        );
        this.getClientTermsList();
      } else if (data.status_code == 422) {
        Emitter.emit("NOTIFICATION_ALERT", true);
        this.props.updateModalState(true, "ERROR", {
          message: data.message,
        });
      } else {
        this.props.updateModalState(true, "ERROR", {
          message: data.message,
        });
      }
      this.setState({ isLoading: false });
    }
  };
  UpdateClientTermAPI = (payload, index) => {
    API.clientUpdateTermsInSettings(this.props.user, payload).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        this.props.updateModalState(
          true,
          "SUCCESS",
          {
            message: data.message,
          },
          this.props.handleNext("step6")
        );
        this.getClientTermsList();
        this.setState({
          isLoading: false,
        });
      } else {
        if (data.status_code == 422) {
          Emitter.emit("NOTIFICATION_ALERT", true);
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        }
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            message: data && data.error ? data.error : "",
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
        this.props.updateModalState(true, "ERROR", {
          message: data.message,
        });
      }
    });
  };

  validateInputStringConsumerInfoInput = (value) => {
    if (value === "" || value === null || value === undefined) {
      return "";
    } else {
      return value;
    }
  };

  validateInputStringCheck = (value) => {
    if (value === "" || value === null || value === undefined) {
      return false;
    } else {
      return true;
    }
  };

  validateRegexForNumbersOnly = (value) => {
    let pattern = new RegExp(/^\d*[0-9]\d*$/);
    return pattern.test(value);
  };
  render() {
    return this.state.isLoading ? (
      <div className='loader_alignment'>
        <div style={{ marginTop: "20%" }}>
          <AppCanvasLoader />
        </div>
      </div>
    ) : (
      <>
        <div className='app-sidebar-layout-canvas-content setting-side-canvas col1-scroll mg-col1-scroll scrollbar'>
          <div className='main_step5'>
            <div style={{ padding: "20px" }}>
              <h3
                style={{
                  color: "black",
                  fontFamily: "Open Sans",
                  fontWeight: "bold",
                }}
              >
                Create Your Default Credit Terms
              </h3>
              <h7>
                Add Your Default Terms that you will apply to invoices. MakeGood
                recommends incentivizing your early payers and penalizing your
                later payers.
              </h7>
            </div>
            {/* subdiv-communication-preferences */}
            <div className='main_step5_main_div'>
              <div className='main_step5_subdiv'>
                <h3 className='labels-text'>Default Term Name</h3>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    // width: "400px",
                    paddingTop: "10px",
                  }}
                  className=''
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "230px",
                    }}
                  >
                    <input
                      type='radio'
                      id='credit_term_use_from_list_radio'
                      name='crediTerm'
                      style={{
                        height: "10px",
                        width: "15px",
                        appearance: "auto",
                      }}
                      onClick={() => {
                        this.switchToTermList(true);
                      }}
                      checked={this.state.termsListFlag}
                    />
                    <span
                      style={{
                        fontFamily: "Open Sans",
                        fontWeight: "bold",
                        fontSize: "18px",
                        marginLeft: "18px",
                      }}
                    >
                      Use Terms List
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "250px",
                    }}
                  >
                    <input
                      type='radio'
                      id='credit_term_create_new_term_radio'
                      name='crediTerm'
                      style={{
                        height: "10px",
                        width: "15px",
                        appearance: "auto",
                      }}
                      onClick={() => {
                        this.switchToTermList(false);
                      }}
                      checked={this.state.newCreditTerms}
                    />
                    <span
                      style={{
                        fontFamily: "Open Sans",
                        fontWeight: "bold",
                        fontSize: "18px",
                        marginLeft: "18px",
                      }}
                    >
                      Create New Term
                    </span>
                  </div>
                </div>
                {this.state.newCreditTerms && (
                  <input
                    type='text'
                    id='credit_termname_input_'
                    placeholder='Enter name'
                    style={{ fontSize: "17px", marginTop: "8px" }}
                    value={this.state.consumerInformation.name.value}
                    onChange={(event) => {
                      this.handleConsumerInfoInputChange({
                        name: {
                          value: event.target.value,
                          hasError: false,
                        },
                        hasAnyError: false,
                      });
                    }}
                  />
                )}
                {this.state.termsListFlag && (
                  <Select
                    inputProps={{
                      autoComplete: "none",
                      autoCorrect: "off",
                      spellCheck: "off",
                    }}
                    id='credit_term_term_list'
                    className='form-select'
                    name='service'
                    placeholder='Select name'
                    options={this.state.termsListSelect}
                    onChange={(e) => this.defaultCreditTerms(e)}
                    value={this.state.consumerInformation.name.value}
                    onInputChange={(e) =>
                      e ? this.creditTermsSearch(e, () => {}) : ""
                    }
                  />
                )}

                {this.state.consumerInformation.name.hasError ? (
                  <div>
                    <p
                      style={{
                        color: "#fb5c82",
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                        height: "10px",
                      }}
                    >
                      Please enter Default Term Name.
                    </p>
                  </div>
                ) : (
                  ""
                )}
                <h2 style={{ color: "#0ab894", marginTop: "10px" }}>
                  (Hint: We recommend a short name that clearly communicates
                  your agreed upon credit terms with the customer. For example:
                  <ul>
                    <li> DUE IN 30 days</li>
                    <li>2% Discount if paid in 10 days</li>
                    <li>$20 Late Fee)</li>
                  </ul>
                </h2>
                <span>
                  <h3 style={{ display: "inline" }}>Default Due Days</h3>

                  <br />
                  <br />
                  <input
                    type='number'
                    id='credit_term_default_due_days_input'
                    min='0'
                    style={{
                      fontSize: "20px",
                      marginLeft: "0px",
                      border: this.state.consumerInformation.defaultDueDays
                        .hasError
                        ? "2px solid #ff6464"
                        : "",
                    }}
                    value={this.state.consumerInformation.defaultDueDays.value}
                    onClick={() => this.validateDueAndDiscountDaysComparison()}
                    onChange={(event) => {
                      this.handleDefaultDueDaysChange({
                        defaultDueDays: {
                          value: this.validateRegexForNumbersOnly(
                            event.target.value
                          )
                            ? event.target.value
                            : "",
                          hasError: false,
                        },
                      });
                    }}
                  />
                  <span> Days</span>
                  {this.state.consumerInformation.defaultDueDays.hasError ? (
                    <div>
                      <p
                        style={{
                          color: "#fb5c82",
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                          height: "10px",
                        }}
                      >
                        Please enter the due day(s).
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </span>
                <br />
                <br />
                <span>
                  <span style={{ fontWeight: "bold" }}>Note : </span> Enter “0”
                  if invoices are due upon receipt
                </span>
              </div>
              <div className='main_step5_subdiv2'>
                <h1
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    fontFamily: "Open Sans",
                    margin: "0px",
                    marginBottom: "5px",
                  }}
                >
                  Incentives & Penalties
                </h1>
                <div className='early_payer_discount'>
                  <input
                    type='radio'
                    id='credit_term_add_early_payer_discount_radio'
                    checked={this.state.radioEarlyPayerDiscount}
                    onClick={(event) => this.checkEarlyPayerDiscount(event)}
                  />
                  <span> Add Early Payer Discount</span>
                </div>
                <div
                  className='main_step5_subdiv5'
                  style={{ marginTop: "10px" }}
                >
                  <input
                    type='number'
                    id='credit_term_add_early_payer_discount_input'
                    min='0'
                    style={
                      this.state.radioEarlyPayerDiscount &&
                      this.state.consumerInformation.discountDays.hasError
                        ? { border: "2px solid #ff6464" }
                        : null
                    }
                    value={this.state.consumerInformation.discountDays.value}
                    disabled={!this.state.radioEarlyPayerDiscount}
                    onClick={() => this.validateDueAndDiscountDaysComparison()}
                    onChange={(event) => {
                      this.handleDiscountDaysChange({
                        discountDays: {
                          value: this.validateRegexForNumbersOnly(
                            event.target.value
                          )
                            ? event.target.value
                            : "",
                          hasError: false,
                        },
                      });
                    }}
                  />
                  <span
                    style={{
                      fontSize: "16px",
                      marginLeft: "8px",
                      marginRight: "-10px",
                    }}
                  >
                    Discount Days
                  </span>
                </div>
                {this.state.radioEarlyPayerDiscount &&
                this.state.consumerInformation.discountDays.hasError &&
                this.state.errorDiscountDaysHigherDueDays ? (
                  <p
                    style={{
                      margin: "0px",
                      color: "#fb5c82",
                      fontFamily: "Open Sans",
                      fontWeight: 600,
                    }}
                  >
                    The Discount day(s) should be less than or equals to Due
                    day(s).
                  </p>
                ) : this.state.radioEarlyPayerDiscount &&
                  this.state.consumerInformation.discountDays.hasError ? (
                  <p
                    style={{
                      margin: "0px",
                      color: "#fb5c82",
                      fontFamily: "Open Sans",
                      fontWeight: 600,
                    }}
                  >
                    Please enter the Discount day(s).
                  </p>
                ) : (
                  ""
                )}
                <h2
                  style={{
                    fontSize: "16px",
                    color: "#0ab894",
                    marginBottom: "10px",
                    marginTop: "10px",
                    fontFamily: "Open Sans ,sans-serif",
                  }}
                >
                  Discount Days – the number of days from the date the invoice
                  created which the discount will apply
                </h2>

                {this.state.radioEarlyPayerDiscount ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      // width: "400px",
                      paddingTop: "5px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "250px",
                      }}
                    >
                      <input
                        type='radio'
                        id='credit_term_add_early_payer_discount_percentage_radio'
                        style={{ height: "10px", width: "15px" }}
                        name='addEarlyPayerDiscount'
                        onClick={() => {
                          this.checkIspercent("is_percent");
                        }}
                        checked={this.state.isEarly_payer_discount_percent}
                      />
                      <span>Discount Percent (%) </span>
                      <span>Or</span>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "230px",
                      }}
                    >
                      <input
                        type='radio'
                        id='credit_term_add_early_payer_discount_amount_radio'
                        style={{ height: "10px", width: "15px" }}
                        name='addEarlyPayerDiscount'
                        onClick={() => {
                          this.checkIspercent("is_amount");
                        }}
                        checked={this.state.isEarly_payer_discount_amount}
                      />
                      <span>Discount Amount ($)</span>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {this.state.radioEarlyPayerDiscount ? (
                  this.state.isEarly_payer_discount_percent ? (
                    <MaskedTextInput
                      style={{
                        color: this.state.radioEarlyPayerDiscount
                          ? "black"
                          : "grey",
                        borderBottomColor: this.state.radioEarlyPayerDiscount
                          ? "black"
                          : "gray",
                        fontSize: "1em",
                      }}
                      id='credit_term_add_early_payer_discount_percentage_input'
                      mask={percentMask}
                      disabled={!this.state.radioEarlyPayerDiscount}
                      name='earlyPayerDiscRatePerc'
                      placeholder='Discount Percent (%)'
                      // placeholderChar={"\u2000"}
                      type='text'
                      value={
                        this.validateInputStringCheck(
                          this.state.consumerInformation.discountPercent.value
                        )
                          ? this.state.consumerInformation.discountPercent
                              .value + "%"
                          : ""
                      }
                      onChange={(event) => {
                        this.handleConsumerInfoInputChange({
                          discountPercent: {
                            value: this.validateInputStringConsumerInfoInput(
                              event.target.value
                            ),
                            hasError: false,
                          },
                        });
                      }}
                    />
                  ) : this.state.isEarly_payer_discount_amount ? (
                    <MaskedTextInput
                      style={{
                        color: this.state.radioEarlyPayerDiscount
                          ? "black"
                          : "grey",
                        borderBottomColor: this.state.radioEarlyPayerDiscount
                          ? "black"
                          : "gray",
                        fontSize: "1em",
                      }}
                      disabled={!this.state.radioEarlyPayerDiscount}
                      mask={amountMask}
                      name='earlyPayerDiscRateAmount'
                      id='credit_term_add_early_payer_discount_amount_input'
                      placeholder='Discount Amount ($)'
                      placeholderChar={"\u2000"}
                      type='text'
                      value={
                        this.validateInputStringCheck(
                          this.state.consumerInformation.discountAmount.value
                        )
                          ? "$" +
                            this.state.consumerInformation.discountAmount.value
                          : ""
                      }
                      onChange={(event) => {
                        this.handleConsumerInfoInputChange({
                          discountAmount: {
                            value: this.validateInputStringConsumerInfoInput(
                              event.target.value
                            ),
                            hasError: false,
                          },
                        });
                      }}
                    />
                  ) : (
                    <MaskedTextInput
                      style={{
                        color: this.state.radioEarlyPayerDiscount
                          ? "black"
                          : "grey",
                        borderBottomColor: this.state.radioEarlyPayerDiscount
                          ? "black"
                          : "gray",
                        fontSize: "1em",
                      }}
                      mask={quantityMask}
                      disabled={true}
                      name='earlyPayerDiscRatePerc'
                      placeholder='Discount Percent (%) or Discount Amount ($)'
                      placeholderChar={"\u2000"}
                      type='text'
                    />
                  )
                ) : (
                  <MaskedTextInput
                    style={{
                      color: this.state.radioEarlyPayerDiscount
                        ? "black"
                        : "grey",
                      borderBottomColor: this.state.radioEarlyPayerDiscount
                        ? "black"
                        : "gray",
                      fontSize: "1em",
                    }}
                    mask={quantityMask}
                    disabled={true}
                    name='earlyPayerDiscRatePerc'
                    value=''
                    placeholder='Discount Percent (%) or Discount Amount ($)'
                    placeholderChar={"\u2000"}
                    type='text'
                  />
                )}
                {this.state.radioEarlyPayerDiscount &&
                this.state.consumerInformation.discountPercent.hasError &&
                this.state.isEarly_payer_discount_percent ? (
                  <div>
                    <p
                      style={{
                        color: "#fb5c82",
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                        height: "10px",
                      }}
                    >
                      Please enter Early Payer Discount percent .
                    </p>
                  </div>
                ) : (
                  ""
                )}
                {this.state.radioEarlyPayerDiscount &&
                this.state.consumerInformation.discountAmount.hasError &&
                this.state.isEarly_payer_discount_amount ? (
                  <div>
                    <p
                      style={{
                        color: "#fb5c82",
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                        height: "10px",
                      }}
                    >
                      Please enter Early Payer Discount Amount .
                    </p>
                  </div>
                ) : (
                  ""
                )}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "20px",
                  }}
                >
                  <input
                    type='radio'
                    style={{
                      appearance: "auto",
                      height: "20px",
                      width: "15px",
                      marginTop: "10px",
                    }}
                    checked={this.state.radioSlowPayerDiscount}
                    onClick={(event) => this.checkSlowPayerPenalty(event)}
                  />
                  <span
                    style={{
                      marginLeft: "18px",
                      fontFamily: "Open Sans",
                      fontSize: "18px",
                      marginTop: "10px",
                    }}
                  >
                    {" "}
                    Add Slow Payer Penalty{" "}
                  </span>
                </div>
                {this.state.radioSlowPayerDiscount ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      // width: "250px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "250px",
                      }}
                    >
                      <input
                        type='radio'
                        id='credit_term_add_late_fee_payer_discount_percentage_radio'
                        style={{ height: "10px", width: "15px" }}
                        checked={this.state.late_fee_percent}
                        onClick={() => {
                          this.checkIspercent("is_percent_late_fee");
                        }}
                      />
                      <span>Late Fee Percent (%) </span>
                      <span>Or</span>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "230px",
                      }}
                    >
                      <input
                        type='radio'
                        style={{ height: "10px", width: "15px" }}
                        id='credit_term_add_late_fee_payer_discount_amount_radio'
                        checked={this.state.late_fee_amount}
                        onClick={() => {
                          this.checkIspercent("is_amount_late_amount");
                        }}
                      />
                      <span style={{ widows: "auto" }}>
                        Late Fee Amount ($)
                      </span>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {this.state.radioSlowPayerDiscount &&
                this.state.late_fee_percent ? (
                  <MaskedTextInput
                    style={{
                      color: this.state.radioSlowPayerDiscount
                        ? "black"
                        : "grey",
                      borderBottomColor: this.state.radioSlowPayerDiscount
                        ? "black"
                        : "gray",
                      fontSize: "1em",
                    }}
                    mask={
                      this.state.radioSlowPayerDiscount &&
                      this.state.late_fee_percent
                        ? percentMask
                        : amountMask
                    }
                    name='earlyPayerDiscRatePerc'
                    id='credit_term_add_late_fee_payer_discount_percentage_input'
                    disabled={!this.state.radioSlowPayerDiscount}
                    placeholder='Late Fee Percent (%)'
                    // placeholderChar={"\u2000"}
                    type='text'
                    checked={this.state.late_fee_percent}
                    value={
                      this.validateInputStringCheck(
                        this.state.consumerInformation.lateFeePercent.value
                      )
                        ? this.state.consumerInformation.lateFeePercent.value +
                          "%"
                        : ""
                    }
                    onChange={(event) => {
                      this.handleConsumerInfoInputChange({
                        lateFeePercent: {
                          value: this.validateInputStringConsumerInfoInput(
                            event.target.value
                          ),
                          hasError: false,
                        },
                      });
                    }}
                  />
                ) : this.state.radioSlowPayerDiscount &&
                  this.state.late_fee_amount ? (
                  <MaskedTextInput
                    style={{
                      color: this.state.radioSlowPayerDiscount
                        ? "black"
                        : "grey",
                      borderBottomColor: this.state.radioSlowPayerDiscount
                        ? "black"
                        : "gray",
                      fontSize: "1em",
                    }}
                    mask={
                      this.state.radioSlowPayerDiscount &&
                      this.state.late_fee_amount
                        ? amountMask
                        : percentMask
                    }
                    disabled={!this.state.radioSlowPayerDiscount}
                    id='credit_term_add_late_fee_payer_discount_amount_input'
                    checked={this.state.late_fee_amount}
                    name='earlyPayerDiscRatePerc'
                    placeholder='Late Fee Amount ($)'
                    placeholderChar={"\u2000"}
                    type='text'
                    value={
                      this.validateInputStringCheck(
                        this.state.consumerInformation.lateFeeAmount.value
                      )
                        ? "$" +
                          this.state.consumerInformation.lateFeeAmount.value
                        : ""
                    }
                    onChange={(event) => {
                      this.handleConsumerInfoInputChange({
                        lateFeeAmount: {
                          value: this.validateInputStringConsumerInfoInput(
                            event.target.value
                          ),
                          hasError: false,
                        },
                      });
                    }}
                  />
                ) : (
                  <MaskedTextInput
                    style={{
                      color: this.state.radioSlowPayerDiscount
                        ? "black"
                        : "grey",
                      borderBottomColor: this.state.radioSlowPayerDiscount
                        ? "black"
                        : "gray",
                      fontSize: "1em",
                    }}
                    mask={quantityMask}
                    name='earlyPayerDiscRatePerc'
                    disabled={true}
                    placeholder='Late Fee Percent (%) or Late Fee Amount ($)'
                    value=''
                    // placeholderChar={"\u2000"}
                    type='text'
                    checked={this.state.late_fee_percent}
                  />
                )}
                {this.state.radioSlowPayerDiscount &&
                this.state.consumerInformation.lateFeePercent.hasError &&
                this.state.late_fee_percent ? (
                  <div>
                    <p
                      style={{
                        color: "#fb5c82",
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                        height: "10px",
                      }}
                    >
                      Please enter Slow Payer Penalty percent .
                    </p>
                  </div>
                ) : (
                  ""
                )}
                {this.state.radioSlowPayerDiscount &&
                this.state.consumerInformation.lateFeeAmount.hasError &&
                this.state.late_fee_amount ? (
                  <div>
                    <p
                      style={{
                        color: "#fb5c82",
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                        height: "10px",
                      }}
                    >
                      Please enter Slow Payer Penalty Amount .
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div
            className='bottum-btns'
            style={{ padding: "20px", paddingBottom: "50px" }}
          >
            <div className='button-left'>
              <input
                type='submit'
                value='Go Back'
                id='credit_term_go_back'
                className='mg-l-0'
                style={{
                  borderRadius: "0.5em",
                  width: "200px",
                  height: "50px",
                  marginTop: "20px",
                  border: "2px solid",
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  fontWeight: "500",
                  float: "left",
                  // left: "60px",
                }}
                onClick={() => this.props.handlebacktoStep4("step4")}
              />
              <span className='go-back-text'>
                <p style={this.style.pText}>Go back to</p>
                <p style={this.style.pText}>"Upload Your W9 Form"</p>
              </span>
            </div>
            <div className='button-right'>
              <input
                type='submit'
                value={"Next"}
                id='credit_term_next'
                className='cta mg-brand2-color mg-nextbtn mg-ml-0'
                style={{
                  width: "200px",
                  height: "50px",
                  borderRadius: "0.5em",
                  marginTop: "20px",
                  // left: "100px",
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  fontWeight: "500",
                  padding: "0px",
                  float: "right",
                }}
                onClick={() => {
                  this.handleSubmitmodal();
                }}
              />
              <span className='next-text'>
                <p style={this.style.pText}>Continue to</p>
                <p style={this.style.pText}>"Set Your Account Placement</p>
                <p style={this.style.pText}>Eligibility Preferences"</p>
              </span>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CustomizeWorkFlowStep5;
