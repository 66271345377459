// Packages
import React, { Component } from "react";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { Redirect, withRouter } from "react-router";
import AppCanvasLoader from "../../../../components/App/AppCanvasLoader";
import ClientsLogo from "../../../../components/Consumer/ClientsLogo";
import { getZohoPagesense } from "../../../../utils/helpers";
import ExpressHeader from "./ExpressHeader";

class InvoiceAmountFormStatic extends Component {
  state = {
    orgkey: "",
    invoiceDetails: [],
    invoiceNumber: {
      value: "",
      hasError: false,
    },
    amount: {
      value: "0.00",
      hasError: false,
    },
    isLoading: false,
    showDetails: false,
    redirectToNext: false,
  };

  componentDidMount() {
    this.getOrganizationData();
    getZohoPagesense();
    ReactGA.pageview("/payInvoiceForm");
    localStorage.setItem("type", "PAYMENT_PORTAL_PREVIEW");
  }

  async getOrganizationData() {}

  handleInputBlur = () => {
    let { amount } = this.state;
    if (amount.value) {
      amount.value = parseFloat(amount.value).toFixed(2);
      this.setState({ amount: this.state.amount });
    }
  };

  handleInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      ...newPartialInput,
    }));
  }

  handleSubmit = (event) => {
    // this.props.history.push("/welcome-screen/accounts/000000");
    localStorage.setItem('isFromExpressTour',true);
    this.setState({redirectToNext: true});
  };

  validateForm = () => {
    let errorsArePresent = false;
    const { invoiceNumber, amount } = this.state;
    if (invoiceNumber.value === "" || invoiceNumber.value === null) {
      invoiceNumber.hasError = true;
      errorsArePresent = true;
    }
    if (amount.value === "" || amount.value === null || amount.value < 0) {
      amount.hasError = true;
      errorsArePresent = true;
    }
    if (errorsArePresent) {
      this.setState({
        invoiceNumber: this.state.invoiceNumber,
        amount: this.state.amount,
      });
      return false;
    } else {
      return true;
    }
  };

  renderErrorMessage(input) {
    let message = "";

    if (input === "number") {
      message = "Please enter an invoice number";
    } else if (input === "amount") {
      message = "Please enter a valid amount";
    } else {
      message = "Please complete this field";
    }

    return <div className="input-error-message">{message}</div>;
  }

  render() {
    const { appName, location, stripePubKey } = this.props;
    const { isLoading, invoiceNumber, amount, invoiceDetails, showDetails } =
      this.state;

    if (isLoading) {
      return <AppCanvasLoader />;
    } else {
      return (
        <div className="app-portal">
          <Helmet>
            <title> Payment</title>
          </Helmet>
          <ExpressHeader appName={appName} />
          <div className="app-sidebar-layout-canvas">
            <div className="app-sidebar-layout-canvas-content express-session-fill">
              <ClientsLogo color={"#000"} />
              <form className="express-pay-form">
                <div
                  className={`input-container consumer-input${
                    invoiceNumber.hasError ? " error" : ""
                  }`}
                >
                  <label>Invoice Number</label>
                  <input disabled={true} value={"000000"} />
                </div>
                <div
                  className={`input-container consumer-input amount-input ov-amt-in ${
                    amount.hasError ? " error" : ""
                  }${amount.value ? " amount-input-value" : ""}`}
                  style={{ marginTop: 0 }}
                >
                  <label>Invoice Amount</label>
                  <input disabled={true} value={"$100"} />
                </div>
                <button
                  className="cta mg-brand2-color-static"
                  style={{
                    backgroundColor: "#008bb2",
                    borderWidth: "0.3em",
                    borderColor: "rgb(174 203 23)",
                    opacity: 1,
                    width: "100%",
                    textAlign: "center",
                    fontSize: "20px",
                    fontWeight: 600,
                    fontFamily: "Open Sans",
                  }}
                  onClick={(event) => this.handleSubmit(event)}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
          {this.state.redirectToNext ? <Redirect
              exact
              from="/welcome-screen/payment"
              to={{
                pathname: "/welcome-screen/accounts/000000",
                
              }}
              push
            />

         : null }
        </div>
      );
    }
  }
}

export default withRouter(InvoiceAmountFormStatic);
