import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import account from "../../assets/images/navbar/accounts.png";
import dashboard from "../../assets/images/navbar/dashboard.png";
import invoice from "../../assets/images/navbar/invoice.png";
import history from "../../assets/images/navbar/history.png";
import question from "../../assets/images/navbar/question.png";
import Down_arrow from "../../assets/icons/Down_arrow.svg";
import Side_aerow from "../../assets/icons/Side_arrow.svg";
import report from "../../assets/images/navbar/report.png";
import task from "../../assets/images/navbar/tasks.png";
import ClientFlyoutMenu from "./ClientFlyoutMenu";
import { getBaseUrl } from "../../utils/api";
import * as API from "../../utils/api";
import SignOutIcon from "../../assets/icons/sign_out.svg";
import OnboardIcon from "../../assets/icons/return_to_onboard.svg";
import settings from "../../assets/images/header/settings.png";
import manageUser from "../../assets/images/header/manage-user.png";
import customerBuildingWhite from "../../assets/images/navbar/customer-building-white.png";
import Cookies from "universal-cookie";

class ClientsNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFlyOut: false,
    };
    this.FlyOutOptions = [
      {
        label: "Settings",
        icon: settings,
        path: "/client/settings",
        disable: false,
      },
      {
        label: "Team Members",
        icon: manageUser,
        path: "/client/team",
        disable: false,
      },
      {
        label: "Return to Onboarding",
        icon: OnboardIcon,
        path: "/welcome-screen",
        // disable: false,
      },
      {
        label: "Sign Out",
        icon: SignOutIcon,
        path: "/sign-out",
        // disable: false,
      },
    ];
  }
  static propTypes = {
    path: PropTypes.string.isRequired,
  };

  isActive(linkUrl) {
    if (this.props.path.includes(linkUrl)) {
      return "active";
    }
  }
  

  
  render() {
    let { disableNav, appName, path, user } = this.props;
    return (
      <>
        <nav className="admin-nav client-nav scrollbar">
          {disableNav ? (
            <Link
              className={`admin-nav-link client-nav-link ${this.isActive(
                "/welcome-screen"
              )}`}
              style={{
                pointerEvents:
                  path === "/sso_dashboard" || path === "/sub-domain"
                    ? "none"
                    : "all",
                opacity: disableNav ? "0.7" : "1",
              }}
              to="/welcome-screen"
            >
              <span
                onClick={this.props.handleback}
                style={{
                  height: "52px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <img
                  src={customerBuildingWhite}
                  width="34"
                  height="34"
                  className="icon"
                />
                {appName != null && appName != ""
                  ? appName
                  : "COMPANY SETTINGS"}
              </span>
            </Link>
          ) : (!disableNav && user.client_admin) || user.admin ? (
            <div
              className={`admin-nav-link client-nav-link ${this.isActive(
                "/welcome-screen"
              )}`}
              style={{
                pointerEvents: !user.is_onboarding_completed ? "none" : "all",
                opacity: user.is_onboarding_completed ? "1" : "0.7",
              }}
              // to=""
              onClick={() => {
                this.setState({ isFlyOut: !this.state.isFlyOut });
                // this.props.isFlyOut(!this.state.isFlyOut)
              }}
            >
              <span
                style={{
                  height: "52px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <img
                  src={customerBuildingWhite}
                  width="34"
                  height="34"
                  className="icon"
                />
                {appName != null && appName != ""
                  ? appName
                  : "COMPANY SETTINGS"}
              </span>
              <span className="aerow-position">
                {this.state.isFlyOut ? (
                  <img
                    src={Side_aerow}
                    height="15px"
                    width="15px"
                    className="rotate-aerow"
                  />
                ) : (
                  <img src={Down_arrow} height="15px" width="15px" />
                )}
              </span>
            </div>
          ) : (
            ""
          )}
          <Link
            className={`admin-nav-link client-nav-link ${this.isActive(
              "/client/dashboard"
            )}`}
            style={{
              pointerEvents: disableNav ? "none" : "all",
              opacity: disableNav ? "0.7" : "1",
            }}
            to="/client/dashboard"
            onClick={() => {
              this.setState({ isFlyOut: false });
            }}
          >
            <img src={dashboard} width="34" height="34" className="icon" />
            DASHBOARD
          </Link>
          <Link
            className={`admin-nav-link client-nav-link ${this.isActive(
              "/client/accounts"
            )}`}
            style={{
              pointerEvents: disableNav ? "none" : "all",
              opacity: disableNav ? "0.7" : "1",
            }}
            to="/client/accounts"
            onClick={() => {
              this.setState({ isFlyOut: false });
            }}
          >
            <img src={account} width="34" height="34" className="icon" />
            ACCOUNTS
          </Link>
          <Link
            className={`admin-nav-link client-nav-link ${this.isActive(
              "/client/invoices"
            )}`}
            style={{
              pointerEvents: disableNav ? "none" : "all",
              opacity: disableNav ? "0.7" : "1",
            }}
            to="/client/invoices"
            onClick={() => {
              this.setState({ isFlyOut: false });
            }}
          >
            <img src={invoice} width="34" height="34" className="icon" />
            INVOICES
          </Link>
          <Link
            className={`admin-nav-link client-nav-link ${this.isActive(
              "/client/tasks"
            )}`}
            style={{
              pointerEvents: disableNav ? "none" : "all",
              opacity: disableNav ? "0.7" : "1",
            }}
            to="/client/tasks"
            onClick={() => {
              this.setState({ isFlyOut: false });
            }}
          >
            <img src={task} width="34" height="34" className="icon" />
            TASKS
          </Link>
          <Link
            className={`admin-nav-link client-nav-link ${this.isActive(
              "/client/reports"
            )}`}
            style={{
              pointerEvents: disableNav ? "none" : "all",
              opacity: disableNav ? "0.7" : "1",
            }}
            to="/client/reports"
            onClick={() => {
              this.setState({ isFlyOut: false });
            }}
          >
            <img src={report} width="34" height="34" className="icon" />
            REPORTS
          </Link>
          <Link
            className={`admin-nav-link client-nav-link ${this.isActive(
              "/client/history"
            )}`}
            style={{
              pointerEvents: disableNav ? "none" : "all",
              opacity: disableNav ? "0.7" : "1",
            }}
            to="/client/history"
            onClick={() => {
              this.setState({ isFlyOut: false });
            }}
          >
            <img src={history} width="34" height="34" className="icon" />
            HISTORY
          </Link>
          <Link
            className={`admin-nav-link client-nav-link ${this.isActive(
              "/client/help"
            )}`}
            style={{
              pointerEvents: disableNav ? "none" : "all",
              opacity: disableNav ? "0.7" : "1",
            }}
            to="/client/help"
            onClick={() => {
              this.setState({ isFlyOut: false });
            }}
          >
            <img src={question} width="34" height="34" className="icon" />
            HELP
          </Link>
        </nav>
        {this.state.isFlyOut ? (
          <div style={{}} className="flyout-main-div">
            <ClientFlyoutMenu
              MenuOptions={this.FlyOutOptions}
              path={path}
              handleBack={this.props.handleback}
              disableOptions={disableNav}
              closeFlyout={() => {
                this.setState({ isFlyOut: false });
              }}
            />
          </div>
        ) : null}
      </>
    );
  }
}

export default ClientsNav;
