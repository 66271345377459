import React, { PureComponent } from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
// Components
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import ClientsLogo from "../../components/Consumer/ClientsLogo";
import ConsumerProfileSidebar from "../../components/Consumer/ConsumerProfileSidebar";
import ConsumerProfileForm from "../../components/Consumer/Forms/ConsumerProfileForm";
// Utils
import * as API from "../../utils/api";
// helpers
import { getZohoPagesense } from "../../utils/helpers";

class ConsumerProfile extends PureComponent {
  state = {
    user: {},
    consumerData: {},
    addressDocument: {},
    communicationPreferences: {},
    isLoading: true,
    showFlash: false,
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
  };

  componentDidMount() {
    const { user } = this.props;
    this.getConsumerData();
    API.getUser(user).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.errors) {
        this.setState((state) => ({
          user: data.user,
          addressDocument: data.address_document,
          communicationPreferences: data.communication_preferences,
          isLoading: false,
        }));
      } else {
        this.props.updateModalState(true, "ERROR", { message: data.message });
      }
    });
    getZohoPagesense();
    ReactGA.pageview("/ConsumerProfile");
  }

  getConsumerData = () => {
    API.getConsumerDetails(this.props.user).then((data) => {
      if (
        (typeof data != "undefined" || data != null) &&
        data.data != null &&
        data.status_code == 200
      ) {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          consumerData: data.data,
        }));
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  handleSubmit = (comapnyUpdate) => {
    API.updateCompanyInfo(this.props.user, comapnyUpdate).then((data) => {
      if ((typeof data !== "undefined" || data !== null) && !data.errors) {
        this.props.updateModalState(true, "SUCCESS", {
          message: "Company Information saved successfully.",
        });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
        }));
        this.props.updateModalState(true, "ERROR", { message: data.message });
      }
    });
  };

  handleSideBarSubmit = (userUpdate) => {
    API.updatePersonalInfo(this.props.user, userUpdate).then((data) => {
      if ((typeof data !== "undefined" || data !== null) && !data.errors) {
        this.props.updateModalState(true, "SUCCESS", {
          message: "Your Information saved successfully.",
        });
        this.getConsumerData()
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
        }));
        this.props.updateModalState(true, "ERROR", { message: data.message });
      }
    });
  };

  dismissLoader = () => {
    this.setState((state) => ({
      ...state,
      isLoading: false,
    }));
  };

  handleSubmitCardAction = (stripeToken) => {
    API.createOrUpdateStripeCustomerId(this.props.user, stripeToken).then(
      (data) => {
        if ((typeof data !== "undefined" || data !== null) && data.cardAdded) {
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message,
          });
          API.getUser(this.props.user).then((data) => {
            if (!data.errors) {
              this.setState((state) => ({
                user: data.user,
                addressDocument: data.address_document,
                communicationPreferences: data.communication_preferences,
                isLoading: false,
              }));
            }
          });
        } else {
          this.props.updateModalState(true, "ERROR", { message: data.error });
        }
      }
    );
  };

  createOrUpdateStripeAccount = (stripeToken) => {
    if (this.state.user.last_4 != null) {
      let message =
        "You have already added card. do you want to replace the card?.";
      this.props.updateModalState(true, "CONFIRM_ALERT", {
        message: message,
        methodData: stripeToken,
        confirmClick: this.handleSubmitCardAction,
        closeOnClickOutside: true,
      });
    } else {
      this.handleSubmitCardAction(stripeToken);
    }
  };

  removeCardActionClick = () => {
    API.removeCard(this.props.user).then((data) => {
      if (typeof data !== "undefined" || data !== null) {
        if (data.cardRemoved) {
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message,
          });
          API.getUser(this.props.user).then((data) => {
            if (!data.errors) {
              this.setState((state) => ({
                user: data.user,
                addressDocument: data.address_document,
                communicationPreferences: data.communication_preferences,
                isLoading: false,
              }));
            }
          });
        } else {
          this.setState((state) => ({
            ...state,
            isLoading: false,
          }));
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message,
            title: "Warning!",
          });
        }
      } else {
        this.props.updateModalState(true, "ERROR", { message: data.message });
      }
    });
  };

  render() {
    const { appName, stripePubKey } = this.props;

    const {
      user,
      addressDocument,
      isLoading,
      communicationPreferences,
      consumerData,
      error,
    } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return isLoading ? (
      <AppCanvasLoader />
    ) : (
      <div className="client-scroll-dashboard scrollbar">
        <Helmet>
          <title>{appName} | Profile</title>
        </Helmet>
        <div className="app-sidebar-layout-canvas">
          <ConsumerProfileSidebar
            consumerData={consumerData}
            handleSideBarSubmit={this.handleSideBarSubmit}
          />
          <div className="app-sidebar-layout-canvas-content consumer-right-canvas big-device-grad consumer-profile-up">
            <ClientsLogo />
            <div className="consumer-scroll-profile scrollbar col7">
              <ConsumerProfileForm
                user={user}
                consumerData={consumerData}
                addressDocument={addressDocument}
                communicationPreferences={communicationPreferences}
                handleSubmit={this.handleSubmit}
                stripePubKey={stripePubKey}
                handleFormSubmit={this.createOrUpdateStripeAccount}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConsumerProfile;
