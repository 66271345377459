import moment from "moment";
import React, { Component } from "react";
import BottomScrollListener from "react-bottom-scroll-listener";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";
// Components
import AppCanvasLoader from "../../../components/App/AppCanvasLoader";
import ObjectEmailHistorySubDetails from "../../../components/ObjectList/ObjectEmailHistorySubDetails";
// API
import * as API from "../../../utils/api";
import { getZohoPagesense } from "../../../utils/helpers";
import ClientsCardView from "../../../components/CardView/ClientsCardView";
import HistoryObjectList from "../../../components/ObjectList/ClientsAccounts/HistoryObjectList";

class EmailHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      savedData: [],
      fromChild: "",
      isLoading: true,
      hasMoreData: false,
      showFlash: false,
      search: {
        value: props.searchText || "",
      },
      offset: 0,
      showLoader: false,
      subDetails: {},
      Delivered: 0,
    };
  }

  componentDidMount() {
    this.getHistoryData();
    this.getSubDetails();
    getZohoPagesense();
    ReactGA.pageview("/EmailHistory");
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.searchText !== this.props.searchText) {
      this.setState(
        (state) => ({
          ...state,
          search: {
            value: nextProps.searchText,
          },
          offset: 0,
          savedData: [],
          isLoading: true,
        }),
        () => {
          this.getHistoryData();
        }
      );
    }
  }

  handleContainerOnBottom = (e) => {
    if (this.state.hasMoreData) {
      let offsetCount = parseInt(this.state.offset) + 1;
      this.setState(
        (state) => ({
          ...state,
          offset: offsetCount,
        }),
        () => {
          this.getHistoryData();
        }
      );
    }
  };

  getHistoryData() {
    const { user } = this.props;
    const { offset, search } = this.state;

    this.setState((state) => ({ ...state, showLoader: true }));

    API.getSentMails(user,offset).then((data) => {
      let isAccessDenied =
        data && data.message && data.message == "Access denied." ? true : false;
      if ((typeof data != "undefined" || data != null) && !isAccessDenied) {
        if (data.data) {
          let savedData = this.state.savedData;
          let newData = savedData.concat(data.data);
          this.setState(
            (state) => ({
              savedData: newData,
              isLoading: false,
              hasMoreData: data.data.length < 20 ? false : true,
              showLoader: false,
            }),
            // () => {
            //   console.log(this.state.savedData);
            // }
          );

          this.setState((state) => ({
            ...state,
            filterBy: {
              ...state.filterBy,
              offset: data.offset,
            },
          }));
        } else {
          this.setState((state) => ({
            isLoading: false,
            showLoader: false,
          }));
        }
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
          showLoader: false,
        }));
      }
    });
  }

  getSubDetails() {
    const { user } = this.props;
    API.getSubAccountDetails(user).then((data) => {
      if (typeof data != "undefined" || data != null) {
        var last_30_days = data.data.last_30_days;
        var total =
          last_30_days.sent -
          (last_30_days.hard_bounces +
            last_30_days.complaints +
            last_30_days.rejects +
            last_30_days.soft_bounces +
            last_30_days.unsubs);
        this.setState({ subDetails: last_30_days, Delivered: total });
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  }

  renderLatestPaymentDate = (date) => {
    return moment(date).format("MM/DD/YYYY");
  };

  handleRowActionClick = (mailInfo) => {
    let props = {
      mailInfo: mailInfo.row,
    };
    this.props.updateModalState(true, "SHOW_MAIL_DESC", props);
  };

  getObjectListData = (data) => {
    let headings = [],
      columns = [];
    headings = [
      "Date & Time",
      "Receiver Email",
      "Template Name",
      "Status",
      "Open Count",
      "Click Count",
      "expander",
    ];

    columns = data.map((row) => {
      let statusPillClass = null;
      if (row.status == "sent") {
        statusPillClass = "success-message";
      } else {
        statusPillClass = "fail-message";
      }

      return {
        primaryColumn: {
          key: "Date & Time",
          title: row.date
            ? moment(row.date * 1000).format("MM/DD/YYYY")
            : "n/a",
          secondaryInfo: row.date
            ? moment(row.date * 1000).format("HH:mm:ss")
            : "n/a",
        },
        primaryRow: {
          key: "Date & Time",
          title: row.date
            ? moment(row.date * 1000).format("MM/DD/YYYY")
            : "n/a",
          secondaryInfo: row.date
            ? moment(row.date * 1000).format("HH:mm:ss")
            : "n/a",
        },
        secondaryColumns: [
          {
            key: "Receiver Email",
            title: row.email ? row.email : "n/a",
          },
          {
            key: "Template Name",
            title: row.template ? row.template : "n/a",
          },
          {
            key: "Status",
            title: row.status ? row.status.toUpperCase() : row.status,
            optionalClasses: `${statusPillClass ? statusPillClass : ""}`,
          },
          {
            key: "Open Count",
            title: row.open_count ? row.open_count : "0",
          },
          {
            key:
            "Click Count",
            title: row.click_count ? row.click_count : "0",
          },
          {
            rowType: "arrow-click",
            optionalClasses: "expander",
            icon: "eye",
          },
        ],
        rowType: "view",
        showDetails: true,
        row: row,
      };
    });
    return {
      headings,
      columns,
    };
  };

  render() {
    const { isLoading } = this.state;
    let isSmallDevice = window.innerWidth <= 680 ? true : false;

    return isLoading ? (
      <div style={{ marginTop: `10rem` }}>
        <AppCanvasLoader />
      </div>
    ) : (
      <>
        {this.state.savedData.length > 0 ? (
          <BottomScrollListener
            onBottom={(e)=> this.handleContainerOnBottom(e)}
            debounce={0}
            offset={200}
          >
            {(scrollRef) => (
              <div>
                <div className="mobile-subdetailed">
                  <ObjectEmailHistorySubDetails
                    data={this.state.subDetails}
                    deliver={this.state.Delivered}
                  />
                </div>
                <div>
                  {isSmallDevice ? (
                    <div className="big-device-grad invoice-line">
                      <div ref={scrollRef} className="scrollbar in-sc">
                        <ClientsCardView
                          data={this.getObjectListData(this.state.savedData)}
                          rowActionClick={(event) =>
                            this.handleRowActionClick(event)
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <div style={{ marginBottom: "1em" }}>
                      <HistoryObjectList
                        data={this.getObjectListData(this.state.savedData)}
                        rowActionClick={(event) =>
                          this.handleRowActionClick(event)
                        }
                        hideForMobile={true}
                        optionalClasses="scrollbar in-sc-email-history"
                        optionalTableClasses="client-mail-history"
                        scrollRef={scrollRef}
                        isPrimaryColumn={true}
                      />
                    </div>
                  )}
                  {this.state.showLoader && <AppCanvasLoader />}
                </div>
              </div>
            )}
          </BottomScrollListener>
        ) : (
          <div className="admin-admins-empty-state">
            <h3 className="empty-mesg-mg">No mail history available.</h3>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(EmailHistory);
