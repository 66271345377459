import React, { useEffect, useState } from "react";
import ToggleSwitch from "../../../components/ToggleSwitch";
import AppCanvasLoader from "../../../components/App/AppCanvasLoader";

import {
  getPaymentPreferences,
  updatePaymentPreferences,
  updateOnboardingPercentage,
  getOrgDetails,
} from "../../../utils/api";
import InfoIcon from "../../../assets/icons/info_teal.png";

export default function PaymentPreferencesComponent({
  props,
  backTosyncData,
  handleback,
  handleNext,
  handleAction,
  updateModalState,
}) {
  const [state, setState] = useState([]);
  const [percentage, setPercentage] = useState(0);
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
   
    getPaymentPreferences(props.user).then((data) => {
      setState(data.data);
    });
  }, []);

  let style = {
    pText: {
      fontSize: "14px",
      color: "#2b2828",
      margin: "0px",
    },
  };

  const handleGoBackAction = () => {
    handleAction("SetUp_Payment_Account");
  };

  const handleNextPress = () =>{
    setIsLoader(true);

    getOrgDetails(props.user).then((data) => {
      if (data && data.status_code == 200) {
        let percent = data.data.organization.onboarding_percentage;
        if(percent == 77.77){
          updateOnboardingPercentage(props.user,77.77);
        }
        
        handleNext();
      }
    });
  }

  const toggleButton = (type) => {
    let query = {};
    switch (type) {
      case "PORTAL":
        query = { payment_portal: !state.payment_portal };
        break;
      case "Credit_Card":
        query = { credit_card: !state.credit_card };
        break;
      case "ACH":
        query = { ach: !state.ach };
        break;
      case "GPAY":
        query = { gpay: !state.gpay };
        break;
      case "Apple_Pay":
        query = { apple_pay: !state.apple_pay };
        break;
      case "Checks_Payable_To":
        query = { checks_payable_to: !state.checks_payable_to };
        break;
      case "Payment_Address":
        query = { payment_address: !state.payment_address };
        break;
      case "ACH_Information":
        query = { ach_information: !state.ach_information };
        break;
      case "Wire_Information":
        query = { wire_information: !state.wire_information };
        break;
      default: {
        setState(state);
      }
    }

    updatePaymentPreferences(props.user, query).then((data) => {
      if (data.status_code === 400) {
        if (type === "ACH_Information" || type === "Wire_Information") {
          updateModalState(true, "Payment_Preferences", true);
        } else {
          updateModalState(true, "ERROR", { message: data.message });
        }
      }
      if (data.status_code === 200) {
        setState({ ...state, ...query });
      }
    });
  };

  return (
    <>
      {isLoader ? (
          <div className="loader_alignment">
          <div style={{ marginTop: "20%" }}>
            <AppCanvasLoader />
          </div>
        </div>
      ) : (
        <>
          <div className="tp-sps app-sidebar-layout-canvas-content setting-side-canvas col1-scroll mg-col1-scroll scrollbar">
            <div
              className="app-sidebar-layout-canvas-content setting-side-canvas col1-scroll"
              style={{
                height: "auto",
                width: "100%",
                backgroundColor: "white",
              }}
            >
              <h3
                style={{
                  lineHeight: "0px",
                  fontFamily: "Open Sans ,sans-serif",
                  color: "#000000",
                  fontWeight: "700",
                }}
              >
                Payments Preferences
              </h3>

              <h4
                style={{ color: "#000000", marginTop: "10px" }}
                className="mg-head-bold"
              >
                Configure your clients' payment experience.
              </h4>

              <div
                style={{
                  display: "grid",
                  "grid-template-columns": "20px 1fr",
                  "grid-gap": "18px",
                  marginLeft: "20px",
                }}
              >
                <div
                  style={{
                    color: "#0ab894",
                    fontSize: 18,
                    fontWeight: 600,
                  }}
                >
                  TIP:
                </div>
                <div className="heading-css">
                  All your payment activity can be tracked on the Payment
                  Dashboard, and in Stripe. Access your Payment Preferences by
                  clicking the button in the green vertical sidebar of the
                  Payment Preferences page.
                </div>
              </div>
              <div className="pref-selection">
                <div className="pref-selection-header">Payment Portal</div>
                <div className="pref-selection-sep" />
                <div className="pref-selection-inner-left2">
                  <p className="pref-selection-rdtx">
                    Turning on the Payment Portal allows your customers to make
                    payments through your company branded payment portal.
                  </p>
                  <ToggleSwitch
                    isOn={state.payment_portal}
                    id='setup_payment_account_toggle_1'
                    toggleButton={() => toggleButton("PORTAL")}
                  />
                </div>
              </div>
              <div className="pref-selection">
                <div className="pref-selection-header">Payment Methods</div>
                <div className="pref-selection-sep" />
                <div className="pref-selection-inner">
                  <div className="pref-selection-inner-left2">
                    <p className="pref-selection-rdtx">Credit Cards</p>
                    <ToggleSwitch
                    id='setup_payment_account_toggle_2'
                      isOn={state.credit_card}
                      toggleButton={() => toggleButton("Credit_Card")}
                    />
                  </div>
                </div>
                <div className="pref-selection-inner">
                  <div className="pref-selection-inner-left2">
                    <p className="pref-selection-rdtx">ACH</p>
                    <ToggleSwitch
                    id='setup_payment_account_toggle_3'
                      isOn={state.ach}
                      toggleButton={() => toggleButton("ACH")}
                    />
                  </div>
                </div>
              </div>
              <div className="pref-selection">
                <div className="pref-selection-header">Wallets</div>
                <div className="pref-selection-sep" />
                <div className="pref-selection-inner">
                  <div className="pref-selection-inner-left2">
                    <p className="pref-selection-rdtx">Gpay</p>
                    <ToggleSwitch
                      isOn={state.gpay}
                      id='setup_payment_account_toggle_4'
                      toggleButton={() => toggleButton("GPAY")}
                    />
                  </div>
                </div>
                <div className="pref-selection-inner">
                  <div className="pref-selection-inner-left2">
                    <p className="pref-selection-rdtx">Apple Pay</p>
                    <ToggleSwitch
                      isOn={state.apple_pay}
                      id='setup_payment_account_toggle_5'
                      toggleButton={() => toggleButton("Apple_Pay")}
                    />
                  </div>
                </div>
              </div>
              <div className="pref-selection">
                <div className="pref-selection-header">
                  Display Alternative Payment Information
                </div>
                <div className="pref-selection-sep" />
                <div className="pref-selection-inner">
                  <div className="pref-selection-inner-left2">
                    <p className="pref-selection-rdtx">
                      Make Checks Payable To
                    </p>
                    <ToggleSwitch
                      isOn={state.checks_payable_to}
                      id='setup_payment_account_toggle_6'
                      toggleButton={() => toggleButton("Checks_Payable_To")}
                    />
                  </div>
                </div>
                <div className="pref-selection-inner">
                  <div className="pref-selection-inner-left2">
                    <p className="pref-selection-rdtx">Payment Address</p>
                    <ToggleSwitch
                      isOn={state.payment_address}
                      id='setup_payment_account_toggle_7'
                      toggleButton={() => toggleButton("Payment_Address")}
                    />
                  </div>
                </div>
                <div className="pref-selection-inner">
                  <div className="pref-selection-inner-left2">
                    <p className="pref-selection-rdtx">ACH Information</p>
                    <ToggleSwitch
                      isOn={state.ach_information}
                      id='setup_payment_account_toggle_8'
                      toggleButton={() => toggleButton("ACH_Information")}
                    />
                  </div>
                </div>
                <div className="pref-selection-inner">
                  <div className="pref-selection-inner-left2">
                    <p className="pref-selection-rdtx">Wire Information</p>
                    <ToggleSwitch
                      isOn={state.wire_information}
                      id='setup_payment_account_toggle_9'
                      toggleButton={() => toggleButton("Wire_Information")}
                    />
                  </div>
                </div>
              </div>
              <div className="pref-selection">
                <div className="pref-selection-header">Login Methods</div>
                <div className="pref-selection-sep" />
                <div className="pref-selection-inner">
                  <div className="pref-selection-inner-left2">
                    <p className="pref-selection-rdtx">
                      Guest Pay{" "}
                      <img
                        id="Info-icon1"
                        src={InfoIcon}
                        title={
                          "TIP: Guest Pay allows your customers to pay without logging in. All they need is the Invoice Number and the Invoice Amount."
                        }
                      />{" "}
                    </p>
                    <ToggleSwitch isOn={true} id='setup_payment_account_toggle_10' />
                  </div>
                </div>
                <div className="pref-selection-inner">
                  <div className="pref-selection-inner-left2">
                    <p className="pref-selection-rdtx">
                      Full Payment Portal Access
                      <img
                        id="Info-icon1"
                        src={InfoIcon}
                        title={
                          "TIP: Full Payment Portal Access allows your customers to see their full billing history, update billing information, make payments, and reach out to you for billing inquiries."
                        }
                      />
                    </p>
                    <div>
                      <ToggleSwitch isOn={true}  id='setup_payment_account_toggle_11' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottum-btns" style={{ padding: "20px" }}>
              <div className="button-left">
                <input
                  type="submit"
                  value="Go Back"
                  id='setup_payment_account_go_back'
                  style={{
                    borderRadius: "10px",
                    width: "200px",
                    height: "50px",
                    marginTop: "17px",
                    border: "2px solid",
                  }}
                  onClick={() => {
                    handleGoBackAction();
                  }}
                />
                <span className="go-back-text">
                  <p style={style.pText}>Go back to</p>
                  <p style={style.pText}>"Set Up Your Payment Account"</p>
                </span>
              </div>
              <div className="button-right">
                <input
                  type="submit"
                  value="Next"
                  id='setup_payment_account_next'
                  onClick={() => {
                   handleNextPress()
                  }}
                  className="cta mg-brand2-color emp-add-btn-mob  mg-ml-0"
                  style={{
                    borderRadius: "10px",
                    width: "200px",
                    height: "50px",
                    marginTop: "17px",
                  }}
                />
                <span className="next-text">
                  <p style={style.pText}>Continue to</p>
                  <p style={style.pText}>"Payment Portal Preview"</p>
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
