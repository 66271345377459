import moment from "moment";
import React, { Component } from "react";
import BottomScrollListener from "react-bottom-scroll-listener";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { Redirect, withRouter } from "react-router-dom";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import AppSectionHeader from "../../components/App/AppSectionHeader";
import ClientsCardView from "../../components/CardView/ClientsCardView";
import FlashMessage from "../../components/FlashMessage";
import InvoiceObjectList from "../../components/ObjectList/ClientsAccounts/InvoiceObjectList";
import ToggleSwitch from "../../components/ToggleSwitch";
// API
import * as API from "../../utils/api";
import { getZohoPagesense, showToast } from "../../utils/helpers";
import Emitter from "../../utils/event-emitter";
import InviteNewTeamObjectList from "../../components/ObjectList/ClientsAccounts/InviteNewTeamObjectList";

class ClientsEmployees extends Component {
  constructor() {
    super();
    this.onCheckboxClick = this.onCheckboxClick.bind(this);
    this.headerCheckBoxClick = this.headerCheckBoxClick.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.state = {
      consumers: [],
      invoiceData: [],
      fromChild: "",
      isLoading: true,
      isUploading: false,
      showFlash: false,
      totalPendingOffers: 0,
      error: {
        hasAnyError: false,
        statusCode: 200,
      },
      input: {
        search: {
          value: "",
        },
      },
      isAllSelected: false,
      hasMoreData: false,
      offset: 0,
      searchText: "",
      showLoader: false,
    };
  }

  componentDidMount() {
    this.fetchEmployees(true);
    getZohoPagesense();
    ReactGA.pageview("/ClientsAccounts");
  }
  formatData = () => {
    if (this.state.invoiceData && this.state.invoiceData.length) {
      this.state.invoiceData.map((dataItem, index) => {
        dataItem.delivery_method =
          dataItem.delivery_method === "Email"
            ? "Email Only"
            : dataItem.delivery_method === "Letter"
            ? "Letter Only"
            : "Email & Letter";
        if (!dataItem.state) {
          dataItem.is_all_client = true;
          dataItem.state = false;
          dataItem.auto_send = false;
          dataItem.auto_send_on_import = false;
          dataItem.internal = false;
          dataItem.delivery_method = "Email & Letter";
        }
      });
      this.setState({ invoiceData: this.state.invoiceData });
    }
  };
  fetchEmployees = (reload) => {
    this.setState((state) => ({ ...state, showLoader: true }));
    API.fetchAllEmployees(
      this.props.user,
      this.state.offset,
      this.state.searchText
    ).then((data) => {
      let isAccessDenied =
        data && data.status && data.status_code === 401 ? true : false;
      Emitter.emit("IS_QUICKBOOK_CONNECTED", data.qb_connected);
      if (
        (typeof data != "undefined" || data != null) &&
        !data.error &&
        !isAccessDenied
      ) {
        let employees = reload ? this.state.consumers : false;
        let newData = employees
          ? employees.concat(data.employees)
          : data.employees;
        this.setState((state) => ({
          ...state,
          consumers: newData,
          defaultConsumers: newData,
          isLoading: false,
          isUploading: false,
          hasMoreData: data.employees.length < 10 ? false : true,
          showLoader: false,
        }));
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
          showLoader: false,
        }));
      }
    });
  };

  handelUpdateInternalContact = (emp_id, isIntenalContact) => {
    let payload = {
      employee_id: emp_id,
      is_internal_contact: !isIntenalContact,
    };
    API.updateInternalContact(this.props.user, payload).then((data) => {
      let isAccessDenied =
        data && data.status && data.status_code === 401 ? true : false;
      // Emitter.emit("IS_QUICKBOOK_CONNECTED", data.qb_connected);
      if (
        (typeof data != "undefined" || data != null) &&
        !data.error &&
        !isAccessDenied
      ) {
        this.setState(
          {
            isLoading: false,
            isUploading: false,
            showLoader: false,
            offset: 0,
          },
          () => {
            this.fetchEmployees(false);
          }
        );
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
          showLoader: false,
        }));
      }
    });
  };

  handelUpdateQuantityOnHand = (emp_id, isEditGoodQuanity) => {
    let payload = {
      employee_id: emp_id,
      is_edit_good_quantity: !isEditGoodQuanity,
    };
    API.updateGoodQuantityPermission(this.props.user, payload).then((data) => {
      let isAccessDenied =
        data && data.status && data.status_code === 401 ? true : false;
      // Emitter.emit("IS_QUICKBOOK_CONNECTED", data.qb_connected);
      if (
        (typeof data != "undefined" || data != null) &&
        !data.error &&
        !isAccessDenied
      ) {
        this.setState(
          {
            isLoading: false,
            isUploading: false,
            showLoader: false,
            offset: 0,
          },
          () => {
            this.fetchEmployees(false);
          }
        );
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
          showLoader: false,
        }));
      }
    });
  };

  handleToggle = (id, flag, index) => {
    const { consumers } = this.state;
    consumers[index].is_internal_contact =
      !consumers[index].is_internal_contact;
    this.setState({ consumers }, () => {
      this.handelUpdateInternalContact(id, flag);
    });
  };

  handleToggleForQuantity = (id, flag, index) => {
    const { consumers } = this.state;
    consumers[index].is_edit_good_quantity =
      !consumers[index].is_edit_good_quantity;
    this.setState({ consumers }, () => {
      this.handelUpdateQuantityOnHand(id, flag);
    });
  };

  handleContainerOnBottom = () => {
    if (this.state.hasMoreData) {
      let offsetCount = parseInt(this.state.offset) + 1;
      this.setState(
        (state) => ({
          ...state,
          offset: offsetCount,
          showLoader: true,
        }),
        () => {
          this.fetchEmployees(true);
        }
      );
    }
  };

  handleKeyPress(event) {
    if (event.key === "Enter") {
      this.handleSearch();
      this.setState(
        (state) => ({
          ...state,
          offset: 0,
        }),
        () => {
          this.handleSearch();
        }
      );
    }
  }

  handleInputChange(newPartialInput) {
    this.setState(
      (state) => ({
        ...state,
        input: {
          ...newPartialInput,
        },
      }),
      () => {
        if (newPartialInput.search.value.length === 0) {
          this.handleKeyPress({ key: "Enter" });
        }
      }
    );
  }

  setConsumersData = (dataSet = []) => {
    this.setState((state) => ({
      ...state,
      consumers: dataSet,
    }));
  };

  handleSearch = () => {
    let queryString = this.state.input.search.value;
    if (queryString) {
      this.state.searchText = queryString;
      API.fetchAllEmployees(
        this.props.user,
        this.state.offset,
        this.state.searchText
      ).then((data) => {
        if ((typeof data != "undefined" || data != null) && !data.error) {
          if (!data) {
            this.setConsumersData();
          } else {
            this.setConsumersData(data.employees);
          }
        } else {
          this.setState((state) => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.message && data.message == "Access denied."
                    ? 401
                    : 500) ||
                  500
                : 500,
            },
          }));
        }
      });
    } else {
      this.setState({ searchText: "" });
      this.releoadTableData();
    }
  };

  handleAddAccountClick = (event) => {
    let props = {
      releoadTableData: this.releoadTableData,
    };
    this.props.updateModalState(true, "ADD_ORGANIZATION_CLIENT_ACCOUNT", props);
  };

  handleAddAdminAccountClick = (event, rowdata) => {
    event.preventDefault();
    this.setState((state) => ({
      ...state,
      offset: 0,
    }));
    let items;
    if (this.state.invoiceData) {
      this.state.invoiceData.map((item) => {
        items = item;
      });
    }
    let props = {
      reloadData: this.releoadTableData,
      props: rowdata,
      user: this.props.user,
      invoiceData: this.state.invoiceData,
      item: items,
    };
    this.state.searchText = "";
    this.props.updateModalState(true, "ADD_TEAM_MEMBERS_ACCOUNT", props);
  };

  handleAddCreditorClick = (event) => {
    event.preventDefault();
    this.props.updateModalState(true, "Add_NEW_CREDITOR", this.props);
  };

  handleUploadClick = () => {
    this.refs.fileUploader.click();
  };

  uploadFile = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    let formData = new FormData();
    formData.append("file", file);

    this.setState(
      (state) => ({
        ...state,
        isUploading: true,
      }),
      () => {
        API.uploadClientCSV(this.props.user, formData).then((data) => {
          if ((typeof data != "undefined" || data != null) && !data.error) {
            if (data.message) {
              this.setState((state) => ({
                ...state,
                showFlash: true,
                flashMessage: data.message,
              }));
            }
            this.fetchEmployees(false);
          } else {
            this.setState((state) => ({
              ...state,
              error: {
                hasAnyError: true,
                statusCode: data
                  ? data.status ||
                    (data.message && data.message == "Access denied."
                      ? 401
                      : 500) ||
                    500
                  : 500,
              },
            }));
          }
        });
      }
    );
  };

  resetPassword = (rowdata) => {
    API.resetPasswords(this.props.user, rowdata).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        this.props.updateModalState(true, "SUCCESS", {
          message: data.message
            ? data.message
            : "password email has been send successfully.",
        });
      } else if (data.status_code !== 200) {
        this.props.updateModalState(true, "ERROR", {
          message: data.message ? data.message : "something went wrong.",
        });
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
          showLoader: false,
        }));
      }
    });
  };
  editUser = (rowdata) => {
    let items;
    if (this.state.invoiceData) {
      this.state.invoiceData.map((item) => {
        items = item;
      });
    }
    API.editUser(this.props.user, rowdata).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        let props = {
          data: data,
          item: items,
          reloadData: this.releoadTableData,
        };
        this.props.updateModalState(true, "ADD_TEAM_MEMBERS_ACCOUNT", props);
      }
    });
  };
  resendInvite = (rowdata) => {
    API.invitation(this.props.user, rowdata).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        this.props.updateModalState(true, "SUCCESS", {
          message: data.message
            ? data.message
            : "Team Invitation sent successfully.",
        });
        this.releoadTableData();
      } else if (data.status_code !== 200) {
        this.props.updateModalState(true, "ERROR", {
          message: data.message ? data.message : "something went wrong.",
        });
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
          showLoader: false,
        }));
      }
    });
  };
  onCheckboxClick(event) {
    const consumerId = event.target.name;

    this.state.consumers.map((consumer) => {
      if (consumer.id == consumerId) {
        if (event.target.checked) {
          consumer.isSelected = true;
        } else {
          consumer.isSelected = false;
          this.state.isAllSelected = false;
        }
      }
    });

    this.setState({
      consumers: this.state.consumers,
      isAllSelected: this.state.isAllSelected,
    });
  }

  headerCheckBoxClick(event) {
    if (event.target.checked) {
      this.state.consumers.map((consumer) => {
        consumer.isSelected = true;
        this.state.isAllSelected = true;
      });
    } else {
      this.state.consumers.map((consumer) => {
        consumer.isSelected = false;
        this.state.isAllSelected = false;
      });
    }

    this.setState({
      consumers: this.state.consumers,
      isAllSelected: this.state.isAllSelected,
    });
  }

  renderAssignConsumerModal(consumerId) {
    let props = {
      consumerId: consumerId,
      assign: "consumer",
      releoadTableData: this.releoadTableData,
    };
    this.props.updateModalState(true, "ASSIGN_EMPLOYEE", props);
  }

  releoadTableData = () => {
    this.setState({ offset: 0 });
    this.fetchEmployees(false);
  };
  disableUser = (rowdata) => {
    API.userDisable(this.props.user, rowdata).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        this.props.updateModalState(true, "SUCCESS", {
          message: data.message
            ? data.message
            : "password email has been send successfully.",
        });
        // this.fetchEmployees(false)
        this.releoadTableData();
      } else if (data.status_code !== 200) {
        this.props.updateModalState(true, "ERROR", {
          message: data.message ? data.message : "something went wrong.",
        });
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
          showLoader: false,
        }));
      }
    });
  };
  handleRowActionClick = (event, rowdata) => {
    this.setState({ currentItem: rowdata });
    let props = {
      consumerId: rowdata,
      assign: "consumer",
      releoadTableData: this.releoadTableData,
      user: this.props.user,
      invoiceData: this.state.invoiceData,
    };
    let { history } = this.props;
    if (event.title === "Edit User") {
      this.editUser(rowdata);
    } else if (event.title === "Send/Re-send Invite") {
      this.resendInvite(rowdata);
    } else if (event.title === "Reset Password") {
      this.resetPassword(rowdata);
    } else if (event.title === "Disable User") {
      this.disableUser(rowdata);
    }
  };

  dismissFlash = () => {
    this.setState((state) => ({
      ...state,
      showFlash: false,
    }));
  };

  getSectionHeaderUtilities() {
    const { input } = this.state;
    let app_api_url = process.env.REACT_APP_API_URL;

    return (
      <div className='app-section-header-utilities emp_mob_ut'>
        <div className='admin-search-input-container emp-mob-ser'>
          <input
            type='text'
            name='search'
            placeholder='Search'
            value={input.search.value}
            className='admin-search-input'
            onKeyDown={this.handleKeyPress}
            onChange={(event) =>
              this.handleInputChange({ search: { value: event.target.value } })
            }
          />
          <span
            className='search-icon'
            onClick={() => this.handleKeyPress({ key: "Enter" })}
          />
        </div>
        <input
          type='submit'
          value='+ Add Team Member'
          className='cta mg-brand2-color emp-add-btn-mob'
          id='formSubmitId'
          to='/admin/accounts/add'
          onClick={(event) => this.handleAddAdminAccountClick(event)}
          style={{ borderRadius: "10px" }}
        />
      </div>
    );
  }

  getAccountsObjectListData = (data) => {
    let headings = [],
      columns = [];
    headings = [
      "Name",
      "Email",
      "Role",
      "Status",
      "Internal Contact",
      "expander",
    ];
    columns = data.map((row, index) => {
      let active = "Last Active";
      let activeDate = moment(row.user_status).utc().format("MM-DD-YYYY");
      return {
        emptyPrimary: true,
        secondaryColumns: [
          {
            key: "Name",
            title: row.first_name + " " + row.last_name,
          },
          {
            key: "Email",
            title: row.email ? row.email : "n/a",
          },
          {
            key: "Role",
            title:
              row.openid_id != null
                ? "Owner"
                : !row.is_employee && row.client_admin
                ? "Admin"
                : "User",
          },
          {
            key: "Status",
            title:
              row.user_status == "Active" ? (
                row.user_status
              ) : row.user_status == "Disabled" ? (
                row.user_status
              ) : row.user_status == "Invite Sent" ? (
                row.user_status
              ) : (
                <div>
                  <div>Active</div>
                  <div>
                    {active}: {activeDate}
                  </div>
                </div>
              ),
          },
          {
            key: "Internal Contact",
            title: (
              <div className={`input-container`} style={{ margin: "auto" }}>
                <ToggleSwitch
                  alignLeft={true}
                  isOn={row.is_internal_contact}
                  toggleButton={(event) => {
                    this.handleToggle(row.id, row.is_internal_contact, index);
                  }} //TO_DO
                />
              </div>
            ),
          },
          {
            rowType: "arrow-click",
            optionalClasses: "expander",
          },
        ],
        rowType: "overflowMenu",
        rowActions: [
          {
            title: "Edit User",
          },
          { title: "Send/Re-send Invite" },
          {
            title: "Reset Password",
          },
        ],
        rowData: row,
      };
    });

    return {
      headings,
      columns,
    };
  };

  renderLatestPaymentDate = (date) => {
    return moment(date).format("MM/DD/YYYY");
  };

  render() {
    const { appName } = this.props;
    const { consumers, isLoading, isUploading, error } = this.state;
    let isSmallDevice = window.innerWidth <= 680 ? true : false;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return isLoading || isUploading ? (
      <AppCanvasLoader />
    ) : (
      <div>
        <Helmet>{/* <title>{appName} | Employees </title> */}</Helmet>
        {this.state.showFlash ? (
          <FlashMessage
            message={this.state.flashMessage}
            handleFlashDismiss={this.dismissFlash}
          />
        ) : null}
        <AppSectionHeader
          title='Team Management'
          utilities={this.getSectionHeaderUtilities()}
          optionalClassNames='emp-account-utility'
          titleClasses='marginTitle'
        />
        {this.state.consumers ? (
          this.state.consumers.length ? (
            <div>
              <BottomScrollListener
                offset={50}
                onBottom={this.handleContainerOnBottom}
                debounce={0}
              >
                {(scrollRef) => (
                  <div>
                    {isSmallDevice ? (
                      <div
                        ref={scrollRef}
                        className='big-device-grad scrollbar ac-sc emp-scr'
                      >
                        <ClientsCardView
                          data={this.getAccountsObjectListData(consumers)}
                          expanderLabel={"Action"}
                          rowActionClick={(event) =>
                            this.handleRowActionClick(event)
                          }
                        />
                      </div>
                    ) : (
                      <InviteNewTeamObjectList
                        data={this.getAccountsObjectListData(consumers)}
                        hideForMobile={true}
                        isClientsEmployees={true}
                        // utilities={this.getSectionHeaderUtilities()}
                        optionalClasses='scrollbar in-sc'
                        scrollRef={scrollRef}
                        rowActionClick={(event, rowdata, data) =>
                          this.handleRowActionClick(event, rowdata, data)
                        }
                        handleAddAdminAccountClick={
                          this.handleAddAdminAccountClick
                        }
                      />
                    )}
                    {this.state.showLoader && (
                      <div>
                        <AppCanvasLoader />
                      </div>
                    )}
                  </div>
                )}
              </BottomScrollListener>
            </div>
          ) : this.state.input.search.value.length ? (
            <div className='admin-admins-empty-state'>
              <p>No results found for:</p>
              <h3 className='admin-admins-empty-state-heading empty-mesg-mg'>
                {this.state.input.search.value}
              </h3>
            </div>
          ) : (
            <div className='admin-admins-empty-state'>
              <AppCanvasLoader />
            </div>
          )
        ) : (
          <div className='admin-admins-empty-state'>
            <h3 className='admin-admins-empty-state-heading empty-mesg-mg'>
              No Employee Accounts
            </h3>
            <p>
              There are currently no employee accounts. Select Add Employee to
              create an Employee account.
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(ClientsEmployees);
