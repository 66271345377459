import React, { Component } from "react";
import "react-select/dist/react-select.css";
import FileBase64 from "react-file-base64";
import FileBase64Component from "../../../components/App/AppInputToBase64";
import moment from "moment";
import * as API from "../../../utils/api";
import Cookies from "universal-cookie";
import { getBaseUrl } from "../../../utils/api";
class CustomizeWorkFlowStep4 extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    input: {
      fileSizeInMB: "",
      fileExtension: "",
      document: "",
    },
    hasError: false,
    isPdf: true,
    isPdfSelected: false,
    isUploadedPdf: false,
  };

  style = {
    pText: {
      fontSize: "14px",
      color: "#2b2828",
      margin: "0px",
    },
  };

  componentDidMount() {
    this.getOrganizationalDetails();
  }

  checkDocument = (input) => {
    if (input.document.length > 0) {
      return input.document[0].document_name.slice(0, 30);
    }
  };
  getOrganizationalDetails = async () => {
    const cookies = new Cookies();
    let authuser = cookies.get("accessToken", { path: "/", secure: true });
    let user = {
      user_auth_token: authuser,
    };
    // await API.getOrgDetails(user).then((data) => {
    // if ((typeof data != "undefined" || data != null) && data.data) {
    if (this.props.orgData.organization.w9_form !== null) {
      this.setState({ isUploadedPdf: true });
    }
  };

  handleSubmit = () => {
    let { input, hasError, isPdf } = this.state;
    if (!hasError && isPdf) {
      if (input.document[0] != undefined) {
        let payload = {
          org_url: getBaseUrl(),
          file_size_in_mb: input.document[0].file_size_in_mb,
          w9_form: input.document[0].document,
        };
        API.updateW9Form(this.props.user, payload).then((data) => {
          if ((typeof data != "undefined" || data != null) && !data.error) {
            if (data.status_code == 200) {
              if (data.modify_count != 0) {
                this.props.updateModalState(true, "SUCCESS", {
                  message: data.message,
                });
              }
            } else if (data.status_code !== 200) {
              this.setState((state) => ({
                ...state,
                hasAnyError: true,
              }));
              this.props.updateModalState(true, "ERROR", {
                message: data.message,
              });
            }
          }
        });
      }
      this.props.handleNext("step5");
    }
  };
  getFiles(files) {
    let newInputState = { ...this.state.input };
    let imgExtension = files[0].type;
    let fileName = moment(new Date()).format("MMDDYYYY");
    let extensionFlag = false;
    if (!imgExtension) {
      imgExtension = files[0].name.split(".").pop();
    }
    let extensionFromName = files[0].name.split(".").pop();
    if (extensionFromName && extensionFromName.toLowerCase() === "pdf") {
      extensionFlag = true;
      this.setState({
        isPdf: true,
      });
    } else {
      extensionFlag = false;
      this.setState({
        isPdf: false,
      });
    }
    if (files[0].file && files[0].file.size > 10000000) {
      this.state.hasError = true;
    } else if (files[0].file && files[0].file.size < 10000000) {
      this.state.hasError = false;
    } else {
      let file_size =
        files[0].file && files[0].file.size
          ? files[0].file.size / (1024 * 1024)
          : 0;
      this.state.input.fileSizeInMB = parseFloat(file_size).toFixed(2);
    }
    if (imgExtension.toLowerCase() === "image/pdf") {
      this.state.input.fileExtension = `${
        files[0].name ? files[0].name : fileName + ".png"
      }`;
    }
    if (extensionFlag) {
      let documents = [];
      if (files) {
        files.map((item) => {
          documents.push({
            document: item.base64,
            document_name: item.file.name,
            file_size_in_mb: parseFloat(item.file.size / (1024 * 1024)),
          });
        });
      }
      this.state.input.document = documents;
      this.setState({ input: this.state.input });
    } else {
      this.setState((state) => ({
        ...state,
        input: newInputState,
      }));
    }
    this.setState({ input: this.state.input, isPdfSelected: true });
  }
  render() {
    return (
      <>
        <div className='app-sidebar-layout-canvas-content setting-side-canvas col1-scroll mg-col1-scroll scrollbar'>
          <div className='step3-app-sidebar-main'>
            <div className='step-4-main-content'>
              <div className='W9Form'>
                <h3
                  style={{
                    color: "#000000",
                    // marginLeft: "145px",
                    // marginBottom: "5px",
                    width: "100%",
                  }}
                  className='mg-steps-header '
                >
                  Upload Your W9 Form (Optional)
                </h3>
                <p className=''>
                  Upload your company’s W-9 form here. You will be able to
                  automatically send your company’s W-9 form to new clients,
                  which will help avoid payment delays. If you don’t have your
                  company’s W-9 on hand then skip this step.
                </p>
                <div className={`input-container error`} style={{}}>
                  <div className='admin-form-row address-document mg-aic'>
                    <FileBase64Component
                      multiple={true}
                      onDone={this.getFiles.bind(this)}
                      acceptDoc='.pdf'
                      buttonText='Upload W9'
                      id='w9_form_upload_file'
                      isCustomBtn={true}
                      optionalClass='mg-ml-0'
                    />
                  </div>
                  {!this.state.isPdfSelected && !this.state.isUploadedPdf ? (
                    <div className=' input-error-message w9uploade '>
                      <p className='subtext_p1' style={{ color: "darkgrey" }}>
                        {" "}
                        No file selected
                      </p>
                    </div>
                  ) : this.state.isUploadedPdf && !this.state.isPdfSelected ? (
                    <div className=' input-error-message w9uploade '>
                      <p className='subtext_p1'> W9_form.pdf Uploaded</p>
                    </div>
                  ) : (
                    <div className=' input-error-message w9uploade '>
                      <p className='subtext_p1'>
                        {" "}
                        {this.checkDocument(this.state.input)}
                      </p>
                    </div>
                  )}
                  {!this.state.isPdf ? (
                    <div className=' input-error-message w9uploade mg-all-content-center'>
                      Please select only .pdf file
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.hasError ? (
                    <div
                      className=' input-error-message w9uploade mg-all-content-center'
                      style={{ marginTop: "10px" }}
                    >
                      File size must be less than 10MB
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    className=' input-error-message w9uploade mg-all-content-center'
                    style={{
                      color: "black",
                      fontFamily: "unset",
                      marginTop: "10px",
                    }}
                  >
                    File upload format (.pdf) <br /> <br /> Size must be less
                    than 10 MB
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='bottum-btns-main-div-step4'>
            <div className='button-left'>
              <input
                className='btns-font'
                id='w9_form_go_back'
                type='submit'
                value='Go Back'
                style={{
                  borderRadius: "0.5em",
                  width: "200px",
                  height: "50px",
                  marginTop: "20px",
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  fontWeight: "500",
                  border: "2px solid",
                  float: "left",
                }}
                onClick={() => this.props.handleBack("step3")}
              />
              <span className='go-back-text'>
                <p style={this.style.pText}>Go back to</p>
                <p style={this.style.pText}>"Select Your Payment Portal"</p>
              </span>
            </div>
            <div className='button-right'>
              <input
                type='submit'
                id='w9_form_next'
                value={"Next"}
                className='mg-brand2-color  mg-ml-0'
                style={{
                  width: "200px",
                  height: "50px",
                  borderRadius: "0.5em",
                  marginTop: "20px",
                  color: "white",
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  fontWeight: "500",
                  float: "right",
                }}
                onClick={() => this.handleSubmit()}
              />
              <span className='next-text'>
                <p style={this.style.pText}>Continue to</p>
                <p style={this.style.pText}>
                  "Create Your Default Credit Terms"
                </p>
              </span>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CustomizeWorkFlowStep4;
