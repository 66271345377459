import { loadStripe } from "@stripe/stripe-js";
import currencyFormatter from "currency-formatter";
import moment from "moment";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import shortid from "shortid";
import downloadImg from "../../assets/icons/download.png";
import viewIcon from "../../assets/icons/view.png";
import CardView from "../../components/CardView/CardView";
import ClientsLogo from "../../components/Consumer/ClientsLogo";
import ConsumerSidebar from "../../components/Consumer/ConsumerSidebar";
import InvoiceAndLineDetails from "../../components/ObjectList/ClientsAccounts/InvoiceAndLineDetails";
import InvoiceObjectList from "../../components/ObjectList/ClientsAccounts/InvoiceObjectList";
import * as API from "../../utils/api";
import ExpressHeader from "./ExpressHeaderDynamic";
import ExpressPayment from "./ExpressPayment";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";

const styles = {
  loader: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    backgroundColor: "#ffffff",
    opacity: 0.95,
  },
};
class InvoiceDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyData: {},
      appThemeData: {},
      loading: false,
      invoiceDetails: props.invoiceDetails,
      stripeSessionId: "",
      expandDoc: false,
      makePayment: false,
      logo_url: "",
      textColor: "",
      amount: {
        value: "",
        hasError: false,
      },
      error: {
        hasAnyError: false,
        statusCode: 200,
      },
      isCC: false,
      isACH: false,
      contact_email: "",
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.invoiceDetails != nextProps.invoiceDetails) {
      return {
        invoiceDetails: nextProps.invoiceDetails,
      };
    }
    return null;
  }

  setloaderoff() {
    this.setState({ loading: false });
  }
  delay = (delayInms) => {
    return new Promise((resolve) => setTimeout(resolve, delayInms));
  };
  getLogoColorsForPayment = async () => {
    this.setState({ loading: true });
    API.getLogoColorsForPayment()
      .then((response) => {
        this.setState({
          ...this.state,
          logo_url: response.data.logo_url,
          textColor: response.data.primary_color,
        });
      })
      .catch(() => {});
    await this.delay(1000);
    this.setState({ loading: false });
  };

  componentDidMount() {
    this.getLogoColorsForPayment();
    this.getOrganizationData();
  }

  getOrganizationData() {
    API.getOrganizationData().then((data) => {
      this.setState(
        {
          companyData: data,
        },
        function () {
          this.getExpressPaymentDetails();
        }
      );
    });
  }

  getExpressPaymentDetails = () => {
    this.setState({ loading: true });
    API.getExpressPaymentDetails(
      this.props.invoiceDetails.id,
      this.state.companyData.organization_key
    ).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        this.setState({
          ...this.state,
          loading: false,
          isCC: data.data.payment_preferences.credit_card,
          isACH: data.data.payment_preferences.ach,
          contact_email: data.data.billing_contact_email,
        });
      } else {
        this.props.updateModalState(true, "ERROR", { message: data.message });
        this.setState({ loading: false });
      }
    });
  };

  handleClick = async (stripeSessionId) => {
    // Get Stripe.js instance
    const stripePromise = loadStripe(this.props.stripePubKey);
    const stripe = await stripePromise;

    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: stripeSessionId,
    });

    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
  };

  checkInvoicePastDue = (date) => {
    return moment(date).isBefore(new Date());
  };

  getAccountType = () => {
    const { invoiceDetails } = this.state;
    let flag = this.checkInvoicePastDue(invoiceDetails.due_date);
    if (flag) {
      return "past due";
    } else {
      return "current";
    }
  };

  renderErrorMessage(input) {
    let message = "";
    if (input === "amount") {
      message = "Please enter a valid amount";
    } else {
      message = "Please complete this field";
    }
    return <div className='input-error-message'>{message}</div>;
  }

  validateForm = () => {
    let errorsArePresent = false;
    const { amount } = this.state;
    if (amount.value === "" || amount.value === null || amount.value < 0) {
      amount.hasError = true;
      errorsArePresent = true;
    }
    if (errorsArePresent) {
      this.setState({
        amount: this.state.amount,
      });
      return false;
    } else {
      return true;
    }
  };

  handleInputBlur = () => {
    let { amount } = this.state;
    if (amount.value) {
      amount.value = parseFloat(amount.value).toFixed(2);
      this.setState({ amount: this.state.amount });
    }
  };

  handleInputChange(newPartialInput) {
    if (!newPartialInput.amount.value) {
      this.setState((state) => ({
        ...state,
        ...newPartialInput,
      }));
    } else {
      var number = newPartialInput.amount.value.split(".");
      if (number && number.length > 1) {
        if (number[1].length > 2) {
          newPartialInput.amount.value = parseFloat(
            newPartialInput.amount.value
          ).toFixed(2);
        }
      }
      if (
        parseInt(newPartialInput.amount.value) <=
        parseInt(this.state.invoiceDetails.balance)
      ) {
        this.setState((state) => ({
          ...state,
          ...newPartialInput,
        }));
      }
    }
  }

  viewValidationDoc = (row) => {
    let props = {
      isOpenApi: true,
      type: row.name,
      arrange_pdf: true,
      doc_name: row.name,
      doc_id: row.document_id,
      invoiceId: row.invoice_id,
    };
    this.props.updateModalState(true, "SHOW_IMAGE", props);
  };

  downloadAttachedDocuement = (doc_id, fileName) => {
    API.getExpressAttachedDocuement(doc_id).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data) {
          let flag = data.data.includes("base64");
          if (!flag) {
            data.data = "data:application/pdf;base64," + data.data;
          }
          let url = data.data;
          let a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          a.remove();
        }
      }
    });
  };

  documentAction = (row) => {
    let imgExtension = row.name && row.name.split(".").pop();
    let fileSizeCheck =
      row.file_size_in_mb && row.file_size_in_mb > 3 ? true : false;
    let flag =
      imgExtension.toLowerCase() === "xls" ||
      imgExtension.toLowerCase() === "xlsx" ||
      imgExtension.toLowerCase() === "doc" ||
      imgExtension.toLowerCase() === "docx"
        ? false
        : true;
    flag = fileSizeCheck ? false : flag;

    return (
      <div>
        <a
          className='make-payment-button'
          style={{ cursor: "pointer", marginRight: 30 }}
          title='Download'
          onClick={() =>
            this.downloadAttachedDocuement(row.document_id, row.name)
          }
        >
          <img src={downloadImg} width='16' height='16' />
        </a>
        {flag && (
          <button
            title='View'
            style={{
              background: "transparent",
              cursor: "pointer",
            }}
            onClick={() => this.viewValidationDoc(row)}
          >
            <img src={viewIcon} width='20' height='20' />
          </button>
        )}
      </div>
    );
  };

  getObjectListData = (data) => {
    let headings = [],
      columns = [];
    headings = [
      {
        name: "Name",
        style: { width: "25%", display: "flex", justifyContent: "flex-start" },
      },
      {
        name: "Description",
        style: { width: "25%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Type",
        style: { width: "25%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Action",
        style: { width: "25%", display: "flex", justifyContent: "center" },
      },
    ];

    columns = data.map((row) => {
      return {
        emptyPrimary: true,
        secondaryColumns: [
          {
            key: "Name",
            title: row.name,
            style: {
              width: "25%",
              display: "flex",
              justifyContent: "flex-start",
            },
          },
          {
            key: "Description",
            title: row.description,
            style: { width: "25%", display: "flex", justifyContent: "center" },
          },
          {
            key: "Type",
            title: row.document_type ? row.document_type.name : "",
            style: { width: "25%", display: "flex", justifyContent: "center" },
          },
          {
            key: "Action",
            title: this.documentAction(row),
            style: { width: "25%", display: "flex", justifyContent: "center" },
          },
        ],
      };
    });

    return {
      headings,
      columns,
    };
  };

  renderDocuments = (data) => {
    let { expandDoc, textColor } = this.state;
    let count = data && data.length ? data.length : 0;
    return (
      <div>
        <div className='ic-wt-fields'>
          <div className='he-hed he-sec-hed'>Documents ({count})</div>
          <a
            key={shortid.generate()}
            className={`client-caret expander-caret consumer-brand ${
              expandDoc ? " down-caret" : ""
            }`}
            style={{
              margin: "12px 0px 0px 12px",
              borderTop: `0.8em solid ${textColor}`,
            }}
            onClick={(event) => this.setState({ expandDoc: !expandDoc })}
          />
        </div>
        {expandDoc && (
          <div>
            {data && data.length ? (
              <div>
                <CardView data={this.getObjectListData(data)} />
                <InvoiceObjectList
                  data={this.getObjectListData(data)}
                  hideForMobile={true}
                  optionalTableClasses='invoice-doc'
                  fromConsumer={true}
                />
              </div>
            ) : (
              this.renderEmpty("No documents available")
            )}
          </div>
        )}
      </div>
    );
  };

  renderEmpty = (msg) => {
    return (
      <div
        className='admin-admins-empty-state'
        style={{ padding: "2rem 2rem 1rem 2rem", backgroundColor: "#FFF" }}
      >
        <p>{msg}</p>
      </div>
    );
  };

  toggleMakePayment = (flag) => {
    if ((this.state.isACH === true || this.state.isCC === true) && flag) {
      this.setState({ makePayment: true });
    } else {
      this.setState({ makePayment: false });
      this.props.updateModalState(true, "ERROR", {
        message: `No payment methods available.Please contact ${this.state.contact_email} for support.`,
      });
    }
  };

  sidebarContent = () => {
    const { showPaymentScreen, invoiceDetails, amount } = this.state;
    return (
      <div className='consumer-sidebar'>
        <div style={StyleSheet.head1}>
          Welcome,{" "}
          {invoiceDetails
            ? invoiceDetails.first_name + " " + invoiceDetails.last_name
            : null}
          !
        </div>
        <div className='sidebar-primary-text' style={StyleSheet.head2}>
          This invoice is {this.getAccountType()}
        </div>
        <label style={StyleSheet.head3}>Balance Due</label>
        <div
          className='invoice-amt-owed payment-balance'
          style={{ marginBottom: "1em", color: "#000000" }}
        >
          {invoiceDetails.balance
            ? currencyFormatter.format(invoiceDetails.balance, { code: "USD" })
            : "0.00"}
        </div>
      </div>
    );
  };

  render() {
    const { appName, stripePubKey } = this.props;
    const { error, invoiceDetails, makePayment, textColor, logo_url, loading } =
      this.state;
    if (error.hasAnyError) {
      if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }
    return loading ? (
      <div style={styles.loader}>
        <AppCanvasLoader />
      </div>
    ) : (
      <div>
        <Helmet>
          <title>
            {appName} | Invoice# {invoiceDetails.invoice_number}
          </title>
        </Helmet>
        <ExpressHeader logoPath={logo_url} />
        <div className='scrollbar exp-pay-scroll'>
          {makePayment ? (
            <ExpressPayment
              primary_color={textColor}
              stripePubKey={stripePubKey}
              invoiceDetails={invoiceDetails}
              updateAmount={this.props.updateAmount}
              toggleMakePayment={this.toggleMakePayment}
              updateModalState={this.props.updateModalState}
            />
          ) : (
            <div className='app-sidebar-layout-canvas'>
              <ConsumerSidebar
                primary_color={textColor}
                sidebarContent={this.sidebarContent()}
                exPay={true}
              />
              <div className='app-sidebar-layout-canvas-content setting-side-canvas big-device-grad scrollbar exp-pay-scroll-inner'>
                <ClientsLogo color={"#000"} />
                <div className='header-make-payment'>
                  <h1
                    className='app-section-consumer-heading'
                    style={{ color: `${textColor}` }}
                  >
                    Invoice# {invoiceDetails.invoice_number}
                  </h1>
                  <button
                    className='cta make-payment-btn .mg-brand2-common-color'
                    onClick={() => this.toggleMakePayment(true)}
                    style={{
                      backgroundColor: textColor,
                      borderColor: "#a1a1a1",
                    }}
                  >
                    Make Payment
                  </button>
                </div>
                <InvoiceAndLineDetails invoiceDetails={invoiceDetails} />
                {this.renderDocuments(invoiceDetails.document)}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default InvoiceDetails;

let StyleSheet = {
  head1: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: "1em",
    color: "#FFFFFF",
  },
  head2: { marginBottom: "2em", display: "flex", color: "#FFFFFF" },
  head3: { marginBottom: "0.3em", color: "#FFFFFF" },
};
