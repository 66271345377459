import moment from "moment";
import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import { getZohoPagesense } from "../../utils/helpers";
import BottomScrollListener from "react-bottom-scroll-listener";
import ClientsCardView from "../../components/CardView/ClientsCardView";
import HistoryObjectList from "../../components/ObjectList/ClientsAccounts/HistoryObjectList";
import * as API from "../../utils/api";
import { formatDate } from "../../utils/helpers";

class NotificationHistory extends Component {
  constructor(props) {
    super();
    this.state = {
      savedData: [],
      flag: false,
      isLoading: false,
      searchData: "",
      showFlash: false,
      closeDropDown: false,
      offset: 0,
      search: {
        value: "",
      },
    };
  }

  componentDidMount() {
    getZohoPagesense();
    this.getLobData();
    ReactGA.pageview("/PaymentHistory");
  }

  handleContainerOnBottom = () => {
    if (this.state.hasMoreData) {
      let offsetCount = parseInt(this.state.offset) + 1;
      this.setState(
        (state) => ({
          ...state,
          offset: offsetCount,
          showLoader: true,
        }),
        () => {
          this.getLobData();
        }
      );
    }
  };

  getLobData() {
    this.setState((state) => ({ ...state, showLoader: true, isLoading: true }));
    let { user } = this.props;
    let { offset } = this.state;

    API.fetchNotificationHistory(user, offset, null).then((data) => {
      let isAccessDenied =
        data && data.message && data.message == "Access denied." ? true : false;
      if (
        (typeof data != "undefined" || data != null) &&
        data.status_code === 200 &&
        !isAccessDenied
      ) {
        let savedData = this.state.savedData;
        let newData = savedData
          ? savedData.concat(data.notifications)
          : data.notifications;

        this.setState((state) => ({
          ...state,
          savedData: newData,
          isLoading: false,
          hasMoreData: data.notifications.length < 20 ? false : true,
          showLoader: false,
          // offset:data.offset,
        }));
      } else if (data.notifications === null) {
        this.setState((state) => ({
          showLoader: false,
          isLoading: false,
        }));
        this.props.updateModalState(true, "ERROR", {
          message: data.message,
          title: "Info!",
        });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  }

  getObjectListData = (data) => {
    let headings = [],
      columns = [];
    headings = [
      "Customer Name",
      "QBO Error detail",
      "Entity No.",
      "Entity Type",
      "Error Msg.",
      "Created At",
      "Updated At",
      "Addressed By",
    ];
    columns = data.map((row) => {
      return {
        emptyPrimary: true,
        secondaryColumns: [
          {
            key: "Customer Name",
            title: row.account_name,
          },
          {
            key: "QBO Error detail",
            title: row.qb_error_description,
          },
          {
            key: "Entity No.",
            title: row.mg_entity_number,
          },
          {
            key: "Entity Type",
            title: row.entity_type,
          },
          {
            key: "Error Msg.",
            title: row.error_message,
          },
          {
            key: "Created At",
            title: row.created_at ? (
              <div
                style={{
                  textAlign: "center",
                }}
              >
                {formatDate(row.created_at)[0]}
                <br />
                {`${formatDate(row.created_at)[1]} 
                ${formatDate(row.created_at)[2]}`}
              </div>
            ) : (
              "n/a"
            ),
          },
          {
            key: "Updated At",
            title: row.updated_at ? (
              <div style={{ textAlign: "center" }}>
                {formatDate(row.updated_at)[0]}
                <br />
                {`${formatDate(row.updated_at)[1]} 
                ${formatDate(row.updated_at)[2]}`}
              </div>
            ) : (
              "n/a"
            ),
          },
          {
            key: "Addressed By",
            title:
              row.is_addressed && row.hasOwnProperty("addressed_by")
                ? row.addressed_by.first_name + " " + row.addressed_by.last_name
                : "Issue is not addressed by admin",
          },
        ],
      };
    });
    return {
      headings,
      columns,
    };
  };

  handleSearchInputChange(newPartialInput) {
    this.setState(
      (state) => ({
        ...state,
        search: {
          value: newPartialInput.search.value,
        },
      }),
      () => {
        if (newPartialInput.search.value.length === 0) {
          this.handleKeyPress({ key: "Enter" });
        }
      }
    );
  }

  render() {
    let { isLoading } = this.state;
    let isSmallDevice = window.innerWidth <= 680 ? true : false;

    return isLoading ? (
      <AppCanvasLoader />
    ) : (
      <>
        <div style={{ marginBottom: "1em" }} />
        {this.state.savedData.length > 0 ? (
          <BottomScrollListener
            offset={50}
            onBottom={this.handleContainerOnBottom}
            debounce={0}
          >
            {(scrollRef) => (
              <div>
                {isSmallDevice ? (
                  <div className='big-device-grad invoice-line'>
                    <div ref={scrollRef} className='scrollbar in-sc'>
                      <ClientsCardView
                        data={this.getObjectListData(this.state.savedData)}
                        rowActionClick={(event) =>
                          this.handleRowActionClick(event)
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <div style={{ marginBottom: "1em" }}>
                    <HistoryObjectList
                      data={this.getObjectListData(this.state.savedData)}
                      rowActionClick={(event) =>
                        this.handleRowActionClick(event)
                      }
                      hideForMobile={true}
                      optionalClasses='scrollbar in-sc'
                      scrollRef={scrollRef}
                    />
                  </div>
                )}
                {this.state.showLoader && <AppCanvasLoader />}
              </div>
            )}
          </BottomScrollListener>
        ) : (
          <div className='admin-admins-empty-state'>
            <h3 className='empty-mesg-mg'>
              No notification history available.
            </h3>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(NotificationHistory);
