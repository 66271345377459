import $ from "jquery";
import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import Select from "react-select";
import "react-select/dist/react-select.css";
import MaskedTextInput from "react-text-mask";
import AppCanvasLoader from "../../../components/App/AppCanvasLoader";
import * as API from "../../../utils/api";
import { showToast, validateEmail } from "../../../utils/helpers";
import stateDataShortNames from "../../../utils/stateDataShortNames.json";
// import Modal from "../../components/App/Modals/Modal";
import { Helmet } from "react-helmet";
import Cookies from "universal-cookie";
import { getBaseUrl } from "../../../utils/api";
class QuickBookAddOrganizations extends Component {
  state = {
    useAddress: false,
    validation_error: false,
    userAlreadyVerified: "",
    displayNameExist: "",
    organizationDetails: "",
    isConnectedToQB: true,
    input: {
      consumerInformation: {
        companyName: {
          value: "",
          hasError: false,
          isRequired: true,
        },
        displayName: {
          value: "",
          hasError: false,
          isRequired: true,
        },
        first_name: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        last_name: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        email: {
          value: "",
          hasError: false,
          isRequired: true,
        },
        phone_number: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        date_of_birth: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        street_address_primary: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        street_address_secondary: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        street_legal_address_primary: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        street_legal_address_secondary: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        city: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        city2: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        state: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        state2: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        zipcode: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        zipcode2: {
          value: "",
          hasError: false,
          isRequired: false,
        },
      },
    },
    isLoading: false,
    isApiCall: false,
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
  };
  style = {
    pText: {
      fontSize: "14px",
      color: "#2b2828",
      margin: "0px",
    },
  };

  componentWillMount() {
    this.getOrganizationalDetails();
  }

  handleConsumerInfoInputChange(newPartialInput, checkboxAddress) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        consumerInformation: {
          ...state.input.consumerInformation,
          ...newPartialInput,
        },
      },
    }));
    if (checkboxAddress) {
      this.setState((state) => ({
        ...state,
        useAddress: false,
      }));
    }
  }

  getNumberFromPhoneString = (phoneString) => {
    let strippedNumber = phoneString.replace(/\(|\)|\s|-/g, "");
    return strippedNumber;
  };

  getOrganizationalDetails = () => {
    this.setState({ isLoading: true });
    const cookies = new Cookies();
    let authuser = cookies.get("accessToken", { path: "/", secure: true });
    let user = {
      user_auth_token: authuser,
    };
    API.getOrgDetails(user).then((data) => {
      this.setState(
        {
          isLoading: false,
          organizationDetails: data.data,
          isConnectedToQB:
            data.data.organization.qb_status &&
              data.data.organization.qb_status === "ACTIVE"
              ? true
              : false,
        },
        () => {
          let { input, organizationDetails } = this.state;
          if (organizationDetails && organizationDetails.organization) {
            input.consumerInformation.email.value =
              organizationDetails.organization.email;
            input.consumerInformation.companyName.value =
              organizationDetails.organization.name;
            input.consumerInformation.displayName.value =
              organizationDetails.organization.legal_name;
            input.consumerInformation.first_name.value =
              organizationDetails.organization.primary_contact_first_name;
            input.consumerInformation.last_name.value =
              organizationDetails.organization.primary_contact_last_name;
            input.consumerInformation.street_address_primary.value =
              organizationDetails.organization.company_add_line1;
            input.consumerInformation.street_address_secondary.value =
              organizationDetails.organization.company_add_line2;
            input.consumerInformation.street_legal_address_primary.value =
              organizationDetails.organization.legal_add_line1;
            input.consumerInformation.street_legal_address_secondary.value =
              organizationDetails.organization.legal_add_line2;
            input.consumerInformation.city.value =
              organizationDetails.organization.company_add_city;
            input.consumerInformation.state.value =
              organizationDetails.organization.company_add_state;
            input.consumerInformation.zipcode.value =
              organizationDetails.organization.company_add_zip;
            input.consumerInformation.city2.value =
              organizationDetails.organization.legal_add_city;
            input.consumerInformation.state2.value =
              organizationDetails.organization.legal_add_state;
            input.consumerInformation.zipcode2.value =
              organizationDetails.organization.legal_add_zip;
            input.consumerInformation.phone_number.value =
              organizationDetails.organization.contact_no;
            this.setState({ input: input }, () => {
              if (
                input.consumerInformation.street_legal_address_primary.value ===
                null &&
                input.consumerInformation.street_legal_address_secondary
                  .value === null &&
                input.consumerInformation.city2.value === null &&
                input.consumerInformation.state2.value === null &&
                input.consumerInformation.zipcode2.value === null
              ) {
                this.setState({ useAddress: true, isLoading: false }, () => {
                  this.setLetterData();
                });
              } else if (
                input.consumerInformation.street_legal_address_primary.value ===
                input.consumerInformation.street_address_primary.value &&
                input.consumerInformation.street_legal_address_secondary
                  .value ===
                input.consumerInformation.street_address_secondary.value &&
                input.consumerInformation.city2.value ===
                input.consumerInformation.city.value &&
                input.consumerInformation.zipcode2.value ===
                input.consumerInformation.zipcode.value &&
                input.consumerInformation.state.value ===
                input.consumerInformation.state2.value
              ) {
                this.setState({ useAddress: true, isLoading: false });
              } else {
                this.setState({ useAddress: false, isLoading: false });
              }
            });
          }
        }
      );
    });
  };

  getAdminClientInfo = () => {
    const { user } = this.props;
    API.getCleintAdminUser(user)
      .then((res) => {
        this.props.updateUserLogin({
          ...user,
          first_name: res.user.first_name,
          last_name: res.user.last_name,
        });
      })
      .catch((err) => {
        console.log("error ===>>", err);
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { input, isConnectedToQB } = this.state;

    if (this.validateForm()) {
      let payload = {
        email: input.consumerInformation.email.value,
        name: input.consumerInformation.companyName.value,
        legal_name: input.consumerInformation.displayName.value,
        primary_contact_first_name: input.consumerInformation.first_name.value,
        primary_contact_last_name: input.consumerInformation.last_name.value,
        org_contactNo: this.getNumberFromPhoneString(
          input.consumerInformation.phone_number.value
        ),
        company_add_line1:
          input.consumerInformation.street_address_primary.value,
        company_add_line2:
          input.consumerInformation.street_address_secondary.value,
        legal_add_line1:
          input.consumerInformation.street_legal_address_primary.value,
        legal_add_line2:
          input.consumerInformation.street_legal_address_secondary.value,
        company_add_city: input.consumerInformation.city.value,
        company_add_state: input.consumerInformation.state.value,
        company_add_zip: input.consumerInformation.zipcode.value,
        legal_add_city: input.consumerInformation.city2.value,
        legal_add_state: input.consumerInformation.state2.value,
        legal_add_zip: input.consumerInformation.zipcode2.value,
        id: this.state.organizationDetails
          ? this.state.organizationDetails.organization.id
          : "",
      };
      API.ssoAddOrganization(this.props.user, payload).then((data) => {
        if ((typeof data != "undefined" || data != null) && !data.error) {
          //show toast only if any field update
          if (data.status_code == 200) {
            if (data.modify_count != 0) {
              this.props.updateModalState(true, "SUCCESS", {
                message: data.message,
              });
            }
            this.props.handleAddOrganizaitions();
            this.props.getOrganizationalDetails();
            // this.props.optionalProps.releoadTableData(data.consumer)
            if (isConnectedToQB || this.props.isQBDataSyncedFromcheckAuthStatus) {
              this.props.handleAction("SYNC_ACCOUNT");
            } else {
              this.props.handleAction("CONNECT_ACCOUNT_SYSTEM");
            }
            this.getAdminClientInfo();
          } else if (data.status_code !== 200) {
            this.setState((state) => ({
              ...state,
              hasAnyError: true,
            }));
            this.props.updateModalState(true, "ERROR", {
              message: data.message,
            });
          }
        }
      });
      // API.updateClient
    } else {
      this.setFormErrorState();
    }
  };

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  validateForm = (formType) => {
    let errorsArePresent = false;

    // Determine which input group to check for errors
    let inputs = { ...this.state.input.consumerInformation };

    // Check inputs for errors
    Object.entries(inputs).forEach(([key, value]) => {
      if (key === "companyName") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
        }
      } else if (key === "first_name") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
        }
      } else if (key === "last_name") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
        }
      } else if (key === "phone_number") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
        }
      } else if (key === "email") {
        // if (!validateEmail(value.value) && value.value !== "") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
        }
      } else if (key === "street_address_primary") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
        }
      } else if (key === "city") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
        }
      } else if (key === "state") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
        }
      } else if (key === "zipcode") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
        }
      }
    });

    if (errorsArePresent) {
      return false;
    } else {
      return true;
    }
  };

  setFormErrorState = (formType) => {
    let errorsArePresent = false;

    // Determine which input group to check for errors
    let newInputState = { ...this.state.input.consumerInformation };

    // Set error state on necessary fields
    Object.entries(newInputState).forEach(([key, value]) => {
      if (key === "email") {
        if (value.value === "" || !validateEmail(value.value)) {
          newInputState[key].hasError = true;
          this.setState({ userAlreadyVerified: "" });
          this.setState({ displayNameExist: "" });
        }
      } else if (key === "companyName") {
        if (value.value === "" || value.value === null) {
          newInputState[key].hasError = true;
        }
      } else if (key === "displayName") {
        if (value.value === "" || value.value === null) {
          newInputState[key].hasError = false;
          this.setState({ userAlreadyVerified: "" });
          this.setState({ displayNameExist: "" });
        }
      } else if (key === "last_name") {
        if (value.value === "" || value.value === null) {
          newInputState[key].hasError = true;
        }
      } else if (key === "first_name") {
        if (value.value === "" || value.value === null) {
          newInputState[key].hasError = true;
        }
      } else if (key === "phone_number") {
        if (value.value === "" || value.value === null) {
          newInputState[key].hasError = true;
        }
      } else if (key === "org_contactNo") {
        if (value.value === "" || value.value === null) {
          newInputState[key].hasError = true;
        }
      } else if (key === "street_address_primary") {
        if (value.value === "" || value.value === null) {
          newInputState[key].hasError = true;
        }
      } else if (key === "city") {
        if (value.value === "" || value.value === null) {
          newInputState[key].hasError = true;
        }
      } else if (key === "state") {
        if (value.value === "" || value.value === null) {
          newInputState[key].hasError = true;
        }
      } else if (key === "zipcode") {
        if (value.value === "" || value.value === null) {
          newInputState[key].hasError = true;
        }
      }

      if (newInputState[key].hasError) {
        errorsArePresent = true;
      }
    });

    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        consumerInformation: newInputState,
      },
    }));
  };
  renderErrorMessage(input) {
    let message = "";
    if (input === "companyName") {
      message = "Please enter company name";
    } else if (input === "displayName") {
      message = "Please enter a display name";
    } else if (input === "email") {
      message = "Please enter a valid email address";
    } else if (input === "street_address_primary") {
      message = "Please enter a street address";
    } else if (input === "first_name") {
      message = "Please enter First name";
    } else if (input === "last_name") {
      message = "Please enter Last name";
    } else if (input === "phone_number") {
      message = "Please enter Phone number";
    } else if (input === "city") {
      message = "Please enter a city";
    } else if (input === "state") {
      message = "Please select a state";
    } else if (input === "zipcode") {
      message = "Please enter a zip code";
    } else if (input === "street_address") {
      message = "Please enter company address Line 1";
    } else {
      message = "Please complete this field";
    }

    return <div className='input-error-message'>{message}</div>;
  }
  handleCheckboxInputChange = (event) => {
    let target = event.target;
    this.setState({ useAddress: target.checked }, () => {
      this.setLetterData();
    });
  };

  setLetterData = () => {
    let { input, useAddress } = this.state;
    let temp = { ...input };
    let inputClone = JSON.parse(JSON.stringify(input));
    if (useAddress) {
      temp.consumerInformation.street_legal_address_primary.value = inputClone
        .consumerInformation.street_address_primary.value
        ? inputClone.consumerInformation.street_address_primary.value
        : "";
      temp.consumerInformation.street_legal_address_secondary.value = inputClone
        .consumerInformation.street_address_secondary.value
        ? inputClone.consumerInformation.street_address_secondary.value
        : "";
      temp.consumerInformation.state2.value = inputClone.consumerInformation
        .state.value
        ? inputClone.consumerInformation.state.value
        : "";
      temp.consumerInformation.city2.value = inputClone.consumerInformation.city
        .value
        ? inputClone.consumerInformation.city.value
        : "";
      temp.consumerInformation.zipcode2.value = inputClone.consumerInformation
        .zipcode.value
        ? inputClone.consumerInformation.zipcode.value
        : "";
    } else {
      temp.consumerInformation.street_legal_address_primary.value = "";
      temp.consumerInformation.street_legal_address_secondary.value = "";
      temp.consumerInformation.state2.value = "";
      temp.consumerInformation.city2.value = "";
      temp.consumerInformation.zipcode2.value = "";
    }
    this.setState((state) => ({
      input: temp,
    }));
  };

  render() {
    let { input, creditors, error, organizationDetails, isConnectedToQB } =
      this.state;
    let { appName } = this.props;
    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }
    return (
      <>
        {this.state.isLoading ? (
          <div className='loader_alignment'>
            <div style={{ marginTop: "20%" }}>
              <AppCanvasLoader />
            </div>
          </div>
        ) : (
          <div className='tp-sps app-sidebar-layout-canvas-content setting-side-canvas col1-scroll mg-col1-scroll scrollbar font-family-content'>
            <Helmet title={appName + " | Organization Detail"}></Helmet>
            <div
              className='app-sidebar-layout-canvas-content setting-side-canvas col1-scroll font-family-content'
              style={{
                height: "auto",
                width: "100%",
                backgroundColor: "white",
                padding: "20px",
              }}
            >
              <h3 style={{ color: "#000000" }} className='mg-head-bold'>
                Organization’s Detail
              </h3>
              <h7
                style={{
                  lineHeight: "20px",
                  fontFamily: "Open Sans ,sans-sarif",
                  fontWeight: "lighter",
                }}
              >
                <span style={{ fontWeight: "bold" }}>Note: </span> Certain
                fields may be pre-populated based on information from your
                QuickBooks Online account. Review and edit these details as
                needed.
              </h7>

              <div className='qb-add-org-form'>
                <div
                  optionalClasses='add-account-modal scroll'
                  title='Add Organization’s Detail'
                  closeOnClickOutside={true}
                  hideModal={this.props.hideModal}
                  className='add-account-details-modal'
                >
                  <form className='admin-form'>
                    <div className={`modal-slide`}>
                      <div className='form-container'>
                        <div className='form-column'>
                          <header
                            className='modal-slide-header'
                            style={{ textAlign: "left", marginBottom: "0.5em" }}
                          >
                            <div
                              className={`modal-slide-header-tab`}
                              style={{
                                margin: 0,
                                color: "black",
                                fontWeight: "bold",
                                fontFamily: "Open Sans ,sans-sarif",
                              }}
                            >
                              Business Information
                            </div>
                          </header>
                          <div
                            className={`input-container${input &&
                                input.consumerInformation &&
                                input.consumerInformation.companyName &&
                                input.consumerInformation.companyName.hasError
                                ? " error"
                                : ""
                              }`}
                            style={{ height: "70px" }}
                          >
                            <label
                              htmlFor='name'
                              style={{
                                fontWeight: "bold",
                                fontFamily: "Open Sans ,sans-sarif",
                              }}
                            >
                              Company Name *
                            </label>
                            <input
                              name='name'
                              id='org_Company_name'
                              type='text'
                              placeholder='Company Name'
                              value={
                                input.consumerInformation.companyName.value
                              }
                              style={{ fontFamily: "Open Sans ,sans-sarif" }}
                              onChange={(event) =>
                                this.handleConsumerInfoInputChange({
                                  companyName: {
                                    value: event.target.value,
                                    hasError: false,
                                  },
                                })
                              }
                            />
                            {input.consumerInformation.companyName.hasError
                              ? this.renderErrorMessage("companyName")
                              : null}
                          </div>
                          <div
                            className={`input-container`}
                            style={{ height: "70px" }}
                          >
                            <label
                              htmlFor='name'
                              style={{
                                fontWeight: "bold",
                                fontFamily: "Open Sans ,sans-sarif",
                              }}
                            >
                              Legal Business Name
                            </label>
                            <input
                              name='display_name'
                              id='org_Legal_Business_name'
                              type='text'
                              placeholder='Display Name'
                              value={
                                input.consumerInformation.displayName
                                  ? input.consumerInformation.displayName.value
                                  : ""
                              }
                              style={{ fontFamily: "Open Sans ,sans-sarif" }}
                              onChange={(event) =>
                                this.handleConsumerInfoInputChange({
                                  displayName: {
                                    value: event.target.value,
                                    hasError: false,
                                  },
                                })
                              }
                            />
                          </div>

                          <div
                            className={`input-container${input.consumerInformation.first_name.hasError ||
                                input.consumerInformation.last_name.hasError
                                ? " error"
                                : ""
                              } mg-ht-auto`}
                            style={{ height: "70px" }}
                          >
                            <label
                              htmlFor='name'
                              style={{
                                fontWeight: "bold",
                                fontFamily: "Open Sans ,sans-sarif",
                              }}
                            >
                              Primary Company Contact *
                            </label>

                            <div
                              className='input-container mg-dir-col'
                              style={{ display: "flex" }}
                            >
                              <div
                                className='mg-wt-auto mg-ht-100'
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "600px",
                                }}
                              >
                                <input
                                  className='multi-input-field-input'
                                  name='name'
                                  id='org_primary_contact_first_name'
                                  type='text'
                                  placeholder='First Name'
                                  value={
                                    input.consumerInformation.first_name.value
                                  }
                                  style={{
                                    fontFamily: "Open Sans ,sans-sarif",
                                    color: "black",
                                  }}
                                  onChange={(event) =>
                                    this.handleConsumerInfoInputChange({
                                      first_name: {
                                        value: event.target.value,
                                        hasError: false,
                                      },
                                    })
                                  }
                                />
                                {input.consumerInformation.first_name.hasError
                                  ? this.renderErrorMessage("first_name")
                                  : null}
                              </div>
                              <div
                                className='mg-wt-auto'
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "600px",
                                }}
                              >
                                <input
                                  className='multi-input-field-input'
                                  name='name'
                                  type='text'
                                  id='org_primary_contact_last_name'
                                  placeholder='Last Name'
                                  value={
                                    input.consumerInformation.last_name.value
                                  }
                                  style={{
                                    fontFamily: "Open Sans ,sans-sarif",
                                    color: "black",
                                  }}
                                  onChange={(event) =>
                                    this.handleConsumerInfoInputChange({
                                      last_name: {
                                        value: event.target.value,
                                        hasError: false,
                                      },
                                    })
                                  }
                                />

                                {input.consumerInformation.last_name.hasError
                                  ? this.renderErrorMessage("last_name")
                                  : null}
                              </div>
                            </div>
                          </div>

                          <div
                            className={`input-container${input.consumerInformation.email.hasError
                                ? " error"
                                : ""
                              }`}
                            style={{ height: "75px" }}
                          >
                            <label
                              htmlFor='email'
                              style={{
                                fontWeight: "bold",
                                fontFamily: "Open Sans ,sans-sarif",
                              }}
                            >
                              Company Email Address *
                            </label>
                            <input
                              name='email'
                              id='org_company_email_address'
                              type='email'
                              placeholder='address@email.com'
                              value={input.consumerInformation.email.value}
                              style={{ fontFamily: "Open Sans ,sans-sarif" }}
                              onChange={(event) =>
                                this.handleConsumerInfoInputChange({
                                  email: {
                                    value: event.target.value,
                                    hasError: false,
                                  },
                                })
                              }
                            />
                            {input.consumerInformation.email.hasError
                              ? this.renderErrorMessage("email")
                              : null}
                            <div className='add-account-exist-error'>
                              {this.state.userAlreadyVerified}
                            </div>
                          </div>
                          <div
                            className={`input-container${input.consumerInformation.phone_number.hasError
                                ? " error"
                                : ""
                              }`}
                            style={{ height: "70px" }}
                          >
                            <label
                              htmlFor='phone'
                              style={{
                                fontWeight: "bold",
                                fontFamily: "Open Sans ,sans-sarif",
                              }}
                            >
                              Company Phone Number *
                            </label>
                            <MaskedTextInput
                              name='phone'
                              id='org_company_phone_number'
                              mask={[
                                "(",
                                /\d/,
                                /\d/,
                                /\d/,
                                ")",
                                " ",
                                /\d/,
                                /\d/,
                                /\d/,
                                "-",
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/,
                              ]}
                              placeholder='(555) 555-5555'
                              placeholderChar={"\u2000"}
                              guide={true}
                              type='text'
                              value={
                                input.consumerInformation.phone_number.value
                              }
                              onChange={(event) =>
                                this.handleConsumerInfoInputChange({
                                  phone_number: {
                                    value: event.target.value,
                                    hasError: false,
                                  },
                                })
                              }
                            />
                            {input.consumerInformation.phone_number.hasError
                              ? this.renderErrorMessage("phone_number")
                              : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div style={{ width: "50px" }}></div>
                <div
                  optionalClasses='add-account-modal scroll'
                  title='Add Organization’s Detail'
                  closeOnClickOutside={true}
                  hideModal={this.props.hideModal}
                  className='add-account-details-modal'
                >
                  <form className='admin-form'>
                    <div className={`modal-slide`}>
                      <div className='form-container'>
                        <div className='form-column'>
                          <header
                            className='modal-slide-header'
                            style={{ textAlign: "left", marginBottom: "0.5em" }}
                          >
                            <div
                              className={`modal-slide-header-tab`}
                              style={{
                                margin: 0,
                                color: "black",
                                fontWeight: "bold",
                                fontFamily: "Open Sans ,sans-sarif",
                              }}
                            >
                              Address Information
                            </div>
                          </header>

                          <div
                            className={`input-container${input.consumerInformation.street_address_primary
                                .hasError
                                ? " error"
                                : ""
                              }`}
                            style={{ height: "70px" }}
                          >
                            <label
                              htmlFor='streetAddress'
                              style={{
                                fontWeight: "bold",
                                fontFamily: "Open Sans ,sans-sarif",
                              }}
                            >
                              Company Address Line 1 *
                            </label>
                            <input
                              name='streetAddress'
                              id='org_company_address_line_1'
                              type='text'
                              placeholder='Enter an Address'
                              value={
                                input.consumerInformation.street_address_primary
                                  .value
                              }
                              style={{ fontFamily: "Open Sans ,sans-sarif" }}
                              onChange={(event) =>
                                this.handleConsumerInfoInputChange(
                                  {
                                    street_address_primary: {
                                      value: event.target.value,
                                      hasError: false,
                                    },
                                  },
                                  true
                                )
                              }
                            />
                            {input.consumerInformation.street_address_primary
                              .hasError
                              ? this.renderErrorMessage("street_address")
                              : null}
                          </div>
                          <div
                            className={`input-container`}
                            style={{ height: "70px" }}
                          >
                            <label
                              htmlFor='streetAddress'
                              style={{
                                fontWeight: "bold",
                                fontFamily: "Open Sans ,sans-sarif",
                              }}
                            >
                              Company Address Line 2
                            </label>
                            <input
                              name='unit'
                              type='text'
                              id='org_company_address_line_2'
                              placeholder='Suite # / Unit #'
                              value={
                                input.consumerInformation
                                  .street_address_secondary.value
                              }
                              style={{ fontFamily: "Open Sans ,sans-sarif" }}
                              onChange={(event) =>
                                this.handleConsumerInfoInputChange(
                                  {
                                    street_address_secondary: {
                                      value: event.target.value,
                                      hasError: false,
                                    },
                                  },
                                  true
                                )
                              }
                            />
                          </div>
                          <div
                            className='mg-dir-col'
                            style={{ display: "flex" }}
                          >
                            <div
                              className={`input-container${input.consumerInformation.city.hasError
                                  ? " error"
                                  : ""
                                }`}
                              style={{ height: "70px" }}
                            >
                              <label
                                htmlFor='city'
                                style={{
                                  fontWeight: "bold",
                                  fontFamily: "Open Sans ,sans-sarif",
                                }}
                              >
                                City
                              </label>
                              <input
                                name='city'
                                id='org_company_address_city_name'
                                type='text'
                                placeholder='Enter a City'
                                value={input.consumerInformation.city.value}
                                style={{ fontFamily: "Open Sans ,sans-sarif" }}
                                onChange={(event) =>
                                  this.handleConsumerInfoInputChange(
                                    {
                                      city: {
                                        value: event.target.value,
                                        hasError: false,
                                      },
                                    },
                                    true
                                  )
                                }
                              />
                              {input.consumerInformation.city.hasError
                                ? this.renderErrorMessage("city")
                                : null}
                            </div>
                            <div
                              className={`input-container${input.consumerInformation.state.hasError
                                  ? " error"
                                  : ""
                                }`}
                              style={{ height: "70px" }}
                            >
                              <label
                                htmlFor='state'
                                style={{
                                  fontWeight: "bold",
                                  fontFamily: "Open Sans ,sans-sarif",
                                }}
                              >
                                State
                              </label>
                              <Select
                                inputProps={{
                                  autoComplete: "none",
                                  autoCorrect: "off",
                                  spellCheck: "off",
                                }}
                                id='org_company_address_state_name'
                                className='form-select'
                                name='state'
                                value={input.consumerInformation.state.value}
                                placeholder='Select'
                                options={stateDataShortNames}
                                resetValue=''
                                onChange={(event) =>
                                  this.handleConsumerInfoInputChange(
                                    {
                                      state: {
                                        value: event ? event.value : "",
                                        hasError: false,
                                      },
                                    },
                                    true
                                  )
                                }
                                style={{
                                  fontFamily: "Open Sans ,sans-sarif",
                                  bottom: "0.9px",
                                }}
                              />
                              {input.consumerInformation.state.hasError
                                ? this.renderErrorMessage("state")
                                : null}
                            </div>
                            <div
                              className={`input-container${input.consumerInformation.zipcode.hasError
                                  ? " error"
                                  : ""
                                }`}
                              style={{ height: "70px" }}
                            >
                              <label
                                htmlFor='zipcode'
                                style={{
                                  fontWeight: "bold",
                                  fontFamily: "Open Sans ,sans-sarif",
                                }}
                              >
                                Zip Code
                              </label>
                              <MaskedTextInput
                                guide={true}
                                mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                                id='org_company_address_zipcode'
                                name='zipcode'
                                placeholder='90210'
                                placeholderChar={"\u2000"}
                                type='text'
                                value={input.consumerInformation.zipcode.value}
                                onChange={(event) =>
                                  this.handleConsumerInfoInputChange(
                                    {
                                      zipcode: {
                                        value: event.target.value,
                                        hasError: false,
                                      },
                                    },
                                    true
                                  )
                                }
                              />
                              {input.consumerInformation.zipcode.hasError
                                ? this.renderErrorMessage("zipcode")
                                : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`modal-slide`}>
                      <div className='form-container'>
                        <div
                          className={`input-container mg-mt-20`}
                          style={{ height: "70px" }}
                        >
                          <div
                            className='mg-dir-rcol'
                            style={{ display: `flex`, height: "18px" }}
                          >
                            <label
                              htmlFor='streetAddress'
                              style={{
                                fontWeight: "bold",
                                fontFamily: "Open Sans ,sans-sarif",
                              }}
                            >
                              Legal Address Line 1
                            </label>
                            <label style={{ display: `flex` }}>
                              <input
                                type='checkbox'
                                id='org_Legal_address_line_1'
                                style={{
                                  WebkitAppearance: `checkbox`,
                                  width: `15px`,
                                  marginRight: `10px`,
                                  marginTop: `-6px`,
                                }}
                                checked={this.state.useAddress}
                                onChange={this.handleCheckboxInputChange}
                              />
                              Same as Company Address
                            </label>
                          </div>
                          <input
                            name='streetAddress'
                            id='org_same_as_company_address_checkbox'
                            type='text'
                            placeholder='Enter an Address'
                            value={
                              input.consumerInformation
                                .street_legal_address_primary.value
                            }
                            style={{ fontFamily: "Open Sans ,sans-sarif" }}
                            onChange={(event) =>
                              this.handleConsumerInfoInputChange(
                                {
                                  street_legal_address_primary: {
                                    value: event.target.value,
                                    hasError: false,
                                  },
                                },
                                true
                              )
                            }
                          />
                        </div>
                        <div
                          className={`input-container`}
                          style={{ height: "70px" }}
                        >
                          <label
                            htmlFor='streetAddress'
                            style={{
                              fontWeight: "bold",
                              fontFamily: "Open Sans ,sans-sarif",
                              marginTop: "5px",
                            }}
                          >
                            Legal Address Line 2
                          </label>
                          <input
                            name='unit'
                            id='org_Legal_address_line_2'
                            type='text'
                            placeholder='Suite # / Unit #'
                            value={
                              input.consumerInformation
                                .street_legal_address_secondary.value
                            }
                            style={{ fontFamily: "Open Sans ,sans-sarif" }}
                            onChange={(event) =>
                              this.handleConsumerInfoInputChange(
                                {
                                  street_legal_address_secondary: {
                                    value: event.target.value,
                                    hasError: false,
                                  },
                                },
                                true
                              )
                            }
                          />
                        </div>
                        <div className='form-column'>
                          <div
                            className='mg-dir-col'
                            style={{ display: "flex" }}
                          >
                            <div
                              className={`input-container`}
                              style={{ height: "70px" }}
                            >
                              <label
                                htmlFor='city'
                                style={{
                                  fontWeight: "bold",
                                  fontFamily: "Open Sans ,sans-sarif",
                                }}
                              >
                                City
                              </label>
                              <input
                                name='city'
                                id='org_Legal_address_city_name'
                                type='text'
                                placeholder='Enter a City'
                                value={input.consumerInformation.city2.value}
                                style={{ fontFamily: "Open Sans ,sans-sarif" }}
                                onChange={(event) =>
                                  this.handleConsumerInfoInputChange(
                                    {
                                      city2: {
                                        value: event.target.value,
                                        hasError: false,
                                      },
                                    },
                                    true
                                  )
                                }
                              />
                            </div>
                            <div
                              className={`input-container`}
                              style={{ height: "70px" }}
                            >
                              <label
                                htmlFor='state'
                                style={{
                                  fontWeight: "bold",
                                  fontFamily: "Open Sans ,sans-sarif",
                                }}
                              >
                                State
                              </label>
                              <Select
                                inputProps={{
                                  autoComplete: "none",
                                  autoCorrect: "off",
                                  spellCheck: "off",
                                }}
                                className='form-select'
                                id='org_Legal_address_state_name'
                                name='state'
                                value={input.consumerInformation.state2.value}
                                placeholder='Select'
                                options={stateDataShortNames}
                                resetValue=''
                                style={{
                                  fontFamily: "Open Sans ,sans-sarif",
                                  bottom: "0.9px",
                                }}
                                onChange={(event) =>
                                  this.handleConsumerInfoInputChange(
                                    {
                                      state2: {
                                        value: event ? event.value : "",
                                        hasError: false,
                                      },
                                    },
                                    true
                                  )
                                }
                              />
                            </div>
                            <div
                              className={`input-container`}
                              style={{ height: "70px" }}
                            >
                              <label
                                htmlFor='zipcode'
                                style={{
                                  fontWeight: "bold",
                                  fontFamily: "Open Sans ,sans-sarif",
                                }}
                              >
                                Zip Code
                              </label>
                              <MaskedTextInput
                                guide={true}
                                mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                                name='zipcode'
                                id='org_Legal_address_zipcode'
                                placeholder='90210'
                                placeholderChar={"\u2000"}
                                type='text'
                                value={input.consumerInformation.zipcode2.value}
                                style={{ fontFamily: "Open Sans ,sans-sarif" }}
                                onChange={(event) =>
                                  this.handleConsumerInfoInputChange(
                                    {
                                      zipcode2: {
                                        value: event.target.value,
                                        hasError: false,
                                      },
                                    },
                                    true
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div
                  id='send-new-mail'
                  className='modal-actions double-btns'
                ></div>
              </div>
              <div className={"go-back-next-button mg-qbbtns-details-page"}>
                <div className='button-left'>
                  <input
                    type='submit'
                    value='Go Back'
                    // className="button-box"
                    id={"go-back-btn"}
                    onClick={() => {
                      this.props.handleback();
                      this.props.handleAction("");
                    }}
                    style={{
                      float: "left",
                    }}
                  />
                  <span className='go-back-text'>
                    <p style={this.style.pText}>Go back to</p>
                    <p style={this.style.pText}>"Welcome To Setup"</p>
                  </span>
                </div>
                <div className='button-right'>
                  <input
                    type='submit'
                    value={"Next"}
                    id={"next-button"}
                    // className="cta mg-brand2-color mg-nextbtn"
                    style={{
                      border: "none",
                      float: "right",
                    }}
                    onClick={this.handleSubmit}
                  />
                  <span className='next-text'>
                    <p style={this.style.pText}>Continue to</p>
                    <p style={this.style.pText}>
                      {isConnectedToQB || this.props.isQBDataSyncedFromcheckAuthStatus
                        ? '"Check Account Sync Preferences"'
                        : '"Connect To Accounting System"'}
                    </p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(QuickBookAddOrganizations);
