import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import { Redirect } from "react-router-dom";
import "react-select/dist/react-select.css";
import shortid from "shortid";
import AppCanvasLoader from "../../../components/App/AppCanvasLoader";
import AppSectionHeader from "../../../components/App/AppSectionHeader";
import Modal from "../../../components/App/Modals/Modal";
import Panel from "../../../components/App/Panel";
import ClientAndContact from "../../../components/Clients/AddInvoice/ClientAndContact";
import InvoiceDetails from "../../../components/Clients/AddInvoice/InvoiceDetails";
import NotesAndAttachments from "../../../components/Clients/AddInvoice/NotesAndAttachments";
import TermsAndDates from "../../../components/Clients/AddInvoice/TermsAndDates";
import MenuItem from "../../../components/MenuItem";
import { toast } from "react-toastify";
import * as API from "../../../utils/api";
import {
  getStrippedDollarAmount,
  getStrippedDollarOrPercentAmount,
  isValidDate,
  showToast,
} from "../../../utils/helpers";
import Emitter from "../../../utils/event-emitter";

const styles = {
  loader: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    backgroundColor: "#000",
    opacity: 0.3,
    zIndex: 10,
  },
};

const invoiceItem = {
  taxable: {
    value: false,
  },
  service: {
    value: "",
    hasError: false,
  },
  itemDate: {
    value: moment(new Date()).format("MM/DD/YYYY"),
    hasError: false,
  },
  description: {
    value: "",
    hasError: false,
  },
  hours: {
    value: "",
    hasError: false,
  },
  rate: {
    value: "",
    hasError: false,
  },
  total: {
    value: "",
    hasError: false,
  },
};

const note = {
  note: {
    value: "",
    hasError: false,
  },
};

const attchedDoc = {
  docName: {
    value: "",
    hasError: false,
  },
  description: {
    value: "",
    hasError: false,
  },
  docType: {
    value: "",
    hasError: false,
  },
  base64Doc: {
    document: {
      value: "",
      hasError: false,
    },
    fileExtension: "",
    fileSizeInMB: "",
  },
};

const termDate = {
  terms: {
    value: "",
    hasError: false,
  },
  invDate: {
    value: moment(new Date()).format("MM/DD/YYYY"),
    hasError: false,
  },
  dueDate: {
    value: moment(moment().add(30, "days")).format("MM/DD/YYYY"),
    hasError: false,
  },
  discount: {
    value: "",
    hasError: false,
  },
  discountRate: {
    value: "",
    hasError: false,
  },
  isDiscountPercentage: {
    value: "",
    hasError: false,
  },
  latefees: {
    value: "",
    hasError: false,
  },
  isLatefeesPercentage: {
    value: "",
    hasError: false,
  },
};

const sub_total = {
  total: {
    value: "",
    hasError: false,
  },
  subTotal: {
    value: "",
    hasError: false,
  },
  discount: {
    value: "",
  },
  latefees: {
    value: "",
  },
  discountAmount: {
    value: "",
  },
  latefeeAmount: {
    value: "",
  },
  taxRate: {
    value: "",
  },
};

class ClientsAddCreditMemo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      credit_memo_id: "",
      creditMemoNumber: {
        value: "",
        hasError: false,
      },
      qbSetting: {},
      //client vars
      clientFileNo: "",
      clientList: [],
      clientDetails: {},
      clientDetailsError: false,
      //terms vars
      termsAndDates: _.cloneDeep(termDate),
      termsDiscountRateType: "PERCENT",
      termsLateFeesType: "PERCENT",
      termsList: [],
      //invoice vars
      invoiceDiscountType: "PERCENT",
      invoiceLateFeesType: "PERCENT",
      serviceList: [],
      invoiceDetails: [_.cloneDeep(invoiceItem)],
      invoiceSubTotal: _.cloneDeep(sub_total),
      //notes & attachments
      notesAndAttchments: [_.cloneDeep(note)],
      attachedDocuments: [_.cloneDeep(attchedDoc)],
      docTypeList: [],
      userList: [],
      //other flags
      previewDD: false,
      saveDD: false,
      isLoading: true,
      isSubmit: false,
      isEdit: false,
      disabledClient: false,
      backPage: "",
      successMessage: "",
      showModal: false,
      error: {
        hasAnyError: false,
        statusCode: 200,
      },
      accountPath: "",
    };
    this.previewDDRef = React.createRef();
    this.saveDDRef = React.createRef();
  }

  componentDidMount() {
    let { location } = this.props;
    let backPath =
      location && location.state && location.state.backPath
        ? location.state.backPath
        : "/client/creditMemo";
    let accountPath =
      location && location.state && location.state.accountPath
        ? location.state.accountPath
        : "/client/acccounts";
    this.setState({ accountPath });
    ReactGA.pageview("/ClientsAddCreditMemo");

    //save, save only, preview button options hide/show based on click
    document.addEventListener("click", this.handleOutsideClick.bind(this));

    //get document types for document type select dropdown
    this.getDocumentType();

    //add invoice from account details screen
    let consumer =
      location && location.state && location.state.consumer
        ? location.state.consumer
        : "";
    if (consumer) {
      this.getDummyClientOptions(consumer);
      consumer.user_id = consumer.id;
      if (consumer.user_contacts && consumer.user_contacts.length) {
      } else {
        let obj = {
          contact_type: "TO_EMAIL",
          id: null,
          value: "",
        };
        obj.value = consumer.email;
        let array = [];
        array.push(obj);
        consumer.user_contacts = array;
      }
      this.setState({ clientDetails: consumer, disabledClient: true });
    }

    // invoice data passed from invoice details screen by using history state,
    // get invoice data using location and set to current page state var
    let creditMemoData =
      location && location.state && location.state.creditMemoDetails
        ? location.state.creditMemoDetails
        : "";
    if (creditMemoData && creditMemoData.id) {
      this.setEditData(creditMemoData);
      this.setState({ isEdit: true, credit_memo_id: creditMemoData.id }, () => {
        this.getCreditMemoNumber(this.state.isEdit);
        this.clientServicesSearch("", () => {});
      });
    } else {
      this.getCreditMemoNumber();
      this.clientServicesSearch("", () => {});
      this.invoiceClientSearch("");
    }

    this.setState({ backPage: backPath });
    // this.getClientTermsList(); //TO_DO
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleOutsideClick);
  }

  //clear all form data
  clearData = () => {
    this.setState({
      clientFileNo: "",
      clientDetails: {},
      notesAndAttchments: [_.cloneDeep(note)],
      attachedDocuments: [_.cloneDeep(attchedDoc)],
      invoiceDetails: [_.cloneDeep(invoiceItem)],
      invoiceSubTotal: _.cloneDeep(sub_total),
      termsAndDates: _.cloneDeep(termDate),
    });
    this.getCreditMemoNumber();
  };

  //set edit data
  setEditData = (creditMemoData) => {
    let { termsAndDates, invoiceDetails, attachedDocuments } = this.state;
    //terms and dates
    termsAndDates = _.cloneDeep({
      terms: {
        value: "Net " + creditMemoData.net_term,
      },
      invDate: {
        value: moment(creditMemoData.date).format("MM/DD/YYYY"),
      },
      dueDate: {
        value: moment(creditMemoData.due_date).format("MM/DD/YYYY"),
      },
      discount: {
        value: creditMemoData.criteria_discount_days,
      },
      discountRate: {
        value: creditMemoData.criteria_discount_percent_based
          ? creditMemoData.criteria_discount + "%"
          : "$" + creditMemoData.criteria_discount,
      },
      latefees: {
        value: creditMemoData.criteria_late_fee_percent_based
          ? creditMemoData.criteria_late_fee + "%"
          : "$" + creditMemoData.criteria_late_fee_percent_based,
      },
      isDiscountPercentage: {
        value: creditMemoData.criteria_discount_percent_based,
      },
      isLatefeesPercentage: {
        value: creditMemoData.criteria_late_fee_percent_based,
      },
    });

    //incoice items
    if (creditMemoData.credit_memo_lines.length) {
      invoiceDetails = [];
      creditMemoData.credit_memo_lines.map((item) => {
        if (!item.item_name.includes("Invoice Credit")) {
          this.clientServicesSearch(item.item_name, (service) => {
            invoiceDetails.push({
              taxable: {
                value: item.is_taxable,
              },
              service: {
                value: item.item_name,
              },
              itemDate: {
                // value: moment(new Date()).format("MM/DD/YYYY"),
                value: item.item_date
                  ? moment(item.item_date).utc().format("MM/DD/YYYY")
                  : moment(new Date()).utc().format("MM/DD/YYYY"),
              },
              description: {
                value: item.description,
              },
              hours: {
                value: item.qty,
              },
              rate: {
                value: item.unit_price,
              },
              total: {
                value: item.amount,
              },
              data: service,
            });
            this.setState({ invoiceDetails }, () => {
              this.setInvoiceSubTotal(creditMemoData);
            });
          });
        }
      });
    }

    this.getDummyClientOptions(creditMemoData.user);
    let tempDe = { ...creditMemoData.user };
    if (tempDe.user_contacts && tempDe.user_contacts.length) {
      tempDe.user_contacts = creditMemoData.user_contacts;
    } else {
      let obj = {
        contact_type: "TO_EMAIL",
        id: null,
        value: "",
      };
      obj.value = tempDe.email;
      let array = [];
      array.push(obj);
      tempDe.user_contacts = array;
    }
    this.setState({ clientDetails: tempDe });

    this.setState(
      {
        creditMemoNumber: { value: creditMemoData.credit_memo_number },
        clientDetails: tempDe,
        termsAndDates: termsAndDates,
        attachedDocuments: attachedDocuments,
        clientFileNo: creditMemoData.client_file_no,
        termsDiscountRateType: creditMemoData.criteria_discount_percent_based
          ? "PERCENT"
          : "DOLLAR",
        termsLateFeesType: creditMemoData.criteria_late_fee_percent_based
          ? "PERCENT"
          : "DOLLAR",
      },
      () => {
        this.getLinesSubTotal();
      }
    );
  };

  setInvoiceSubTotal = (invoiceData) => {
    let { invoiceSubTotal } = this.state;
    //invoice subtotal
    invoiceSubTotal = _.cloneDeep({
      total: {
        value: invoiceData.total_amount,
      },
      subTotal: {
        value: invoiceSubTotal.subTotal.value,
      },
      // discount: {
      //   value: this.getTaxAndDiscount(invoiceData, "discount"),
      // },
      discount: {
        value: this.getInvoiceCredit(invoiceData),
      },
      latefees: {
        value: this.getTaxAndDiscount(invoiceData, "lateFee"),
      },
      taxRate: {
        value: this.getTaxAndDiscount(invoiceData, "tax"),
      },
      discountAmount: {
        value: this.getTaxAndDiscount(invoiceData, "discountAmount"),
      },
      latefeeAmount: {
        value: this.getTaxAndDiscount(invoiceData, "lateFeeAmount"),
      },
    });

    this.setState({ invoiceSubTotal }, () => {
      this.setRadioButtonValue(invoiceData);
      this.getLinesSubTotal();
    });
  };

  //set radio button value for discount and late fee
  setRadioButtonValue = (data) => {
    let { invoiceDiscountType, invoiceLateFeesType } = this.state;
    if (data.tax_and_discount_line && data.tax_and_discount_line.length) {
      data.tax_and_discount_line.map((item) => {
        if (item.detail_type === "DiscountLineDetail") {
          invoiceDiscountType =
            item.discount_percent > 0
              ? "PERCENT"
              : item.amount > 0
              ? "DOLLAR"
              : "PERCENT";
        } else if (item.detail_type === "SalesItemLineDetail") {
          invoiceLateFeesType =
            item.discount_percent > 0
              ? "PERCENT"
              : item.amount > 0
              ? "DOLLAR"
              : "PERCENT";
        }
      });
    }
    this.setState({ invoiceDiscountType, invoiceLateFeesType });
  };

  getDummyClientOptions = (client) => {
    if (client && client.display_name) {
      this.state.clientList = [];
      this.setState({
        clientList: [
          { label: client.display_name, value: client.display_name },
        ],
      });
    }
  };

  //get invoice credit
  getInvoiceCredit = (invoiceData) => {
    let value = "";
    let type = "PERCENT";
    if (
      invoiceData.discount_percent_based !== null &&
      parseFloat(invoiceData.discount) > 0
    ) {
      if (invoiceData.discount_percent_based === true) {
        value = invoiceData.discount + "%";
        type = "PERCENT";
      } else {
        value = "$" + parseFloat(invoiceData.discount).toFixed(2);
        type = "DOLLAR";
      }
    }
    this.setState({ invoiceDiscountType: type }, () => {
      this.handleInvoiceSubTotalChange({
        target: {
          name: type === "PERCENT" ? "discount" : "discountAmount",
          value: value,
        },
      });
    });
    return invoiceData.discount;
  };

  //returns tax, discount and late fee
  getTaxAndDiscount = (data, type) => {
    let tax = 0;
    let discount = "";
    let discountAmount = "";
    let lateFee = "";
    let lateFeeAmount = "";
    if (data.tax_and_discount_line && data.tax_and_discount_line.length) {
      data.tax_and_discount_line.map((item) => {
        if (item.detail_type === "TaxLineDetail") {
          tax = item.discount_percent;
        } else if (item.detail_type === "DiscountLineDetail") {
          discount = this.getPercentAmount(item, "discount");
          discountAmount = this.getPercentAmount(item, "discountAmount");
        } else if (item.detail_type === "SalesItemLineDetail") {
          lateFee = this.getPercentAmount(item, "lateFee");
          lateFeeAmount = this.getPercentAmount(item, "lateFeeAmount");
        }
      });
    }
    if (type == "discount") {
      return this.getFloatingValue(discount);
    } else if (type == "discountAmount") {
      return this.getFloatingValue(discountAmount);
    } else if (type == "lateFee") {
      return this.getFloatingValue(lateFee);
    } else if (type == "lateFeeAmount") {
      return this.getFloatingValue(lateFeeAmount);
    }
    return this.getFloatingValue(tax);
  };

  getFloatingValue = (value) => {
    if (value > 0) {
      value = parseFloat(value).toFixed(2);
      value = parseFloat(value);
    }
    return value;
  };

  //if percent available return amount and if amount available return percent
  getPercentAmount = (data, type) => {
    let retValue = 0;
    let { invoiceDetails } = this.state;
    if (invoiceDetails.length) {
      let subTotal = 0;
      invoiceDetails.map((item) => {
        if (item.total.value) {
          subTotal = subTotal + parseFloat(item.total.value);
        }
      });

      if (subTotal > 0) {
        if (type == "discount" || type == "lateFee") {
          retValue =
            data.discount_percent > 0
              ? data.discount_percent
              : data.amount
              ? (data.amount * 100) / subTotal
              : 0;
        } else if (type == "discountAmount" || type == "lateFeeAmount") {
          retValue =
            data.amount > 0
              ? data.amount
              : data.discount_percent
              ? (data.discount_percent * subTotal) / 100
              : 0;
        }
      }
    }
    return retValue;
  };

  //get document type list
  getDocumentType = () => {
    let { user } = this.props;
    API.getDocumentType(user).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data) {
          if (data.data && data.data.length) {
            data.data.map((i) => {
              i.label = i.name;
              i.value = i.name;
            });
          }
          this.setState({ docTypeList: data.data });
        }
        this.setState({ isLoading: false });
      } else {
        this.setState({ isLoading: false });
        this.setState((state) => ({
          ...state,
          hasAnyError: true,
          disable: false,
        }));
      }
    });
  };

  //get new credit memo number
  getCreditMemoNumber = (isEdit) => {
    API.getCreditMemoNumber(this.props.user).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data) {
          this.state.creditMemoNumber.value = isEdit
            ? this.state.creditMemoNumber.value
            : data.data.credit_memo_number;
          this.setState(
            {
              creditMemoNumber: this.state.creditMemoNumber,
              qbSetting: data.data.qb_setting,
            },
            () => this.handleQBSetting()
          );
        }
        this.setState({ isLoading: false });
      } else {
        this.setState({ isLoading: false });
        this.setState((state) => ({
          ...state,
          hasAnyError: true,
          disable: false,
        }));
      }
    });
  };

  //TO_DO
  getClientTermsList = () => {
    // this.setState({ isLoading: true })
    let { location } = this.props;
    API.getTermsListInSettings(this.props.user).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data) {
          let newData = data.data;
          let x = newData.map((data, i) => {
            data.value = data.name;
            data.label = data.name;
          });
          this.setState((state) => ({
            ...state,
            termsList: newData,
          }));
          let invoiceData =
            location && location.state && location.state.invoiceDetails
              ? location.state.invoiceDetails
              : "";

          if (invoiceData && invoiceData.id) {
            this.setDefaultTermData(invoiceData, newData, "edit");
          } else {
            this.setDefaultTermData(invoiceData, newData, "create");
          }
        }
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            message: data && data.error ? data.error : "",
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  //TO_DO
  getTermName = (net_term) => {
    let finalTerm;
    if (
      net_term === "15" ||
      net_term === "30" ||
      net_term === "45" ||
      net_term === "60" ||
      net_term === "90" ||
      net_term === "120"
    ) {
      finalTerm = "Net " + net_term;
    } else {
      if (net_term) {
        finalTerm = net_term;
      } else {
        finalTerm = "";
      }
    }
    return finalTerm;
  };

  setTermEarlyDiscount = (
    early_payer_discount_rate_percent_based,
    early_payer_discount_rate
  ) => {
    let value = "";
    if (early_payer_discount_rate_percent_based) {
      if (early_payer_discount_rate) {
        value = early_payer_discount_rate + "%";
      } else {
        value = "0%";
      }
    } else {
      if (early_payer_discount_rate) {
        value = "$" + early_payer_discount_rate;
      } else {
        value = "$0";
      }
    }
    return value;
  };

  setTermLatePenalty = (
    late_payer_penalty_rate_percent_based,
    late_payer_penalty_rate
  ) => {
    let value = "";
    if (late_payer_penalty_rate_percent_based) {
      if (late_payer_penalty_rate) {
        value = late_payer_penalty_rate + "%";
      } else {
        value = "0%";
      }
    } else {
      if (late_payer_penalty_rate) {
        value = "$" + late_payer_penalty_rate;
      } else {
        value = "$0";
      }
    }
    return value;
  };

  //set default term data //TO_DO
  setDefaultTermData = (invoiceData, termList, type) => {
    let { termsAndDates, invoiceDetails, attachedDocuments } = this.state;

    if (termList && termList.length && type === "edit") {
      termList.map((term, termIndex) => {
        if (term && term.is_default === true) {
          termsAndDates = _.cloneDeep({
            terms: {
              value: this.getTermName(invoiceData.net_term),
            },
            invDate: {
              value: moment(invoiceData.date).format("MM/DD/YYYY"),
            },
            dueDate: {
              value: moment(invoiceData.due_date).format("MM/DD/YYYY"),
            },
            discount: {
              value: invoiceData.early_payer_discount_days
                ? invoiceData.early_payer_discount_days
                : "0",
            },
            // discountRate: {
            //   value: invoiceData.criteria_discount_percent_based ? invoiceData.criteria_discount + '%' : '$' + invoiceData.criteria_discount,
            // },
            discountRate: {
              value: this.setTermEarlyDiscount(
                invoiceData.criteria_discount_percent_based,
                invoiceData.criteria_discount
              ),
            },
            isDiscountPercentage: {
              value: invoiceData.criteria_discount_percent_based,
            },
            // latefees: {
            //   value: invoiceData.criteria_late_fee_percent_based ? invoiceData.criteria_late_fee + '%' : '$' + invoiceData.criteria_late_fee,
            // },
            latefees: {
              value: this.setTermLatePenalty(
                invoiceData.criteria_late_fee_percent_based,
                invoiceData.criteria_late_fee
              ),
            },
            isLatefeesPercentage: {
              value: invoiceData.criteria_late_fee_percent_based,
            },
          });
          return;
        }
      });
    } else {
      termList.map((term, termIndex) => {
        if (term && term.is_default === true) {
          termsAndDates = _.cloneDeep({
            terms: {
              value: term.name,
            },
            invDate: {
              value: moment(new Date()).format("MM/DD/YYYY"),
            },
            dueDate: {
              value: moment(moment().add(30, "days")).format("MM/DD/YYYY"),
            },
            discount: {
              value: term.early_payer_discount_days
                ? term.early_payer_discount_days
                : "0",
            },
            // discountRate: {
            //   value: term.early_payer_discount_rate_percent_based ? term.early_payer_discount_rate + '%' : '$' + term.early_payer_discount_rate,
            // },
            discountRate: {
              value: this.setTermEarlyDiscount(
                term.early_payer_discount_rate_percent_based,
                term.early_payer_discount_rate
              ),
            },
            isDiscountPercentage: {
              value: term.early_payer_discount_rate_percent_based,
            },
            // latefees: {
            //   value: term.late_payer_penalty_rate_percent_based ? term.late_payer_penalty_rate + '%' : '$' + term.late_payer_penalty_rate,
            // },
            latefees: {
              value: this.setTermLatePenalty(
                term.late_payer_penalty_rate_percent_based,
                term.late_payer_penalty_rate
              ),
            },
            isLatefeesPercentage: {
              value: term.late_payer_penalty_rate_percent_based,
            },
          });
          return;
        }
      });
    }
    this.setState({ termsAndDates });
  };

  //set tax based on qb setting
  handleQBSetting = () => {
    let { qbSetting, invoiceSubTotal } = this.state;
    if (qbSetting && qbSetting.using_sales_tax && qbSetting.tax_value > 0) {
      invoiceSubTotal.taxRate.value = qbSetting.tax_value;
      this.setState({ invoiceSubTotal });
    }
  };

  //close save/preview card modals
  handleOutsideClick(event) {
    if (
      this.previewDDRef &&
      this.previewDDRef.current &&
      !this.previewDDRef.current.contains(event.target)
    ) {
      this.setState({ previewDD: false, backPath: this.state.backPage });
      this.props.handleStateChange(this.state.backPath);
    }
    if (
      this.saveDDRef &&
      this.saveDDRef.current &&
      !this.saveDDRef.current.contains(event.target)
    ) {
      this.setState({ saveDD: false });
    }
  }

  //handle flag changes
  handleLateFeesTypeChange = (type) => {
    this.setState({ termsLateFeesType: type });
    this.updateTermsDataONTypeChange("latefees");
  };

  handleDiscountRateTypeChange = (type) => {
    this.setState({ termsDiscountRateType: type });
    this.updateTermsDataONTypeChange("discountRate");
  };

  //Udate in UI
  updateTermsDataONTypeChange = (name) => {
    let { termsAndDates } = this.state;
    let value = termsAndDates[name].value;
    termsAndDates[name].value =
      typeof value == "string"
        ? getStrippedDollarOrPercentAmount(value)
        : value;
    this.setState({ termsAndDates });
  };

  handleInvoiceDiscountTypeChange = (type) => {
    this.setState({ invoiceDiscountType: type }, () => {
      this.getLinesSubTotal();
    });
  };

  handleInvoiceLateFeesTypeChange = (type) => {
    this.setState({ invoiceLateFeesType: type }, () => {
      this.getLinesSubTotal();
    });
  };

  //on/off preview button card
  togglePreviewDD = () => {
    this.setState({ previewDD: !this.state.previewDD });
  };

  //on/off save button card
  toggleSaveDD = () => {
    this.setState({ saveDD: !this.state.saveDD });
  };

  //search api calling
  clientServicesSearch = (searchText, callback) => {
    API.clientServicesSearch(this.props.user, searchText).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data && data.data.length) {
          data.data.map((service) => {
            service.label = service.name;
            service.value = service.name;
          });
          this.setState({}, () => {
            //callback(data.data[0]);
            data.data.map((item, index) => {
              if (
                item.name &&
                item.name.trim().toLowerCase() === searchText.toLowerCase()
              ) {
                callback(data.data[index]);
              }
            });
          });
          if (searchText === "") {
            this.setState({ serviceList: data.data });
          }
        }
      } else {
        this.handleError(data);
      }
    });
  };

  //search for client accounts
  invoiceClientSearch = (searchText) => {
    API.invoiceClientSearch(this.props.user, searchText).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data && data.data.length) {
          data.data.map((client) => {
            client.label = client.display_name;
            client.value = client.display_name;
          });
          this.setState({ clientList: data.data });
        }
      } else {
        this.handleError(data);
      }
    });
  };

  handleClientFileChange = (e) => {
    this.setState({ clientFileNo: e.target.value });
  };

  //set search data
  saveClientDetails = (client) => {
    this.setState({ clientDetails: client, clientDetailsError: false }, () => {
      this.getDummyClientOptions(client);
    });
  };

  saveserviceDetails = (service, index) => {
    let { invoiceDetails } = this.state;
    if (invoiceDetails && invoiceDetails.length) {
      invoiceDetails.map((item, i) => {
        if (index == i && service) {
          Object.entries(item).forEach(([key, value]) => {
            if (key == "service") {
              item[key].value = service.name;
              item[key].hasError = false;
            } else if (key == "taxable") {
              item[key].value = service.is_taxable;
              item[key].hasError = false;
            } else if (key == "description") {
              item[key].value = service.description;
              item[key].hasError = false;
            } else if (key == "rate") {
              item[key].value = parseFloat(service.rate).toFixed(2);
              item[key].hasError = false;
            } else if (key == "hours") {
              item[key].value = "";
            }

            item.total.value = service.rate * item.hours.value;
            item.data = service;
          });
        }
      });
    }
    this.setState({ invoiceDetails }, () => {
      //set suntotal and overall total
      this.getLinesSubTotal();
    });
  };

  //handle input field change
  handleTermsAndDatesChange = (e) => {
    // let name = e.target.name;
    // let value = e.target.value;
    // let { termsAndDates } = this.state;
    // termsAndDates[name] = { value: value, hasError: false };
    // if (name == "terms") {
    //   let num = value.replace(/[^0-9]/g, "");
    //   num = num || 0;
    //   let nextDate = moment().add(num, "days");
    //   nextDate = moment(nextDate).format("MM/DD/YYYY");
    //   termsAndDates["dueDate"] = { value: nextDate, hasError: false };
    // }
    // this.setState({ termsAndDates });

    //MAK-490 term changes
    let nextDate;
    let name = e.target.name;
    let value = e.target.value;
    let { termsAndDates } = this.state;
    termsAndDates[name] = { value: value, hasError: false };
    if (name == "terms") {
      let num = value.replace(/[^0-9]/g, "");
      num = num || 0;
      nextDate = moment().add(num, "days");
      nextDate = moment(nextDate).format("MM/DD/YYYY");
      termsAndDates["dueDate"] = { value: nextDate, hasError: false };
    }

    termsAndDates = _.cloneDeep({
      terms: {
        value: e.target.data.name,
      },
      invDate: {
        value: moment(new Date()).format("MM/DD/YYYY"),
      },
      dueDate: {
        value: nextDate,
      },
      discount: {
        value: e.target.data.early_payer_discount_days
          ? e.target.data.early_payer_discount_days
          : "0",
      },
      // discountRate: {
      //   value: e.target.data.early_payer_discount_rate_percent_based ? e.target.data.early_payer_discount_rate + '%' : '$' + e.target.data.early_payer_discount_rate ,
      // },
      discountRate: {
        value: this.setTermEarlyDiscount(
          e.target.data.early_payer_discount_rate_percent_based,
          e.target.data.early_payer_discount_rate
        ),
      },
      isDiscountPercentage: {
        value: e.target.data.early_payer_discount_rate_percent_based,
      },
      // latefees: {
      //   value: e.target.data.late_payer_penalty_rate_percent_based ? e.target.data.late_payer_penalty_rate + '%' : '$' + e.target.data.late_payer_penalty_rate,
      // },
      latefees: {
        value: this.setTermLatePenalty(
          e.target.data.late_payer_penalty_rate_percent_based,
          e.target.data.late_payer_penalty_rate
        ),
      },
      isLatefeesPercentage: {
        value: e.target.data.late_payer_penalty_rate_percent_based,
      },
    });

    this.setState({ termsAndDates });
  };

  handleInvoiceDetailsChange = (e, index) => {
    let name = e.target.name;
    let value = e.target.value;
    let { invoiceDetails } = this.state;
    if (invoiceDetails[index].data.item_type === "Inventory") {
      value = e.target.value ? parseInt(e.target.value) : "";
    }
    invoiceDetails[index][name] = { value: value, hasError: false };

    //calculate total based on qty and rate
    if (name == "hours" || name == "rate") {
      let newValue = getStrippedDollarAmount(
        invoiceDetails[index]["rate"].value
      );

      invoiceDetails[index]["total"] = {
        value:
          Math.round(invoiceDetails[index]["hours"].value * newValue * 100) /
          100,
        hasError: false,
      };
    }
    this.setState({ invoiceDetails }, () => {
      if (name == "hours" || name == "rate" || name == "taxable") {
        //set suntotal and overall total
        this.getLinesSubTotal();
      }
    });
  };

  handlediscountLatefeeOnBlur = () => {
    let { invoiceSubTotal } = this.state;
    if (invoiceSubTotal.discountAmount.value) {
      invoiceSubTotal.discountAmount.value = parseFloat(
        invoiceSubTotal.discountAmount.value
      ).toFixed(2);
    }
    if (invoiceSubTotal.latefeeAmount.value) {
      invoiceSubTotal.latefeeAmount.value = parseFloat(
        invoiceSubTotal.latefeeAmount.value
      ).toFixed(2);
    }
    this.setState({ invoiceSubTotal });
  };

  handleInvoiceSubTotalChange = (e) => {
    let name = e.target.name;
    let value = getStrippedDollarOrPercentAmount(e.target.value);
    value = value == 0 ? "" : value;
    let { invoiceSubTotal } = this.state;
    invoiceSubTotal[name] = { value: value, hasError: false };
    this.setState({ invoiceSubTotal }, () => {
      this.getLinesSubTotal();
      this.calculateDiscountLateFeeAmount(name);
    });
  };

  calculateDiscountLateFeeAmount = (name) => {
    let { invoiceSubTotal } = this.state;
    if (invoiceSubTotal.subTotal.value > 0) {
      switch (name) {
        case "discount":
          invoiceSubTotal.discountAmount.value = invoiceSubTotal.discount.value
            ? (
                (invoiceSubTotal.subTotal.value *
                  invoiceSubTotal.discount.value) /
                100
              ).toFixed(2)
            : "";
          break;
        case "discountAmount":
          invoiceSubTotal.discount.value = invoiceSubTotal.discountAmount.value
            ? (invoiceSubTotal.discountAmount.value * 100) /
              invoiceSubTotal.subTotal.value
            : "";
          break;
        case "latefees":
          invoiceSubTotal.latefeeAmount.value = invoiceSubTotal.latefees.value
            ? (
                (invoiceSubTotal.subTotal.value *
                  invoiceSubTotal.latefees.value) /
                100
              ).toFixed(2)
            : "";
          break;
        case "latefeeAmount":
          invoiceSubTotal.latefees.value = invoiceSubTotal.latefeeAmount.value
            ? (invoiceSubTotal.latefeeAmount.value * 100) /
              invoiceSubTotal.subTotal.value
            : "";
          break;
      }
    }
    this.setState({ invoiceSubTotal: invoiceSubTotal });
  };

  //get invoice line subtotal and total
  getLinesSubTotal = () => {
    let { invoiceDetails, invoiceSubTotal } = this.state;
    let subTotal = 0;
    let finalTotal = 0;
    let taxableAmount = 0;
    if (invoiceDetails && invoiceDetails.length) {
      invoiceDetails.map((item) => {
        if (item.total.value) {
          // if taxable and taxrate then calculate subtotal and total
          if (item.taxable.value && invoiceSubTotal.taxRate.value > 0) {
            finalTotal =
              finalTotal +
              (item.total.value +
                (item.total.value * invoiceSubTotal.taxRate.value) / 100);
            taxableAmount = taxableAmount + item.total.value;
          } else {
            finalTotal = finalTotal + item.total.value;
          }
          subTotal = subTotal + parseFloat(item.total.value);
        }
      });
    }

    //save subtotal
    invoiceSubTotal.subTotal.value =
      subTotal > 0 ? parseFloat(subTotal).toFixed(2) : "";

    this.setState({ invoiceSubTotal, taxableSubTotal: taxableAmount }, () => {
      this.getLinesTotal(finalTotal);
    });
  };

  getLinesTotal = (finalTotal) => {
    let { invoiceSubTotal, invoiceDiscountType, invoiceLateFeesType } =
      this.state;
    let total = 0;
    let subTotal = invoiceSubTotal.subTotal.value || 0;
    let discount = invoiceSubTotal.discount.value || 0;
    let latefees = invoiceSubTotal.latefees.value || 0;
    let discountAmount = invoiceSubTotal.discountAmount.value || 0;
    let latefeeAmount = invoiceSubTotal.latefeeAmount.value || 0;

    if (subTotal > 0) {
      discount =
        invoiceDiscountType == "PERCENT"
          ? (subTotal * discount) / 100
          : discountAmount;
      latefees =
        invoiceLateFeesType == "PERCENT"
          ? (subTotal * latefees) / 100
          : latefeeAmount;

      total =
        parseFloat(finalTotal) - parseFloat(discount) + parseFloat(latefees);
      invoiceSubTotal.total.value = parseFloat(total).toFixed(2);
      this.setState({ invoiceSubTotal });
    }
  };

  handleNotesChange = (e, index) => {
    let name = e.target.name;
    let value = e.target.value;
    let { notesAndAttchments } = this.state;
    notesAndAttchments[index][name] = { value: value, hasError: false };
    this.setState({ notesAndAttchments });
  };

  handleDocumentChange = (e, note, index) => {
    let name = e.target.name;
    let value = e.target.value;
    let { attachedDocuments } = this.state;
    attachedDocuments[index][name] = {
      value: value,
      hasError: false,
      data: note,
    };
    this.setState({ attachedDocuments });
  };

  //Add more button click handling
  handleAddItemClick = (e) => {
    e.preventDefault();
    let { invoiceDetails } = this.state;
    invoiceDetails.push(_.cloneDeep(invoiceItem));
    this.setState({ invoiceDetails });
    this.clientServicesSearch("", () => {});
  };

  handleAddNotesClick = (e) => {
    e.preventDefault();
    let { notesAndAttchments } = this.state;
    notesAndAttchments.push(_.cloneDeep(note));
    this.setState({ notesAndAttchments });
  };

  handleAddDocumentClick = (e) => {
    e.preventDefault();
    let { attachedDocuments } = this.state;
    attachedDocuments.push(_.cloneDeep(attchedDoc));
    this.setState({ attachedDocuments });
  };

  //get invoice lines
  getInvoiceLines = () => {
    let { invoiceDetails } = this.state;
    let lines = [];
    if (invoiceDetails && invoiceDetails.length) {
      invoiceDetails.map((item) => {
        lines.push({
          service_id:
            item.data && item.data.service_id ? item.data.service_id : "",
          description: item.description.value,
          qty: item.hours.value,
          rate: getStrippedDollarAmount(item.rate.value),
          // date: item.itemDate.value,
          date: moment(item.itemDate.value).format("YYYY-MM-DD"),
          taxable: item.taxable.value,
        });
      });
    }
    return lines;
  };

  //get invoice notes
  getInvoiceNotes = () => {
    let { notesAndAttchments } = this.state;
    let user = this.props;
    let notes = [];
    if (notesAndAttchments && notesAndAttchments.length) {
      notesAndAttchments.map((item) => {
        if (item.note.value) {
          notes.push({
            note: item.note.value,
          });
        }
      });
    }
    return notes;
  };

  //attahced documents
  getInvoiceAttachedDoc = () => {
    let { attachedDocuments } = this.state;
    let documents = [];
    if (attachedDocuments && attachedDocuments.length) {
      attachedDocuments.map((item) => {
        let itemDoc = item.base64Doc;
        if (itemDoc && itemDoc.document && itemDoc.document.value) {
          documents.push({
            description: item.description.value,
            document: itemDoc.document ? itemDoc.document.value : "",
            document_name: itemDoc.fileExtension ? itemDoc.fileExtension : "",
            name: item.docName.value,
            file_size_in_mb: itemDoc.fileSizeInMB,
            document_type_id: item.docType.data ? item.docType.data.id : "",
          });
        }
      });
    }
    return documents;
  };

  //remove added items from invoice, notes and attachments
  handleCrossClick = (type, index) => {
    let { invoiceDetails, notesAndAttchments, attachedDocuments } = this.state;
    switch (type) {
      case "invoice-item":
        if (invoiceDetails && invoiceDetails.length) {
          invoiceDetails.splice(index, 1);
        }
        this.setState({ invoiceDetails }, () => {
          this.getLinesSubTotal();
        });
        break;
      case "notes":
        if (notesAndAttchments && notesAndAttchments.length) {
          notesAndAttchments.splice(index, 1);
        }
        this.setState({ notesAndAttchments });
        break;
      case "attachments":
        if (attachedDocuments && attachedDocuments.length) {
          attachedDocuments.splice(index, 1);
        }
        this.setState({ attachedDocuments });
        break;
      default:
        alert("Error: Something went wrong");
    }
  };

  scrollToTop = () => {
    let { history } = this.props;
    let a = document.createElement("a");
    a.href = "#invoice";
    document.body.appendChild(a);
    a.click();
    a.remove();
    history.push(`/client/add/creditMemo`);
  };

  ////handle ok click
  handleModalOk = () => {
    let { history } = this.props;

    this.setState({ showModal: false }, () => {
      // history.push(`/client/creditMemo/${this.state.credit_memo_id}`);
      // let backPath =
      // history.location && history.location.state && history.location.state.backPath
      //     ? history.location.state.backPath
      //     : "/client/creditMemo";
      history.push({
        pathname: `/client/creditMemo/${this.state.credit_memo_id}`,
        state: {
          accountPath: this.state.accountPath,
        },
      });
    });
  };

  //handle cancel
  handleCancelClick = () => {
    let { history, location } = this.props;
    // history.push({
    //   pathName: `${this.state.backPage}`,
    //   state: {backPage: history.location.state.prev_path ? history.location.state.prev_path : '/client/accounts'}
    // });

    history.push({
      pathname: this.state.backPage,
      state: {
        accountPath: this.state.accountPath,
      },
    });
  };

  //convert date YYYY/MM/DD to YYYY-MM-DD
  getFormattedDate = (date) => {
    if (date) return moment(new Date(date)).format("YYYY-MM-DD");
    return date;
  };

  //handle submit
  handleAddInvoice = (action, saveAndSend) => {
    let {
      credit_memo_id,
      creditMemoNumber,
      termsAndDates,
      clientDetails,
      termsDiscountRateType,
      termsLateFeesType,
      invoiceSubTotal,
      invoiceDiscountType,
      invoiceLateFeesType,
      clientFileNo,
      isEdit,
    } = this.state;
    if (this.validateForm()) {
      let payload = {
        credit_memo_number: creditMemoNumber.value,
        txn_date: this.getFormattedDate(termsAndDates.invDate.value),
        save_and_send: saveAndSend ? true : false,
        user_id:
          clientDetails && clientDetails.user_id
            ? clientDetails.user_id
            : clientDetails.id,
        lines: this.getInvoiceLines(),
        tax: invoiceSubTotal.taxRate ? invoiceSubTotal.taxRate.value : "0.00", //TO_DO
      };

      if (isEdit) {
        payload.credit_memo_id = credit_memo_id;
      } else {
        payload.notes = this.getInvoiceNotes();
        payload.attachments = this.getInvoiceAttachedDoc();
      }

      if (action == "PREVIEW") {
        this.handlePreviewPDFClick(payload);
      } else if (action == "ADDEDIT") {
        this.handleAddUpdateInvoice(payload);
      }
    }
  };

  //Add/Edit invoice api call
  handleAddUpdateInvoice = (payload) => {
    let { history } = this.props;
    this.setState({ isSubmit: true });
    API.addCreditMemo(this.props.user, payload).then((data) => {
      if (data && data.status_code) {
        this.setState({ isSubmit: false });
        this.scrollToTop();
        if (data.status_code === 200) {
          this.setState({
            successMessage: data.message,
            showModal: true,
            credit_memo_id: data.data,
          });
          this.clearData();
        } else if (data.status_code == 422) {
          Emitter.emit("NOTIFICATION_ALERT", true);
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
          history.push(`/client/accounts/${payload.user_id}`);
        } else {
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        }
      } else {
        this.setState({ isSubmit: false });
        this.scrollToTop();
        this.handleError(data);
      }
    });
  };

  //Preview pdf click
  handlePreviewPDFClick = (payload) => {
    const { user } = this.props;
    this.setState({ isSubmit: true });
    API.clientPreviewInvoice(user, payload).then((data) => {
      if (typeof data != "undefined" || (data != null && !data.error)) {
        let url = window.URL.createObjectURL(data);
        let a = document.createElement("a");
        a.href = url;
        a.download = `invoice-${payload.invoice_number}.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        this.setState({ isSubmit: false });
      } else {
        this.setState({ isSubmit: false });
        this.handleError(data);
      }
    });
  };

  validateForm = () => {
    let {
      creditMemoNumber,
      termsAndDates,
      clientDetails,
      invoiceDetails,
      invoiceSubTotal,
      notesAndAttchments,
      attachedDocuments,
      isEdit,
    } = this.state;
    let errorsArePresent = false;
    let isTaxable = false;

    if (!creditMemoNumber.value) {
      this.setState({
        creditMemoNumber: { ...creditMemoNumber, hasError: true },
      });
      errorsArePresent = true;
    }

    if (!clientDetails || (clientDetails && !clientDetails.display_name)) {
      this.setState({ clientDetailsError: true });
      errorsArePresent = true;
    }

    //terms and date validation
    // Object.entries(termsAndDates).forEach(([key, value]) => {
    //   if (key === "terms") {
    //     if (value.value === "" || value.value === null) {
    //       termsAndDates[key].hasError = true;
    //       errorsArePresent = true;
    //     }
    //   } else if (key === "invDate") {
    //     if (value.value === "" || value.value === null) {
    //       termsAndDates[key].hasError = true;
    //       errorsArePresent = true;
    //     }
    //   } else if (key === "dueDate") {
    //     if (value.value === "" || value.value === null) {
    //       termsAndDates[key].hasError = true;
    //       errorsArePresent = true;
    //     }
    //   }
    // });

    //invoice line validation
    if (invoiceDetails && invoiceDetails.length) {
      invoiceDetails.map((invoice, index) => {
        Object.entries(invoice).forEach(([key, value]) => {
          if (key === "service") {
            if (value.value === "" || value.value === null) {
              invoiceDetails[index][key].hasError = true;
              errorsArePresent = true;
            }
          } else if (key === "itemDate") {
            if (value.value && !isValidDate(value.value)) {
              invoiceDetails[index][key].hasError = true;
              errorsArePresent = true;
            }
          } else if (key === "hours") {
            if (value.value === "" || value.value === null) {
              invoiceDetails[index][key].hasError = true;
              errorsArePresent = true;
            }
          } else if (key === "taxable") {
            if (value.value) {
              isTaxable = true;
            }
          }
        });
      });
    }

    //attachment validation
    if (!isEdit && attachedDocuments && attachedDocuments.length) {
      attachedDocuments.map((note, index) => {
        let { docName, docType, base64Doc, description } = note;
        if (
          docName.value ||
          docType.value ||
          base64Doc.value ||
          description.value
        ) {
          if (
            !(
              docName.value &&
              docType.value &&
              base64Doc.value &&
              description.value
            )
          ) {
            Object.entries(note).forEach(([key, value]) => {
              if (key === "docName") {
                if (value.value === "" || value.value === null) {
                  attachedDocuments[index][key].hasError = true;
                  errorsArePresent = true;
                }
              } else if (key === "description") {
                if (value.value === "" || value.value === null) {
                  attachedDocuments[index][key].hasError = true;
                  errorsArePresent = true;
                }
              } else if (key === "docType") {
                if (value.value === "" || value.value === null) {
                  attachedDocuments[index][key].hasError = true;
                  errorsArePresent = true;
                }
              } else if (key === "base64Doc") {
                if (
                  value.document.value === "" ||
                  value.document.value === null
                ) {
                  errorsArePresent = true;
                }
              }
            });
          }
        } else {
          attachedDocuments[index]["docName"].hasError = false;
          attachedDocuments[index]["description"].hasError = false;
          attachedDocuments[index]["docType"].hasError = false;
          attachedDocuments[index]["base64Doc"].hasError = false;
        }
      });
    }

    //check for tax rate mandatory or not
    if (
      isTaxable &&
      invoiceSubTotal.taxRate &&
      !invoiceSubTotal.taxRate.value
    ) {
      invoiceSubTotal.taxRate.hasError = true;
      errorsArePresent = true;
    }

    this.setState({
      termsAndDates,
      invoiceDetails,
      invoiceSubTotal,
      notesAndAttchments,
      attachedDocuments,
    });
    if (errorsArePresent) {
      return false;
    } else {
      return true;
    }
  };

  //show api response error
  handleError = (data) => {
    this.setState((state) => ({
      ...state,
      isLoading: false,
      error: {
        hasAnyError: true,
        statusCode: data
          ? data.status ||
            (data.message && data.message == "Access denied." ? 401 : 500) ||
            500
          : 500,
      },
    }));
  };

  setBase64Doc = (data, index) => {
    let { attachedDocuments } = this.state;
    attachedDocuments[index].base64Doc = data;
    this.setState({ attachedDocuments });
  };

  headerContent = (header) => {
    return (
      <div>
        <div className='ic-wt-fields border-sep'>
          <div className='he-hed he-sec-hed'>{header}</div>
          <a
            key={shortid.generate()}
            className={`client-caret expander-caret consumer-brand ${
              false ? " down-caret" : ""
            }`}
            style={{ margin: "12px 0px 0px 12px" }}
          />
        </div>
      </div>
    );
  };

  innerButtons = (content) => {
    return (
      <div className='inner-btn-border'>
        <div className='half'>{content}</div>
      </div>
    );
  };

  renderCheckBoxes = (type, handleAction, isDisable) => {
    return (
      <div className='check-box-view'>
        <div className='inner-row'>
          <input
            type='radio'
            checked={type == "PERCENT" ? true : false}
            readOnly={isDisable && isDisable === true ? true : false}
            disabled={isDisable && isDisable === true ? true : false}
            style={{
              WebkitAppearance: `radio`,
              width: `18px`,
              MozAppearance: `radio`,
            }}
            onClick={() => handleAction("PERCENT")}
          />
          <label>Percent ( % )</label>
        </div>
        <div className='inner-row'>
          <input
            type='radio'
            readOnly={isDisable && isDisable === true ? true : false}
            disabled={isDisable && isDisable === true ? true : false}
            checked={type == "DOLLAR"}
            style={{
              WebkitAppearance: `radio`,
              width: `18px`,
              MozAppearance: `radio`,
            }}
            onClick={() => handleAction("DOLLAR")}
          />
          <label>Amount ( $ )</label>
        </div>
      </div>
    );
  };

  renderErrorMessage(input) {
    let message = "";
    if (input === "creditMemoNumber") {
      message = "Please enter credit memo number";
    } else {
      message = "Please complete this field";
    }

    return <div className='input-error-message'>{message}</div>;
  }

  handleModalChange = () => {
    toast.success(this.state.successMessage);
    this.handleModalOk();
  };

  render() {
    const {
      isSubmit,
      isLoading,
      error,
      isEdit,
      previewDD,
      saveDD,
      disabledClient,
      creditMemoNumber,
      termsAndDates,
      termsDiscountRateType,
      termsLateFeesType,
      invoiceDiscountType,
      invoiceLateFeesType,
    } = this.state;
    const { appName } = this.props;

    let title = isEdit ? "Edit Credit Memo" : "Add Credit Memo";

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return isLoading ? (
      <AppCanvasLoader />
    ) : (
      <div>
        <Helmet>
          <title>
            {appName} | {title}{" "}
          </title>
        </Helmet>
        {isSubmit ? (
          <div style={styles.loader}>
            <AppCanvasLoader />
          </div>
        ) : null}
        <AppSectionHeader
          title={title}
          optionalClassNames='create-invoice-utility'
        />
        <div className='app-sidebar-layout-canvas-content add-invoice scrollbar'>
          <div className='add-inv-inner-scroll scrollbar'>
            <div className='main-container'>
              <div
                id='invoice'
                className={`input-container invoice-fields half ${
                  creditMemoNumber.hasError ? " error" : ""
                }`}
              >
                <label htmlFor='invoice#'>Credit Memo #</label>
                <input
                  name='invoice#'
                  guide={"true"}
                  placeholder='12345'
                  type='number'
                  readOnly={isEdit ? true : false}
                  value={creditMemoNumber.value}
                  onChange={(e) =>
                    this.setState({
                      creditMemoNumber: {
                        value: e.target.value,
                        hasError: false,
                      },
                    })
                  }
                />
                {creditMemoNumber.hasError
                  ? this.renderErrorMessage("creditMemoNumber")
                  : null}
              </div>
              {/* <div
                id="invoice-file"
                className={`input-container invoice-fields half`}
              >
                <label htmlFor="fileno">Client File #</label>
                <input
                  autoComplete="off"
                  name="fileno"
                  placeholder="Optional"
                  type="text"
                  value={this.state.clientFileNo}
                  onChange={this.handleClientFileChange}
                />
              </div> */}
            </div>
            <Panel>
              <ClientAndContact
                disabledClient={disabledClient}
                headerContent={this.headerContent}
                innerButtons={this.innerButtons}
                clientList={this.state.clientList}
                clientDetails={this.state.clientDetails}
                saveClientDetails={this.saveClientDetails}
                invoiceClientSearch={this.invoiceClientSearch}
                clientDetailsError={this.state.clientDetailsError}
                handleClientFileChange={this.handleClientFileChange}
                updateModalState={this.props.updateModalState}
                setNewlyAddedClient={this.saveClientDetails}
              />
            </Panel>
            {/* <Panel>
              <TermsAndDates
                termsAndDates={termsAndDates}
                headerContent={this.headerContent}
                innerButtons={this.innerButtons}
                termsLateFeesType={termsLateFeesType}
                termsDiscountRateType={termsDiscountRateType}
                renderCheckBoxes={this.renderCheckBoxes}
                handleTermsAndDatesChange={this.handleTermsAndDatesChange}
                handleLateFeesTypeChange={this.handleLateFeesTypeChange}
                handleDiscountRateTypeChange={this.handleDiscountRateTypeChange}
                termsList={this.state.termsList}
              />
            </Panel> */}
            <Panel>
              <InvoiceDetails
                qbSetting={this.state.qbSetting}
                headerText={"Credit Details"}
                isEdit={this.state.isEdit}
                headerContent={this.headerContent}
                innerButtons={this.innerButtons}
                renderCheckBoxes={this.renderCheckBoxes}
                updateModalState={this.props.updateModalState}
                invoiceDiscountType={invoiceDiscountType}
                invoiceLateFeesType={invoiceLateFeesType}
                serviceList={this.state.serviceList}
                taxableSubTotal={this.state.taxableSubTotal}
                invoiceDetails={this.state.invoiceDetails}
                invoiceSubTotal={this.state.invoiceSubTotal}
                handleCrossClick={this.handleCrossClick}
                saveserviceDetails={this.saveserviceDetails}
                handleAddItemClick={this.handleAddItemClick}
                clientServicesSearch={this.clientServicesSearch}
                handleInvoiceDetailsChange={this.handleInvoiceDetailsChange}
                handlediscountLatefeeOnBlur={this.handlediscountLatefeeOnBlur}
                handleInvoiceSubTotalChange={this.handleInvoiceSubTotalChange}
                from={"credit_memo"}
                handleInvoiceDiscountTypeChange={
                  this.handleInvoiceDiscountTypeChange
                }
                handleInvoiceLateFeesTypeChange={
                  this.handleInvoiceLateFeesTypeChange
                }
              />
            </Panel>
            {isEdit ? null : (
              <Panel>
                <NotesAndAttachments
                  userList={this.state.userList}
                  docTypeList={this.state.docTypeList}
                  setBase64Doc={this.setBase64Doc}
                  headerContent={this.headerContent}
                  innerButtons={this.innerButtons}
                  handleCrossClick={this.handleCrossClick}
                  handleNotesChange={this.handleNotesChange}
                  handleAddNotesClick={this.handleAddNotesClick}
                  handleAddDocumentClick={this.handleAddDocumentClick}
                  attachedDocuments={this.state.attachedDocuments}
                  notesAndAttchments={this.state.notesAndAttchments}
                  handleDocumentChange={this.handleDocumentChange}
                />
              </Panel>
            )}
          </div>
          <div className='form-buttons'>
            <div className='dropdown-button' ref={this.previewDDRef}>
              {/* <button
                className={`drop-down-button`}
                onClick={this.togglePreviewDD}
              >
                <span>Preview</span>
                <div className={`caret ${previewDD ? " rotate" : ""}`}></div>
              </button>
              <ul
                className={`dropdown-menu ${previewDD ? " active" : ""}`}
                onClick={this.togglePreviewDD}
              >
                <MenuItem handleClick={(event) => {}}>
                  Preview as letter
                </MenuItem>
                <MenuItem handleClick={() => this.handleAddInvoice("PREVIEW")}>
                  Preview as PDF
                </MenuItem>
              </ul> */}
            </div>
            <div className='dropdown-button' ref={this.saveDDRef}>
              <button
                className={`drop-down-button`}
                onClick={this.toggleSaveDD}
              >
                <span>Save</span>
                <div className={`caret ${saveDD ? " rotate" : ""}`}></div>
              </button>
              <ul
                className={`dropdown-menu ${saveDD ? " active" : ""}`}
                style={{ top: "-55px" }}
                onClick={this.toggleSaveDD}
              >
                {/* <MenuItem
                  handleClick={() => this.handleAddInvoice("ADDEDIT", true)}
                >
                  Save & Send
                </MenuItem> */}
                <MenuItem handleClick={() => this.handleAddInvoice("ADDEDIT")}>
                  Save Only
                </MenuItem>
              </ul>
            </div>
            <div className='dropdown-button'>
              <button
                className={`drop-down-button cancel`}
                onClick={this.handleCancelClick}
              >
                <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>

        {/* show success modal with callback */}
        {/* {this.state.showModal ? <div className="curtain"></div> : ""}
          {this.state.showModal ? (
              <Modal
                  title={"Success!"}
                  hideModal={() => {
                    this.setState({ showModal: false });
                  }}
                  closeOnClickOutside={true}
              >
                <p style={{ textAlign: "center" }}>{this.state.successMessage}</p>
                <div className="modal-actions">
                  <a className="cta mg-brand2-color" onClick={this.handleModalOk}>
                    Ok
                  </a>
                </div>
              </Modal>
          ) : null} */}
        {this.state.showModal ? this.handleModalChange() : null}
      </div>
    );
  }
}

export default ClientsAddCreditMemo;
