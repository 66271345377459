import React, { Component } from "react";
import Select from "react-select";
import { withRouter, Redirect } from "react-router-dom";
import TextareaAutosize from "react-autosize-textarea";
import MenuItem from "../../../../components/MenuItem";
import $ from "jquery";
// API
import * as API from "../../../../utils/api";

// Packages
import "react-select/dist/react-select.css";
import MaskedTextInput from "react-text-mask";

// Components
import stateDataShortNames from "../../../../utils/stateDataShortNames.json";
import Panel from "../../../App/Panel";
import Modal from "../Modal";

import {
  validateEmail,
  showToast,
  getFormattedDateMMDDYYYY,
} from "../../../../utils/helpers";
import moment from "moment";
import ToggleSwitch from "../../../ToggleSwitch";

class SendNewLetterModal extends Component {
  state = {
    consumers: null,
    currentUserData: {},
    consumerData: {},
    creditors: [],
    consumer_name: "",
    toggleFlag: false,
    templateData: [],
    filterdTemplateData: [],
    letterOptionData: [
      {
        label: "Invoice",
        value: "Invoice",
      },
    ],
    mergeVaribales: [],
    mergeVaribalesValues: {},
    invoiceMergeVariable: {},
    checkArray: [],
    dropdownIsActive: false,
    mergeVaribalesValuesError: false,
    mailToUserOptions: [],
    mailFrom: {
      name: {
        value: "",
        hasError: false,
      },
      email: {
        value: "",
        hasError: false,
      },
      street_address_primary: {
        value: "",
        hasError: false,
      },
      street_address_secondary: {
        value: "",
        hasError: false,
      },
      city: {
        value: "",
        hasError: false,
      },
      state: {
        value: "",
        hasError: false,
      },
      zipcode: {
        value: "",
        hasError: false,
      },
    },
    mailTo: {
      name: {
        value: "",
        hasError: false,
      },
      email: {
        value: "",
        hasError: false,
      },
      street_address_primary: {
        value: "",
        hasError: false,
      },
      street_address_secondary: {
        value: "",
        hasError: false,
      },
      city: {
        value: "",
        hasError: false,
      },
      state: {
        value: "",
        hasError: false,
      },
      zipcode: {
        value: "",
        hasError: false,
      },
    },
    message: {
      value: "",
      label: "",
      hasError: false,
    },
    letterType: {
      label: "Invoice",
      value: "Invoice",
      hasError: false,
    },
    isLoading: true,
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
    showFlash: false,
    flashMessage: "",
    pageCount: 1,
    pageSize: 6,
    prefData: [],
    returnEnvelope: false,
    perforatedPage: null,
  };

  componentDidMount() {
    if (
      this.props &&
      this.props.optionalProps &&
      this.props.optionalProps.consumerId
    ) {
      this.getContactLetter(this.props.optionalProps.consumerId);
    }
    this.getCurrentUserData();
    this.getMiscellaneousSettingStatus();
    this.getInvoiceLetterMergeVariables();
    API.getLobTemplate(this.props.user, this.props.optionalProps.type).then(
      (data) => {
        if ((typeof data != "undefined" || data != null) && !data.error) {
          if (data.error) {
            showToast(data.error);
          } else {
            if (data.data) {
              this.setState({ templateData: data.data }, () => {
                this.setTemplateData();
              });
            }
          }
        } else {
          this.setState((state) => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.message && data.message == "Access denied."
                    ? 401
                    : 500) ||
                  500
                : 500,
            },
          }));
        }
      }
    );
  }

  getContactLetter = (id) => {
    API.getContactLetter(this.props.user, id).then((data) => {
      if (data && data.status_code == 200 && data.data) {
        this.handleClickAction(data.data);
      }
    });
  };

  //get payment preferences data
  getMiscellaneousSettingStatus = () => {
    API.getMiscellaneousInSettings(this.props.user).then((data) => {
      if (typeof data != "undefined" || data != null) {
        if (data.data) {
          this.setState({ prefData: data.data }, () => {
            this.setReturnEnvelopePerfortedPage();
          });
        }
        this.setState({ isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
          isLoading: false,
        }));
      }
    });
  };

  getInvoiceLetterMergeVariables = () => {
    const { optionalProps } = this.props;
    const { invoiceDetails } = optionalProps;
    API.getLetterMergeVariables(this.props.user, invoiceDetails.id).then(
      (data) => {
        if ((typeof data != "undefined" || data != null) && !data.error) {
          if (data.error) {
            showToast(data.error);
          } else {
            if (data.data) {
              this.setState(
                {
                  invoiceMergeVariable: data.data,
                },
                () => {
                  this.setMergeVaribleData();
                }
              );
              this.setCurrentUserData(data.data);
            }
          }
        } else {
          this.setState((state) => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.message && data.message == "Access denied."
                    ? 401
                    : 500) ||
                  500
                : 500,
            },
          }));
        }
      }
    );
  };

  getMergeVariablesForTemplate = (id) => {
    API.getMergeVariablesForLobTemplate(this.props.user, id).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        if (data.error) {
          showToast(data.error);
        } else {
          if (data.data) {
            this.setState(
              {
                mergeVaribales: data.data,
                mergeVaribalesValuesError: false,
              },
              () => {
                this.setMergeVaribleData();
              }
            );
          }
        }
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  getCurrentUserData() {
    let { user } = this.props;
    API.getCleintAdminUser(user).then((data) => {
      let isAccessDenied =
        data && data.message && data.message == "Access denied." ? true : false;

      if (
        (typeof data != "undefined" || data != null) &&
        !data.error &&
        !isAccessDenied
      ) {
        if (data.error) {
          showToast(data.error);
        } else {
          if (data.user) {
            this.setState({ currentUserData: data.user }, () => {
              // this.setCurrentUserData(data.user);
              this.setMergeVaribleData();
            });
          }
        }
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  }

  setCurrentUserData(data) {
    if (data) {
      if (data.client_company_name) {
        this.state.mailFrom.name.value = data.client_company_name;
      }
      if (data.billing_contact_email) {
        this.state.mailFrom.email.value = data.billing_contact_email;
      }
      if (data.payment_address.line1) {
        this.state.mailFrom.street_address_primary.value =
          data.payment_address.line1;
      }
      if (data.payment_address.line2) {
        this.state.mailFrom.street_address_secondary.value =
          data.payment_address.line2;
      }
      if (data.payment_address.city) {
        this.state.mailFrom.city.value = data.payment_address.city;
      }
      if (data.payment_address.state) {
        this.state.mailFrom.state.value = data.payment_address.state;
      }
      if (data.payment_address.zip) {
        this.state.mailFrom.zipcode.value = data.payment_address.zip;
      }

      this.setState({ mailFrom: this.state.mailFrom });
    }
  }

  setLineItemData = (item, index) => {
    //this.state.mergeVaribalesValues[item] = value;
    let item_name = "item_" + index;
    let description = "description_" + index;
    let quantity = "quantity_" + index;
    let rate = "rate_" + index;
    let total = "total_" + index;
    let taxable = "taxable_" + index;
    this.state.mergeVaribalesValues[item_name] = item.item_name
      ? item.item_name
      : "";
    this.state.mergeVaribalesValues[description] = item.description
      ? item.description
      : "";
    this.state.mergeVaribalesValues[quantity] = item.qty ? item.qty : "";
    this.state.mergeVaribalesValues[rate] = item.unit_price
      ? item.unit_price
      : "";
    this.state.mergeVaribalesValues[total] = item.amount
      ? parseFloat(item.amount).toFixed(2)
      : item.amount;
    this.state.mergeVaribalesValues[taxable] = item.is_taxable
      ? item.is_taxable
      : item.is_taxable;

    this.setState({
      mergeVaribalesValues: this.state.mergeVaribalesValues,
    });
  };

  // gettig primary color only multiple invoices
  setMergeVaribleData = () => {
    const { optionalProps } = this.props;
    const { invoiceDetails } = optionalProps;
    const { prefData, invoiceMergeVariable } = this.state;

    //invoice line item data
    if (
      invoiceDetails &&
      invoiceDetails.invoice_lines &&
      invoiceDetails.invoice_lines.length
    ) {
      let pageCount = Math.ceil(
        invoiceDetails.invoice_lines.length / this.state.pageSize
      );
      let multiple_pages = "multiple_pages";
      let _multiple_pages = "_multiple_pages";
      let multiple_pages_ = "multiple_pages_";
      let show_account = "show_account";
      let _show_account = "_show_account";
      this.setState({ pageCount: pageCount });
      if (pageCount > 2) {
        this.state.mergeVaribalesValues[multiple_pages] = true;
        this.state.mergeVaribalesValues[_multiple_pages] = true;
        this.state.mergeVaribalesValues[multiple_pages_] = true;
      } else {
        this.state.mergeVaribalesValues[multiple_pages] = false;
        this.state.mergeVaribalesValues[_multiple_pages] = false;
        this.state.mergeVaribalesValues[multiple_pages_] = false;
      }

      if (prefData && prefData.length) {
        if (
          prefData[0].data[0].is_enable &&
          invoiceDetails.user.account_number &&
          invoiceDetails.user.account_number !== ""
        ) {
          this.state.mergeVaribalesValues[show_account] = false;
          this.state.mergeVaribalesValues[_show_account] = false;
        } else if (
          !prefData[0].data[0].is_enable &&
          invoiceDetails.user.account_number &&
          invoiceDetails.user.account_number !== ""
        ) {
          this.state.mergeVaribalesValues[show_account] = true;
          this.state.mergeVaribalesValues[_show_account] = true;
        } else if (
          prefData[0].data[0].is_enable &&
          !(
            invoiceDetails.user.account_number ||
            invoiceDetails.user.account_number === ""
          )
        ) {
          this.state.mergeVaribalesValues[show_account] = false;
          this.state.mergeVaribalesValues[_show_account] = true;
        }
      } else {
        this.state.mergeVaribalesValues[show_account] = false;
        this.state.mergeVaribalesValues[_show_account] = false;
      }

      invoiceDetails.invoice_lines.map((lineItem, index) => {
        if (
          lineItem.detail_type === "SalesItemLineDetail" &&
          lineItem.item_name &&
          !lineItem.item_name.includes("Early Payer Dis") &&
          !lineItem.item_name.includes("Slow Payer Penalty")
        )
          this.setLineItemData(lineItem, index + 1);
      });

      // Invoice Other data
      let balance_due = "balance_due";
      let invoice_date = "invoice_date";
      let invoice_number = "invoice_number";
      let due_date = "due_date";
      let net_terms = "net_terms";
      let client_company_name = "client_company_name";
      let discount_date = "discount_date";
      let discount_percent = "discount_percent";
      let subtotal = "subtotal";
      let taxes = "taxes";
      let discounts = "discounts";
      let late_fees = "late_fees";
      let billing_contact_email = "billing_contact_email";
      let phone_number_call = "phone_number_call";
      let account_number = "account_number";
      let account_name = "account_name";

      if (
        invoiceDetails &&
        invoiceDetails.criteria_discount_percent_based === false
      ) {
        this.state.mergeVaribalesValues[discounts] =
          invoiceDetails.criteria_discount;
      } else {
        this.state.mergeVaribalesValues[discounts] = "";
      }

      if (
        invoiceDetails.criteria_discount_days &&
        invoiceDetails.criteria_discount_days > 0
      ) {
        let startdate = invoiceDetails.date;
        let targetDate;
        // var new_date = moment(startdate, "YYYY-MM-DD").add(5, "days");
        let nn = moment(
          moment(startdate)
            .add(invoiceDetails.criteria_discount_days, "days")
            .format("YYYY/MM/DD")
        );
        let today = new Date();
        targetDate = moment(nn);
        var month = today.getUTCMonth() + 1; //months from 1-12
        var day = today.getUTCDate();
        var year = today.getUTCFullYear();

        let newdate = year + "/" + month + "/" + day;
        let momentToday = moment(newdate);
        let diffNumber = targetDate.diff(momentToday, "days");
        if (diffNumber >= 0) {
          if (invoiceDetails.criteria_discount_percent_based === true) {
            this.state.mergeVaribalesValues[discount_percent] =
              invoiceDetails.criteria_discount;
          } else if (invoiceDetails.criteria_discount_percent_based === false) {
            // let calcPercent = (parseFloat(invoiceDetails.total_amount) / 100) * parseFloat(invoiceDetails.criteria_discount)
            let calcPercent =
              (parseFloat(invoiceDetails.criteria_discount) /
                parseFloat(invoiceDetails.total_amount)) *
              100;
            this.state.mergeVaribalesValues[discount_percent] =
              calcPercent.toFixed(2);
          } else {
            this.state.mergeVaribalesValues[discount_percent] = "";
          }
        } else {
          this.state.mergeVaribalesValues[discount_percent] = "";
        }
      } else {
        this.state.mergeVaribalesValues[discount_percent] = "";
      }
      this.state.mergeVaribalesValues[discount_date] =
        invoiceDetails.criteria_discount_days
          ? moment(
              moment(invoiceDetails.date).add(
                Number(invoiceDetails.criteria_discount_days),
                "days"
              )
            ).format("MM/DD/YYYY")
          : "";
      this.state.mergeVaribalesValues[balance_due] = invoiceDetails.balance
        ? parseFloat(invoiceDetails.balance).toFixed(2)
        : "";
      this.state.mergeVaribalesValues[invoice_date] = invoiceDetails.date
        ? invoiceDetails.date
        : "";
      this.state.mergeVaribalesValues[invoice_number] =
        invoiceDetails.invoice_number ? invoiceDetails.invoice_number : "";
      this.state.mergeVaribalesValues[due_date] = invoiceDetails.due_date
        ? invoiceDetails.due_date
        : "";
      this.state.mergeVaribalesValues[net_terms] = invoiceDetails.net_term
        ? invoiceDetails.net_term
        : "";
      this.state.mergeVaribalesValues[subtotal] =
        invoiceDetails.invoice_lines && invoiceDetails.invoice_lines.length
          ? this.getSubtotal(invoiceDetails.invoice_lines)
          : "";
      this.state.mergeVaribalesValues[taxes] =
        invoiceDetails.tax_and_discount_line &&
        invoiceDetails.tax_and_discount_line.length &&
        invoiceDetails.tax_and_discount_line[0].amount
          ? invoiceDetails.tax_and_discount_line[0].amount
          : "0.0";
      this.state.mergeVaribalesValues[billing_contact_email] =
        invoiceDetails.user && invoiceDetails.user.billing_primary_email
          ? invoiceDetails.user.billing_primary_email
          : "";
      this.state.mergeVaribalesValues[phone_number_call] =
        invoiceDetails.user && invoiceDetails.user.billing_phone_no
          ? invoiceDetails.user.billing_phone_no
          : "";

      if (
        this.state.mergeVaribales &&
        this.state.mergeVaribales.length &&
        this.state.mergeVaribales.includes("account_number")
      ) {
        this.state.mergeVaribalesValues[account_number] =
          invoiceDetails.user && invoiceDetails.user.account_number
            ? invoiceDetails.user.account_number
            : "";
      }
      if (
        this.state.mergeVaribales &&
        this.state.mergeVaribales.length &&
        this.state.mergeVaribales.includes("account_name")
      ) {
        this.state.mergeVaribalesValues[account_name] =
          invoiceDetails.user && invoiceDetails.user.first_name
            ? invoiceDetails.user.first_name +
              " " +
              invoiceDetails.user.last_name
            : "";
      }

      if (Object.keys(invoiceMergeVariable).length != 0) {
        this.state.mergeVaribalesValues["billing_contact_email"] =
          invoiceMergeVariable.billing_contact_email;
        this.state.mergeVaribalesValues["my_account_link"] =
          invoiceMergeVariable.my_account_link;
        this.state.mergeVaribalesValues["client_company_name"] =
          invoiceMergeVariable.client_company_name;
        this.state.mergeVaribalesValues["phone_number_call"] =
          invoiceMergeVariable.phone_number_call;
        this.state.mergeVaribalesValues["primary_color"] =
          invoiceMergeVariable.primary_color;
        this.state.mergeVaribalesValues["qr_code"] =
          invoiceMergeVariable.qr_code;
        this.state.mergeVaribalesValues["secondary_color"] =
          invoiceMergeVariable.secondary_color;
        this.state.mergeVaribalesValues["client_logo"] =
          invoiceMergeVariable.client_logo;
        this.state.mergeVaribalesValues["return_address"] =
          invoiceMergeVariable.payment_address.line1 +
          (invoiceMergeVariable.payment_address.hasOwnProperty("line2")
            ? " " + invoiceMergeVariable.payment_address.line2
            : "");
        this.state.mergeVaribalesValues["return_city"] =
          invoiceMergeVariable.payment_address.city;
        this.state.mergeVaribalesValues["return_state"] =
          invoiceMergeVariable.payment_address.state;
        this.state.mergeVaribalesValues["return_zip"] =
          invoiceMergeVariable.payment_address.zip;
      }

      this.setState({
        mergeVaribalesValues: this.state.mergeVaribalesValues,
      });
    }
  };

  getSubtotal = (data) => {
    let subTotal = 0;
    if (data && data.length) {
      data.map((item) => {
        if (
          item.detail_type === "SalesItemLineDetail" &&
          item.item_name &&
          !item.item_name.includes("Early Payer Dis") &&
          !item.item_name.includes("Slow Payer Penalty")
        ) {
          item.amount = parseFloat(item.amount);
          subTotal = subTotal + item.amount;
        }
      });
    }
    return subTotal.toFixed(2);
  };

  setTemplateData() {
    if (this.state.templateData) {
      this.state.templateData.map((item, index) => {
        let data = {
          label: item.name ? item.name : "",
          value: item.id ? item.id : "",
          data: item ? item : "",
        };
        this.state.filterdTemplateData.push(data);
        this.setState({ filterdTemplateData: this.state.filterdTemplateData });
      });
    }
  }

  setReturnEnvelopePerfortedPage = () => {
    const { prefData } = this.state;
    prefData.map((item, index) => {
      if (
        item.data[0].is_enable &&
        item.data[0].name ==
          "Use Perforated Edges and Return Envelopes for Invoices"
      ) {
        this.setState({
          returnEnvelope: true,
          perforatedPage: this.state.pageCount,
        });
      }
    });
  };

  handleInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      mailFrom: {
        ...state.mailFrom,
        ...newPartialInput,
      },
    }));
  }

  handleInputChangeOfMailTo(newPartialInput) {
    this.setState((state) => ({
      ...state,
      mailTo: {
        ...state.mailTo,
        ...newPartialInput,
      },
    }));
  }

  handleInputChangeForMsg(msg, data) {
    if (msg && msg.message && msg.message.value) {
      this.getMergeVariablesForTemplate(msg.message.value);
    }

    this.setState({
      ...this.state.message.value,
      ...msg,
    });
  }

  handleInputChangeForLetterType(msg, data) {
    this.setState({
      ...this.state.letterType.value,
      ...msg,
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const { mailFrom, mailTo, message, mergeVaribalesValues } = this.state;
    const { optionalProps } = this.props;
    const { invoiceDetails } = optionalProps;

    if (this.validateForm()) {
      let mailData = {
        from_name: mailFrom.name.value,
        from_email: mailFrom.email.value,
        from_street_address1: mailFrom.street_address_primary.value,
        from_street_address2: mailFrom.street_address_secondary.value,
        from_city: mailFrom.city.value,
        from_state: mailFrom.state.value,
        from_zipcode: mailFrom.zipcode.value,
        to_name: mailTo.name.value,
        to_email: mailTo.email.value,
        to_street_address1: mailTo.street_address_primary.value,
        to_street_address2: mailTo.street_address_secondary.value,
        to_city: mailTo.city.value,
        to_state: mailTo.state.value,
        to_zipcode: mailTo.zipcode.value,
        template_name: message.label,
        merge_variables: mergeVaribalesValues,
        template_id: message.value,
        letter_type: this.state.letterType.value,
        page_count: this.state.pageCount,
        return_envelope: this.state.returnEnvelope,
        perforated_page: this.state.perforatedPage,
        to_account_number: invoiceDetails.user.account_number,
        invoice_id: invoiceDetails.id,
      };
      $("#send-lob").replaceWith('<div class="spinner"></div>');
      API.sendMailByLob(this.props.user, mailData).then((data) => {
        if ((typeof data != "undefined" || data != null) && !data.error) {
          if (data.status_code != 200) {
            this.handleModalClose();
            this.props.updateModalState(true, "ERROR", {
              message: data.message,
              title: "Error!",
            });
          } else {
            this.handleModalClose();
            optionalProps.reloadData();
            this.props.updateModalState(true, "SUCCESS", {
              message: data.message,
            });
          }
        } else {
          this.setState((state) => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.message && data.message == "Access denied."
                    ? 401
                    : 500) ||
                  500
                : 500,
            },
          }));
        }
      });
    }
  };

  validateForm = () => {
    let errorsArePresent = false;

    // Determine which input group to check for errors
    let mailFrom = { ...this.state.mailFrom };
    let mailTo = { ...this.state.mailTo };
    let message = { ...this.state.message };
    let letterType = { ...this.state.letterType };

    // Check mailFrom for errors
    Object.entries(mailFrom).forEach(([key, value]) => {
      if (key === "name") {
        if (value.value === "") {
          mailFrom[key].hasError = true;
          errorsArePresent = true;
        }
      } else if (key === "email") {
        if (value.value === "" && !validateEmail(value.value)) {
          // if (value.value && !validateEmail(value.value))
          mailFrom[key].hasError = true;
          errorsArePresent = true;
        }
      } else if (key === "street_address_primary") {
        if (value.value === "") {
          mailFrom[key].hasError = true;
          errorsArePresent = true;
        }
      } else if (key === "zipcode") {
        if (value.value === "" || value.value.trim().length !== 5) {
          mailFrom[key].hasError = true;
          errorsArePresent = true;
        }
      } else if (key === "city") {
        if (value.value === "") {
          mailFrom[key].hasError = true;
          errorsArePresent = true;
        }
      } else if (key === "state") {
        if (value.value === "") {
          mailFrom[key].hasError = true;
          errorsArePresent = true;
        }
      } else if (value.isRequired && value.value === "") {
        mailFrom[key].hasError = true;
        errorsArePresent = true;
      }
    });

    // Check mailTo for errors
    Object.entries(mailTo).forEach(([key, value]) => {
      if (key === "name") {
        if (value.value === "") {
          mailTo[key].hasError = true;
          errorsArePresent = true;
        }
      }
      //  else if (key === "email") {
      //   // if (value.value && !validateEmail(value.value))
      //   if (value.value == "" && !validateEmail(value.value)) {
      //     mailTo[key].hasError = true;
      //     errorsArePresent = true;
      //   }
      // }
      else if (key === "street_address_primary") {
        if (value.value === "") {
          mailTo[key].hasError = true;
          errorsArePresent = true;
        }
      } else if (key === "zipcode") {
        if (value.value === "" || value.value.trim().length !== 5) {
          mailTo[key].hasError = true;
          errorsArePresent = true;
        }
      } else if (key === "city") {
        if (value.value === "") {
          mailTo[key].hasError = true;
          errorsArePresent = true;
        }
      } else if (key === "state") {
        if (value.value === "") {
          mailTo[key].hasError = true;
          errorsArePresent = true;
        }
      } else if (value.isRequired && value.value === "") {
        mailTo[key].hasError = true;
        errorsArePresent = true;
      }
    });

    if (message.value == "") {
      message.hasError = true;
      errorsArePresent = true;
    }

    if (letterType.value == "") {
      letterType.hasError = true;
      errorsArePresent = true;
    }
    let check =
      this.state.checkArray.length === this.state.mergeVaribales.length;
    if (!this.state.mergeVaribalesValues) {
      this.setState({ mergeVaribalesValuesError: true });
      errorsArePresent = true;
    }

    if (errorsArePresent) {
      this.setState((state) => ({
        ...state,
        mailFrom: mailFrom,
        mailTo: mailTo,
        message: message,
        letterType: letterType,
      }));

      return false;
    } else {
      return true;
    }
  };

  renderErrorMessage(input) {
    let message = "";

    if (input === "name") {
      message = "Please enter name";
    } else if (input === "email") {
      message = "Please enter email Id";
    } else if (input === "zipcode") {
      message = "Enter a valid zipcode";
    } else if (input === "street_address_primary") {
      message = "Please enter street address";
    } else if (input === "city") {
      message = "Please enter city name";
    } else if (input === "state") {
      message = "Please select state";
    } else if (input === "message") {
      message = "Please select template";
    } else if (input === "letterType") {
      message = "Please select letter type";
    } else {
      message = "Please complete this field";
    }

    return <div className='input-error-message'>{message}</div>;
  }

  handleModalClose = () => {
    this.props.hideModal();
  };

  dismissFlash = () => {
    this.handleModalClose();
    this.setState((state) => ({
      ...state,
      showFlash: false,
      flashMessage: "",
    }));
  };

  handleMergeVariableValue(value, item) {
    let data = {};
    if (
      item === "#multiple_pages" ||
      item === "/multiple_pages" ||
      item === "^multiple_pages" ||
      item === "#show_account" ||
      item === "/show_account"
    ) {
      item = item.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "");
    }
    data[item] = value;
    let index = -1;
    index = this.state.checkArray.findIndex((o) => Object.keys(o)[0] === item);

    if (index === -1) {
      this.state.checkArray.push(data);
    }
    if (value === "" || value === null || value === undefined) {
      this.state.checkArray.splice(index, 1);
    }

    this.state.mergeVaribalesValues[item] = value;

    if (!this.state.mergeVaribalesValues[item]) {
      this.setState({ mergeVaribalesValuesError: true });
    } else {
      this.setState({
        mergeVaribalesValuesError: false,
      });
    }

    this.setState({
      mergeVaribalesValues: this.state.mergeVaribalesValues,
    });
  }

  getMergeVariableValue = (item) => {
    let {
      mergeVaribalesValues,
      mergeVaribalesValuesError,
      invoiceMergeVariable,
    } = this.state;
    let finalValue;
    if (item == "#multiple_pages") {
      finalValue = mergeVaribalesValues["multiple_pages"];
    } else if (item == "/multiple_pages") {
      finalValue = mergeVaribalesValues["_multiple_pages"];
    } else if (item == "^multiple_pages") {
      finalValue = mergeVaribalesValues["multiple_pages_"];
    } else if (item == "#show_account") {
      finalValue = mergeVaribalesValues["show_account"];
    } else if (item == "/show_account") {
      finalValue = mergeVaribalesValues["_show_account"];
    } else if (item == "due_date") {
      finalValue = getFormattedDateMMDDYYYY(mergeVaribalesValues["due_date"]);
    } else if (item == "invoice_date") {
      finalValue = getFormattedDateMMDDYYYY(
        mergeVaribalesValues["invoice_date"]
      );
    } else if (item.includes("due_date_")) {
      finalValue = getFormattedDateMMDDYYYY(mergeVaribalesValues[item]);
    }
    return finalValue;
  };

  renderMergeVaribles(item, index) {
    let { mergeVaribalesValues, mergeVaribalesValuesError } = this.state;
    return (
      <div className='admin-form-row' key={index.toString()}>
        <div className={`input-container half`}>
          <label htmlFor='name'>{item}</label>
          {item == "due_date" ||
          item == "invoice_date" ||
          item.includes("due_date_") ? (
            <MaskedTextInput
              name='phone'
              mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
              placeholder='mm/dd/yyyy'
              placeholderChar={"\u2000"}
              guide={true}
              type='text'
              disabled={this.getMergeVariableValue(item) ? true : false}
              value={this.getMergeVariableValue(item)}
              onChange={(event) =>
                this.handleMergeVariableValue(event.target.value, item)
              }
            />
          ) : (
            <input
              name={item}
              placeholder={"Enter " + item}
              type='text'
              value={
                item == "#multiple_pages" ||
                item == "/multiple_pages" ||
                item == "^multiple_pages" ||
                item == "#show_account" ||
                item == "/show_account"
                  ? this.getMergeVariableValue(item)
                  : mergeVaribalesValues[item]
              }
              onChange={(event) =>
                this.handleMergeVariableValue(event.target.value, item)
              }
            />
          )}
        </div>
      </div>
    );
  }

  toggleDropdown = (event) => {
    event.preventDefault();

    this.setState({
      dropdownIsActive: !this.state.dropdownIsActive,
    });
  };

  handleClickOutside = (event) => {
    if (this.state.dropdownIsActive) {
      this.toggleDropdown(event);
    }
  };

  showSearchDropDown() {
    return (
      <li
        className={`dropdown-menu search-name${
          this.state.dropdownIsActive ? " active" : ""
        }`}
      >
        {this.state.mailToUserOptions &&
          this.state.mailToUserOptions.length &&
          this.state.mailToUserOptions.map((item) => {
            return (
              <MenuItem
                handleClick={(event) => {
                  this.handleClickAction(item);
                  this.handleClickOutside(event);
                }}
              >
                {item.first_name + " " + (item.last_name ? item.last_name : "")}
              </MenuItem>
            );
          })}
      </li>
    );
  }

  handleClickAction = (item) => {
    let { invoiceDetails } = this.props.optionalProps;
    if (invoiceDetails && invoiceDetails.user) {
      if (invoiceDetails.user.first_name) {
        this.state.mailTo.name.value =
          invoiceDetails.user.first_name +
          " " +
          (invoiceDetails.user.last_name ? invoiceDetails.user.last_name : "");
      }
      if (invoiceDetails.user.email) {
        this.state.mailTo.email.value = invoiceDetails.user.email;
      }
    }
    if (item) {
      if (item.address_line1) {
        this.state.mailTo.street_address_primary.value = item.address_line1;
      }
      if (item.address_line2) {
        this.state.mailTo.street_address_secondary.value = item.address_line2;
      }
      if (item.city) {
        this.state.mailTo.city.value = item.city;
      }
      if (item.state) {
        this.state.mailTo.state.value = item.state;
      }
      if (item.zip) {
        this.state.mailTo.zipcode.value = item.zip;
      }

      this.setState({ mailTo: this.state.mailTo });
    }
  };

  handleSearch = (userData) => {
    this.state.mailTo.name = userData.name;
    this.mailToUserOptions = [];
    this.setState(
      {
        mailTo: this.state.mailTo,
      },
      () => {
        if (
          this.props &&
          this.props.optionalProps &&
          this.props.optionalProps.consumerId
        ) {
          //
        } else {
          if (userData.name.value) {
            API.clientSearch(
              this.props.user,
              "consumers",
              this.state.mailTo.name.value,
              0,
              true
            ).then((data) => {
              if ((typeof data != "undefined" || data != null) && !data.error) {
                if (data.results) {
                  this.setState({
                    mailToUserOptions: data.results,
                    dropdownIsActive: true,
                  });
                } else {
                  this.setState({
                    mailToUserOptions: [],
                    dropdownIsActive: false,
                  });
                }
              } else {
                this.setState({ mailToUserOptions: [] });
                this.setState((state) => ({
                  ...state,
                  error: {
                    hasAnyError: true,
                    statusCode: data
                      ? data.status ||
                        (data.message && data.message == "Access denied."
                          ? 401
                          : 500) ||
                        500
                      : 500,
                  },
                }));
              }
            });
          } else {
            this.setState({ mailToUserOptions: "", dropdownIsActive: false });
          }
        }
      }
    );
  };

  render() {
    let { mailFrom, mailTo, message, error, letterType } = this.state;
    let styels = { marginRight: `10px`, maxWidth: `30rem`, marginBottom: 0 };

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return (
      <Modal
        optionalClasses='add-account-modal send-new-mail-title scroll'
        title='Send New Letter'
        closeOnClickOutside={true}
        hideModal={this.props.hideModal}
      >
        <form
          className='admin-form'
          onClick={(event) => {
            this.handleClickOutside(event);
          }}
        >
          <div className='app-sidebar-layout-canvas'>
            <div className='app-sidebar app-sidebar-left' style={styels}>
              <label>From</label>
              <Panel>
                <div className='admin-form-row'>
                  <div
                    className={`input-container ${
                      mailFrom.name.hasError ? " error" : ""
                    }`}
                  >
                    <label htmlFor='name'>Name</label>
                    <input
                      name='name'
                      guide={"true"}
                      placeholder='Enter name'
                      type='text'
                      value={mailFrom.name.value}
                      onChange={(event) =>
                        this.handleInputChange({
                          name: { value: event.target.value, hasError: false },
                        })
                      }
                    />
                    {mailFrom.name.hasError
                      ? this.renderErrorMessage("name")
                      : null}
                  </div>
                </div>

                <div className='admin-form-row'>
                  <div
                    className={`input-container ${
                      mailFrom.email.hasError ? " error" : ""
                    }`}
                  >
                    <label htmlFor='email'>E-mail:</label>
                    <input
                      name='email'
                      placeholder='Enter email Id'
                      type='text'
                      value={mailFrom.email.value}
                      onChange={(event) =>
                        this.handleInputChange({
                          email: { value: event.target.value, hasError: false },
                        })
                      }
                    />
                    {mailFrom.email.hasError
                      ? this.renderErrorMessage("email")
                      : null}
                  </div>
                </div>
                <div className='admin-form-row'>
                  <div
                    className={`input-container${
                      mailFrom.street_address_primary.hasError ? " error" : ""
                    }`}
                  >
                    <label htmlFor='street_address_primary'>
                      Street Address{" "}
                    </label>
                    <input
                      name='street_address_primary'
                      placeholder='Enter street address'
                      type='text'
                      value={mailFrom.street_address_primary.value}
                      onChange={(event) =>
                        this.handleInputChange({
                          street_address_primary: {
                            value: event.target.value,
                            hasError: false,
                          },
                        })
                      }
                    />
                    {mailFrom.street_address_primary.hasError
                      ? this.renderErrorMessage("street_address_primary")
                      : null}
                  </div>
                </div>
                <div className='admin-form-row'>
                  <div
                    className={`input-container two-thirds${
                      mailFrom.street_address_secondary.hasError ? " error" : ""
                    }`}
                  >
                    <label htmlFor='street_address_secondary'>
                      Street Address Line 2
                    </label>
                    <input
                      name='street_address_secondary'
                      placeholder='Enter street address'
                      type='text'
                      value={mailFrom.street_address_secondary.value}
                      onChange={(event) =>
                        this.handleInputChange({
                          street_address_secondary: {
                            value: event.target.value,
                            hasError: false,
                          },
                        })
                      }
                    />
                    {mailFrom.street_address_secondary.hasError
                      ? this.renderErrorMessage("street_address_secondary")
                      : null}
                  </div>
                  <div
                    className={`input-container one-third ${
                      mailFrom.zipcode.hasError ? " error" : ""
                    }`}
                  >
                    <label htmlFor='zipcode'>Zip Code</label>
                    <MaskedTextInput
                      guide={"true"}
                      mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                      name='zipcode'
                      placeholder='90210'
                      placeholderChar={"\u2000"}
                      type='text'
                      value={mailFrom.zipcode.value}
                      onChange={(event) =>
                        this.handleInputChange({
                          zipcode: {
                            value: event.target.value,
                            hasError: false,
                          },
                        })
                      }
                    />
                    {mailFrom.zipcode.hasError
                      ? this.renderErrorMessage("zipcode")
                      : null}
                  </div>
                </div>
                <div className='admin-form-row'>
                  <div
                    className={`input-container half${
                      mailFrom.city.hasError ? " error" : ""
                    }`}
                  >
                    <label htmlFor='city'>City</label>
                    <input
                      name='city'
                      placeholder='Enter city name'
                      type='text'
                      value={mailFrom.city.value}
                      onChange={(event) =>
                        this.handleInputChange({
                          city: { value: event.target.value, hasError: false },
                        })
                      }
                    />
                    {mailFrom.city.hasError
                      ? this.renderErrorMessage("city")
                      : null}
                  </div>
                  <div
                    className={`input-container half${
                      mailFrom.state.hasError ? " error" : ""
                    }`}
                  >
                    <label htmlFor='state'>State </label>
                    <Select
                      inputProps={{
                        autoComplete: "none",
                        autoCorrect: "off",
                        spellCheck: "off",
                      }}
                      className='form-select'
                      name='state'
                      value={mailFrom.state.value}
                      placeholder='Select a State'
                      options={stateDataShortNames}
                      resetValue=''
                      onChange={(event) =>
                        this.handleInputChange({
                          state: {
                            value: event ? event.value : "",
                            hasError: false,
                          },
                        })
                      }
                    />
                    {mailFrom.state.hasError
                      ? this.renderErrorMessage("state")
                      : null}
                  </div>
                </div>
              </Panel>
            </div>
            <div className='app-sidebar app-sidebar-left' style={styels}>
              <label>To</label>
              <Panel>
                <div className='admin-form-row'>
                  <div
                    className={`input-container ${
                      mailTo.name.hasError ? " error" : ""
                    }`}
                  >
                    <label htmlFor='name'>Name</label>
                    <input
                      autoComplete='off'
                      name='name'
                      placeholder='Enter name'
                      type='text'
                      value={mailTo.name.value}
                      onChange={(event) =>
                        this.handleSearch({
                          name: { value: event.target.value, hasError: false },
                        })
                      }
                    />
                    {mailTo.name.hasError
                      ? this.renderErrorMessage("name")
                      : null}
                    {this.state.mailToUserOptions &&
                    this.state.mailToUserOptions.length
                      ? this.showSearchDropDown()
                      : ""}
                  </div>
                </div>

                <div className='admin-form-row'>
                  <div
                    className={`input-container ${
                      mailTo.email.hasError ? " error" : ""
                    }`}
                  >
                    <label htmlFor='email'>E-mail:</label>
                    <input
                      name='email'
                      placeholder='Enter email Id'
                      type='text'
                      value={mailTo.email.value}
                      onChange={(event) =>
                        this.handleInputChangeOfMailTo({
                          email: { value: event.target.value, hasError: false },
                        })
                      }
                    />
                    {mailTo.email.hasError
                      ? this.renderErrorMessage("email")
                      : null}
                  </div>
                </div>
                <div className='admin-form-row'>
                  <div
                    className={`input-container${
                      mailTo.street_address_primary.hasError ? " error" : ""
                    }`}
                  >
                    <label htmlFor='street_address_primary'>
                      Street Address{" "}
                    </label>
                    <input
                      name='street_address_primary'
                      placeholder='Enter street address'
                      type='text'
                      value={mailTo.street_address_primary.value}
                      onChange={(event) =>
                        this.handleInputChangeOfMailTo({
                          street_address_primary: {
                            value: event.target.value,
                            hasError: false,
                          },
                        })
                      }
                    />
                    {mailTo.street_address_primary.hasError
                      ? this.renderErrorMessage("street_address_primary")
                      : null}
                  </div>
                </div>
                <div className='admin-form-row'>
                  <div
                    className={`input-container two-thirds${
                      mailTo.street_address_secondary.hasError ? " error" : ""
                    }`}
                  >
                    <label htmlFor='street_address_secondary'>
                      Street Address Line 2
                    </label>
                    <input
                      name='street_address_secondary'
                      placeholder='Enter street address'
                      type='text'
                      value={mailTo.street_address_secondary.value}
                      onChange={(event) =>
                        this.handleInputChangeOfMailTo({
                          street_address_secondary: {
                            value: event.target.value,
                            hasError: false,
                          },
                        })
                      }
                    />
                    {mailTo.street_address_secondary.hasError
                      ? this.renderErrorMessage("street_address_secondary")
                      : null}
                  </div>
                  <div
                    className={`input-container one-third ${
                      mailTo.zipcode.hasError ? " error" : ""
                    }`}
                  >
                    <label htmlFor='zipcode'>Zip Code</label>
                    <MaskedTextInput
                      guide={"true"}
                      mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                      name='zipcode'
                      placeholder='90210'
                      placeholderChar={"\u2000"}
                      type='text'
                      value={mailTo.zipcode.value}
                      onChange={(event) =>
                        this.handleInputChangeOfMailTo({
                          zipcode: {
                            value: event.target.value,
                            hasError: false,
                          },
                        })
                      }
                    />
                    {mailTo.zipcode.hasError
                      ? this.renderErrorMessage("zipcode")
                      : null}
                  </div>
                </div>
                <div className='admin-form-row'>
                  <div
                    className={`input-container half${
                      mailTo.city.hasError ? " error" : ""
                    }`}
                  >
                    <label htmlFor='city'>City</label>
                    <input
                      name='city'
                      placeholder='Enter city name'
                      type='text'
                      value={mailTo.city.value}
                      onChange={(event) =>
                        this.handleInputChangeOfMailTo({
                          city: { value: event.target.value, hasError: false },
                        })
                      }
                    />
                    {mailTo.city.hasError
                      ? this.renderErrorMessage("city")
                      : null}
                  </div>
                  <div
                    className={`input-container half${
                      mailTo.state.hasError ? " error" : ""
                    }`}
                  >
                    <label htmlFor='state'>State </label>
                    <Select
                      inputProps={{
                        autoComplete: "none",
                        autoCorrect: "off",
                        spellCheck: "off",
                      }}
                      className='form-select'
                      name='state'
                      value={mailTo.state.value}
                      placeholder='Select a State'
                      options={stateDataShortNames}
                      resetValue=''
                      onChange={(event) =>
                        this.handleInputChangeOfMailTo({
                          state: {
                            value: event ? event.value : "",
                            hasError: false,
                          },
                        })
                      }
                    />
                    {mailTo.state.hasError
                      ? this.renderErrorMessage("state")
                      : null}
                  </div>
                </div>
              </Panel>
            </div>
          </div>

          <div className='app-sidebar-layout-canvas'>
            <div className='app-sidebar app-sidebar-left' style={styels}>
              <label>Letter Type</label>
              {/* <Panel> */}
              <div
                className={`input-container half${
                  letterType.hasError ? " error" : ""
                }`}
                style={{ marginBottom: 0 }}
              >
                <Select
                  inputProps={{
                    autoComplete: "none",
                    autoCorrect: "off",
                    spellCheck: "off",
                  }}
                  className='form-select'
                  name='message'
                  //type="textarea"
                  placeholder='Select a letter type'
                  options={this.state.letterOptionData}
                  value={letterType.value}
                  onChange={(event) =>
                    this.handleInputChangeForLetterType(
                      {
                        letterType: {
                          value: event.value,
                          label: event.label,
                          hasError: false,
                        },
                      },
                      { data: event }
                    )
                  }
                />
                {letterType.hasError
                  ? this.renderErrorMessage("letterType")
                  : null}
              </div>
              {/* </Panel> */}
            </div>
            <div className='app-sidebar app-sidebar-left' style={styels}>
              <div className={`input-container`}>
                <label htmlFor='pageCount'>Page Count</label>
                <input
                  name='pageCount'
                  placeholder='Enter page count'
                  type='text'
                  value={this.state.pageCount}
                  style={{ width: "100%" }}
                  readOnly
                  // onChange={event => this.handleInputChangeOfMailTo({ city: { value: event.target.value, hasError: false } })}
                />
                {/* {mailTo.city.hasError ? this.renderErrorMessage('city') : null} */}
              </div>
            </div>
          </div>

          <label>Template</label>
          <Panel>
            <div
              className={`input-container half${
                message.hasError ? " error" : ""
              }`}
              style={{ marginBottom: 0 }}
            >
              <Select
                inputProps={{
                  autoComplete: "none",
                  autoCorrect: "off",
                  spellCheck: "off",
                }}
                className='form-select'
                name='message'
                //type="textarea"
                placeholder='Select a template'
                options={this.state.filterdTemplateData}
                value={message.value}
                onChange={(event) =>
                  this.handleInputChangeForMsg(
                    {
                      message: {
                        value: event.value,
                        label: event.label,
                        hasError: false,
                      },
                    },
                    { data: event }
                  )
                }
              />
              {message.hasError ? this.renderErrorMessage("message") : null}
            </div>
          </Panel>

          {this.state.mergeVaribales && this.state.mergeVaribales.length ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ width: "auto" }}>Merge Variables</label>
              <div style={{ marginLeft: "20px" }}>
                <ToggleSwitch
                  isOn={this.state.toggleFlag}
                  toggleButton={(event) => {
                    event.preventDefault();
                    this.setState({ toggleFlag: !this.state.toggleFlag });
                  }}
                />
              </div>
              {this.state.mergeVaribalesValuesError ? (
                <div
                  className={`input-container half error`}
                  style={{ marginBottom: 10 }}
                >
                  {this.renderErrorMessage("mergeVaribalesValuesError")}
                </div>
              ) : (
                <div></div>
              )}
            </div>
          ) : (
            <div></div>
          )}
          {this.state.toggleFlag ? (
            this.state.mergeVaribales.map((item, index) => {
              return this.renderMergeVaribles(item, index);
            })
          ) : (
            <div></div>
          )}
          <div id='send-lob' className='modal-actions'>
            {this.state.mergeVaribales.length != 0 ? (
              <a
                className='cta mg-brand2-color'
                onClick={(event) => this.handleSubmit(event)}
                style={{ padding: `1em 3em ` }}
              >
                Submit
              </a>
            ) : (
              ""
            )}
          </div>
        </form>
      </Modal>
    );
  }
}

export default withRouter(SendNewLetterModal);
