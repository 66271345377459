import moment from "moment";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import locationIcon from "../../assets/images/navbar/location.png";
import mailIcon from "../../assets/images/navbar/mail.png";
import phoneIcon from "../../assets/images/navbar/phone.png";
import Panel from "../../components/App/Panel";
import shortid from "shortid";

class ClientsInvoiceSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getDate = (date) => {
    if (date) {
      return moment(date).format("MM/DD/YYYY") + " - ";
    }
    return "";
  };

  emptyView = (activities) => {
    return (
      <div className="empty-activity">
        <p>There are currently no {activities} to review.</p>
      </div>
    );
  };

  renderActivities = () => {
    let { invoiceDetails } = this.props;
    let { upcoming_activities, recent_activities,isPaymentPlan } = invoiceDetails;
    // sort recent activities
    if (recent_activities && recent_activities.length) {
      recent_activities.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
      });
    }
    //sort upcoming activities
    if (upcoming_activities && upcoming_activities.length) {
      upcoming_activities.sort(function (a, b) {
        return new Date(a.due_date) - new Date(b.due_date);
      });
    }

    return (
      <div>
        <div
          className="panel-heading-secondary"
          style={{ marginBottom: "1em", marginTop: "2em" }}
        >
          Activity Feed
        </div>
        <Panel
          heading="Upcoming Activities"
          optionalClasses="activity-panel scrollbar"
        >
          {upcoming_activities && upcoming_activities.length ? (
            <div className="content activity-text">
              <ul>
                {upcoming_activities.map((item, index) => {
                  return (
                    <div
                      className={`${index % 2 == 0 ? "alt-row" : ""}`}
                      key={"up" + shortid.generate()}
                    >
                    
                      <li>{ isPaymentPlan ? (this.getDate(item.date) + item.name + ' - '+item.status )
                     :   (this.getDate(item.date) + item.name)}
                     </li>
                    </div>
                  );
                })}
              </ul>
            </div>
          ) : (
            this.emptyView("Upcoming Activities")
          )}
        </Panel>
        <Panel
          heading="Recent Activities"
          optionalClasses="activity-panel scrollbar"
        >
          {(recent_activities &&
            recent_activities.tasks &&
            recent_activities.tasks.length) ||
          (recent_activities &&
            recent_activities.payments &&
            recent_activities.payments.length)
            ? this.recentActivities(
                this.combileTasksAndPayments(recent_activities)
              )
            : recent_activities.length 
            ? this.recentActivities(recent_activities) :  this.emptyView("Recent Activities")}
        </Panel>
      </div>
    );
  };

  recentActivities = (data) => {
    let { invoiceDetails } = this.props;
    let { isPaymentPlan } = invoiceDetails;
    if (data && data.length) {
      return (
        <div className="content activity-text">
          <ul>
            {data.map((item, index) => {
              if (item.payment_id) {
                return (
                  <div
                    className={`${index % 2 == 0 ? "alt-row" : ""}`}
                    key={"rec" + shortid.generate()}
                  >
                    <li key={shortid.generate()}>
                    
                      {this.getDate(item.date) + this.getMessage(item)}
                    </li>
                  </div>
                );
              } else {
                return (
                  <div
                    className={`${index % 2 == 0 ? "alt-row" : ""}`}
                    key={"rec" + shortid.generate()}
                  >
                    {item.status == "Cancelled" ? (
                      <li key={shortid.generate()}>
                        {this.getDate(item.complete_date) +
                          item.name +
                          "- Cancelled"}{" "}
                      </li>
                    ) : item.status == "Completed" || item.status == "Aborted" ||
                      item.status == "complete" ? (
                      <li key={shortid.generate()}>
                      { isPaymentPlan ? (this.getDate(item.date) + item.name + ' - '+item.status )
                     :   (this.getDate(item.date) + item.name)}
                      </li>
                    ) : item.status == "Past Due" ? (
                      ""
                    ) : (
                      <li key={shortid.generate()}>
                        {this.getDate(item.date) + item.name}
                      </li>
                    )}
                  </div>
                );
              }
            })}
          </ul>
        </div>
      );
    }
  };

  getMessage = (item) => {
    if (item.is_cancelled) return "payment cancelled.";
    else if (item.is_refund) return "payment refunded.";
    return "payment processed successfully.";
  };

  // getMessage = (item) => {
  //   if (item.is_cancelled) {
  //     return `a payment of $${parseFloat(item.cancel_amount).toFixed(2)} is cancelled.`
  //   } else if (item.is_refund) {
  //     return `a payment of $${parseFloat(item.amount).toFixed(2)} is refunded.`
  //   } else {
  //     if(item.is_partial){
  //       return `a partial payment of $${parseFloat(item.amount).toFixed(2)} was made.`;
  //     } else {
  //       return `payment processed successfully.`;
  //     }
  //   }
  // };

  combileTasksAndPayments = (data) => {
    let newData = [];
    newData = data.tasks ? data.tasks : newData;
    newData = data.payments ? newData.concat(data.payments) : newData;
    if (newData && newData.length) {
      newData.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
      });
    }
    return newData;
  };

  renderAddress(account) {
    if (
      account.street_address_primary ||
      account.street_address_secondary ||
      account.city ||
      account.state ||
      account.zipcode
    ) {
      return (
        <h4 className="ic-sp">
          {account.street_address_primary ? account.street_address_primary : ""}
          {account.street_address_primary && <br />}
          {account.street_address_secondary
            ? account.street_address_secondary
            : ""}
          {account.street_address_secondary && <br />}
          {account.city ? account.city : ""}
          {account.city && account.state ? ", " : ""}
          {account.state ? account.state : ""}
          {account.state && account.zipcode ? " " : ""}
          {account.zipcode ? account.zipcode : ""}
        </h4>
      );
    }
  }

  getTitle = (user) => {
    let fullname = "";
    if (user && user.hasOwnProperty("display_name")) {
      fullname = user.display_name;
    } else if (user && user.hasOwnProperty("company_name")) {
      fullname = user.company_name;
    }
    return fullname;
  };

  render() {
    let { title, optionalClassNames, invoiceDetails, isShowHistory, backPath } =
      this.props;
    const id = this.props.match.params.id;
    let { user } = invoiceDetails;
    title = this.getTitle(user);

    return (
      <div className="app-sidebar app-sidebar-left accounts-view-sidebar clients-side-bar col1-scroll scrollbar">
        <div
          className={`app-section-header client-section-header${
            optionalClassNames ? " " + optionalClassNames : ""
          }`}
          style={{ flexDirection: "column", alignItems: "flex-start" }}
        >
          <div className="app-section-header-content">
            <Link
              to={`${isShowHistory ? `/client/invoices/${id}` : backPath}`}
              className="app-section-header-back-link"
              onClick={() => this.props.showHistory(false)}
              style={{ color: "#ffffff" }}
            >
              « Back
            </Link>
            <h1
              className="app-section-header-heading side-hed-col"
              style={{ fontSize: "2em" }}
            >
              {title}
            </h1>
          </div>
        </div>
        <div className="invoice-sidebar">
          {user &&
          user.hasOwnProperty("first_name") &&
          user.hasOwnProperty("last_name") ? (
            <h4
              className="panel-heading-secondary"
              style={{ fontSize: "1em", fontWeight: 600 }}
            >
              {user && user.first_name + " " + user.last_name}
            </h4>
          ) : null}
          {user && user.email ? (
            <h4 className="panel-heading-secondary ic-sp">{user.email}</h4>
          ) : null}
          {user && user.phone_number ? (
            <h4 className="panel-heading-secondary ic-sp">
              {user.phone_number}
            </h4>
          ) : null}
          {user ? this.renderAddress(user) : null}

          {invoiceDetails.net_term ? (
            <div style={{ marginTop: "30px" }}>
              <label htmlFor="accountNo">Default Term</label>
              <div className="invoice-amt-owed" style={{ fontSize: "18px" }}>
                {invoiceDetails.net_term !== "" ? invoiceDetails.net_term : ""}
              </div>
            </div>
          ) : null}
          {this.renderActivities()}
          <div className="history-btn">
            {/* <button
              onClick={() => this.props.showHistory(!isShowHistory)}
              className={`${isShowHistory ? "active" : ""}`}
              style={{ marginLeft: 0 }}
            >
              {"Invoice History"}
            </button> */}
          </div>
        </div>
      </div>
    );
  }
}

export default ClientsInvoiceSidebar;
