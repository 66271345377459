import moment from "moment";
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import ReactGA from "react-ga";
import { Redirect } from "react-router-dom";
import deleteIcon from "../../assets/icons/delete.png";
import viewIcon from "../../assets/icons/view.png";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import ClientsCardView from "../../components/CardView/ClientsCardView";
import InvoiceObjectList from "../../components/ObjectList/ClientsAccounts/InvoiceObjectList";
import ObjectList from "../../components/ObjectList/ObjectList";
// API
import * as API from "../../utils/api";
import {
  getStrippedDollarAmount,
  getStrippedNumberFromString,
  getZohoPagesense,
  showToast,
} from "../../utils/helpers";

class ClientsUploadValidationDoc extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    consumer: {},
    debts: [],
    debtDetails: [],
    documents: [],
    offers: [],
    isLoading: true,
    selectedOfferForCounter: {},
    showCounterOfferModal: false,
    currentModal: null,
    optionalModalProps: null,
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
    open_additional_details: false,
  };

  componentDidMount() {
    getZohoPagesense();
    ReactGA.pageview("/ClientsAccountsView");
    let { debtData } = this.props;
    this.setState(
      {
        debtDetails: debtData,
      },
      () => {
        this.getDocumentData();
      }
    );
  }

  getDocumentData() {
    const accountId = this.state.debtDetails.id;
    API.getDebtValidationDocuments(this.props.user, accountId).then((data) => {
      let isAccessDenied =
        data && data.message && data.message == "Access denied." ? true : false;
      if (
        (typeof data != "undefined" || data != null) &&
        data.data &&
        !isAccessDenied
      ) {
        setTimeout(() => {
          this.setState((state) => ({
            ...state,
            documents: data.data,
            isLoading: false,
          }));
        }, 100);
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  }

  deleteDocument = (doc_id) => {
    confirmAlert({
      message: "Do you want to delete document?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            API.deleteVerificationDocument(this.props.user, doc_id).then(
              (data) => {
                this.props.updateModalState(true, "SUCCESS", {
                  message: data.message,
                });
                this.getDocumentData();
              }
            );
          },
        },
        {
          label: "Cancel",
          onClick: () => "",
        },
      ],
    });
  };

  viewValidationDoc = (base64Data) => {
    let props = {
      image: base64Data,
      arrange_pdf: true,
    };
    this.props.updateModalState(true, "SHOW_IMAGE", props);
  };

  renderLatestPaymentDate = (date) => {
    return moment.utc(date).format("MM/DD/YYYY");
  };

  getAdjustmentDebt = (debt) => {
    let adjustment = 0;
    if (debt.adjustment) {
      adjustment =
        parseFloat(
          getStrippedDollarAmount(debt.balance_in_dollars_as_currency)
        ) + parseFloat(debt.adjustment);
    } else {
      adjustment = parseFloat(
        getStrippedDollarAmount(debt.balance_in_dollars_as_currency)
      );
    }
    adjustment = parseFloat(adjustment).toFixed(2);
    return adjustment;
  };

  getObjectListData = (data, tableType) => {
    let headings = [],
      columns = [];
    if (tableType === "debt") {
      headings = ["Date", "Inv. Total", "Amt Paid", "Discount", "Balance"];
    } else if (tableType === "payment") {
      headings = [
        "Date",
        "Amount",
        "Payment Method",
        "Customer ID",
        "Transaction ID",
      ];
    }

    if (tableType === "debt") {
      columns = [
        {
          secondaryColumns: [
            {
              title: data.created_at
                ? this.renderLatestPaymentDate(data.created_at)
                : "--",
            },
            {
              title: data.total_in_dollars_as_currency
                ? `$${data.total_in_dollars_as_currency}`
                : "n/a",
            },
            {
              title: data.initial_payment ? `$${data.initial_payment}` : "n/a",
            },
            {
              title: data.adjustment ? `$${data.adjustment}` : "n/a",
            },
            {
              title: data.balance_in_dollars_as_currency
                ? `$${data.balance_in_dollars_as_currency}`
                : "n/a",
            },
          ],
        },
      ];
    } else if (tableType === "payment") {
      columns = data.map((row) => {
        return {
          primaryColumn: {},
          secondaryColumns: [
            {
              title: moment(row.created_at).format("MM/DD/YYYY"),
              optionalClasses: "mobile-hide",
            },
            {
              title: `$${getStrippedNumberFromString(row.amount).toFixed(2)}`,
              optionalClasses: "mobile-hide",
            },
            {
              title:
                row.payment_type && row.payment_type === "Stripe"
                  ? "Credit Card"
                  : row.payment_type,
              optionalClasses: "mobile-hide",
            },
            {
              title: row.stripe_id ? row.stripe_id : "n/a",
              optionalClasses: "mobile-hide word-wrap right-space",
            },
            {
              title: row.transaction_id ? row.transaction_id : "n/a",
              optionalClasses: "mobile-hide word-wrap right-space",
            },
          ],
          fullRowIsClickable: false,
          rowType: "",
        };
      });
    }
    return {
      headings,
      columns,
    };
  };

  getDocumentObjectList = (data) => {
    let headings = [],
      columns = [];

    headings = ["Name", "Description", "Action"];

    columns = data.map((document) => {
      return {
        primaryColumn: {
          title: document.name,
        },
        secondaryColumns: [
          {
            title: document.description,
            optionalClasses: "long-desc",
          },
          {
            title: (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <button
                  title='View'
                  style={{
                    background: "transparent",
                    marginLeft: "2em",
                    marginRight: "1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => this.viewValidationDoc(document.document)}
                >
                  <img src={viewIcon} width='24' height='24' />
                </button>
                <button
                  title='View'
                  style={{
                    background: "transparent",
                    marginRight: "1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => this.deleteDocument(document.document_id)}
                >
                  <img src={deleteIcon} width='35' height='35' />
                </button>
              </div>
            ),
            isAction: true,
            optionalClasses: "mobile-hide rmv-right-space",
          },
        ],
      };
    });
    return {
      headings,
      columns,
    };
  };

  reload = () => {
    this.getDocumentData();
  };

  showValidationModal() {
    let props = {
      rowData: this.state.debtDetails,
      consumer: this.props.consumer,
      reloadData: this.reload,
    };
    this.props.updateModalState(true, "VALIDATION_DOCUMENT", props);
  }

  getPaidAmount = (invoice) => {
    let amount = 0;
    if (invoice) {
      amount = invoice.total_amt
        ? invoice.balance
          ? parseFloat(invoice.total_amt) - parseFloat(invoice.balance)
          : invoice.total_amt
        : invoice.total_amt;
    }
    return amount.toFixed(2);
  };

  checkInvoicePastDue = (date) => {
    return moment(date).isBefore(new Date());
  };

  getAccountStatus = (rowData) => {
    let flag = this.checkInvoicePastDue(rowData.due_date);
    return !flag;
  };

  getInvoiceListData = (data, type) => {
    let headings = [],
      columns = [];
    headings =
      type === "line"
        ? ["LineNum", "Item Name", "Qty", "Rate", "Amount", "Description"]
        : [
            "Inv. #",
            "Inv. Date",
            "Due Date",
            "Inv. Total",
            "Amt Paid",
            "Discount",
            "Balance",
          ];

    if (type === "line") {
      columns = data.map((row) => {
        return {
          emptyPrimary: true,
          secondaryColumns: [
            {
              key: "LineNum",
              title: row.line_num ? row.line_num : "n/a",
            },
            {
              key: "Item Name",
              title: row.item_name ? row.item_name : "n/a",
            },
            {
              key: "Qty",
              title: row.qty ? row.qty : "n/a",
            },
            {
              key: "Rate",
              title: row.unit_price ? `$${row.unit_price}` : "n/a",
            },
            {
              key: "Amount",
              title: row.amount ? `$${row.amount}` : "n/a",
            },
            {
              key: "Description",
              title: row.description ? row.description : "n/a",
            },
          ],
        };
      });
    } else {
      columns = data.map((row) => {
        return {
          emptyPrimary: true,
          secondaryColumns: [
            {
              key: "Inv. #",
              title: row.invoice_number ? row.invoice_number : "n/a",
            },
            {
              key: "Inv. Date",
              title: row.txn_date
                ? moment(row.txn_date).format("MM/DD/YYYY")
                : "n/a",
            },
            {
              key: "Due Date",
              title: row.due_date
                ? moment(row.due_date).format("MM/DD/YYYY")
                : "n/a",
            },
            {
              key: "Inv. Total",
              title: `$${parseFloat(row.total_amt).toFixed(2)}`,
            },
            {
              key: "Amt Paid",
              title: `$${this.getPaidAmount(row)}`,
            },
            {
              key: "Discount",
              title: row.adjustment
                ? `$${parseFloat(row.adjustment).toFixed(2)}`
                : `$0.00`,
            },
            {
              key: "Balance",
              title: `$${parseFloat(row.balance).toFixed(2)}`,
            },
          ],
          status: this.getAccountStatus(row),
        };
      });
    }

    return {
      headings,
      columns,
    };
  };

  renderInvoice = (data, type) => {
    let tableData = [data];

    return tableData && tableData.length > 0 ? (
      <div>
        <div className='big-device-grad invoice-line'>
          <ClientsCardView
            data={this.getInvoiceListData(tableData, type)}
            isStatus={type === "line" ? false : true}
          />
        </div>
        <div style={{ marginBottom: "1em" }}>
          <InvoiceObjectList
            data={this.getInvoiceListData(tableData, type)}
            isStatus={type === "line" ? false : true}
            hideForMobile={true}
          />
        </div>
      </div>
    ) : (
      <div className='object-list-empty-state'>
        <span className='object-list-empty-state-message'>
          No details available
        </span>
      </div>
    );
  };

  renderBillingAddress = (address) => {
    return address ? (
      <div className='object-list-empty-state billing-addr'>
        <span>
          {address.billaddr_line1 ? address.billaddr_line1 : ""}
          {address.billaddr_line1 && ", "}
          {address.billaddr_line2 ? address.billaddr_line2 : ""}
          {address.billaddr_line2 && ", "}
          {address.billaddr_line3 ? address.billaddr_line3 : ""}
          {address.billaddr_line3 && ", "}
          {address.billaddr_line4 ? address.billaddr_line4 : ""}
        </span>
      </div>
    ) : (
      <div className='object-list-empty-state' style={{ padding: "1.4rem" }}>
        <span className='object-list-empty-state-message'>
          Address not available.
        </span>
      </div>
    );
  };

  renderNotes = (notes) => {
    return notes ? (
      <div
        className='object-list-empty-state'
        style={{ textAlign: "left", padding: "1.4rem", background: "white" }}
      >
        <span>{notes}</span>
      </div>
    ) : (
      <div
        className='object-list-empty-state'
        style={{ padding: "1.4rem", backgroundColor: "#FFF" }}
      >
        <span className='object-list-empty-state-message'>
          No notes for review.
        </span>
      </div>
    );
  };

  renderValidationDoc = (document) => {
    return (
      <div>
        {document && document.length ? (
          <ObjectList
            data={this.getDocumentObjectList(document)}
            optionalClasses={"no-row-actions"}
            removeExtraSpace={true}
          />
        ) : (
          <div
            className='consumer-accounts-empty-state'
            style={{ padding: "2rem", height: "auto", backgroundColor: "#FFF" }}
          >
            <h3 className='consumer-accounts-empty-state-heading'>
              No Validation Documents
            </h3>
            <p>There are currently no validation documents for this debt.</p>
          </div>
        )}
        <button
          className='cta mg-brand2-color'
          onClick={() => {
            this.showValidationModal();
          }}
          style={{ marginBottom: "2rem", marginTop: "2rem" }}
        >
          Upload Validation Document
        </button>
      </div>
    );
  };

  renderTable = (data, tableType) => {
    let emptyStateMessage = "No payment details available";
    let tableData = data;

    return tableData && tableData.length > 0 ? (
      <InvoiceObjectList
        data={this.getObjectListData(tableData)}
        isStatus={true}
        hideForMobile={true}
      />
    ) : (
      <div
        className='object-list-empty-state'
        style={{
          padding: "2rem",
          height: "auto",
          backgroundColor: "#FFF",
          textAlign: "center",
        }}
      >
        <span className='object-list-empty-state-message'>
          {emptyStateMessage}
        </span>
      </div>
    );
  };

  qbInvoiceData = (debtDetails) => {
    return (
      <div>
        <div className='he-hed' style={{ marginBottom: "0.5em" }}>
          Invoice
        </div>
        {this.renderInvoice(debtDetails.debt_invoice)}
        <div className='he-hed' style={{ marginBottom: "0.5em" }}>
          Invoice Line
        </div>
        {this.renderInvoice(debtDetails.invoice_lines, "line")}
        {debtDetails.debt_invoice && (
          <div className='he-hed' style={{ marginBottom: "0.5em" }}>
            Billing Address
          </div>
        )}
        {debtDetails.debt_invoice &&
          this.renderBillingAddress(debtDetails.debt_invoice)}
      </div>
    );
  };

  render() {
    const { debtDetails, documents, isLoading, error } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return isLoading ? (
      <div
        style={{
          height: window.innerHeight * 0.7,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <AppCanvasLoader />
      </div>
    ) : (
      <div className='app-sidebar-layout-canvas-content'>
        {debtDetails &&
        debtDetails.debt_invoice &&
        debtDetails.debt_invoice.invoice_number
          ? this.qbInvoiceData(debtDetails)
          : ""}
        <div className='he-hed' style={{ marginBottom: "0.5em" }}>
          Notes
        </div>
        {this.renderNotes(debtDetails.additional_details)}
        <div className='he-hed' style={{ marginBottom: "0.5em" }}>
          Payment Details
        </div>
        {this.renderTable(debtDetails.payment_details, "payment")}
        <div className='he-hed' style={{ marginBottom: "0.5em" }}>
          Documents
        </div>
        {this.renderValidationDoc(documents)}
      </div>
    );
  }
}

export default ClientsUploadValidationDoc;
