import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import "react-select/dist/react-select.css";
import AppCanvasLoader from "../../../components/App/AppCanvasLoader";
import MaskedTextInput from "react-text-mask";
import moment from "moment";
import Panel from "../../../components/App/Panel";
import * as API from "../../../utils/api";
import { getBaseUrl } from "../../../utils/api";
import { stringify } from "query-string";
class CustomizeWorkFlowStep19 extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    isLoading: true,
    cGoLiveDate: "",
    isDateError: false,
    isEditDate: false,
    dateErrorType: "",
    details: null,
    percentage: 0,
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
  };

  style = {
    pText: {
      fontSize: "14px",
      color: "#2b2828",
      margin: "0px",
    },
  };

  componentDidMount() {
    this.getOrgPercentage();
    this.getSettingsDetails();
  }
  getOrgPercentage = () => {
    API.getOrgDetails(this.props.user).then((data) => {
      if (data && data.status_code == 200) {
        this.setState({
          percentage: data.data.organization.onboarding_percentage,
        });
      }
    });
  };
  getSettingsDetails = () => {
    const { user } = this.props;
    API.getSsoGoLiveDate(user, getBaseUrl()).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data) {
          this.setState({ details: data.data }, () => {
            this.getDateString();
          });
        }
        this.setState({ isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  getDateString = () => {
    const { details } = this.state;
    if (details && details.go_live_date) {
      const dateArray = details.go_live_date.split("T");
      let dateString = moment(dateArray[0]).format("MM/DD/YYYY");
      this.setState({ cGoLiveDate: dateString });
    }
  };

  callUpdateDateApi() {
    let { user } = this.props;
    let payload = {
      org_url: getBaseUrl(),
      go_live_date: this.state.cGoLiveDate,
    };
    API.updateSsoGoLiveDate(user, payload).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        this.props.updateModalState(true, "SUCCESS", {
          message: data.message,
        });
        this.getSettingsDetails();
        this.setState({ isLoading: false, isEditDate: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  }

  handleDateChange = (value) => {
    this.setState((state) => ({
      ...state,
      error: {
        hasAnyError: false,
      },
      cGoLiveDate: value,
    }));
    this.validateSubmit(value.trim());
  };

  handleEdit = () => {
    this.setState({ isEditDate: true });
  };

  handleSubmit = () => {
    if (this.validateSubmit()) {
      this.callUpdateDateApi();
    } else {
      //Submit API _ error
    }
  };

  getDateFormat = (date) => {
    // let tempdate = new Date(date).toISOString().slice(0, 10);
    let tempdate = new Date(date).getTime();
    return tempdate;
  };

  validateSubmit = (value) => {
    const { cGoLiveDate, isDateError } = this.state;
    let error = false;
    let isValid = true;
    let errorType = "";
    let checkValue;
    if (value) {
      checkValue = value;
    } else {
      checkValue = cGoLiveDate;
    }
    const now = new Date();

    // let endDate = new Date(now - (now % 86400000) - 86400000);
    let endDate = moment(now).format("MM/DD/YYYY");
    // let endDate = now.getTime();
    var date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;

    if (checkValue === "") {
      error = true;
      isValid = false;
      errorType = "req";
    } else if (!date_regex.test(checkValue)) {
      error = true;
      isValid = false;
      errorType = "req";
      //  return false;
    } else if (checkValue && checkValue.length && checkValue.length < 10) {
      error = true;
      isValid = false;
      errorType = "valid";
    }
    // else if (this.getDateFormat(checkValue) < this.getDateFormat(endDate)) {
    //   error = true;
    //   isValid = false;
    //   errorType = "pastDate";
    // }
    this.setState({ dateErrorType: errorType, isDateError: error });
    return isValid;
  };

  renderErrorMessage(input) {
    let message = "";
    if (input === "pastDate") {
      message = "Go live date cannot be prior to today's date.";
    } else {
      message = "Please enter valid date.";
    }

    return (
      <div
        style={{
          color: "#fb5c82",
          fontFamily: "Open Sans",
          width: "250px",
          fontWeight: "bold",
        }}
        className='input-error-message'
      >
        {message}
      </div>
    );
  }

  render() {
    const { isLoading, cGoLiveDate, isDateError, isEditDate, error } =
      this.state;
    return (
      <div className='app-sidebar-layout-canvas-content setting-side-canvas col1-scroll scrollbar'>
        <div style={{ padding: "20px", backgroundColor: "white" }}>
          <h3
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              fontFamily: "Open Sans",
              color: "black",
            }}
          >
            Schedule Your Go Live Date
          </h3>
          <h7
            style={{
              fontFamily: "Open Sans",
              fontSize: "16px",
              lineHeight: "20px",
            }}
          >
            In this last step of customizing your workflow, Schedule the date
            you want to go live with your new Accounts Receivable workflow. All
            invoices created on or after the date selected will be managed by
            your new workflow.
          </h7>
          <div className='step9_panel_div' style={{}}>
            <Panel>
              <h3
                className='panel-heading'
                style={{ fontFamily: "Open Sans", fontWeight: "500" }}
              >
                Communication Go Live Date
              </h3>
              <form style={{}} className='step9_panel_form'>
                <div style={style.container} className={`onboading-container`}>
                  <div
                    style={style.content}
                    className={`${
                      this.state.isEditDate === false
                        ? "disabled-container"
                        : ""
                    }`}
                  >
                    Date :{" "}
                  </div>
                  <div
                    className={`input-container${isDateError ? " error" : ""} ${
                      this.state.isEditDate === false
                        ? "disabled-container"
                        : ""
                    }`}
                    style={style.inputStyle}
                  >
                    <MaskedTextInput
                      guide={true}
                      mask={[
                        /\d/,
                        /\d/,
                        "/",
                        /\d/,
                        /\d/,
                        "/",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      name='date'
                      id='go_live_date_input'
                      placeholder='mm/dd/yyyy'
                      placeholderChar={"\u2000"}
                      type='text'
                      value={cGoLiveDate}
                      disabled={isEditDate === false}
                      onChange={(event) =>
                        this.handleDateChange(event.target.value)
                      }
                    />
                    {isDateError
                      ? this.renderErrorMessage(this.state.dateErrorType)
                      : null}
                    {error.hasAnyError
                      ? this.renderErrorMessage(
                          this.state.dateErrorType === "req"
                            ? "reqError"
                            : "validError"
                        )
                      : null}
                  </div>
                  {isEditDate ? (
                    <div
                      id='submit-form'
                      className='modal-actions'
                      style={style.submitContent}
                    >
                      <a
                        className='cta mg-brand2-color'
                        style={{
                          width: "110px",
                          fontFamily: "Open Sans",
                          fontWeight: "500",
                        }}
                        id='go_live_date_submit_button'
                        onClick={(event) => this.handleSubmit(event)}
                      >
                        Submit
                      </a>
                    </div>
                  ) : (
                    <div
                      id='submit-form'
                      className='modal-actions'
                      style={style.submitContent}
                    >
                      <a
                        className='cta mg-brand2-color'
                        style={{
                          width: "110px",
                          fontFamily: "Open Sans",
                          fontWeight: "500",
                        }}
                        id='go_live_date_edit_button'
                        onClick={(event) => this.handleEdit()}
                      >
                        Edit
                      </a>
                    </div>
                  )}
                </div>
              </form>
            </Panel>
          </div>
        </div>
        <div
          className='bottum-btns'
          style={{ paddingTop: "10px", padding: "20px" }}
        >
          <div className='button-left'>
            <input
              className=''
              type='submit'
              id='go_live_date_go_back'
              value='Go Back'
              style={{
                borderRadius: "0.5em",
                width: "200px",
                fontFamily: "Open Sans",
                height: "50px",
                marginTop: "20px",
                fontSize: "16px",
                fontWeight: "500",
                border: "2px solid",
              }}
              onClick={() => this.props.handleBack("step9")}
            />
            <span className='go-back-text'>
              <p style={this.style.pText}>Go back to</p>
              <p style={this.style.pText}>"Schedule Your Communications"</p>
            </span>
          </div>
          <div className='button-right'>
            <input
              type='submit'
              value={"Next"}
              id='go_live_date_next'
              className='cta mg-brand2-color mg-nextbtn'
              style={{
                width: "200px",
                height: "50px",
                borderRadius: "0.5em",
                marginTop: "20px",
                padding: "0px",
                fontFamily: "Open Sans",
                fontSize: "16px",
                fontWeight: "500",
              }}
              onClick={() => {
                let { user } = this.props;
                let { percentage } = this.state;
                if (percentage == 55.55) {
                  API.updateOnboardingPercentage(user, percentage).then(
                    (res) => {}
                  );
                }
                this.props.history.push("/welcome-screen/enable-payment");
              }}
            />
            <span className='next-text'>
              <p style={this.style.pText}>Continue to</p>
              <p style={this.style.pText}>"Set Up Your Payment Account"</p>
            </span>
          </div>
        </div>
      </div>
    );
  }
}
const style = {
  settingTitle: { fontSize: "16px", fontWeight: "bold" },
  // container: { display: 'flex' },
  inputStyle: {
    maxWidth: "300px",
    height: "75px",
    paddingTop: "10px",
    flex: "4",
  },
  content: {
    paddingTop: `20px`,
    fontWeight: "600",
    flex: "2",
    maxWidth: "50px",
  },
  submitContent: { flex: "2" },
};

export default withRouter(CustomizeWorkFlowStep19);
