import React, { Component } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

// Components
import AppClientHeader from "../../components/App/AppClientHeader";
import ClientsNav from "../../components/Clients/ClientsNav";
import Emitter from "../../utils/event-emitter";
import { getZohoPagesense } from "../../utils/helpers";

class ClientsPortal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progressPercent: 0,
    };
  }
  static propTypes = {
    location: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    screen: PropTypes.object.isRequired,
  };

  componentDidMount() {
    getZohoPagesense();
  }

  // || (!this.props.user.client_admin || !this.props.user.is_employee )
  render() {
    if (!this.props.isLoggedIn) {
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: this.props.location },
          }}
        />
      );
    }
    if (
      this.props.user.client_admin === false &&
      this.props.user.is_employee === true
    ) {
      if (
        this.props.location === "/client/settings" ||
        this.props.location === "/client/team"
      ) {
        return (
          <Redirect
            to={{
              pathname: "/client/dashboard",
              state: { from: this.props.location },
            }}
          />
        );
      }
    }
    if (
      this.props.user.super_admin === false &&
      this.props.user.client_admin === false &&
      this.props.user.is_employee === false &&
      this.props.user.admin === false
    ) {
      return (
        <Redirect
          to={{
            pathname: "/dashboard/accounts/invoices",
            state: { from: this.props.location },
          }}
        />
      );
    }
    if (this.props.user.super_admin === true) {
      return (
        <Redirect
          to={{
            pathname: "/super-admin/accounts",
            state: { from: this.props.location },
          }}
        />
      );
    }
    return (
      <div className="app-portal">
        <AppClientHeader
          user={this.props.user}
          appThemeData={this.props.appThemeData}
          appName={this.props.appName}
          logoPath="/client/accounts/"
          hideNav={true}
          headerTab={this.props.headerTab}
        />
        <div className={`app-portal-canvas consumer-portal canvas-back`}>
          <ClientsNav
            path={this.props.location}
            user={this.props.user}
            disableNav={
              Math.floor(this.props.progressPercent) >= 88 ? true : false
            }
          />
          <div
            className="wrap"
            style={
              this.props.removePadding ? { padding: 0 } : { padding: "1rem" }
            }
          >
            {this.props.screen}
          </div>
        </div>
      </div>
    );
  }
}

export default ClientsPortal;
