import moment from "moment";
import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
// Components
import AppSectionHeader from "../../components/App/AppSectionHeader";
import { getZohoPagesense } from "../../utils/helpers";
import LobHistory from "./LobHistory";
import PaymentHistory from "./PaymentHistory";
import NotificationHistory from "./NotificationHistory";
import EmailHistory from "../ClientsPortal/history/EmailHistory";

class ClientsHistory extends Component {
  constructor(props) {
    super();
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.state = {
      savedData: [],
      flag: false,
      isLoading: true,
      searchData: "",
      showFlash: false,
      type: {
        value: "Letters",
      },
      search: {
        value: "",
      },
    };
  }

  componentDidMount() {
    if (localStorage.getItem("NOTIFICATION") === "true") {
      this.state.type.value = "Notifications";
    }
    this.setState((state) => ({
      isLoading: false,
    }));
    getZohoPagesense();
    ReactGA.pageview("/PaymentHistory");
  }
  componentWillUnmount() {
    localStorage.removeItem("NOTIFICATION");
  }

  handleInputChange(newPartialInput) {
    const { user } = this.props;
    let type = newPartialInput.type.value;
    let offset = 0;

    this.setState((state) => ({
      ...state,
      type: {
        ...state.type,
        value: type,
      },
      search: {
        value: "",
      },
      searchData: "",
    }));
  }

  handleKeyPress(event) {
    let searchText = this.state.search.value;
    if (event.key === "Enter") {
      this.setState((state) => ({
        ...state,
        searchData: searchText,
      }));
    }
  }

  handleSearchInputChange(newPartialInput) {
    this.setState(
      (state) => ({
        ...state,
        search: {
          value: newPartialInput.search.value,
        },
      }),
      () => {
        if (newPartialInput.search.value.length === 0) {
          this.handleKeyPress({ key: "Enter" });
        }
      }
    );
  }

  renderLatestPaymentDate = (date) => {
    return moment(date).format("MM/DD/YYYY");
  };

  handleFlilterChange = (value) => {
    this.state.type.value = value;
    this.setState({ type: this.state.type });
  };

  getSectionHeaderUtilities() {
    const { type, search } = this.state;
    return (
      <div className="task-co-fl">
        <button
          className={`task-filter-btns all payment-filter-btns${
            type.value === "Letters" ? " active" : ""
          }`}
          onClick={() => this.handleFlilterChange("Letters")}
        >
          Letters
        </button>
        <button
          className={`task-filter-btns all payment-filter-btns${
            type.value === "Emails" ? " active" : ""
          }`}
          onClick={() => this.handleFlilterChange("Emails")}
        >
          Emails
        </button>
        <button
          className={`task-filter-btns all payment-filter-btns${
            type.value === "Payments" ? " active" : ""
          }`}
          onClick={() => this.handleFlilterChange("Payments")}
        >
          Payments
        </button>
        <button
          className={`task-filter-btns all payment-filter-btns${
            type.value === "Notifications" ? " active" : ""
          }`}
          onClick={() => this.handleFlilterChange("Notifications")}
        >
          Notifications
        </button>
      </div>
    );
  }

  render() {
    const { appName, user } = this.props;
    const { isLoading, type } = this.state;

    return isLoading ? (
      <AppCanvasLoader />
    ) : (
      <div>
        <Helmet>
          <title>
            {appName} | {type.value}
          </title>
        </Helmet>
        <AppSectionHeader
          title={type.value}
          utilities={this.getSectionHeaderUtilities()}
          titleClasses=""
          optionalClassNames="history-header"
        />
        {type.value === "Letters" ? (
          <LobHistory
            {...this.props}
            appName={appName}
            user={user}
            hideModal={this.props.hideModal}
            updateModalState={this.props.updateModalState}
          />
        ) : type.value === "Emails" ? (
          <EmailHistory
            {...this.props}
            appName={appName}
            user={user}
            hideModal={this.props.hideModal}
            updateModalState={this.props.updateModalState}
          />
        ) : type.value === "Payments" ? (
          <PaymentHistory
            {...this.props}
            appName={appName}
            user={user}
            hideModal={this.props.hideModal}
            updateModalState={this.props.updateModalState}
          />
        ) : type.value === "Notifications" ? (
          <NotificationHistory
            {...this.props}
            appName={appName}
            user={user}
            hideModal={this.props.hideModal}
            updateModalState={this.props.updateModalState}
          />
        ) : null}
      </div>
    );
  }
}

export default withRouter(ClientsHistory);
