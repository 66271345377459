import React, { useState } from "react";
import { Table } from "reactstrap";
import styles from "./DynamicTable.module.scss";
import InfoIcon from "../../assets/icons/info_teal.png";
import EditIcon from "../../assets/icons/editLineIcon.png";
import EyeIcon from "../../assets/icons/eyeLineIcon.png";
import CheckIcon from "../../assets/icons/CheckIcon.png";
import CrossIcon from "../../assets/icons/CrossIcon.png";
import "bootstrap/dist/css/bootstrap.min.css";
import ToggleSwitch from "../ToggleSwitch";

const DynamicTable = ({
  headers,
  data,
  handleToggle,
  handleDayChange,
  handleEyeClick,
  handleEditRow,
  activeTab,
}) => {
  const [editMode, setEditMode] = useState(null);
  const [editedValue, setEditedValue] = useState("");
  const [hoveredRow, setHoveredRow] = useState(null);

  const extractNumberAndText = (schedule) => {
    const numberMatch = schedule.match(/\d+/);
    const textMatch = schedule.match(/[a-zA-Z\s]+/g);
    return {
      number: numberMatch ? numberMatch[0] : "",
      text: textMatch ? textMatch.join("").trim() : "",
    };
  };

  const handleEditClick = (id, value) => {
    const numberMatch = value.match(/\d+/);
    setEditMode({ id });
    if (numberMatch) {
      setEditedValue(numberMatch[0]);
    }
  };

  const checkDocs = (docsString) => {
    if (!docsString.trim()) {
      return { firstDocType: "--", moreDocType: [] };
    }

    let docsArray = docsString.split(",").map((item) => item.trim());
    let firstDocType = docsArray[0];
    let moreDocType = docsArray.slice(1);
    return { firstDocType, moreDocType };
  };

  return (
    <Table bordered striped responsive>
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index} style={{ backgroundColor: "#DADADA" }}>
              <div className={styles.headerTile}>
                <div className={styles.headingStyle}>{header.name}</div>
                <div style={{ cursor: "pointer" }}>
                  {header.info && (
                    <img
                      src={InfoIcon}
                      width='25px'
                      height='25px'
                      title={header.info}
                    />
                  )}
                </div>
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((entry, index) => (
          <React.Fragment key={index}>
            {entry.category && (
              <tr scope='row'>
                <th colSpan={headers.length} className={styles.categoryHeader}>
                  {entry.category}
                </th>
              </tr>
            )}
            {entry.items.map((item, idx) => (
              <tr
                key={idx}
                className={styles.rowData}
                onMouseEnter={() => setHoveredRow(item.id)}
                onMouseLeave={() => setHoveredRow(null)}
              >
                {headers.map((header, i) => {
                  const { number, text } =
                    header.name === "Delivery Schedule"
                      ? extractNumberAndText(item[header.name])
                      : { number: "", text: "" };

                  const { firstDocType, moreDocType } =
                    header.name === "Document Type"
                      ? checkDocs(item[header.name])
                      : { firstDocType: "", moreDocType: "" };

                  return (
                    <td key={i} className={styles.rowData}>
                      <div
                        className={
                          header.name === "Auto Send" ||
                          header.name === "Internal Contact" ||
                          header.name === "Contact Pref." ||
                          header.name === "Task Status"
                            ? styles.toggleCell
                            : header.name === "Delivery Schedule"
                            ? styles.deliverySchedule
                            : ""
                        }
                      >
                        {(header.name === "Auto Send" ||
                          header.name === "Internal Contact" ||
                          header.name === "Task Status") && (
                          <ToggleSwitch
                            isOn={item[header.name] === "Yes"}
                            isDisable={
                              activeTab === "Statements" &&
                              (header.name === "Auto Send" ||
                                header.name === "Internal Contact")
                            }
                            customStyle={{ padding: 4 }}
                            toggleButton={(e) =>
                              handleToggle(
                                item.id,
                                header.name,
                                item[header.name]
                              )
                            }
                          />
                        )}
                        {header.name === "Delivery Schedule" ? (
                          <>
                            <div className={styles.deliveryNumber}>
                              {editMode && editMode.id === item.id ? (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <input
                                    type='text'
                                    value={editedValue}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      if (/^\d*$/.test(value)) {
                                        setEditedValue(value);
                                      }
                                    }}
                                    className={styles.editInput}
                                  />
                                  <div
                                    style={{
                                      width: "40%",
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      justifyContent: "space-evenly",
                                    }}
                                  >
                                    <img
                                      src={CheckIcon}
                                      width='20px'
                                      height='20px'
                                      title={"Confirm"}
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setEditMode(null);
                                        handleDayChange(
                                          item.id,
                                          header.name,
                                          editedValue
                                        );
                                      }}
                                    />
                                    <img
                                      src={CrossIcon}
                                      width='20px'
                                      height='20px'
                                      title={"Cancel"}
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() => setEditMode(null)}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  {number}
                                  {/* <div
                                    className=
                                  > */}
                                  <img
                                    src={EditIcon}
                                    width='20px'
                                    height='20px'
                                    title={"Edit"}
                                    className={
                                      hoveredRow === item.id
                                        ? styles.editDetails
                                        : styles.hide
                                    }
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handleEditClick(
                                        item.id,
                                        item["Delivery Schedule"]
                                      )
                                    }
                                  />
                                  {/* </div> */}
                                </div>
                              )}
                            </div>
                            <div className={styles.deliveryText}>{text}</div>
                          </>
                        ) : header.name === "Document Type" ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          >
                            <div>{`${firstDocType} ${
                              moreDocType.length ? "," : ""
                            }`}</div>
                            {moreDocType.length > 0 && (
                              <div
                                className={styles.moreDoctype}
                                title={moreDocType.join("\n")}
                              >{` +${moreDocType.length} More`}</div>
                            )}
                          </div>
                        ) : (
                          <div>{item[header.name]}</div>
                        )}
                        {header.name === "Contact Pref." && (
                          <div
                            className={
                              hoveredRow === item.id
                                ? styles.editDetails
                                : styles.hide
                            }
                          >
                            <img
                              src={EyeIcon}
                              width='20px'
                              height='20px'
                              title={"view"}
                              onClick={() =>
                                handleEyeClick(
                                  item.id,
                                  item["Email Slug"],
                                  item.fullInfo
                                )
                              }
                            />
                            <img
                              src={EditIcon}
                              width='20px'
                              height='20px'
                              title={"Edit"}
                              onClick={() => handleEditRow(item.fullInfo)}
                            />
                          </div>
                        )}
                      </div>
                    </td>
                  );
                })}
              </tr>
            ))}
          </React.Fragment>
        ))}
      </tbody>
    </Table>
  );
};

export default DynamicTable;
