import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import Select from "react-select";
import "react-select/dist/react-select.css";
import MaskedTextInput from "react-text-mask";
import AppCanvasLoader from "../../../components/App/AppCanvasLoader";
import * as API from "../../../utils/api";
import { showToast, validateEmail } from "../../../utils/helpers";
import stateDataShortNames from "../../../utils/stateDataShortNames.json";
import { getBaseUrl } from "../../../utils/api";
import Cookies from "universal-cookie";
class CustomizeWorkFlowStep1 extends Component {
  state = {
    useAddress: false,
    useCompanyName: false,
    useCompanyAddress: false,
    validation_error: false,
    userAlreadyVerified: "",
    displayNameExist: "",
    organizationDetails: "",
    input: {
      consumerInformation: {
        companyName: {
          value: "",
          hasError: false,
          isRequired: true,
        },
        displayName: {
          value: "",
          hasError: false,
          isRequired: true,
        },
        first_name: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        last_name: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        email: {
          value: "",
          hasError: false,
          isRequired: true,
        },
        phone_number: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        date_of_birth: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        street_address_primary: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        street_address_secondary: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        street_legal_address_primary: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        street_legal_address_secondary: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        companyName2: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        city: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        state: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        zipcode: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        city2: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        state2: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        zipcode2: {
          value: "",
          hasError: false,
          isRequired: false,
        },
      },
    },
    isLoading: true,
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
  };
  style = {
    pText: {
      fontSize: "14px",
      color: "#2b2828",
      margin: "0px",
    },
  };
  componentDidMount() {
    this.getOrganizationalDetails();
  }
  componentWillReceiveProps = (props) =>{
    this.getOrganizationalDetails();

  }
  back = () => {
    let { history } = this.props;
    history.push("/welcome-screen/invite-your-team-members");
  };
  getMergeVariablesList = () => {
    let { location } = this.props;
    let { mergeVarData, organizationDetails } = this.state;
    const accountId = this.state.organizationDetails.organization.id;
    // API.getMergeVariablesListClient(this.props.user, accountId).then((data) => {
      // if ((typeof data != "undefined" || data != null) && !data.message) {
        this.setState((state) => ({
          ...state,
          mergeVarData: this.props.mergeVarData,
          isLoading: false,
        }),() =>{

          this.getMergeData();
        });
      // } else {
      //   this.setState((state) => ({
      //     ...state,
      //     error: {
      //       hasAnyError: true,
      //       statusCode: data
      //         ? data.status ||
      //           (data.status_code && data.status_code == 401 ? 401 : 500) ||
      //           500
      //         : 500,
      //     },
      //   }));
      // }
    // });
  };
  getMergeData = () => {
    let { input, organizationDetails } = this.state;
    let PaymetAdress = this.state.mergeVarData["Payment Address"][""];
    let PaymentInformation = this.state.mergeVarData["Payment Information"][""];
    input.consumerInformation.street_legal_address_primary.hasError = false;
    input.consumerInformation.city2.hasError = false;
    input.consumerInformation.zipcode2.hasError = false;
    input.consumerInformation.state2.hasError = false;
    input.consumerInformation.street_legal_address_secondary.hasError = false;
    input.consumerInformation.first_name.hasError = false;
    input.consumerInformation.last_name.hasError = false;
    input.consumerInformation.email.hasError = false;

    input.consumerInformation.companyName.value =
      PaymentInformation[0].value !== null && PaymentInformation[0].value !== ""
        ? PaymentInformation[0].value
        : organizationDetails.organization.name;
    input.consumerInformation.first_name.value =
      PaymentInformation[2].value !== "" && PaymentInformation[2].value !== null
        ? PaymentInformation[2].value
        : organizationDetails.organization.primary_contact_first_name;
    input.consumerInformation.last_name.value =
      PaymentInformation[3].value !== "" && PaymentInformation[3].value !== null
        ? PaymentInformation[3].value
        : organizationDetails.organization.primary_contact_last_name;
    input.consumerInformation.email.value =
      PaymentInformation[4].value !== "" && PaymentInformation[4].value !== null
        ? PaymentInformation[4].value
        : organizationDetails.organization.email;
    input.consumerInformation.street_legal_address_primary.value =
      PaymetAdress[0].value !== "" && PaymetAdress[0].value !== null
        ? PaymetAdress[0].value
        : organizationDetails.organization.company_add_line1;
    input.consumerInformation.street_legal_address_secondary.value =
      PaymetAdress[4].value !== null
        ? PaymetAdress[4].value
        : organizationDetails.organization.company_add_line2 !== "" &&
          organizationDetails.organization.company_add_line2 !== null
        ? organizationDetails.organization.company_add_line2
        : null;
    input.consumerInformation.city2.value =
      PaymetAdress[1].value !== "" && PaymetAdress[1].value !== null
        ? PaymetAdress[1].value
        : organizationDetails.organization.company_add_city;
    input.consumerInformation.state2.value =
      PaymetAdress[2].value !== "" && PaymetAdress[2].value !== null
        ? PaymetAdress[2].value
        : organizationDetails.organization.company_add_state;
    input.consumerInformation.zipcode2.value =
      PaymetAdress[3].value !== "" && PaymetAdress[3].value !== null
        ? PaymetAdress[3].value
        : organizationDetails.organization.company_add_zip;
    this.setState({ input });
    this.checkMergeVarData(PaymentInformation);
    // this.setLetterData('OrgAdmin');
  };
  handleConsumerInfoInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        consumerInformation: {
          ...state.input.consumerInformation,
          ...newPartialInput,
        },
      },
      useAddress: false,
      useCompanyName: false,
      useCompanyAddress: false,
    }));
    this.handleUncheckedValue(newPartialInput);
  }
  checkMergeVarData = (PaymentInformation) => {
    PaymentInformation.map((item) => {
      item.value === null || item.value === ""
        ? this.setState({
            useAddress: true,
            useCompanyName: true,
            useCompanyAddress: true,
          })
        : this.setState({
            useAddress: false,
            useCompanyName: false,
            useCompanyAddress: false,
          });
    });
    this.handleCheckOrgData();
  };
  handleUncheckedValue = (newPartialInput) => {
    let { useAddress, useCompanyName, useCompanyAddress } = this.state;

    if (newPartialInput.first_name || newPartialInput.last_name) {
      useAddress = false;
    } else if (newPartialInput.companyName) {
      useCompanyName = false;
    } else if (
      newPartialInput.street_legal_address_primary ||
      newPartialInput.street_legal_address_secondary ||
      newPartialInput.city2 ||
      newPartialInput.state2 ||
      newPartialInput.zipcode2
    ) {
      useCompanyAddress = false;
    }
    this.setState({ useAddress, useCompanyName, useCompanyAddress });
  };
  getOrganizationalDetails = () => {
    const cookies = new Cookies();
    let authuser = cookies.get("accessToken", { path: "/", secure: true });
    let user = {
      user_auth_token: authuser,
    };
    if (authuser && authuser != null) {
      // API.getOrgDetails(user).then((data) => {
        // if ((typeof data != "undefined" || data != null) && data.data) {
          this.setState({ organizationDetails: this.props.orgData }, () =>
            this.getMergeVariablesList()
          );
          let { input, organizationDetails } = this.state;
        // } else {
        //   this.setState((state) => ({
        //     ...state,
        //     error: {
        //       hasAnyError: true,
        //       statusCode: data
        //         ? data.status ||
        //           (data.status_code && data.status_code == 401 ? 401 : 500) ||
        //           500
        //         : 500,
        //     },
        //   }));
        // }
      // });
    } else {
      //  this.props.handleSignOut()
    }
  };
  handleCheckOrgData = () => {
    let { input, organizationDetails } = this.state;
    if (
      input.consumerInformation.street_legal_address_primary.value ===
        organizationDetails.organization.company_add_line1 &&
      input.consumerInformation.street_legal_address_secondary.value ===
        organizationDetails.organization.company_add_line2 &&
      input.consumerInformation.city2.value ===
        organizationDetails.organization.company_add_city &&
      input.consumerInformation.zipcode2.value ===
        organizationDetails.organization.company_add_zip &&
      input.consumerInformation.state2.value ===
        organizationDetails.organization.company_add_state
    ) {
      this.setState({ useCompanyAddress: true });
    } else {
      this.setState({ useCompanyAddress: false });
    }

    if (
      input.consumerInformation.companyName.value ===
      organizationDetails.organization.name
    ) {
      this.setState({
        useCompanyName: true,
      });
    } else {
      this.setState({ useCompanyName: false });
    }

    if (
      input.consumerInformation.first_name.value ===
        organizationDetails.organization.primary_contact_first_name &&
      input.consumerInformation.last_name.value ===
        organizationDetails.organization.primary_contact_last_name &&
      input.consumerInformation.email.value ===
        organizationDetails.organization.email
    ) {
      this.setState({
        useAddress: true,
      });
    } else {
      this.setState({ useAddress: false });
    }
  };
  handleSubmit = (event) => {
    let { organizationDetails } = this.state;
    let PaymetAdress = this.state.mergeVarData["Payment Address"][""];
    let PaymentInformation = this.state.mergeVarData["Payment Information"][""];

    event.preventDefault();
    const { input } = this.state;

    if (this.validateForm()) {
      let payload = {
        merge_vars: [
          {
            merge_variable_id: PaymetAdress[0].merge_variable_id,
            value: input.consumerInformation.street_legal_address_primary.value,
            organization_id: organizationDetails.organization.id,
          },
          {
            merge_variable_id: PaymetAdress[1].merge_variable_id,
            value: input.consumerInformation.city2.value,
            organization_id: organizationDetails.organization.id,
          },
          {
            merge_variable_id: PaymetAdress[2].merge_variable_id,
            value: input.consumerInformation.state2.value,
            organization_id: organizationDetails.organization.id,
          },
          {
            merge_variable_id: PaymetAdress[3].merge_variable_id,
            value: input.consumerInformation.zipcode2.value,
            organization_id: organizationDetails.organization.id,
          },
          {
            merge_variable_id: PaymetAdress[4].merge_variable_id,
            value:
              input.consumerInformation.street_legal_address_secondary.value,
            organization_id: organizationDetails.organization.id,
          },
          {
            merge_variable_id: PaymentInformation[0].merge_variable_id,
            value: input.consumerInformation.companyName.value,
            organization_id: organizationDetails.organization.id,
          },
          {
            merge_variable_id: PaymentInformation[2].merge_variable_id,
            value: input.consumerInformation.first_name.value,
            organization_id: organizationDetails.organization.id,
          },
          {
            merge_variable_id: PaymentInformation[3].merge_variable_id,
            value: input.consumerInformation.last_name.value,
            organization_id: organizationDetails.organization.id,
          },
          {
            merge_variable_id: PaymentInformation[4].merge_variable_id,
            value: input.consumerInformation.email.value,
            organization_id: organizationDetails.organization.id,
          },
        ],
      };
      API.updateMergeVariablesClient(this.props.user, payload).then((data) => {
        if ((typeof data != "undefined" || data != null) && !data.error) {
          if (data.status_code == 200) {
            //show toast only if any field update
            if (data.modify_count != 0) {
              this.props.updateModalState(true, "SUCCESS", {
                message: data.message,
              });
            }
            this.props.getOrganizationalDetails();
            this.props.handleNext("step2");
          } else if (data.status_code !== 200) {
            this.setState((state) => ({
              ...state,
              hasAnyError: true,
            }));
            this.props.updateModalState(true, "ERROR", {
              message: data.message,
            });
          }
        }
      });
    } else {
      this.setFormErrorState();
    }
  };

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  validateForm = (formType) => {
    let errorsArePresent = false;

    // Determine which input group to check for errors
    let inputs = { ...this.state.input.consumerInformation };

    // Check inputs for errors
    Object.entries(inputs).forEach(([key, value]) => {
      if (key === "companyName") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
        }
      } else if (key === "first_name") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
        }
      } else if (key === "last_name") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
        }
      } else if (key === "email") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
        }
      } else if (key === "street_legal_address_primary") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
        }
      } else if (key === "city2") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
        }
      } else if (key === "state2") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
        }
      } else if (key === "zipcode2") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
        }
      } else if (key === "email") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
        }
      }
    });

    if (errorsArePresent) {
      return false;
    } else {
      return true;
    }
  };

  setFormErrorState = (formType) => {
    let errorsArePresent = false;

    // Determine which input group to check for errors
    let newInputState = { ...this.state.input.consumerInformation };

    // Set error state on necessary fields
    Object.entries(newInputState).forEach(([key, value]) => {
      if (key === "email") {
        if (value.value === "" || !validateEmail(value.value)) {
          newInputState[key].hasError = true;
          this.setState({ userAlreadyVerified: "" });
          this.setState({ displayNameExist: "" });
        }
      } else if (key === "companyName") {
        if (value.value === "" || value.value === null) {
          newInputState[key].hasError = true;
        }
      } else if (key === "street_legal_address_secondary") {
        if (value.value === "" || value.value === null) {
          newInputState[key].hasError = true;
        }
      } else if (key === "last_name") {
        if (value.value === "" || value.value === null) {
          newInputState[key].hasError = true;
        }
      } else if (key === "first_name") {
        if (value.value === "" || value.value === null) {
          newInputState[key].hasError = true;
        }
      } else if (key === "street_legal_address_primary") {
        if (value.value === "" || value.value === null) {
          newInputState[key].hasError = true;
        }
      } else if (key === "city2") {
        if (value.value === "" || value.value === null) {
          newInputState[key].hasError = true;
        }
      } else if (key === "state2") {
        if (value.value === "" || value.value === null) {
          newInputState[key].hasError = true;
        }
      } else if (key === "zipcode2") {
        if (value.value === "" || value.value === null) {
          newInputState[key].hasError = true;
        }
      } else if (key === "email") {
        if (value.value === "" || !validateEmail(value.value)) {
          newInputState[key].hasError = true;
          this.setState({ userAlreadyVerified: "" });
          this.setState({ displayNameExist: "" });
        }
      }

      if (newInputState[key].hasError) {
        errorsArePresent = true;
      }
    });

    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        consumerInformation: newInputState,
      },
    }));
  };
  renderErrorMessage(input) {
    let message = "";
    if (input === "companyName") {
      message = "Please enter company name";
    } else if (input === "displayName") {
      message = "Please enter a display name";
    } else if (input === "email") {
      message = "Please enter a valid email address";
    }
    if (input === "first_name") {
      message = "Please enter First name";
    } else if (input === "last_name") {
      message = "Please enter Last name";
    } else if (input === "city2") {
      message = "Please enter a city";
    } else if (input === "state2") {
      message = "Please select a state";
    } else if (input === "zipcode2") {
      message = "Please enter a zip code";
    } else if (input === "street_legal_address_primary") {
      message = "Please enter company address Line 1";
    } else if (input === "street_legal_address_secondary") {
      message = "Please enter company address Line 2";
    } else if (input === "companyName") {
      message = "Please enter company name";
    } else if (input === "email") {
      message = "Please enter a valid email address";
    } else {
      message = "Please complete this field";
    }

    return <div className="input-error-message">{message}</div>;
  }
  handleCheckboxInputChange = (event, res) => {
    let { input } = this.state;
    let target = event.target;
    if (res === "OrgAdmin") {
      this.setState({ useAddress: target.checked }, () => {
        this.setLetterData(res);
      });
    } else if (res === "CompanyName") {
      this.setState({ useCompanyName: target.checked }, () => {
        this.setLetterData(res);
      });
    } else if (res === "CompanyAddress") {
      this.setState({ useCompanyAddress: target.checked }, () => {
        this.setLetterData(res);
      });
    }
  };

  setLetterData = (res) => {
    let {
      input,
      organizationDetails,
      useAddress,
      useCompanyAddress,
      useCompanyName,
    } = this.state;
    let temp = { ...input };
    let PaymetAdress = this.state.mergeVarData["Payment Address"][""];
    let PaymentInformation = this.state.mergeVarData["Payment Information"][""];
    if (res === "OrgAdmin") {
      if (!useAddress) {
        temp.consumerInformation.first_name.value = "";
        temp.consumerInformation.first_name.hasError = false;
        temp.consumerInformation.last_name.value = "";
        temp.consumerInformation.email.value = "";
        // this.state.organizationDetails.organization.email;
        temp.consumerInformation.email.hasError = false;
        temp.consumerInformation.last_name.hasError = false;
        this.setState((state) => ({
          input: temp,
        }));
      }
      if (useAddress) {
        let temp2 = { ...input };
        input.consumerInformation.first_name.value =
          organizationDetails.organization.primary_contact_first_name !== null
            ? organizationDetails.organization.primary_contact_first_name
            : "";
        // PaymentInformation[2].value !== null
        // ? PaymentInformation[2].value : "";
        input.consumerInformation.first_name.hasError = false;
        input.consumerInformation.last_name.value =
          organizationDetails.organization.primary_contact_last_name !== null
            ? organizationDetails.organization.primary_contact_last_name
            : "";
        // PaymentInformation[3].value !== null
        // ? PaymentInformation[3].value : "";
        input.consumerInformation.last_name.hasError = false;
        input.consumerInformation.email.value =
          organizationDetails.organization.email !== null
            ? organizationDetails.organization.email
            : "";
        // PaymentInformation[4].value !== null
        //   ? PaymentInformation[4].value
        //   : "";
        input.consumerInformation.email.hasError = false;
        this.setState({ input });
      }
    }
    if (res === "CompanyName") {
      if (useCompanyName) {
        let temp2 = { ...input };
        // let { organizationDetails } = this.state;
        temp2.consumerInformation.companyName.value =
          organizationDetails.organization.name;
        temp2.consumerInformation.companyName.hasError = false;
        this.setState((state) => ({
          input: temp2,
        }));
      } else if (!useCompanyName) {
        let temp2 = { ...input };
        temp2.consumerInformation.companyName.value = "";
        // PaymentInformation[0].value !== null
        //   ? PaymentInformation[0].value
        //   : "";
        temp2.consumerInformation.companyName.hasError = false;
        this.setState((state) => ({
          input: temp2,
        }));
      }
    } else if (res === "CompanyAddress") {
      if (useCompanyAddress) {
        let temp2 = { ...input };
        let { organizationDetails } = this.state;
        temp2.consumerInformation.street_legal_address_primary.value =
          organizationDetails.organization.company_add_line1;
        temp2.consumerInformation.street_legal_address_primary.hasError = false;
        temp2.consumerInformation.street_legal_address_secondary.value =
          organizationDetails.organization.company_add_line2;
        temp2.consumerInformation.street_legal_address_secondary.hasError = false;
        temp2.consumerInformation.city2.value =
          organizationDetails.organization.company_add_city;
        temp2.consumerInformation.city2.hasError = false;
        temp2.consumerInformation.zipcode2.value =
          organizationDetails.organization.company_add_zip;
        temp2.consumerInformation.zipcode2.hasError = false;
        temp2.consumerInformation.state2.value =
          organizationDetails.organization.company_add_state;
        temp2.consumerInformation.state2.hasError = false;
        this.setState((state) => ({
          input: temp2,
        }));
      } else if (!useCompanyAddress) {
        let temp2 = { ...input };
        temp2.consumerInformation.street_legal_address_primary.value = "";
        // PaymetAdress[0].value !== null && PaymetAdress[0].value;
        temp2.consumerInformation.street_legal_address_primary.hasError = false;
        temp.consumerInformation.city2.value = "";
        // PaymetAdress[1].value !== null && PaymetAdress[1].value;
        temp.consumerInformation.city2.hasError = false;
        temp.consumerInformation.zipcode2.value = "";
        // PaymetAdress[3].value !== null ? PaymetAdress[3].value : "";
        temp.consumerInformation.zipcode2.hasError = false;
        temp.consumerInformation.state2.value = "";
        // PaymetAdress[2].value !== null ? PaymetAdress[2].value : "";
        temp.consumerInformation.state2.hasError = false;
        temp2.consumerInformation.street_legal_address_secondary.value = "";
        // PaymetAdress[4].value !== null ? PaymetAdress[4].value : "";
        temp2.consumerInformation.street_legal_address_secondary.hasError = false;
        this.setState((state) => ({
          input: temp2,
        }));
      }
    }
  };

  render() {
    let { input, creditors, error, organizationDetails, isLoading } =
      this.state;
    let { location } = this.props;
    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return isLoading ? (
      <div className="loader_alignment">
        <div style={{ marginTop: "20%" }}>
          <AppCanvasLoader />
        </div>
      </div>
    ) : (
      <div className="app-sidebar-layout-canvas-content setting-side-canvas col1-scroll mg-col1-scroll scrollbar mg-wt-100 mg-plpr-0">
        <div
          // className="app-sidebar-layout-canvas-content setting-side-canvas col1-scroll scrollbar mg-wt-100 mg-ml-0"
          style={{
            // width: "95%",
            // height: "80%",
            backgroundColor: "white",
            padding: "20px",
            marginLeft: "2rem",
            marginRight: "2rem",
          }}
        >
          <h3
            className="mg-steps-header mg-step-head mg-ml-0 mg-ml-10"
            style={{
              color: "black",
              marginBottom: "10px",
              fontFamily: "Open Sans ,sans-serif",

              // width: "0%",
            }}
          >
            Add Billing Details
          </h3>
          <h7
            style={{
              fontFamily: "Open Sans ,sans-serif",
              lineHeight: "20px",
              fontSize: "16px",
            }}
          >
            Enter your organization's billing information below. This
            information in this step will be used to communicate your
            organization's billing details to your clients including how they
            contact you for billing inquiries, who to make checks payable to,
            and where to send payment when sending a check.
          </h7>
          <div style={{ paddingTop: "20px" }}>
            <div
              optionalClasses="add-account-modal scroll"
              title="Add Organization’s Detail"
              closeOnClickOutside={true}
              hideModal={this.props.hideModal}
              style={{ width: "100%", height: "50%" }}
            >
              <form className="admin-form">
                <div className={`modal-slide`}>
                  <div className="form-container">
                    <div className="form-column">
                      <header
                        className="modal-slide-header"
                        style={{ textAlign: "left", marginBottom: "0.5em" }}
                      >
                        <div
                          style={{ display: "flex", alignItems: "flex-end" }}
                        >
                          <div
                            className={`modal-slide-header-tab`}
                            style={{
                              margin: 0,
                              color: "black",
                              fontWeight: "bold",
                              fontFamily: "unset",
                              width: "400px",
                            }}
                          >
                            Billing Information
                          </div>
                          <label
                            style={{
                              display: `flex`,
                              fontFamily: "Open Sans ,sans-serif",
                              fontWeight: "bold",
                            }}
                          >
                            <input
                              type="checkbox"
                              id='add_billing_detail_use_org_admin_checkbox'
                              style={{
                                WebkitAppearance: `checkbox`,
                                width: `15px`,

                                marginRight: `10px`,
                                marginTop: `-6px`,
                              }}
                              checked={this.state.useAddress}
                              onChange={(e) =>
                                this.handleCheckboxInputChange(e, "OrgAdmin")
                              }
                            />
                            Use Organization Admin
                          </label>
                        </div>
                      </header>
                      <div
                        className={`input-container${
                          input.consumerInformation.first_name.hasError ||
                          input.consumerInformation.last_name.hasError
                            ? " error"
                            : ""
                        }`}
                        style={{ height: "70px" }}
                      >
                        <label
                          htmlFor="name"
                          style={{ fontWeight: "bold", fontFamily: "unset" }}
                        >
                          Primary Billing Contact *
                        </label>

                        <div
                          className="input-container"
                          style={{ display: "flex", marginTop: "20px" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "600px",
                            }}
                          >
                            <label
                              htmlFor="streetAddress"
                              style={{
                                fontWeight: "bold",
                                fontFamily: "unset",
                              }}
                            >
                              First Name
                            </label>
                            <input
                              className="multi-input-field-input"
                              name="name"
                              id='add_billing_detail_first_name'
                              type="text"
                              placeholder="First Name"
                              value={input.consumerInformation.first_name.value}
                              style={{ fontFamily: "unset", color: "black" }}
                              onChange={(event) =>
                                this.handleConsumerInfoInputChange({
                                  first_name: {
                                    value: event.target.value,
                                    hasError: false,
                                  },
                                })
                              }
                            />
                            {input.consumerInformation.first_name.hasError
                              ? this.renderErrorMessage("first_name")
                              : null}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "600px",
                            }}
                          >
                            <label
                              htmlFor="streetAddress"
                              style={{
                                fontWeight: "bold",
                                fontFamily: "unset",
                              }}
                            >
                              Last Name
                            </label>
                            <input
                              className="multi-input-field-input"
                              name="name"
                              id='add_billing_detail_last_name'
                              type="text"
                              placeholder="Last Name"
                              value={input.consumerInformation.last_name.value}
                              style={{ fontFamily: "unset", color: "black" }}
                              onChange={(event) =>
                                this.handleConsumerInfoInputChange({
                                  last_name: {
                                    value: event.target.value,
                                    hasError: false,
                                  },
                                })
                              }
                            />

                            {input.consumerInformation.last_name.hasError
                              ? this.renderErrorMessage("last_name")
                              : null}
                          </div>
                        </div>
                      </div>

                      <div
                        className={`input-container${
                          input.consumerInformation.email.hasError
                            ? " error"
                            : ""
                        }`}
                        style={{ height: "75px" }}
                      >
                        <label
                          htmlFor="streetAddress"
                          style={{
                            fontWeight: "bold",
                            fontFamily: "unset",
                            marginTop: "20px",
                          }}
                        >
                          Billing Contact Email Address
                        </label>
                        <input
                          name="email"
                          id='add_billing_detail_contact_email_address'
                          type="email"
                          placeholder="address@email.com"
                          value={input.consumerInformation.email.value}
                          style={{ fontFamily: "unset" }}
                          onChange={(event) =>
                            this.handleConsumerInfoInputChange({
                              email: {
                                value: event.target.value,
                                hasError: false,
                              },
                            })
                          }
                        />

                        {input.consumerInformation.email.hasError
                          ? this.renderErrorMessage("email")
                          : null}
                        <div className="add-account-exist-error">
                          {this.state.userAlreadyVerified}
                        </div>
                      </div>
                      <div
                        className={`input-container${
                          input &&
                          input.consumerInformation &&
                          input.consumerInformation.companyName &&
                          input.consumerInformation.companyName.hasError
                            ? " error"
                            : ""
                        }`}
                        style={{ height: "70px" }}
                      >
                        <div style={{ display: "flex" }}>
                          <label
                            htmlFor="name"
                            style={{
                              fontWeight: "bold",
                              fontFamily: "unset",
                              width: "400px",
                            }}
                          >
                            Make Checks Payable To *
                          </label>
                          <label
                            style={{
                              display: `flex`,
                              fontFamily: "Open Sans,sans-serif",
                              fontWeight: "bold",
                            }}
                          >
                            <input
                              type="checkbox"
                              id='add_billing_detail_use_company_name_checkbox'
                              style={{
                                WebkitAppearance: `checkbox`,
                                width: `15px`,
                                marginRight: `10px`,
                                marginTop: `-6px`,
                              }}
                              checked={this.state.useCompanyName}
                              onChange={(e) =>
                                this.handleCheckboxInputChange(e, "CompanyName")
                              }
                            />
                            Use Company Name
                          </label>
                        </div>
                        <input
                          name="name"
                          id='add_billing_detail_company_name'
                          type="text"
                          placeholder="Company Name"
                          value={input.consumerInformation.companyName.value}
                          style={{ fontFamily: "unset" }}
                          onChange={(event) =>
                            this.handleConsumerInfoInputChange({
                              companyName: {
                                value: event.target.value,
                                hasError: false,
                              },
                            })
                          }
                        />
                        {input.consumerInformation.companyName.hasError
                          ? this.renderErrorMessage("companyName")
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div style={{ width: "50px" }}></div>
            <div
              optionalClasses="add-account-modal scroll"
              title="Add Organization’s Detail"
              closeOnClickOutside={true}
              hideModal={this.props.hideModal}
              style={{ width: "100%", height: "50%" }}
            >
              <form className="admin-form">
                <div className={`modal-slide`}>
                  <div className="form-container">
                    <div
                      className={`input-container${
                        input.consumerInformation.street_legal_address_primary
                          .hasError
                          ? " error"
                          : ""
                      }`}
                      style={{ height: "70px" }}
                    >
                      <div style={{ display: `flex`, height: "18px" }}>
                        <label
                          htmlFor="streetAddress"
                          style={{
                            fontWeight: "bold",
                            fontFamily: "unset",
                            width: "400px",
                          }}
                        >
                          Payment Address *
                        </label>
                        <label
                          style={{
                            display: `flex`,
                            fontFamily: "Open Sans ,sans-serif",
                            fontWeight: "bold",
                          }}
                        >
                          <input
                            type="checkbox"
                            id='add_billing_detail_same_as_company_address_checkbox'
                            style={{
                              WebkitAppearance: `checkbox`,
                              width: `15px`,
                              marginRight: `10px`,
                              marginTop: `-6px`,
                            }}
                            checked={this.state.useCompanyAddress}
                            onChange={(e) =>
                              this.handleCheckboxInputChange(
                                e,
                                "CompanyAddress"
                              )
                            }
                          />
                          Same as Company Address
                        </label>
                      </div>
                      <input
                        name="streetAddress"
                        id='add_billing_detail_company_address_line_1'
                        type="text"
                        placeholder="Company Address Line 1"
                        value={
                          input.consumerInformation.street_legal_address_primary
                            .value
                            ? input.consumerInformation
                                .street_legal_address_primary.value
                            : ""
                        }
                        style={{ fontFamily: "unset" }}
                        onChange={(event) =>
                          this.handleConsumerInfoInputChange({
                            street_legal_address_primary: {
                              value: event.target.value,
                              hasError: false,
                            },
                          })
                        }
                      />
                      {input.consumerInformation.street_legal_address_primary
                        .hasError
                        ? this.renderErrorMessage(
                            "street_legal_address_primary"
                          )
                        : null}
                    </div>
                    <div
                      className={`input-container`}
                      style={{ height: "70px" }}
                    >
                      <label
                        htmlFor="streetAddress"
                        style={{
                          fontWeight: "bold",
                          fontFamily: "unset",
                          marginTop: "5px",
                        }}
                      >
                        {/* Legal Address Line 2 */}
                      </label>
                      <input
                        name="unit"
                        id='add_billing_detail_company_address_line_2'
                        type="text"
                        placeholder="Company Address Line 2"
                        value={
                          input.consumerInformation
                            .street_legal_address_secondary.value
                        }
                        style={{ fontFamily: "unset" }}
                        onChange={(event) =>
                          this.handleConsumerInfoInputChange({
                            street_legal_address_secondary: {
                              value: event.target.value,
                              hasError: false,
                            },
                          })
                        }
                      />
                    </div>
                    <div className="form-column">
                      <div style={{ display: "flex" }} className="mg-dir-col">
                        <div
                          className={`input-container ${
                            input.consumerInformation.city2.hasError
                              ? "error"
                              : ""
                          }`}
                          style={{ height: "70px" }}
                        >
                          <label
                            htmlFor="city"
                            style={{ fontWeight: "bold", fontFamily: "unset" }}
                          >
                            City
                          </label>
                          <input
                            name="city"
                            type="text"
                            id='add_billing_detail_city_name'
                            placeholder="Enter a City"
                            value={
                              input.consumerInformation.city2.value
                                ? input.consumerInformation.city2.value
                                : ""
                            }
                            style={{ fontFamily: "unset" }}
                            onChange={(event) =>
                              this.handleConsumerInfoInputChange({
                                city2: {
                                  value: event.target.value,
                                  hasError: false,
                                },
                              })
                            }
                          />
                          {input.consumerInformation.city2.hasError
                            ? this.renderErrorMessage("city2")
                            : null}
                        </div>
                        <div
                          className={`input-container ${
                            input.consumerInformation.state2.hasError
                              ? "error"
                              : ""
                          }`}
                          style={{ height: "70px" }}
                        >
                          <label
                            htmlFor="state"
                            style={{ fontWeight: "bold", fontFamily: "unset" }}
                          >
                            State
                          </label>
                          <Select
                            inputProps={{
                              autoComplete: "none",
                              autoCorrect: "off",
                              spellCheck: "off",
                            }}
                            id='add_billing_detail_state'
                            className="form-select"
                            name="state"
                            value={input.consumerInformation.state2.value}
                            placeholder="Select"
                            options={stateDataShortNames}
                            resetValue=""
                            style={{ fontFamily: "unset", bottom: "0.9px" }}
                            onChange={(event) =>
                              this.handleConsumerInfoInputChange({
                                state2: {
                                  value: event ? event.value : "",
                                  hasError: false,
                                },
                              })
                            }
                          />
                          {input.consumerInformation.state2.hasError
                            ? this.renderErrorMessage("state2")
                            : null}
                        </div>
                        <div
                          className={`input-container ${
                            input.consumerInformation.zipcode2.hasError
                              ? "error"
                              : ""
                          }`}
                          style={{ height: "70px" }}
                        >
                          <label
                            htmlFor="zipcode"
                            style={{ fontWeight: "bold", fontFamily: "unset" }}
                          >
                            Zip Code
                          </label>
                          <MaskedTextInput
                            guide={true}
                            mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                            id='add_billing_detail_zip_code'
                            name="zipcode"
                            placeholder="90210"
                            placeholderChar={"\u2000"}
                            type="text"
                            value={input.consumerInformation.zipcode2.value}
                            style={{ fontFamily: "unset" }}
                            onChange={(event) =>
                              this.handleConsumerInfoInputChange({
                                zipcode2: {
                                  value: event.target.value,
                                  hasError: false,
                                },
                              })
                            }
                          />
                          {input.consumerInformation.zipcode2.hasError
                            ? this.renderErrorMessage("zipcode2")
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div
              className="bottum-btns"
              style={{
                // display: "flex",
                // justifyContent: "space-between",
                // width: "100%",
                paddingTop: "0px",
                paddingBottom: "50px",
              }}
            >
              <div className="button-left">
                <input
                  type="submit"
                  id='add_billing_detail_go_back'
                  value="Go Back"
                  style={{
                    borderRadius: "0.5em",
                    width: "200px",
                    height: "50px",
                    marginTop: "17px",
                    border: "2px solid",
                    fontFamily: "Open Sans",
                    fontSize: "16px",
                    fontWeight: "500",
                    float: "left",
                  }}
                  //   onClick={location.backToInviteTeam.backToInviteTeam()}
                  onClick={this.back}
                />
                <span className="go-back-text">
                  <p style={this.style.pText}>Go back to</p>
                  <p style={this.style.pText}>"Team Management"</p>
                </span>
              </div>
              <div className="button-right">
                <input
                  type="submit"
                  id='add_billing_detail_next'
                  value={"Next"}
                  className="cta mg-brand2-color mg-nextbtn"
                  style={{
                    width: "200px",
                    height: "50px",
                    borderRadius: "0.5em",
                    marginTop: "17px",
                    // left: "110px",
                    marginLeft: "0px",
                    fontFamily: "Open Sans",
                    fontSize: "16px",
                    fontWeight: "500",
                    float: "right",
                  }}
                  onClick={this.handleSubmit}
                />
                <span className="next-text">
                  <p style={this.style.pText}>Continue to</p>
                  <p style={this.style.pText}>"Add Banking Information"</p>
                </span>
              </div>
            </div>
            {/* <div id="send-new-mail" className="modal-actions double-btns">
              {" "}
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CustomizeWorkFlowStep1);
