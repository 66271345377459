import moment from "moment";
import React, { Component } from "react";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import EditAdjustment from "../../assets/icons/edit.svg";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
// Components
import AppSectionHeader from "../../components/App/AppSectionHeader";
import CounterofferModal from "../../components/App/Modals/Client/CounterofferModal";
import EmployeeSidebar from "../../components/Employee/EmployeeSidebar";
import ObjectDebtList from "../../components/ObjectList/ObjectDebtList";
import ObjectList from "../../components/ObjectList/ObjectList";
import EmployeeUploadValidationDoc from "./EmployeeUploadValidationDoc";
// API
import * as API from "../../utils/api";
import {
  getNumberWithCommas,
  getStrippedDollarAmount,
  getZohoPagesense,
  showToast,
} from "../../utils/helpers";
import { type } from "jquery";

class EmployeeAccountsView extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    user: {},
    consumer: {},
    debts: [],
    offers: [],
    selectedDebt: [],
    isLoading: true,
    selectedOfferForCounter: {},
    showCounterOfferModal: false,
    currentModal: null,
    optionalModalProps: null,
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
    open_additional_details: false,
    showValidationScreen: false,
  };

  componentDidMount() {
    this.getData();
    getZohoPagesense();
    ReactGA.pageview("/EmployeesAccountsView");
  }

  getData() {
    const accountId = this.props.match.params.id;
    API.getClientsConsumer(this.props.user, accountId).then((data) => {
      let isAccessDenied =
        data && data.message && data.message == "Access denied." ? true : false;

      if (
        (typeof data != "undefined" || data != null) &&
        !data.message &&
        !isAccessDenied
      ) {
        let offersForReview = data.account.offers.filter((offer) => {
          return offer.status === "pending";
        });

        this.setState((state) => ({
          ...state,
          user: this.props.user,
          consumer: data.account.consumer,
          debts: data.account.debts,
          offers: offersForReview,
          isLoading: false,
        }));
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  }

  getAccountFromData = (id, users) => {
    let currentId = parseInt(id, 10);
    let user = users.filter(function (user) {
      return user.id === currentId;
    });

    return user[0];
  };

  handleRowActionClick = (event) => {
    const actionBehavior = event.target.getAttribute("data-behavior");
    const offerId = event.target.getAttribute("data-target");

    switch (actionBehavior) {
      case "offer-accept":
        API.clientsAcceptOffer(this.props.user, "employee", offerId).then(
          (data) => {
            if ((typeof data != "undefined" || data != null) && !data.error) {
              this.handleOfferActionResponse(data, offerId, actionBehavior);
            } else {
              if (data && data.error) {
                this.props.updateModalState(true, "ERROR", {
                  message: data.error,
                });
              }
              this.setState((state) => ({
                ...state,
                error: {
                  hasAnyError: true,
                  statusCode: data
                    ? data.status ||
                      (data.message && data.message == "Access denied."
                        ? 401
                        : 500) ||
                      500
                    : 500,
                },
              }));
            }
          }
        );

        break;
      case "offer-counter":
        this.handleCounterOffer(offerId);
        break;
      case "offer-reject":
        API.clientsRejectOffer(this.props.user, "employee", offerId).then(
          (data) => {
            if ((typeof data != "undefined" || data != null) && !data.error) {
              this.handleOfferActionResponse(data, offerId, actionBehavior);
            } else {
              this.setState((state) => ({
                ...state,
                error: {
                  hasAnyError: true,
                  statusCode: data
                    ? data.status ||
                      (data.message && data.message == "Access denied."
                        ? 401
                        : 500) ||
                      500
                    : 500,
                },
              }));
            }
          }
        );
        break;
      default:
        alert("Error: Something went wrong");
    }
  };

  handleCounterOffer = (offerId) => {
    let selectedOffer = this.getClientsOffer(offerId);
    let modalProps = {};

    if (selectedOffer.number_of_payments === 1) {
      this.setState((state) => ({
        ...state,
        showCounterOfferModal: true,
        selectedOfferForCounter: selectedOffer,
      }));
    } else {
      modalProps = {
        message:
          "This offer cannot be countered because the consumer wants to make multiple payments. Please accept or reject this offer.",
      };
      this.props.updateModalState(true, "ERROR", modalProps);
    }
  };

  handleOfferActionResponse = (data, offerId, actionBehavior) => {
    if (data.message && data.paymentComplete) {
      this.props.updateModalState(true, "SUCCESS", {
        message: data.message,
        title: "You accepted the offer.",
      });
    } else if (data.message) {
      this.hideCounterOfferModal();
      this.props.updateModalState(true, "ERROR", { message: data.message });
    } else {
      let optionalProps = {};
      let { offers, consumer } = this.state;

      switch (actionBehavior) {
        case "offer-accept":
          let selectedOffer = offers.filter((offer) => {
            return offer.id === parseInt(offerId, 10);
          });

          optionalProps = {
            user: consumer,
            offer: selectedOffer[0],
          };
          this.props.updateModalState(true, "OFFER_ACCEPTED", optionalProps);
          break;
        case "offer-counter":
          optionalProps = {
            user: consumer,
            appEmail: this.props.appEmail,
          };
          this.props.updateModalState(true, "COUNTEROFFER_SENT", optionalProps);
          this.hideCounterOfferModal();
          break;
        case "offer-reject":
          optionalProps = {
            user: consumer,
          };
          this.props.updateModalState(true, "OFFER_REJECTED", optionalProps);
          break;
        default:
          alert("Error: Something went wrong");
      }

      let newOffers = offers.filter((offer) => {
        return offer.id !== parseInt(offerId, 10);
      });

      this.setState((state) => ({
        ...state,
        offers: newOffers,
      }));
    }
  };

  hideCounterOfferModal = () => {
    this.setState((state) => ({
      ...state,
      showCounterOfferModal: false,
    }));
  };

  getClientsOffer = (offerId) => {
    let { offers } = this.state;

    let selectedOffer = offers.filter((offer) => {
      return offer.id === parseInt(offerId, 10);
    });

    return selectedOffer[0];
  };

  rowExpanderClick = (event) => {
    this.setState(
      {
        showValidationScreen: true,
        selectedDebt: event.rowData,
      },
      () => {}
    );
  };

  handleMakePaymentClick = (event, debtInfo) => {
    event.preventDefault();
    let props = {
      debtInfo: debtInfo,
      reloadData: this.reloadData,
    };
    this.props.updateModalState(true, "MAKE_PAYMENT", props);
  };

  handleMakeAdjustmentClick = (event, debtInfo) => {
    event.preventDefault();
    let props = {
      debtInfo: debtInfo,
      reloadData: this.reloadData,
    };
    this.props.updateModalState(true, "MAKE_ADJUSTMENT", props);
  };

  reloadData = () => {
    this.getData();
  };

  showAdditionalDetails = (additional_details) => {
    let optionalProps = {
      additional_details: additional_details,
    };
    this.props.updateModalState(true, "NOTES", optionalProps);
  };

  renderLatestPaymentDate = (date) => {
    return moment.utc(date).format("MM/DD/YYYY");
  };

  getObjectListData = (data, tableType) => {
    let headings = [],
      columns = [];

    if (tableType === "review-offers") {
      headings = ["Offer For", "User's Offer", "Original Debt"];

      columns = data.map((offer) => {
        return {
          primaryColumn: {
            title: offer.creditor_name,
          },
          secondaryColumns: [
            {
              title: `$${offer.total_in_dollars_as_currency} (${
                parseFloat(offer.number_of_payments) === 1
                  ? "one time payment"
                  : offer.number_of_payments + " payments"
              })`,
            },
            {
              title: `$${parseFloat(offer.original_debt_in_dollars).toFixed(
                2
              )}`,
            },
          ],
          rowType: "overflowMenu",
          rowActions: [
            {
              title: "Accept Offer",
              optionalClasses: "alert-positive",
              dataBehavior: "offer-accept",
              dataTarget: offer.id,
            },
            {
              title: "Counter Offer",
              dataBehavior: "offer-counter",
              dataTarget: offer.id,
            },
            {
              title: "Reject Offer",
              optionalClasses: "alert",
              dataBehavior: "offer-reject",
              dataTarget: offer.id,
            },
          ],
        };
      });
    } else {
      if (tableType === "closed-debts") {
        headings = [
          "Creditor",
          "Date",
          "Total",
          "Amount Paid",
          "Discount",
          "Balance",
        ];
      } else {
        headings = [
          "Creditor",
          "Date",
          "Total",
          "Amount Paid",
          "Discount",
          "Balance",
          "",
        ];
      }

      columns = data.map((debt) => {
        return {
          primaryColumn: {},
          secondaryColumns:
            debt.status == "settled"
              ? [
                  {
                    title: debt.creditor_name,
                  },
                  {
                    title: debt.created_at
                      ? this.renderLatestPaymentDate(debt.created_at)
                      : "--",
                  },
                  {
                    title: `$${debt.total_in_dollars_as_currency}`,
                  },
                  {
                    title: `$${getNumberWithCommas(
                      (
                        getStrippedDollarAmount(
                          debt.total_in_dollars_as_currency
                        ) -
                        getStrippedDollarAmount(
                          debt.balance_in_dollars_as_currency
                        ) -
                        (debt.adjustment ? debt.adjustment : 0)
                      ).toFixed(2)
                    )}`,
                    optionalClasses: "row-inner-data-utility",
                  },
                  {
                    title: `$${this.getAdjustmentDebt(debt)}`,
                    optionalClasses: "row-inner-data-utility",
                  },
                  {
                    title: `$${parseFloat(0).toFixed(2)}`,
                    optionalClasses: "row-inner-data-utility",
                  },
                ]
              : [
                  {
                    title: debt.creditor_name,
                  },
                  {
                    title: debt.created_at
                      ? this.renderLatestPaymentDate(debt.created_at)
                      : "--",
                  },
                  {
                    title: `$${debt.total_in_dollars_as_currency}`,
                  },
                  {
                    title: `$${getNumberWithCommas(
                      parseFloat(
                        debt.initial_payment ? debt.initial_payment : 0
                      ).toFixed(2)
                    )}`,
                    optionalClasses: "row-inner-data-utility",
                  },
                  {
                    title: (
                      <button
                        className='make-payment-button'
                        onClick={(event) => {
                          this.handleMakeAdjustmentClick(event, debt);
                        }}
                      >
                        $
                        {parseFloat(
                          debt.adjustment ? debt.adjustment : 0
                        ).toFixed(2)}{" "}
                        <img width='10' height='10' src={EditAdjustment} />
                      </button>
                    ),
                    optionalClasses: "row-inner-data-utility",
                  },
                  {
                    title: `$${debt.balance_in_dollars_as_currency}`,
                    optionalClasses: "row-inner-data-utility",
                  },
                  {
                    title:
                      debt.status != "settled" ? (
                        <button
                          className='make-payment-button'
                          onClick={(event) => {
                            this.handleMakePaymentClick(event, debt);
                          }}
                        >
                          Accept Payment
                        </button>
                      ) : null,
                  },
                ],
          rowType: "arrow-click",
          rowData: debt,
        };
      });
    } // End if else

    return {
      headings,
      columns,
    };
  };

  getAdjustmentDebt = (debt) => {
    let adjustment = 0;
    if (debt.adjustment) {
      adjustment =
        parseFloat(
          getStrippedDollarAmount(debt.balance_in_dollars_as_currency)
        ) + parseFloat(debt.adjustment);
    } else {
      adjustment = parseFloat(
        getStrippedDollarAmount(debt.balance_in_dollars_as_currency)
      );
    }
    adjustment = parseFloat(adjustment).toFixed(2);
    return adjustment;
  };

  renderTable = (data, tableType) => {
    let emptyStateMessage = "";
    let tableData = null;

    if (tableType === "review-offers") {
      emptyStateMessage = "No offers to review.";

      tableData = data;
    } else if (tableType === "open-debts") {
      emptyStateMessage = "No Open Debts";

      tableData = data.filter((row) => {
        return row.status === "settled" ? false : true;
      });
    } else if (tableType === "closed-debts") {
      emptyStateMessage = "No Closed Debts";
      tableData = data.filter((row) => {
        return row.status === "settled" ? true : false;
      });
    }

    return tableData.length > 0 ? (
      <ObjectDebtList
        data={this.getObjectListData(tableData, tableType)}
        rowActionClick={(event) => this.handleRowActionClick(event)}
        handleRowExpanderClick={(event) => this.rowExpanderClick(event)}
        hideScrollbar={true}
      />
    ) : (
      <div className='object-list-empty-state'>
        <span className='object-list-empty-state-message'>
          {emptyStateMessage}
        </span>
      </div>
    );
  };

  renderOfferReviewTable = (consumer, offers, tableType) => {
    let emptyStateMessage = "No offers to review.";
    let activeOffers = offers.filter((offer) => {
      return offer.status !== "accepted";
    });

    return activeOffers.length > 0 ? (
      <ObjectList
        optionalClasses='offer-review-table'
        data={this.getObjectListData(activeOffers, tableType)}
        rowActionClick={(event) => this.handleRowActionClick(event)}
        removeExtraSpace={true}
      />
    ) : (
      <div className='object-list-empty-state'>
        <span className='object-list-empty-state-message'>
          {emptyStateMessage}
        </span>
      </div>
    );
  };

  handleHeaderActionClick = (event) => {
    let { history } = this.props;
    const actionBehavior = event.target.getAttribute("data-behavior");
    const consumerId = event.target.getAttribute("data-target");
    const thresholdData = JSON.parse(
      event.target.getAttribute("data-threshold")
    );
    const addDebtData = JSON.parse(event.target.getAttribute("data-debt"));

    switch (actionBehavior) {
      case "account-debt":
        this.renderDebtAddModal([consumerId], thresholdData);
        break;
      case "view-conversation":
        history.push(`/employee/view/conversation/${consumerId}`);
        break;
      case "approve-address":
        history.push(`/employee/accounts/${consumerId}/addressDocument`);
        break;
      case "automatic-negotiation":
        this.renderNegotiationModal([consumerId], thresholdData);
        break;
      case "send-letter":
        this.renderNewLetterModal(consumerId);
        break;
      case "send-mail":
        this.renderSendMailModal(consumerId);
        break;
      default:
        alert("Error: Something went wrong");
    }
  };

  renderDebtAddModal(consumerId, addDebtData) {
    let props = {
      consumerId: consumerId,
      releoadTableData: this.releoadTableData,
      addDebtData: addDebtData,
      context: "employee",
      reloadData: this.reloadData,
    };
    this.props.updateModalState(true, "ADD_CONSUMER_DEBT", props);
  }

  deactivateClientsConsumer = (id) => {
    let consumers = [...this.state.consumers];
    let targetId = parseInt(id, 10);
    let newConsumers = consumers.filter((consumer) => {
      return consumer.id !== targetId;
    });

    if (newConsumers.length < consumers.length) {
      API.deactivateClientsConsumer(this.props.user, id);
      this.setState((state) => ({
        ...state,
        consumers: newConsumers,
      }));
    }
  };

  renderNegotiationModal(consumerId, thresholdData) {
    let props = {
      consumerId: consumerId,
      releoadTableData: this.releoadTableData,
      thresholdData: thresholdData,
    };
    this.props.updateModalState(true, "AUTOMATIC_NEGOTIATION", props);
  }

  releoadTableData = () => {
    //
  };

  renderNewLetterModal(consumerId) {
    let props = {
      consumerId: consumerId,
      type: "Account",
    };
    this.props.updateModalState(true, "SEND_NEW_LETTER", props);
  }

  renderSendMailModal(consumerId) {
    this.startAutomatedMails(consumerId);
  }

  startAutomatedMails(consumerId) {
    if (consumerId) {
      API.startAutomatedMails(this.props.user, consumerId).then((data) => {
        if ((typeof data != "undefined" || data != null) && !data.error) {
          this.props.updateModalState(true, "SUCCESS", {
            message: "Mail send successfully.",
          });
        } else {
          this.setState((state) => ({
            ...state,
            isLoading: false,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.message && data.message == "Access denied."
                    ? 401
                    : 500) ||
                  500
                : 500,
            },
            showLoader: false,
          }));
        }
      });
    }
  }

  handleBackLink = () => {
    this.setState({ showValidationScreen: false });
  };

  render() {
    const { appName, user, history } = this.props;
    const {
      consumer,
      debts,
      isLoading,
      offers,
      selectedDebt,
      showValidationScreen,
      selectedOfferForCounter,
      showCounterOfferModal,
      error,
    } = this.state;

    let backPath = showValidationScreen
      ? `/employee/accounts/${consumer.id}`
      : "/employee/accounts";
    let backText = showValidationScreen
      ? "Back to Account"
      : "Back to All Accounts";

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    const modal = showCounterOfferModal ? (
      <CounterofferModal
        handleOfferActionResponse={this.handleOfferActionResponse}
        user={user}
        offerRecipient={consumer}
        offer={selectedOfferForCounter}
        hideModal={this.hideCounterOfferModal}
        updateModalState={this.props.updateModalState}
      />
    ) : null;

    return isLoading ? (
      <AppCanvasLoader />
    ) : (
      <div className='accounts-view app-sidebar-layout'>
        <Helmet>
          <title>
            {appName} | {consumer.first_name + " " + consumer.last_name}
          </title>
        </Helmet>
        <AppSectionHeader
          title={consumer.first_name + " " + consumer.last_name}
          optionalClassNames='secondary'
          backLink={{
            text: backText,
            path: backPath,
            arrangeLink: true,
          }}
          handleBackLink={this.handleBackLink}
          showHeaderThreeDots={true}
          account={consumer}
          rowActionClick={(event) => this.handleHeaderActionClick(event)}
        />
        <div
          className='scrollbar'
          style={{
            height: window.innerHeight * 0.65,
            overflow: "auto",
            paddingBottom: 50,
          }}
        >
          <div className='app-sidebar-layout-canvas'>
            <EmployeeSidebar
              account={consumer}
              name={consumer.id}
              user={user}
              history={history}
            />
            {showValidationScreen ? (
              <EmployeeUploadValidationDoc
                {...this.props}
                debtData={selectedDebt}
                consumer={consumer}
              />
            ) : (
              <div className='app-sidebar-layout-canvas-content'>
                <h2 className='accounts-view-heading'>Review Offers</h2>
                {this.renderOfferReviewTable(consumer, offers, "review-offers")}
                <h2 className='accounts-view-heading'>Open Debt</h2>
                {this.renderTable(debts, "open-debts")}
                <h2 className='accounts-view-heading'>Closed Debt</h2>
                {this.renderTable(debts, "closed-debts")}
              </div>
            )}
          </div>
        </div>
        {showCounterOfferModal ? modal : ""}
        {showCounterOfferModal ? <div className='curtain'></div> : ""}
      </div>
    );
  }
}

export default EmployeeAccountsView;
